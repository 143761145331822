    import { useReactToPrint } from "react-to-print";
    import { useRef } from "react";

    import Table from 'react-bootstrap/Table';
    import Container from 'react-bootstrap/Container';
    import Row from 'react-bootstrap/Row';
    import Col from 'react-bootstrap/Col';
    import Stack  from 'react-bootstrap/Stack';
    import Image  from 'react-bootstrap/Image';
    import logo from '../../../llogo.jpeg'
    
    const PrintPayments = ({open,onClose,statement}) =>{

    const marginLeft="60px"
    const marginRight="60px"
    const marginTop="10px"
    const marginBottom="10px"
    
    const getPageMargins = () => {
        return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    };

    const componentRef = useRef()

    const handlePrint = useReactToPrint({
    content:()=> componentRef.current,
    documentTitle: 'Payments Transactions',
    onAfterPrint:()=>onClose()
    })

    let today = new Date().toUTCString().slice(5, 16);
    if(!open) return null;
    return(
    <div className='overlay' onClick={onClose} >
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className = "card card-primary" >
        <div className="card-header" >
        <span className='closeModal btn btn-sm btn-dark' onClick={handlePrint} style={{margin:'2px'}} > <span className='fa fa-print' ></span> Print</span>
        </div>
            
        <div className="card-body">

            <div ref={componentRef} statement={statement} style={{width: '100%'}} >
            <style>{getPageMargins()}</style>

            <Container>
            <Row className="mt-5">
            <Col>
            <div>
            <Image src={logo} style={{width:'250px'}} ></Image>
            </div>

            <div className="mt-3" >
            <h6><b>Payments Transactions List</b></h6>
            </div>

            </Col>

            <Col style={{textAlign:'right'}} >
            <Stack gap={1}>
            <div>Northwave Credit Ltd</div>
            <div>P.O BOX 2760 - 00100</div>
            <div>Nairobi, Kenya</div>
            <div>Tel: 0703999944</div>
            <div>Email: info@northwavecredit.com</div>
            <div>Website: www.northwavecredit.com</div>
            </Stack>
            </Col>
            </Row>       

            <Row className="mt-3" >
           
            <Col>
            <div>
            Auto generated on :  {today}       
            </div>
            <Table striped bordered hover className="mt-3" >
            <thead>
            <tr style={{fontSize:'12px'}} >
            <th>#</th>
            <th>Entry date</th>
            <th>Payment date</th>
            <th>Contact</th>
            <th>Amount</th>
            <th>Mode</th>
            <th>Reference</th>
            </tr>
            </thead>
            <tbody>
            
            {statement.map((item,index)=>{
         
            return(
                <tr style={{fontSize:'11px'}} key = {index}>
                <td>{index+1}</td>
                <td>{item.created_at.substr(0,10)}</td>
                <td>{item.entry_date.substr(0,10)}</td>
                <td>{item.first_name} {item.other_name} {item.last_name}</td>
                <td>KES {parseInt(item.amount).toLocaleString('en-US')}</td>
                <td>{item.payment_mode}</td>
                <td>{item.reference}</td>
                </tr>
            )

            })}

            </tbody>
            </Table>

            </Col>
            </Row>

            </Container>
       

        </div>
        </div>

        <div className='card-footer' >        
        </div>
        </div>
        </div>
        </div>
    );
}

export default PrintPayments;