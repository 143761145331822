import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

import IndexGraph from '../elements/charts/IndexGraph';
import LoansDisbursalGraph from '../elements/charts/LoansDisbursalGraph';

function Index(){

    const [principal,setPrincipal] = useState([0,0,0,0,0])
    const [interest,setInterest] = useState([0,0,0,0,0])
    const [charges,setCharges] = useState([0,0,0,0,0])
    const [monthlyDisbursal,setMonthlyDisbursal] = useState([0,0,0,0,0,0,0,0,0,0,0,0])
    const [year,setYear] = useState(new Date().getFullYear())

    const [notifications,setNotifications] = useState({
        mpesa:0,
        manual:0,
        invoices:0
    })

    const ceoNotifications=()=>{
        axios.get(`api/ceo_notifications`).then((res)=>{
            if(res.data.status === 200)
            {
                setNotifications({
                    mpesa: res.data.mpesa,
                    manual: res.data.manual,
                    invoices: res.data.invoices
                })
            }
        })
    }

    const [pipeline,setPipeline] = useState({
    count:0,
    amount:0,
    })

    const [summary,setSummary] = useState({
        arrears:0,
        principal:0,
        interest:0,
        charges:0
    })

    const [tiers,setTiers] = useState({
        tier_one:0,
        tier_two:0,
        tier_three:0,
        tier_four:0,
        tier_five:0
    })

    const [tabs,setTabs] = useState({
        tab_one:0,
        tab_two:0,
        tab_three:0,
        tab_four:0,
        tab_five:0
    })

    const [percentages,setPercentages] = useState({
        charges:0,
        principal:0,
        interest:0
    })

    const [loanrates,setRates] = useState({
        active:0,
        closed:0,
        defaulted:0
    })

    const [stats,setStats] = useState({
        all:0,
        closed:0,
        current:0,
        defaulted:0
    })

    const [loan_officer,setLoanOfficer] = useState({
    count:0,
    percent:0,
    amount:0
    })
    
    const [hcs,setHCS] = useState({
    count:0,
    percent:0,
    amount:0
    })

    const [finance,setFinance] = useState({
    count:0,
    percent:0,
    amount:0
    })

    const [ceo,setCEO] = useState({
    count:0,
    percent:0,
    amount:0
    })

    const [pending,setPending] = useState({
    count:0,
    percent:0,
    amount:0
    })

    const [rejected,setRejected] = useState({
    count:0,
    percent:0,
    })

    const fetchDistribution=(year)=>{
        axios.get(`api/disbursal_distribution/${year}`).then((res)=>{
            if(res.data.status === 200)
            {
                setMonthlyDisbursal(res.data.months)
            }
        })
    }

    const effectRun = useRef(false)

    const fetchData=()=>{
    
    axios.post(`api/arrears_breakdown`).then((res)=>{

        if(res.data.status === 200)
        {
            setCharges(res.data.charges)
            setPrincipal(res.data.principal)
            setInterest(res.data.interest)
            setTiers(res.data.tiers)
            setTabs(res.data.tabs)
            setSummary(res.data.summary)
            setPercentages(res.data.percentages)
            setStats(res.data.stats)
            setRates(res.data.loan_rates)
            setLoanOfficer(res.data.loan_officer)
            setHCS(res.data.hcs)
            setFinance(res.data.finance)
            setCEO(res.data.ceo)
            setPending(res.data.pending)
            setRejected(res.data.rejected)
            setPipeline(res.data.pipeline)
        }

    })

    }

    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchData()
        fetchDistribution(year)
        ceoNotifications()
    }

    return()=>{
    effectRun.current = true
    }

    })

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>Dashboard</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                ...
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                               
                <div className='card card-primary'>
                
                <div className='card-body'>
                
                <div className='row'>

                <div className="col-12 col-sm-6 col-md-3">

                <div className="info-box" style={{backgroundColor:'#01153e',color:'white'}}>
                <div className="info-box-content">
                <span className="info-box-text"><Link to="/loans" style={{color:'white'}} ><span className='fa fa-chart-line' ></span> All Loans </Link>  <span className='float-right'> <small>100.00 %</small> </span> </span>
                <span className="info-box-number">
                <small>KES {parseInt(stats.all).toLocaleString('en-US')}</small>
                </span>
                </div>
                </div>

                </div>

                <div className="col-12 col-sm-6 col-md-3">

                <div className="info-box" style={{backgroundColor:'#051040',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text"><span className='fa fa-chart-line' ></span> Current Loans  <span className='float-right'> <small>{loanrates.active} %</small> </span></span>
                <span className="info-box-number">
                <small>KES {parseInt(stats.current).toLocaleString('en-US')}</small>
                </span>
                </div>
                </div>

                </div>

                <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box" style={{backgroundColor:'#051040',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text"><span className='fa fa-chart-line' ></span> In Default </span>
                <span className="info-box-number">
                <small>KES {parseInt(stats.defaulted).toLocaleString('en-US')}</small>
                </span>
                </div>
                </div>
                </div>

                <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box" style={{backgroundColor:'#051040',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text"><span className='fa fa-coins' ></span> Arrears <span className="float-right"> <small>100.00 %</small> </span></span>
                <span className="info-box-number">
                <small>KES {summary.arrears}</small>
                </span>
                </div>
                </div>
                </div>
                
                </div>

                <div className='row'>
                
                <div className="col-12 col-sm-6 col-md-4">

                <div className='card card-danger card-outline'>

                <div className='card-header'>
                <h6> <span className='fa fa-bell'></span> Notifications</h6>
                </div>

                <div className='card-body'>
                
                <div className="info-box" >
                <div className="info-box-content">
                <span className="info-box-text" style={{padding:'3px'}} ><Link to='/mpesa' style={{color:'black',textDecoration:'none'}} ><span className='fa fa-spinner'></span> Pending Mpesa payments</Link></span>
                <span className="info-box-number">
                <small class="badge badge-danger" >{notifications.mpesa}</small>
                </span>
                </div>
                </div>

                <div className="info-box" >
                <div className="info-box-content">
                <span className="info-box-text" style={{padding:'3px'}} > <Link to='/payments' style={{color:'black',textDecoration:'none'}} ><span className='fa fa-spinner'></span> Pending manual payments</Link> </span>
                <span className="info-box-number">
                <small class="badge badge-danger" >{notifications.manual}</small>
                </span>
                </div>
                </div>

                <div className="info-box" >
                <div className="info-box-content">
                <span className="info-box-text" style={{padding:'3px'}} > <Link to='/payments' style={{color:'black',textDecoration:'none'}} ><span className='fa fa-spinner'></span> Pending charges</Link> </span>
                <span className="info-box-number">
                <small class="badge badge-danger" >{notifications.invoices}</small>
                </span>
                </div>
                </div>

                </div>

                </div>

                <div className='card card-dark card-outline'>
                <div className='card-header'>
                <h6> <span className='fa fa-user-shield'></span> Loans approval pipeline</h6>
                </div>
                <div className='card-body'>

                <div className="info-box" style={{backgroundColor:'#212121',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text" style={{padding:'3px'}} ><span className='fa fa-user-shield'></span> Loan officers <span className='float-right'><small>{loan_officer.percent} %</small></span> </span>
                <span className="info-box-number">
                <small>KES {parseInt(loan_officer.amount).toLocaleString('en-US')}</small>
                <span style={{display:'block'}}> <small>{loan_officer.count} Loan (s)<span className='float-right'><span className='fa fa-arrow-down'></span></span> </small></span>
                </span>
                </div>
                </div>

                <div className="info-box" style={{backgroundColor:'#212121',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text" style={{padding:'3px'}} ><span className='fa fa-user-shield'></span> HCS <span className='float-right'> <small>{hcs.percent} %</small> </span> </span>
                <span className="info-box-number">
                <small>KES {parseInt(hcs.amount).toLocaleString('en-US')}</small>
                <span style={{display:'block'}}> <small>{hcs.count} Loan (s)<span className='float-right'><span className='fa fa-arrow-down'></span></span> </small></span>
                </span>
                </div>
                </div>

                <div className="info-box" style={{backgroundColor:'#212121',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text" style={{padding:'3px'}}  >
                <span className='fa fa-user-shield'></span> Finance <span className='float-right'> <small>{finance.percent} %</small> </span> </span>
                <span className="info-box-number">
                <small>KES {parseInt(finance.amount).toLocaleString('en-US')}</small>
                <span style={{display:'block'}}> <small>{finance.count} Loan (s)<span className='float-right'><span className='fa fa-arrow-down'></span></span> </small></span>
                </span>
                </div>
                </div>

                <div className="info-box" style={{backgroundColor:'#212121',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text"  style={{padding:'3px'}}  >
                <span className='fa fa-user-shield'></span> CEO <span className='float-right'> <small> {ceo.percent} % </small> </span> </span>
                <span className="info-box-number">
                <small>KES {ceo.amount}</small>
                <span style={{display:'block'}}> <small>{ceo.count} Loan (s)<span className='float-right'><span className='fa fa-arrow-down'></span></span> </small></span>
                </span>
                </div>
                </div>

                <div className="info-box" style={{backgroundColor:'#212121',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text"  style={{padding:'3px'}}  ><Link to="/approved" style={{color:'white'}} ><span className='fa fa-check-circle'></span> Pending Disbursal </Link> <span className='float-right'><small>{pending.percent} %</small></span> </span>
                <span className="info-box-number">
                <small>KES {parseInt(pending.amount).toLocaleString('en-US')}</small>
                <span style={{display:'block'}}> <small>{pending.count} Loan (s)</small></span>
                </span>
                </div>
                </div>

                <div className="info-box" style={{backgroundColor:'#212121',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text" style={{padding:'3px'}} ><span className='fa fa-chart-pie'></span> Pipeline Value </span>
                <span className="info-box-number">
                <small>KES {parseInt(pipeline.amount).toLocaleString('en-US')}</small>
                <span style={{display:'block'}}> <small>{pipeline.count} Loan (s)</small></span>
                </span>
                </div>
                </div>

                <div className="info-box" style={{backgroundColor:'#212121',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text"  style={{padding:'3px'}}  ><span className='fa fa-times-circle'></span> Rejected Loans <span className='float-right'><small>{rejected.percent} %</small> </span> </span>
                <span className="info-box-number">
                <span style={{display:'block'}}> <small>{rejected.count} Loan (s)</small></span>
                </span>
                </div>
                </div>

                </div>
                </div>

                </div>

                <div className="col-12 col-sm-6 col-md-8">
                <div className='card card-primary card-outline'>

                <div className='card-header'>
                <h6> <span className='fa fa-chart-bar'></span> Loan Arrears Aging Overview</h6>
                </div>

                <div className='card-body'>

                <div className='row'>
                <div className='col-md-12'>

                <IndexGraph charges={charges}  />

                </div>
                </div>

                <div className='row' style={{marginTop:'40px'}}>
                
                <div className="col-12 col-sm-6 col-md-4">

                <div className="info-box" style={{backgroundColor:'#01153e',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text">0 - 30 days <span className='float-right'><small>{tabs.tab_one} %</small></span> </span>
                <span className="info-box-number">
                <small>KES {parseInt(tiers.tier_one).toLocaleString('en-US')}</small>
                <span style={{display:'block'}}><small><span className='fa fa-exclamation-circle'></span> Normal</small></span>
                </span>
                </div>
                </div>
                </div>

                <div className="col-12 col-sm-6 col-md-4">
                <div className="info-box" style={{backgroundColor:'#01153e',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text">31 - 90 days <span className='float-right'><small>{tabs.tab_two} %</small></span> </span>
                <span className="info-box-number">
                <small>KES {parseInt(tiers.tier_two).toLocaleString('en-US')}</small>
                <span style={{display:'block'}}> <small><span className='fa fa-exclamation-circle'></span> Watch</small></span>
                </span>
                </div>
                </div>
                </div>

                <div className="col-12 col-sm-6 col-md-4">
                <div className="info-box" style={{backgroundColor:'#01153e',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text">91 - 180 days <span className='float-right'><small>{tabs.tab_three} %</small></span> </span>
                <span className="info-box-number">
                <small>KES {parseInt(tiers.tier_three).toLocaleString('en-US')}</small>
                <span style={{display:'block'}}> <small><span className='fa fa-exclamation-circle'></span> Substancial</small></span>
                </span>
                </div>
                </div>
                </div>

                <div className="col-12 col-sm-6 col-md-4">
                <div className="info-box" style={{backgroundColor:'#01153e',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text">181 - 360 days <span className='float-right'><small>{tabs.tab_four} %</small></span> </span>
                <span className="info-box-number">
                <small>KES {parseInt(tiers.tier_four).toLocaleString('en-US')}</small>
                <span style={{display:'block'}}> <small><span className='fa fa-exclamation-circle'></span> Doubtful</small></span>
                </span>
                </div>
                </div>
                </div>

                <div className="col-12 col-sm-6 col-md-4">
                <div className="info-box" style={{backgroundColor:'#01153e',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text">361 days > <span className='float-right'><small>{tabs.tab_five} %</small></span> </span>
                <span className="info-box-number">
                <small>KES {parseInt(tiers.tier_five).toLocaleString('en-US')}</small>
                <span style={{display:'block'}}><small><span className='fa fa-exclamation-circle'></span> Loss</small></span>
                </span>
                </div>
                </div>
                </div>

                </div>

                </div>

                <div className='card-footer'></div>

                </div>

                <div className='card card-primary card-outline'>
                <div className='card-header'>
                <h6>Annual disbursment overview</h6>
                </div>
                <div className='card-body'>
                <select className='form-control'>
                <option>2023</option>
                </select>
                <LoansDisbursalGraph monthlyDisbursal={monthlyDisbursal}></LoansDisbursalGraph>
                </div>
                <div className='card-footer'></div>
                </div>

                </div>

                </div>

                </div>

                </div>

                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default Index;