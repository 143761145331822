import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import Cards from '../elements/cards/Cards';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import PaydayDisbursalGraph from '../elements/charts/PaydayDisbursalGraph';
import IndexGraph from '../elements/charts/IndexGraph';

const Payday=()=>{

const effectRun = useRef(false);
const [monthlyDisbusal,setMonthlyDisbursal] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
const [year,setYear] = useState(new Date().getFullYear())
const navigate = useNavigate()

const [principal,setPrincipal] = useState([0,0,0,0,0])
const [interest,setInterest] = useState([0,0,0,0,0])
const [charges,setCharges] = useState([0,0,0,0,0])
const [tiers,setTiers] = useState([])

const [stats,setStats] = useState({
staff:0,
loans:0,
organizations:0
})

const [analysis,setAnalysis] = useState({})

const fetchStats=()=>{

axios.post(`api/payday_stats`).then((res)=>{

if(res.data.status === 200)
{
    setStats(res.data.stats)
}

}).catch(error=>{
    if(error == "AxiosError: Request failed with status code 403")
    {
      navigate('/unauthorized');
    }
})

}

const fetchAnalysis=()=>{

axios.post(`api/payday_analysis`).then((res)=>{

    if(res.data.status === 200)
    {
        setAnalysis(res.data.analysis)
    }

}).catch(error=>{
    if(error == "AxiosError: Request failed with status code 403")
    {
      navigate('/unauthorized');
    }
})

}

    const fetchDisbursments=(year)=>{

        axios.get(`api/payday_disbursal_graph/${year}`).then((res)=>{
            if(res.data.status === 200)
            {
                setMonthlyDisbursal(res.data.disbursments)
            }
        })
    }

    const fetchAging=()=>{
        axios.post(`api/payday_aging`).then((res)=>{
            
            if(res.data.status === 200)
            {
                setPrincipal(res.data.principal)
                setInterest(res.data.interest)
                setCharges(res.data.charges)
                setTiers(res.data.tiers)
            }

        }).catch(error=>{
            if(error == "AxiosError: Request failed with status code 403")
            {
              navigate('/unauthorized');
            }
        })
    }

    useEffect(()=>{
        if(effectRun.current === false)
        {
            fetchStats()
            fetchAnalysis()
            fetchDisbursments(year)
            fetchAging()

            return()=>{
                effectRun.current = true
            }
        }
    },[])

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>Payday</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >
                
                <div className='row'>
                
                <div className='col-md-12'>
                <div className='card'>
                <div className='card-body'>

                <div className='row'>
                
                <div className="col-12 col-sm-6 col-md-3">

                <div className="info-box" style={{backgroundColor:'#051040',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text"><Link to='/organizations' > <span style={{color:'white',padding:'3px'}}><span className='fa fa-landmark' ></span> Organizations</span> </Link> <span className="float-right"> <small></small> </span></span>
                <span className="info-box-number">
                <small>{stats.organizations}</small>
                </span>
                </div>
                </div>

                </div>

                <div className="col-12 col-sm-6 col-md-3">

                <div className="info-box" style={{backgroundColor:'#051040',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text"> <Link to='/payday_staff' > <span style={{color:'white'}}> <span className='fa fa-users' ></span> Employees  </span> </Link> </span>
                <span className="info-box-number">
                <small>{stats.staff}</small>
                </span>
                </div>
                </div>

                </div>

                <div className="col-12 col-sm-6 col-md-3">

                <div className="info-box" style={{backgroundColor:'#051040',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text"><Link to='/payday_loans' > <span style={{color:'white'}} > <span className='fa fa-chart-bar' ></span> All Loans </span> </Link> <span className="float-right"> <small>{stats.loans}</small> </span></span>
                <span className="info-box-number">
                <small>KES {parseInt(analysis.total_loans).toLocaleString('en-US')}</small>
                </span>
                </div>
                </div>

                </div>

                <div className="col-12 col-sm-6 col-md-3">

                <div className="info-box" style={{backgroundColor:'#051040',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text"><Link to='/payday_loans' > <span style={{color:'white'}} > <span className='fa fa-chart-line' ></span> Active Loans </span> </Link> <span className="float-right"> <small>{stats.loans}</small> </span></span>
                <span className="info-box-number">
                <small>KES {parseInt(analysis.active_loans).toLocaleString('en-US')}</small>
                </span>
                </div>
                </div>

                </div>                

                <div className="col-12 col-sm-6 col-md-3">

                <div className="info-box" style={{backgroundColor:'#051040',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text"><span className='fa fa-coins' ></span> Arrears <span className="float-right"> <small>100.00 %</small> </span></span>
                <span className="info-box-number">
                <small>KES {parseInt(analysis.arrears).toLocaleString('en-US')}</small>
                </span>
                </div>
                </div>

                </div>

                <div className="col-12 col-sm-6 col-md-3">

                <div className="info-box" style={{backgroundColor:'#051040',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text"><span className='fa fa-coins' ></span> Principal <span className="float-right"> <small>{analysis.principal_percent} %</small> </span></span>
                <span className="info-box-number">
                <small>KES {parseInt(analysis.unpaid_principal).toLocaleString('en-US')}</small>
                </span>
                </div>
                </div>

                </div>

                <div className="col-12 col-sm-6 col-md-3">

                <div className="info-box" style={{backgroundColor:'#051040',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text"><span className='fa fa-coins' ></span> Interest <span className="float-right"> <small>{analysis.interest_percent} %</small> </span></span>
                <span className="info-box-number">
                <small>KES {parseInt(analysis.unpaid_interest).toLocaleString('en-US')}</small>
                </span>
                </div>
                </div>

                </div>

                <div className="col-12 col-sm-6 col-md-3">

                <div className="info-box" style={{backgroundColor:'#051040',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text"><span className='fa fa-coins' ></span> Charges <span className="float-right"> <small>{analysis.charges_percent} %</small> </span></span>
                <span className="info-box-number">
                <small>KES {parseInt(analysis.unpaid_charges).toLocaleString('en-US')}</small>
                </span>
                </div>
                </div>

                </div>

                </div>

                </div>
                </div>
                </div>

                </div>

                <div className='row'>
                
                <div className="col-md-4">

                <div className='card card-primary card-outline'>
                <div className='card-header'>
                 <span className='fa fa-user-shield' ></span> Loans Approval Pipeline
                </div>
                <div className='card-body'>

                <div className="info-box" style={{backgroundColor:'#212121',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text" style={{padding:'3px'}} ><span className='fa fa-user-shield'></span> Account manager <span className='float-right'><small>0.00 %</small></span> </span>
                <span className="info-box-number">
                <small>KES 0.00</small>
                <span style={{display:'block'}}> <small>0 Loan (s)<span className='float-right'><span className='fa fa-arrow-down'></span></span> </small></span>
                </span>
                </div>
                </div>

                <div className="info-box" style={{backgroundColor:'#212121',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text" style={{padding:'3px'}} ><span className='fa fa-user-shield'></span> Finance <span className='float-right'><small>0.00 %</small></span> </span>
                <span className="info-box-number">
                <small>KES 0.00</small>
                <span style={{display:'block'}}> <small>0 Loan (s)<span className='float-right'><span className='fa fa-arrow-down'></span></span> </small></span>
                </span>
                </div>
                </div>

                <div className="info-box" style={{backgroundColor:'#212121',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text" style={{padding:'3px'}} ><span className='fa fa-user-shield'></span> C.E.O <span className='float-right'><small>0.00 %</small></span> </span>
                <span className="info-box-number">
                <small>KES 0.00</small>
                <span style={{display:'block'}}> <small>0 Loan (s)<span className='float-right'><span className='fa fa-arrow-down'></span></span> </small></span>
                </span>
                </div>
                </div>

                <div className="info-box" style={{backgroundColor:'#212121',color:'white'}} >
                <div className="info-box-content">
                <Link to="/pending_disbursal"> <span className="info-box-text" style={{padding:'3px',color:'white'}} ><span className='fa fa-check-circle'></span> Pending Disbursal <span className='float-right'><small>0.00 %</small></span> </span></Link>
                <span className="info-box-number">
                <small>KES 0.00</small>
                <span style={{display:'block'}}> <small>0 Loan (s)<span className='float-right'><span className='fa fa-arrow-down'></span></span> </small></span>
                </span>
                </div>
                </div>

                <div className="info-box" style={{backgroundColor:'#212121',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text" style={{padding:'3px'}} ><span className='fa fa-chart-pie'></span> Pipeline Value <span className='float-right'><small>0.00 %</small></span> </span>
                <span className="info-box-number">
                <small>KES 0.00</small>
                <span style={{display:'block'}}> <small>0 Loan (s)<span className='float-right'><span className='fa fa-arrow-down'></span></span> </small></span>
                </span>
                </div>
                </div>

                <div className="info-box" style={{backgroundColor:'#212121',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text" style={{padding:'3px'}} ><span className='fa fa-times-circle'></span> Rejected Loans <span className='float-right'><small>0.00 %</small></span> </span>
                <span className="info-box-number">
                <small>KES 0.00</small>
                <span style={{display:'block'}}> <small>0 Loan (s)<span className='float-right'><span className='fa fa-arrow-down'></span></span> </small></span>
                </span>
                </div>
                </div>

                </div>
                </div>

                </div>
                
                <div className="col-md-8">

                <div className='card card-primary card-outline'>
                <div className='card-header'>
                <h6>Annual Payday Disbursal Overview</h6>
                </div>
                <div className='card-body' >
               <PaydayDisbursalGraph monthlyDisbursal={monthlyDisbusal}></PaydayDisbursalGraph>
                </div>
                </div>

                <div className='card card-primary card-outline'>
                    <div className='card-header'>
                    <h6>Payday Aging Overview</h6>
                    </div>
                    <div className='card-body'>
                    <IndexGraph charges={charges} interest={interest} principal={principal} />

                    <div className='row mt-5'>
                    
                    <div className="col-12 col-sm-6 col-md-4">
                    <div className="info-box" style={{backgroundColor:'#01153e',color:'white'}} >
                    <div className="info-box-content">
                    <span className="info-box-text">0 - 30 days <span className='float-right'><small> %</small></span> </span>
                    <span className="info-box-number">
                    <small>KES {parseInt(tiers.tier_one).toLocaleString('en-US')} </small>
                    <span style={{display:'block'}}><small><span className='fa fa-exclamation-circle'></span> Normal</small></span>
                    </span>
                    </div>
                    </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                    <div className="info-box" style={{backgroundColor:'#01153e',color:'white'}} >
                    <div className="info-box-content">
                    <span className="info-box-text">31 - 90 days <span className='float-right'><small> %</small></span> </span>
                    <span className="info-box-number">
                    <small>KES {parseInt(tiers.tier_two).toLocaleString('en-US')} </small>
                    <span style={{display:'block'}}><small><span className='fa fa-exclamation-circle'></span> Watch</small></span>
                    </span>
                    </div>
                    </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                    <div className="info-box" style={{backgroundColor:'#01153e',color:'white'}} >
                    <div className="info-box-content">
                    <span className="info-box-text">91 - 180 days <span className='float-right'><small> %</small></span> </span>
                    <span className="info-box-number">
                    <small>KES {parseInt(tiers.tier_three).toLocaleString('en-US')} </small>
                    <span style={{display:'block'}}><small><span className='fa fa-exclamation-circle'></span> Substancial</small></span>
                    </span>
                    </div>
                    </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                    <div className="info-box" style={{backgroundColor:'#01153e',color:'white'}} >
                    <div className="info-box-content">
                    <span className="info-box-text">181 - 360 days <span className='float-right'><small> %</small></span> </span>
                    <span className="info-box-number">
                    <small>KES {parseInt(tiers.tier_four).toLocaleString('en-US')} </small>
                    <span style={{display:'block'}}><small><span className='fa fa-exclamation-circle'></span> Doubtful</small></span>
                    </span>
                    </div>
                    </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                    <div className="info-box" style={{backgroundColor:'#01153e',color:'white'}} >
                    <div className="info-box-content">
                    <span className="info-box-text">361 days > <span className='float-right'><small> %</small></span> </span>
                    <span className="info-box-number">
                    <small>KES {parseInt(tiers.tier_five).toLocaleString('en-US')} </small>
                    <span style={{display:'block'}}><small><span className='fa fa-exclamation-circle'></span> Loss</small></span>
                    </span>
                    </div>
                    </div>
                    </div>

                    </div>

                    </div>
                    </div>

                    </div>

                    </div>

                    </div>
                    </div>

                    </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default Payday;