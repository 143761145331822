

const PaydayNext=({processStage,stage})=>{

if(stage < 3)
{
    return (<span className='btn btn-primary btn-sm' style={{float:'right',width:'100px'}} onClick={processStage} > Next <span className="fa fa-arrow-right"></span></span>)
}
else if(stage === 3)
{
    return (<span className='btn btn-success btn-sm' style={{float:'right',width:'100px'}} onClick={processStage} ><span className="fa fa-check-circle"></span> Complete </span>)
}

}
export default PaydayNext;