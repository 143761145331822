import { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios"; 
import swal from "sweetalert";


const OrganizationDocuments=({documents,setCreateDocument,fetchDetails})=>{

    const [open,setOpen] = useState(false)
    const [id,setId] = useState('')

    const closeDialogue=()=>{
        setOpen(false)
        setId('')
    }

    const deleteDocument=()=>{
    
        const data = {
        id: id
        }
    
        axios.post(`api/delete_orgDoc`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
            fetchDetails()
            swal("Success",res.data.message,'success')
            closeDialogue()
        }
    
        })
    
        }

    const DownloadFile=(id,name)=>{

        const data = {
            id: id,
        }
    
        axios({
            url: 'api/download_orgDocs', 
            method: 'POST',
            data: data,
            responseType: 'blob', // important
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
          });

    }

    const initialize=(id)=>{
            setId(id)
            setOpen(true)
    }

return(
    <>

    <Modal
    show={open}
    onHide={closeDialogue}
    backdrop="static"
    keyboard={false}
    size="lg"
    >
    <Modal.Header closeButton>
    <Modal.Title>
    <span className="fa fa-trash"></span> Are you sure ?
    </Modal.Title>
    </Modal.Header>
    
    <Modal.Body>
    
    <span className="btn btn-success btn-sm m-1" onClick={deleteDocument}><span className="fa fa-check-circle"></span > Yes</span>
    <span className="btn btn-danger btn-sm m-1" onClick={closeDialogue} ><span className="fa fa-times-circle"></span > No</span>
    
    </Modal.Body>
    
    <Modal.Footer>
    </Modal.Footer>
    
    </Modal>

    <div className="card card-primary card-outline">

    <div className="card-header">
    <span className="fa fa-folder"></span> Documents
    <span className="btn btn-primary btn-sm" style={{float:'right'} } onClick={()=>setCreateDocument(true)} ><span className="fa fa-upload"></span> Upload Document</span>
    </div>

    <div className="card-body">
    
    <table className="table">
    <thead>
    <tr className="small-row">
    <th>#</th>
    <th>Document</th>
    <th>Scan</th>
    <th style={{color:'green'}} ><span className="fa fa-download"></span></th>
    <th style={{color:'red'}}><span className="fa fa-trash"></span></th>
    </tr>
    </thead>

    <tbody>
    {documents.map((item,index)=>{
    return(
    <tr className="small-row" key={index} >
        <td>{index+1}</td>
        <td>{item.document}</td>
        <td>{item.scan}</td>
        <td ><span style={{color:'green'}} onClick={()=>DownloadFile(item.id,item.scan)} ><span className="fa fa-download"></span></span></td>
        <td><span style={{color:'red'}} onClick={()=>initialize(item.id)} ><span className="fa fa-trash"  ></span></span></td>
    </tr>
    )
    })}
    </tbody>

    </table>

    </div>

    <div className="card-footer">
    </div>

    </div>

    </>
)

}

export default OrganizationDocuments;