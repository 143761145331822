import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

function RejectedLoans(){

    const [rejected,setRejected] = useState([])

    // Fetch pending loans 

    const fetchRejected=()=>{
    
    axios.post(`api/rejected_loans`).then((res)=>{

        if(res.data.status === 200)
        {
            setRejected(res.data.rejected)
        }
    })

    }

    useEffect(()=>{
    fetchRejected()
    },[])

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5> <span className='fas fa-layer-group'></span> Rejected Loans</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='row'>
                <div className='col-md-12'>

                <div className='card card-primary card-outline'>
            <div className='card-body'>

            <table className='table'>
            <thead>
            <tr>
            <th>#</th>
            <th>Loan #</th>
            <th>Client</th>
            <th>Loan Type</th>
            <th>Amount </th>
            <th>Period</th>
            <th>Interest</th>
            <th>Stage</th>
            </tr>
            </thead>

            <tbody>
            {rejected.map((loan,index)=>{
            return(
                <tr key={index}>
                <td>{index+1}</td>
                <td><Link to={`/loan/${loan.id}`}><span style={{color:'blue'}} >{loan.loan_code}</span></Link></td>
                <td>{loan.first_name} {loan.last_name}</td>
                <td>{loan.type}</td>
                <td>KES {parseInt(loan.amount_loanable).toLocaleString('en-US')}</td>
                <td>{loan.period} Months</td>
                <td>{loan.interest_rate} %</td>
                <td>{loan.approval}</td>
                
                </tr>
            )
            })}
            </tbody>

            </table>

            </div>
            </div>

                </div>
                </div>

                </div>
                </div>

                </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default RejectedLoans;