const CustomerLoans=({startLoan,loans,viewLoan})=>{

return(

<div className="card card-outline card-primary" >
<div className="card-header">
<h5>
<span className="fa fa-file" ></span> Loans
<span className="btn btn-primary btn-sm" style={{float:'right'}} onClick={()=>startLoan()} > <span className="fa fa-plus" ></span> Apply Loan</span>
</h5>
</div>
<div className="card-body">

<table className="table" >
<thead>
<tr style={{fontSize:'14px'}} >
<th>#</th>
<th>Loan No</th>
<th>Date</th>
<th>Amount </th>
<th>Loan Type</th>
<th>Rate (%)</th>
<th>Period</th>
<th>Status</th>
</tr>
</thead>

<tbody>
{loans.map((item,index)=>{
return(
<tr className="small-row" key={index} >
<td>{index+1}</td>
<td><span style={{color:'blue'}} onClick={()=>viewLoan(item.id)} >{item.loan_code}</span></td>
<td>{item.day_applied}/{item.month_applied}/{item.year_applied}</td>
<td>KES {parseFloat(item.amount_loanable).toLocaleString('en-US')}</td>
<td>{item.type}</td>
<td>{item.interest_rate} %</td>
<td>{item.period} Months</td>
<td>{item.approval}</td>
</tr>
)
})}
</tbody>

</table>

</div>
<div className="card-footer">
</div>
</div>

)

}

export default CustomerLoans;