const AddKpi=({open,onClose,missingKpis,AssignKpi})=>{
    if(!open) return null;

    return(
        <div className='overlay' onClick={onClose}>
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

            <div className="card card-primary card">
                <div className="card-header">
                <h4>Add Kpi</h4>
                </div>

                <div className="card-body">

                <table className="table">
                    <thead>
                        <tr className="small-row">
                            <td>#</td>
                            <td>Kpi</td>
                            <td>Description</td>
                            <td><span className="fa fa-plus-circle"></span> Add Kpi</td>
                        </tr>
                    </thead>
                    <tbody>
                    {missingKpis.map((item,index)=>{
                    return(
                    <tr className="small-row">
                        <td>{index+1}</td>
                        <td>{item.kpi}</td>
                        <td>{item.description}</td>
                        <td> <span className="btn btn-xs btn-primary" onClick={()=>AssignKpi(item.id)} ><span className="fa fa-plus-circle"></span> Add Kpi</span> </td>
                    </tr>
                    )
                    })}
                    </tbody>
                </table>

                </div>

                <div className="card-footer"></div>
            </div>

        </div>
        </div>
    )

}
export default AddKpi;