import user_image from './../../../img/user1-128x128.jpg';

function CardHeader(props)
{
    return(
        <div className="widget-user-header bg-warning">
        <div className="widget-user-image">
        <img className="img-circle elevation-2" src={user_image}  />
        </div>
                
        <h3 className="widget-user-username">{localStorage.getItem('auth_name')}</h3>
        <h5 className="widget-user-desc">{localStorage.getItem('auth_role')}</h5>
        </div>
    );
}

export default CardHeader;