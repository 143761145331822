import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

import FetchLoanStage from "./FetchLoanStage";
import Previous from './Previous';
import NextLoan from './NextLoan';
import LoanHeader from './LoanStages/LoanHeader';

const NewLoan=({newLoanFlag,closeLoan,loanProducts,loanTypes,vehicles,loanInitials,handleLoanInitials,setLoanInitials,loanDocsInitial,setLoanDocsInitial,clearLoanInitials,checks,checkStatus,users,profile})=>{

const [stage,setStage] = useState(0)
const [loanDocuments,setLoanDocuments] = useState([])

const previousPage=()=>{
setStage(stage-1)
}

const nextPage=()=>{

if(stage === 0)
{
    if(checkStatus === "Passed")
    {
        setStage(stage+1)
    }
    else{
        swal("Warning","Ensure you meet the minimum requirement checks","warning")
    }
    
}

if(stage === 1)
{
    saveLoan()
}

if(stage===2)
{
   closeLoan()
}

}

const clearDocuments=()=>{

    loanDocsInitial.document = ''
    loanDocsInitial.scan = null
    
    }

const saveLoan=()=>{

const data = {
loan_id: loanInitials.loan_id,
customer: loanInitials.customer,
loan_product: loanInitials.loan_product,
loan_type: loanInitials.loan_type,
amount_loanable: loanInitials.amount_loanable,
interest_rate: loanInitials.interest_rate,
period: loanInitials.period,
collateral: loanInitials.collateral,
loan_officer: loanInitials.loan_officer,
created_by: loanInitials.created_by,
}

axios.post(`api/create_loan`,data).then((res)=>{
    if(res.data.status === 200)
    {
        swal("Success",res.data.message,"success")
        setStage(stage+1)

        loanInitials.loan_id = res.data.loan_id
        loanDocsInitial.loan = res.data.loan_id
        loanDocsInitial.customer = data.customer
    }
    else{
        setLoanInitials({...loanInitials, error_list: res.data.validation_errors})
    }
})

}

const handleDocument=(e)=>{
e.persist()
setLoanDocsInitial({...loanDocsInitial, [e.target.name]: e.target.value })
}

const handleScan=(e)=>{
e.persist()
setLoanDocsInitial({...loanDocsInitial, [e.target.name]: e.target.files[0] })
}

const uploadDocument=()=>{

if(loanDocsInitial.scan === null)
{
    swal("Warning","You must select a file upload","warning")
}
else
{

    const formData = new FormData()
    formData.append('loan',loanDocsInitial.loan)
    formData.append('customer',loanDocsInitial.customer)
    formData.append('created_by',loanDocsInitial.created_by)
    formData.append('document',loanDocsInitial.document)
    formData.append('scan',loanDocsInitial.scan,loanDocsInitial.scan.name)
    
    axios.post(`api/loan_upload`,formData).then((res)=>{
    
    if(res.data.status === 200)
    {
        swal("Success",res.data.message,"success")
        setLoanDocsInitial({...loanDocsInitial, document:''})
        setLoanDocsInitial({...loanDocsInitial, scan:null})
        fetchDocuments()
    }
    else
    {
        setLoanDocsInitial({...loanDocsInitial, error_list: res.data.validation_errors})
    }
    
    })


}

}

const fetchDocuments=()=>{

if(loanDocsInitial.loan === "na")
{
}
else
{
    const data = {
        loan:loanDocsInitial.loan
    }
    
    axios.post(`api/loan_documents`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        setLoanDocuments(res.data.documents)
    }

    })
}

}

if(!newLoanFlag) return false;

return(
<div className='overlay' onClick={closeLoan} >
<div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

<div className="card card-primary card-default">

<div className="card-header">
<div className='row' >
<LoanHeader stage={stage} />
</div>
</div>

<div className="card-body" >
<FetchLoanStage stage={stage} loanProducts={loanProducts} loanTypes={loanTypes} vehicles={vehicles} loanInitials={loanInitials} handleLoanInitials={handleLoanInitials} handleDocument={handleDocument} handleScan={handleScan} loanDocsInitial={loanDocsInitial} uploadDocument={uploadDocument} loanDocuments={loanDocuments} fetchDocuments={fetchDocuments} checks={checks} checkStatus={checkStatus} users={users} profile={profile} />
</div>

<div className="card-footer" >

<Previous stage={stage} previousPage={previousPage} />
<NextLoan stage={stage} nextPage={nextPage} />

</div>

</div>

</div>
</div>
)

}
export default NewLoan;