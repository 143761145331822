const Stage_Two = ({stageTwo,handleStageTwo})=>{

    return(
        <div className="row"  >

            <div className="col-md-3 form-group" >
                <label>Name <span className='red' >*</span></label>
                <input type = "text" className="form-control" name="name" value={stageTwo.name} onChange={handleStageTwo} />
                <span className="error_holder"  >{stageTwo.error_list.name}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>Phone <span className='red' >*</span></label>
                <input type = "text" className="form-control" name="phone" value={stageTwo.phone} onChange={handleStageTwo}  />
                <span className="error_holder"  >{stageTwo.error_list.phone}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>Email <span className='red' >*</span></label>
                <input type = "text" className="form-control"  name="email"  value={stageTwo.email}  onChange={handleStageTwo} />
                <span className="error_holder"  >{stageTwo.error_list.email}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>ID / Passport <span className='red' >*</span></label>
                <input type = "text" className="form-control"  name="id"  value={stageTwo.id}  onChange={handleStageTwo}  />
                <span className="error_holder"  >{stageTwo.error_list.id}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>Huduma # <span className='red' >*</span></label>
                <input type = "text" className="form-control"  name="huduma"  value={stageTwo.huduma}  onChange={handleStageTwo}  />
                <span className="error_holder"  >{stageTwo.error_list.huduma}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>Physical Address <span className='red' >*</span></label>
                <input type = "text" className="form-control"  name="physical_address"  value={stageTwo.physical_address}  onChange={handleStageTwo}  />
                <span className="error_holder"  >{stageTwo.error_list.physical_address}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>Relation to kin <span className='red' >*</span></label>
                <input type = "text" className="form-control"  name="relation"  value={stageTwo.relation}  onChange={handleStageTwo}  />
                <span className="error_holder"  >{stageTwo.error_list.relation}</span>
            </div>

        </div>
    )

}

export default Stage_Two;