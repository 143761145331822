import React, {useState, useEffect} from 'react';
import useAuth from '../../hooks/useAuth';

import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import swal from 'sweetalert';


function Recovery(){

    const navigate = useNavigate();
    const {setAuth} = useAuth()

    useEffect(() =>{
        if(localStorage.getItem('auth_token'))
        {
             navigate('/')
        }
    },[])
    
    const [loginInput, setLogin] = useState({
        reset_code: '',
        password: '',
        confirm: '',
        error_list: [],
    });
    
    const handleInput = (e) => {
        e.persist();
        setLogin({...loginInput, [e.target.name]: e.target.value})
    }
    
    const loginSubmit = (e) => {
        e.preventDefault();
    
    const data = {
        reset_code: loginInput.reset_code,
        password: loginInput.password,
        confirm: loginInput.confirm,
    }
    
        axios.post(`api/recover`,data).then(res => {
    
                if(res.data.status === 200)
                {
                    swal('Success',res.data.message,"success")
                    navigate('/index')
                }
                else if(res.data.status === 401)
                {
                      swal('Warning',res.data.message,"warning")
                }
                else
                {
                    setLogin({...loginInput, error_list: res.data.validation_errors})
                }
    
          })
    
    }

return (
    <div className="hold-transition login-page">
    <div className="login-box">

        <div className="login-logo">
        <a href="#"><b>Northwave</b> Credit</a>
        </div>

        <div className = "card" >
        <div className="card-body login-card-body">

            <p className="login-box-msg">Recover your account.</p>    
            <form onSubmit={loginSubmit} >

            <div className="input-group mb-1">
            <input type="reset_code" className="form-control" placeholder="Reset code" name = "reset_code" onChange={handleInput} value={loginInput.reset_code} />
            <div className="input-group-append">
            <div className="input-group-text">
            <span className="fa fa-code"></span>
            </div>
            </div>                    
            </div>
            <span  className='error_holder'  style={{display:'block', marginBottom:'10px'}}>{loginInput.error_list.reset_code}</span>

            <div className="input-group mb-1">
            <input type="password" className="form-control" placeholder="New password" name = "password" onChange={handleInput} value={loginInput.password} />
            <div className="input-group-append">
            <div className="input-group-text">
            <span className="fa fa-key"></span>
            </div>
            </div>        
            </div>
            <span  className='error_holder'  style={{display:'block', marginBottom:'10px'}}>{loginInput.error_list.password}</span>

            <div className="input-group mb-1">
            <input type="password" className="form-control" placeholder="Confirm" name = "confirm" onChange={handleInput} value={loginInput.confirm} />
            <div className="input-group-append">
            <div className="input-group-text">
            <span className="fa fa-key"></span>
            </div>
            </div>       
            </div>
            <span  className='error_holder'  style={{display:'block', marginBottom:'10px'}}>{loginInput.error_list.confirm}</span>
                       

            <div className="row" >
                <div className ="col-md-12" >
                <button type="submit" className="btn btn-primary btn-block">Recover</button>
                </div>
            </div>

            </form>

        </div>
        </div>
    </div>
    </div>
);

}

export default Recovery;