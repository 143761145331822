import {Link, NavLink, useNavigate} from 'react-router-dom';
function CardBody(){
    return (
        <div className="card-footer p-0">
        <ul className="nav flex-column">

        <li className="nav-item">
            <Link to='/settings' className="nav-link">
            <span className="fa fa-cog" ></span> System Settings
            </Link>
            </li>

            <li className="nav-item">
            <Link to='/staff' className="nav-link">
            <span className="fa fa-users" ></span> Staff
            </Link>
            </li>

            <li className="nav-item">
            <Link to="/departments" className="nav-link">
            <span className="fa fa-building" ></span> Departments
            </Link>
            </li>

            <li className="nav-item">
            <Link to="/roles" className="nav-link">
            <span className="fa fa-user-shield" ></span> Roles
            </Link>
            </li>

            <li className="nav-item">
            <Link to='/permissions' className="nav-link">
            <span className="fa fa-lock" ></span> Permissions
            </Link>
            </li>

            <li className="nav-item">
            <Link to="/loan_types" className="nav-link">
            <span className="fa fa-circle" ></span> Loan types
            </Link>
            </li>

            <li className="nav-item">
            <Link to="/kpis" className="nav-link">
            <span className="fa fa-chart-bar" ></span> KPI's
            </Link>
            </li>

        </ul>
      </div>
    );
}

export default CardBody;