const LoanDocuments=({documents})=>{

    return (
    <div className="card card-primary card-outline">
    <div className="card-header">
    <h5>Loan Documents</h5>
    </div>
    <div className="card-body">

    <table className="table">
        <thead>
            <tr>
                <th>#</th>
                <th><span className="fa fa-file"></span> Document</th>
                <th><span className="fa fa-user"></span>  Uploaded By</th>
                <th><span className="fa fa-download"></span></th>
            </tr>
        </thead>

        <tbody>
            {documents.map((item,index)=>{
            return(<tr key ={index}>
            <td>{index+1}</td>
            <td>{item.document}</td>
            <td> {item.name}</td>
            <td><span className="fa fa-download"></span></td>
            </tr>)
            })}
        </tbody>

    </table>

    </div>
    <div className="card-footer">
    
    </div>
    </div>
    )

}

export default LoanDocuments;