import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import LoanIncomeGraph from '../elements/charts/LoanIncomeGraph';
import LoanIncomeChart from '../elements/charts/LoanIncomeChart';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

import LoanIncomePrint from '../elements/print/LoanIncomePrint';

function LoanIncome(){
   
    const effectRun = useRef(false)
    const [entries,setEntries] = useState([])
    const [open,setOpen] = useState(false)
    const [year,setYear] = useState(new Date().getFullYear())
    const [loans,setLoans] = useState([0,0,0,0,0,0,0,0,0,0,0,0])
    const [segments,setSegments] = useState([0, 0, 0, 0, 0, 0])
    const navigate = useNavigate()

    const [summary,setSummary] = useState({
        'principal':0,
        'interest':0,
        'tracking':0,
        'service':0,
        'penalty':0,
        'total':0
    })

    const [details,setDetails] = useState({
        start_date:'2023-01-01',
        end_date: '',
        error_list:[],
    })

    const handleChange=(e)=>{
        setDetails({...details, [e.target.name]: e.target.value})
    }

    const fetchEntries=()=>{
    
    axios.post(`api/loan_income`).then((res)=>{
        if(res.data.status === 200)
        {
            setEntries(res.data.entries)
            setDetails({...details, end_date: res.data.current_date})
        }
    }).catch(error=>{
        if(error == "AxiosError: Request failed with status code 403")
        {
          navigate('/unauthorized');
        }
    })

    }

    const queryEntries=()=>{
    
        const data = {
            start_date: details.start_date,
            end_date: details.end_date
        }

        axios.post(`api/query_loan_income`,data).then((res)=>{

            if(res.data.status === 200)
            {
                setEntries(res.data.entries)
                setDetails({...details, error_list: []})
                setSummary(res.data.summary)
            }
            else
            {
                setDetails({...details, error_list: res.data.validation_errors})
            }

        })

    }

    const fetchLoans=(year)=>{

    axios.get(`api/annual_loan_income/${year}`).then((res)=>{
        if(res.data.status === 200)
        {
            setLoans(res.data.months)
            setSegments(res.data.segments)
            setSummary(res.data.summary)
        }
    })

    }

    const onClose=()=>{
        setOpen(false)
    }

    const yearChange=(e)=>{
        const year = e.target.value
        setYear(year)
        fetchLoans(year)
    }

    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchEntries()
        fetchLoans(year)

        return()=>{
        effectRun.current = true
        }
    }

    })

    return (
        <div className="wrapper">

            <LoanIncomePrint open={open} onClose={onClose} statement={entries} summary={summary} details={details} ></LoanIncomePrint>
            
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>...</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >
                <div className='row'>
                <div className='col-md-12'>

                <div className='card card-default card-outline'>
                <div className='card-header'>
                    Loan Income Overview
                </div>
                <div className='card-body'>

                <div className='row'>
                <div className='col-md-12'>
                <div className='mt-1' > 
                <div className='form-group'>
                <select className='form-control' onChange={yearChange}>
                <option value='2023' >2023</option>
                <option value='2024'>2024</option>
                </select> 
                </div>
                </div>
                </div>
                </div>

                <div className='row'>

                <div className='col-md-8'>
                <div className='card card-primary card-outline'>
                <div className='card-header'>
                <h6>Loan income overview</h6>
                </div>
                <div className='card-body'>
                <LoanIncomeGraph loans={loans} ></LoanIncomeGraph> 
                </div>
                </div>
                </div>

                <div className='col-md-4'>
                <div className='card card-primary card-outline'>
                <div className='card-header'>
                Loan Income Segmentation
                </div>
                <div className='card-body' >
                <LoanIncomeChart segments={segments} ></LoanIncomeChart>
                </div>
                </div>
                </div>

                </div>
                </div>
                </div>

                <div className='card card-primary card-outline'>
                <div className='card-header'>
                <h6>Loan Income Particulars
                <span className='btn btn-dark btn-sm float-right m-1' onClick={()=>setOpen(true)} ><span className='fa fa-print'></span>  Print Report</span>
                <span className='btn btn-dark btn-sm float-right m-1'><span className='fa fa-eraser'></span> Clear</span>
                <span className='btn btn-dark btn-sm float-right m-1' onClick={()=>queryEntries()} ><span className='fa fa-search'></span> Search</span>
                </h6>
                </div>

                <div className='card-body'>
                
                <div className='row'>

                <div className='form-group col-md-6'>
                <label>Start Date</label>
                <input type="date" className='form-control' name="start_date" value={details.start_date} onChange={handleChange} ></input>
                <span className='error_holder'>{details.error_list.start_date}</span>
                </div>

                <div className='form-group col-md-6'>
                <label>End Date</label>
                <input type="date" className='form-control'  name="end_date" value={details.end_date} onChange={handleChange} ></input>
                <span className='error_holder'>{details.error_list.end_date}</span>
                </div>

                </div>

                <table className='table'>
                <thead>
                <tr className='small-row' >
                <th>#</th>
                <th>Date</th>
                <th>Loan #</th>
                <th>Principal</th>
                <th>Interest</th>
                <th>Tracking Fee</th>
                <th>Service Charge</th>
                <th>Other charges</th>
                <th>Total Income</th>
                </tr>
                </thead>
                <tbody>
                {entries.map((item,index)=>{
                
                return (
                <tr className='small-row' key={index} >
                <td>{index+1}</td>
                <td>{item.date.substr(0,10)}</td>
                <td>{item.loan}</td>
                <td>KES {parseInt(item.principal).toLocaleString('en-US')}</td>
                <td>KES {parseInt(item.interest).toLocaleString('en-US')}</td>
                <td>KES {parseInt(item.tracking).toLocaleString('en-US')}</td>
                <td>KES {parseInt(item.service).toLocaleString('en-US')}</td>
                <td>KES {parseInt(item.penalties).toLocaleString('en-US')}</td>
                <td>KES {parseInt(item.total).toLocaleString('en-US')}</td>
                </tr>
                )

                })}
                </tbody>
                <tfoot>
                <tr  className='small-row' style={{fontWeight:'bold'}} >
                <td></td>
                <td></td>
                <td><b>Total</b> </td>
                <td>KES {parseInt(summary.principal).toLocaleString('en-US')}</td>
                <td>KES {parseInt(summary.interest).toLocaleString('en-US')}</td>
                <td>KES {parseInt(summary.tracking).toLocaleString('en-US')}</td>
                <td>KES {parseInt(summary.service).toLocaleString('en-US')}</td>
                <td>KES {parseInt(summary.penalty).toLocaleString('en-US')}</td>
                <td></td>
                </tr>
                </tfoot>
                </table>
                </div>
                <div className='card-footer'></div>
                </div>

                </div>
                </div>
                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default LoanIncome;