import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

import Table from 'react-bootstrap/Table';

function GeneralPerfomance(){

    const effectRun = useRef(false)

    const [entity,setEntity] = useState('team')
    const [loans,setLoans] = useState([])

    const fetchLoans=()=>{

        const data = {
            entity: entity
        }

        axios.post(`api/loan_perfomance`,data).then((res)=>{
            if(res.data.status === 200)
            {
                setLoans(res.data.loans)
            }
        })
    }

   useEffect(()=>{

    if(effectRun.current === false)
    {
        fetchLoans()

        return()=>{
            effectRun.current = true;
        }
    }

   })

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>General Perfomance</h5>
                </div>
    
                <div className="col-sm-6">
                
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >
                    <div className='row'>

                        <div className='col-md-3'>
                        <div className="card card-widget widget-user-2"  >

                        <div className="card-footer p-0">
                        <ul className="nav flex-column">

                        <li className="nav-item">
                        <Link to='/general_perfomance' className="nav-link">
                        <span className="fa fa-chart-line" ></span> Loans
                        </Link>
                        </li>

                        <li className="nav-item">
                        <Link to='' className="nav-link">
                        <span className="fa fa-chart-line" ></span> Leads
                        </Link>
                        </li>

                        <li className="nav-item">
                        <Link to='' className="nav-link">
                        <span className="fa fa-chart-line" ></span> Valuations
                        </Link>
                        </li>


                        </ul>
                        </div>

                        </div>
                        </div>

                        <div className='col-md-9'>
                        
                                <div className='card card-primary card-outline'>
                                    <div className='card-header'>
                                        <h6>Loans Perfomance</h6>
                                    </div>
                                    <div className='card-body'>

                                        <div className='row'>

                                            <div className='form-group col-md-4'>
                                                <label>Start Date</label>
                                                <input type="date" className='form-control'></input>
                                            </div>

                                            <div className='form-group col-md-4'>
                                                <label>End Date</label>
                                                <input type="date" className='form-control'></input>
                                            </div>

                                            <div className='form-group col-md-4'>
                                                <label>Entity</label>
                                                <select className='form-control'>
                                                    <option>Individual</option>
                                                    <option>Team</option>        
                                                </select>
                                            </div>

                                        </div>

                                        <Table hover striped bordered>
                                            <thead>
                                                <tr className='small-row' >
                                                    <td>#</td>
                                                    <td>Person / Team</td>
                                                    <td>Loans (counts) </td>
                                                    <td>Loans (KES) </td>
                                                </tr>
                                            </thead>
                                            <tbody className='small-row'>
                                                {loans.map((item,index)=>{
                                                    return(<tr  className='small-row' key={index}>
                                                    <td>{index+1}</td>
                                                    <td>{item.entity}</td>
                                                    <td>{item.loan_count}</td>
                                                    <td>KES {parseInt(item.loan_total).toLocaleString('en-US')}</td>
                                                    </tr>)
                                                })}
                                            </tbody>
                                        </Table>

                                    </div>
                                    <div className='card-footer'></div>
                                </div>

                        </div>
                    </div>
                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default GeneralPerfomance;