
const NewLoanTypeModal = ({open, onClose, typeDetails, handleType, saveType}) =>{

    if(!open) return false;
    
    return(
    <div className='overlay' onClick={onClose} >
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className = "card card-primary" >
        <div className="card-header" ><span className='fa fa-edit' ></span> New Loan Catgory
        <span className='closeModal btn btn-sm btn-default' onClick={onClose} > <span className='fa fa-times-circle' ></span> Close</span>
        </div>

        <div className="card-body login-card-body">

        <form className="form" onSubmit={saveType}>
            <div className="row" >

            <div className="col-md-6 form-group ">
                <label>Loan category <span className='red' >*</span> </label>
                <input type ='text' className='form-control' name = 'type' onChange={handleType} />
                <span className="error_holder"  >{typeDetails.error_list.type}</span>
            </div>

            <div className="col-md-6 form-group ">
                <label>Description</label>
                <input type ='text' className='form-control' name = 'description' onChange={handleType} />
                <span className="error_holder"  >{typeDetails.error_list.description}</span>
            </div>

            <div className="col-md-12 form-group ">
                <input type ='submit' className='btn btn-primary btn-sm' value = 'Save Loan Type' />
            </div>

            </div>
        </form>

        </div>

        <div className='card-footer' style={{color:'red'}}>
        <i>* fields are mandatory </i>
        </div>

        </div>
   

        </div>
    </div>
    );
}

export default NewLoanTypeModal;