const NextLoan = ({stage,nextPage}) =>{

    if(stage<2)
    {
        return (
            <span className="btn btn-sm btn-dark" style = {{float:'right'}} onClick={()=>nextPage()} > <span className="fa fa-arrow-right" ></span> Next</span>
        )
    }

    if(stage === 2)
    {
        return(
            <span className="btn btn-sm btn-success" style = {{float:'right'}} onClick={()=>nextPage()} > <span className="fa fa-check-circle" ></span> Complete</span>
        )
    }
     

}
export default NextLoan;