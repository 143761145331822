const UpdateRepocession=({open,onClose,details,handleDetails,updateRepo})=>{
if(!open) return null;
return (
    <div className='overlay' onClick={onClose}>
    <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

    <div className="card card-success">
    <div className="card-header">
    <h6>Update Repocession</h6>
    </div>
    <div className="card-body">
        <div className="row">
            <div className="col-md-12">
                <form className="form" onSubmit={updateRepo} >
                    <div className="row">

                        <div className="form-group col-md-4">
                            <label>Status</label>
                            <select className="form-control" name = "status" value = {details.status} onChange={handleDetails} >
                            <option>Issued</option>
                            <option>At the yard</option>
                            <option>Auction Valuation</option>
                            <option>Tracker removed</option>
                            <option>Sold</option>
                            </select>
                        </div>

                        <div className="form-group col-md-4">
                            <label>Market Value</label>
                            <input className="form-control" type="number" min="0" name = "market_value" value = {details.market_value} onChange={handleDetails} ></input>
                        </div>

                        <div className="form-group col-md-4">
                            <label>Forced Sale Value</label>
                            <input className="form-control" type="number" min="0" name="forced_value" value = {details.forced_value} onChange={handleDetails}></input>
                        </div>

                        <div className="form-group col-md-4">
                            <label>Auction Date</label>
                            <input className="form-control" type="date" name="auction_date"  value = {details.auction_date} onChange={handleDetails}></input>
                        </div>

                        <div className="form-group col-md-4">
                            <label>Auctioneer Dealing</label>
                            <select className="form-control" name = "auctioneer" value = {details.auctioneer} onChange={handleDetails} >
                                <option value = ''>na</option>
                            </select>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <input type="submit" className="btn btn-success btn-sm" value="Update Repocession"></input>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    </div>

    </div>
    </div>
)   

}
export default UpdateRepocession;