import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import Modal  from 'react-bootstrap/Modal';

const NewVehicle=({vehicleFlag,closeVehicle,person,fetchVehicles})=>{

const [details,setDetails] = useState({
    customer:person,
    registration:'',
    make:'',
    model:'',
    year:'',
    color:'',
    transmission:'',
    body:'',
    log_book:'',
    scan:null,
    engine_no:'',
    chasis_no:'',
    error_list: [],
}) 

const clearVehicle=()=>{

setDetails({
    customer:person,
    registration:'',
    make:'',
    model:'',
    year:'',
    color:'',
    transmission:'',
    body:'',
    log_book:'',
    engine_no:'',
    chasis_no:'',
    scan:null,
    error_list: [],
})

}

const handleDetails=(e)=>{
    e.persist();
    setDetails({...details, [e.target.name]: e.target.value})
}



const SaveVehicle=(e)=>{
    e.preventDefault()
    const data = {
    customer:details.customer,
    color:details.color,
    registration:details.registration,
    make:details.make,
    model:details.model,
    year:details.year,
    transmission:details.transmission,
    body:details.body,
    chasis_no: details.chasis_no,
    engine_no: details.engine_no,
    log_book:details.log_book,
    }


axios.post(`api/add_vehicle`,data).then((res)=>{

if(res.data.status === 200)
{
    swal('Success',res.data.message,"success")
    closeVehicle()
    clearVehicle()
    fetchVehicles()
}
else
{
    setDetails({...details, error_list: res.data.validation_errors})
}

})

}

if(!vehicleFlag) return false;

    return(

    <Modal
    show={vehicleFlag}
    onHide={closeVehicle}
    backdrop="static"
    keyboard={false}
    size="lg"
    >
    <Modal.Header closeButton>
    <Modal.Title>New Vehicle</Modal.Title>
    </Modal.Header>
    <Modal.Body>

    <form className='form' onSubmit={SaveVehicle} >
    <div className='row' >

    <div className='col-md-3 form-group' >
    <label>Registration # <span className='red' >*</span></label>
    <input type="text" className="form-control" name="registration" value={details.registration} onChange={handleDetails} />
    <span className="error_holder"  >{details.error_list.registration}</span>
    </div>

    <div className='col-md-3 form-group' >
    <label>Make <span className='red' >*</span></label>
    <input type="text" className="form-control" name="make"  value={details.make} onChange={handleDetails} />
    <span className="error_holder"  >{details.error_list.make}</span>
    </div>

    <div className='col-md-3 form-group' >
    <label>Model <span className='red' >*</span></label>
    <input type="text" className="form-control" name="model"  value={details.model} onChange={handleDetails} />
    <span className="error_holder"  >{details.error_list.model}</span>
    </div>

    <div className='col-md-3 form-group' >
    <label>Year <span className='red' >*</span></label>
    <input type="number" className="form-control" name="year"  value={details.year} onChange={handleDetails} />
    <span className="error_holder"  >{details.error_list.year}</span>
    </div>

    <div className='col-md-3 form-group' >
    <label>Body <span className='red' >*</span></label>
    <select className="form-control" name="body"  value={details.body} onChange={handleDetails} >
        <option value='' >Select Body</option>
        <option value='Saloon' >Saloon</option>
        <option value='Sedan' >Sedan</option>
        <option value='S.U.V' >S.U.V</option>
        <option value='Wagon' >Wagon</option>
        <option value='Pickup' >Pickup</option>
        <option value='Van' >Van</option>
        <option value='Hatch Back' >Hatch Back</option>
        <option value='Sport Coupe' >Sport Coupe</option>
    </select>
    <span className="error_holder"  >{details.error_list.body}</span>
    </div>

    <div className='col-md-3 form-group' >
    <label>Color <span className='red' >*</span></label>
    <input type="text" className="form-control" name="color"  value={details.color} onChange={handleDetails} />
    <span className="error_holder" >{details.error_list.color}</span>
    </div>

    <div className='col-md-3 form-group' >
    <label>Transmission <span className='red' >*</span></label>
    <select className="form-control" name="transmission"  value={details.transmission} onChange={handleDetails}>
        <option value='' >Select Transmission</option>
        <option value='automatic' >Automatic</option>
        <option value='manual' >Manual</option>
        <option value='hybrid' >Hybrid</option>
    </select>
    <span className="error_holder"  >{details.error_list.transmission}</span>
    </div>

    <div className='col-md-3 form-group' >
    <label>Log Book #  <span className='red' >*</span></label>
    <input type="text" className="form-control" name="log_book"  value={details.log_book} onChange={handleDetails} />
    <span className="error_holder"  >{details.error_list.log_book}</span>
    </div>

    <div className='col-md-3 form-group' >
    <label>Engine #  <span className='red' >*</span></label>
    <input type="text" className="form-control" name="engine_no"  value={details.engine_no} onChange={handleDetails} />
    <span className="error_holder"  >{details.error_list.engine_no}</span>
    </div>

    <div className='col-md-3 form-group' >
    <label>Chasis #  <span className='red' >*</span></label>
    <input type="text" className="form-control" name="chasis_no"  value={details.chasis_no} onChange={handleDetails} />
    <span className="error_holder"  >{details.error_list.chasis_no}</span>
    </div>

    <div className='col-md-12 form-group' >
    <input className="btn btn-primary btn-sm" value="New Vehicle" type="submit" />
    </div>

    </div>
    </form>

    </Modal.Body>
    </Modal>

    )

}
export default NewVehicle;