import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

const RegisterCheque=({flag,onClose,details,handleCheque,saveCheque})=>{


if(!flag) return false;
return (

<div className='overlay' onClick={onClose} >
<div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

<div className="card card-primary">

    <div className="card-header">
    <h5>Register Cheque</h5>
    </div>

    <div className="card-body">
    <div className="row">  
    <div className="col-md-12">
    <form className='form' onSubmit={saveCheque}>

        <div className='form-group'>
            <label>Cheque No.</label>
            <input type = "text" className='form-control' name="cheque" value={details.cheque} onChange={handleCheque} ></input>
            <span className='error_holder'>{details.error_list.cheque}</span>
        </div>

        <div className='form-group'>
            <label>Notes </label>
            <input type = "text" className='form-control' name="notes" value={details.notes} onChange={handleCheque} ></input>
            <span className='error_holder'>{details.error_list.notes}</span>
        </div>

        <div className='form-group'>
            <input type="submit" className='btn btn-primary btn-sm' value ="Update"></input>
        </div>
    </form>
    </div>
    </div>
    </div>

    <div className="card-footer">
    </div>

</div>

</div>
</div>
)

}
export default RegisterCheque;