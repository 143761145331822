function CalculatorModal({open,closeModal,simulateLoan,handleChange,info})
{

    if(!open) return false;

    return(
        <div className='overlay' onClick={closeModal} >
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className="card card-primary card-outline" >

            <div className="card-header" >
            Calculator
            <span className='closeModal btn btn-xs btn-danger' onClick={()=>closeModal()} > <span className='fa fa-times-circle' ></span> Close modal</span>
            </div>

            <div className='card-body' >
            
            <div className='row' >
            <div className='col-lg-12' >

            <div className="card card-primary card-outline">
            <div className="card-header">
            <h5 className="card-title"> <span className='fa fa-chart-line' ></span> Loan calculator</h5>
            </div>

            <div className="card-body">

            <div className='row' >

                <div className='form-group col-md-6' >
                    <label>Loan amount</label>
                    <input className='form-control' value={info.loan_amount} name = "loan_amount" onChange={(e)=>handleChange(e)} />
                    <span className="error_holder" >{info.error_list.loan_amount}</span>
                </div>

                <div className='form-group col-md-6' >
                    <label>Interest rate %</label>
                    <input className='form-control' value={info.interest_rate}  name = "interest_rate" onChange={(e)=>handleChange(e)} />
                    <span className="error_holder" >{info.error_list.interest_rate}</span>
                </div>

                <div className='form-group col-md-6' >
                    <label>Duration (months) </label>
                    <input className='form-control'  value={info.repayment_period}  type='number' name = "repayment_period" onChange={(e)=>handleChange(e)} />
                    <span className="error_holder" >{info.error_list.repayment_period}</span>
                </div>

                <div className='form-group col-md-6' >
                <label>Loan Type</label>
                <select className="form-control" name='loan_type' onChange={(e)=>handleChange(e)} >
                    <option value='flat'>Flat</option>
                    <option value='compound'>Compound</option>
                </select>
                </div>

            </div>

            <div className='row' >

            <div className='form-group col-md-12' >
            <span className='btn btn-primary btn-sm' onClick={(e)=>simulateLoan(e)} > <span className='fa fa-calculator' ></span> Simulate loan repayment </span>
            </div>

            </div>

            </div>

            <div className='card-footer' >

            </div>

            </div>

            </div>

            </div>
            </div>

            <div className="card-footer">
            </div>

            </div>
            </div>
            </div>
    )

}

export default CalculatorModal;