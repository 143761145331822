import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';
import Table  from 'react-bootstrap/Table';
import TeamLoansBarGraph from '../elements/charts/TeamLoansBarGraph';
import AddMember from '../elements/modals/teams/AddMember';

function TeamInfo(){

    const effectRun = useRef(false)

    const [open,setOpen] = useState(false)

    const onClose=()=>{
        setOpen(false)
    }

    const id = useParams().id

    const [loans,setLoans] = useState([0,0,0,0,0,0,0,0,0,0,0,0])
    const [quarters,setQuarters] = useState([0,0,0,0])
    const [members,setMembers] = useState([])
    const [users,setUsers] = useState([])
    const [totals,setTotals] = useState(0)

    const [details,setDetails] = useState({
        team_name:'',
        description:'',
        leader:''
    })

    const fetchDetails=()=>{
        axios.get(`api/team_info/${id}`).then((res)=>{
            if(res.data.status === 200)
            {
                setDetails(res.data.details)
            }            
        })
    }

    const removeMember=(id)=>{
        axios.get(`api/remove_member/${id}`).then((res)=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success")
                fetchMembers()
            }            
        })
    }

    const fetchUsers=()=>{
        axios.post(`api/users`).then((res)=>{
            if(res.data.status === 200)
            {
                setUsers(res.data.users)
            }
        })
    }

    const fetchMembers=()=>{
        axios.get(`api/team_members/${id}`).then((res)=>{
            if(res.data.status === 200)
            {
                setMembers(res.data.members)
                setLoans(res.data.months)
                setTotals(res.data.total_loans)
                setQuarters(res.data.quarters)
            }            
        })
    }

    const addPerson=(personId)=>{
        const data = {
            person: personId,
            team:id
        }

        axios.post(`api/add_member`,data).then((res)=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success")
                fetchMembers()
            }else if(res.data.status === 305)
            {
                swal("Warning",res.data.message,"warning")
            }
            else
            {
                swal("Error","Member addition failed","error")
            }
        })
    }

    useEffect(()=>{

        if(effectRun.current === false)
        {
            fetchDetails()
            fetchMembers()
            fetchUsers()
        }

        return()=>{
            effectRun.current = true
        }
    })

    return (
        <div className="wrapper">

            <AddMember open={open} onClose={onClose} members={members} users={users} addPerson={addPerson}  ></AddMember>

            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5><span className='fa fa-users'></span> {details.team_name}</h5>
                </div>
    
                <div className="col-sm-6">
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card card-default'>
                                <div className='card-body'>
                                    <div className='row'>

<div className="col-12 col-sm-6 col-md-6">

<div className="info-box" style={{backgroundColor:'#000044',color:'white'}} >
<div className="info-box-content">
<span className="info-box-text"><span className='fa fa-users' ></span> Members <span className='float-right'> <small>{members.length}</small> </span> </span>
<span className="info-box-number">
<small>...</small>
</span>
</div>

</div>
</div>

                

                <div className="col-12 col-sm-6 col-md-6">

                <div className="info-box" style={{backgroundColor:'#000044',color:'white'}} >
                <div className="info-box-content">
                <span className="info-box-text"><span className='fa fa-layer-group' ></span> Loans <span className='float-right'> <small>{totals}</small> </span> </span>
                <span className="info-box-number">
                <small>...</small>
                </span>
                </div>

                </div>
                </div>

                </div>

                <div className='row'>

                        <div className='col-md-5'>
                        <div className='card card-primary card-outline'>
                                <div className='card-header'>
                                    <h6>
                                        <span className='fa fa-users'></span> Team Members
                                        <span className="btn btn-dark btn-xs float-right" onClick={()=>setOpen(true)} ><span className='fa fa-plus-circle'></span> Add Members</span>
                                    </h6>
                                </div>
                                <div className='card-body'>
                                    <Table striped hover bordered>
                                        <thead>
                                            <tr className='small-row' >
                                                <td>#</td>
                                                <td>Name</td>
                                                <td>Loans</td>
                                                <td><span className="fa fa-trash red"></span></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {members.map((item,index)=>{
                                                return(
                                                    <tr className='small-row' key={index}>
                                                        <td>{index+1}</td>
                                                        <td>{item.person}</td>
                                                        <td>KES {parseInt(item.total).toLocaleString('en-US')}</td>
                                                        <td><span className="fa fa-trash red" onClick={()=>removeMember(item.entry_id)} ></span></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-7'>
                            <div className='card card-primary card-outline'>
                                <div className='card-header'>
                                    <h6>
                                        Annual loan perfomance overview
                                    </h6>
                                </div>
                                <div className='card-body'>
                                    <TeamLoansBarGraph loans={loans}></TeamLoansBarGraph>
                                </div>
                            </div>
                            <div className='card card-primary card-outline'>
                                <div className='card-header'>
                                    <h6>Quarterly Loan Perfomance</h6>
                                </div>
                                <div className='card-body'>
                                    <Table striped hover bordered>
                                        <thead>
                                            <tr className='small-row'>
                                                <td>#</td>
                                                <td>Quarter</td>
                                                <td>Loans</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr className='small-row'>
                                                <td>1</td>
                                                <td>Quarter 1</td>
                                                <td>KES {parseInt(quarters[0]).toLocaleString('en-US')}</td>
                                            </tr>

                                            <tr className='small-row'>
                                                <td>2</td>
                                                <td>Quarter 2</td>
                                                <td>KES {parseInt(quarters[1]).toLocaleString('en-US')}</td>
                                            </tr>

                                            <tr className='small-row'>
                                                <td>3</td>
                                                <td>Quarter 3</td>
                                                <td>KES {parseInt(quarters[2]).toLocaleString('en-US')}</td>
                                            </tr>

                                            <tr className='small-row'>
                                                <td>4</td>
                                                <td>Quarter 4</td>
                                                <td>KES {parseInt(quarters[3]).toLocaleString('en-US')}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                </div>
                </div>
                </div>

    

                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default TeamInfo;