import { Modal } from "react-bootstrap";

const CreateContact=({createContact,closeCreateContact,contact,handleContact,saveContact,roles})=>{
if(!createContact) return false;

return(

<Modal
show={createContact}
onHide={closeCreateContact}
backdrop="static"
keyboard={false}
size="lg"
>
<Modal.Header closeButton>
<Modal.Title>
<span className="fa fa-user-shield"></span> Create Staff
</Modal.Title>
</Modal.Header>

<Modal.Body>

<form className="form" onSubmit={saveContact} >

<div className="row">

<div className="form-group col-md-3">
<label>First Name</label>
<input type="text" name="first_name" className="form-control" value={contact.first_name} onChange={handleContact} />
<span className="error_holder"  >{contact.error_list.first_name}</span>
</div>

<div className="form-group col-md-3">
<label>Other Name</label>
<input type="text" name="other_name" className="form-control"  value={contact.other_name} onChange={handleContact}  />
<span className="error_holder"  >{contact.error_list.other_name}</span>
</div>

<div className="form-group col-md-3">
<label>Last Name</label>
<input type="text" name="last_name" className="form-control"  value={contact.last_name} onChange={handleContact}  />
<span className="error_holder"  >{contact.error_list.last_name}</span>
</div>

<div className="form-group col-md-3">
<label>ID / Passport </label>
<input type="text" name="id_number" className="form-control"  value={contact.id_number} onChange={handleContact}  />
<span className="error_holder"  >{contact.error_list.id_number}</span>
</div>

<div className="form-group col-md-3">
<label>Payroll</label>
<input type="text" name="payroll" className="form-control"  value={contact.payroll} onChange={handleContact}  />
<span className="error_holder"  >{contact.error_list.payroll}</span>
</div>

<div className="form-group col-md-3">
<label>Phone</label>
<input type="text" name="phone" className="form-control"  value={contact.phone} onChange={handleContact}  />
<span className="error_holder"  >{contact.error_list.phone}</span>
</div>

<div className="form-group col-md-3">
<label>Email</label>
<input type="text" name="email" className="form-control"  value={contact.email} onChange={handleContact}  />
<span className="error_holder"  >{contact.error_list.email}</span>
</div>

<div className="form-group col-md-3">
<label>Role</label>
<select name="role" className="form-control"  value={contact.role} onChange={handleContact}  >
    <option value=''>Select role</option>
    {roles.map((item,index)=>{
    if(item.category === "payday")
    {
        return(
            <option value={item.id} key={index} >{item.role}</option>
        )
    }

    })}
</select>
<span className="error_holder"  >{contact.error_list.role}</span>
</div>

<div className="form-group col-md-3">
<label>Employment Type</label>
<select name="employment_type" className="form-control"  value={contact.employment_type} onChange={handleContact}  >
    <option value=''>Select employment type</option>
    <option>Permanent</option>
    <option>Contract</option>
</select>

<span className="error_holder"  >{contact.error_list.employment_type}</span>
</div>

<div className="form-group col-md-3">
<label>Employment Date</label>
<input type="date" name="employment_date" className="form-control"  value={contact.employment_date} onChange={handleContact}  />
<span className="error_holder"  >{contact.error_list.employment_date}</span>
</div>

<div className="form-group col-md-3">
<label>Contract expiry</label>
<input type="date" name="contract_expiry" className="form-control"  value={contact.contract_expiry} onChange={handleContact}  />
<span className="error_holder"  >{contact.error_list.contract_expiry}</span>
</div>

<div className="form-group col-md-3">
<label>Gross Income</label>
<input type="number" name="gross_income" className="form-control"  value={contact.gross_income} onChange={handleContact}  />
<span className="error_holder"  >{contact.error_list.gross_income}</span>
</div>

<div className="form-group col-md-3">
<label>Net Income</label>
<input type="number" name="net_income" className="form-control"  value={contact.net_income} onChange={handleContact}  />
<span className="error_holder"  >{contact.error_list.net_income}</span>
</div>

<div className="form-group col-md-3">
<label>Total Deductions</label>
<input type="number" name="total_deduction" className="form-control"  value={contact.total_deduction} onChange={handleContact}  />
<span className="error_holder"  >{contact.error_list.total_deduction}</span>
</div>

</div>

<div className="row">
<div className="form-group col-md-12">
<input type = "submit" className="btn btn-primary btn-sm" value="Save staff"></input>
</div>

</div>

</form>

</Modal.Body>

<Modal.Footer>
</Modal.Footer>

</Modal>

)
}
export default CreateContact;