import React from 'react';
import useAuth from '../../hooks/useAuth';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

function NotFound(){

    return (
        <div className="wrapper">
        <Nav/>
        <Aside/>

        <div className="content-wrapper">  

        <section class="content-header">
            <div class="container-fluid">
            <div class="row mb-2">
            <div class="col-sm-6">
            <h1>404 Error</h1>
            </div>

            <div class="col-sm-6">
        
            </div>
            </div>
            </div>
        </section>

        <section class="content">
            <div class="error-page" style={{paddingTop:'100px'}}>
            <h2 class="headline text-warning"> 404</h2>

            <div class="error-content">
            <h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! Not found.</h3>

            <p>
            We could not find the element you are looking for.
            </p>
            </div>        
            </div>
      
        </section>
        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    )
}

export default NotFound;