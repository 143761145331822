const NewStakeholder=({open,onClose,details,handleDetails,saveDeveloper})=>{
    if(!open) return null;
    return (
        <div className='overlay' onClick={onClose}>
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className="card card-primary card-outline">
        <div className="card-header">
        <h6> <span className="fa fa-user-circle"></span> Developer's details </h6>
        </div>

        <div className="card-body">

        <form className="form" onSubmit={saveDeveloper} >
        <div className="row">
        
        <div className="col-md-3 form-group">
        <label>First Name <span className="red">*</span></label>
        <input type="text" className="form-control" name="first_name" value={details.first_name} onChange={handleDetails} />
        <span className="red">{details.error_list.first_name}</span>
        </div>

        <div className="col-md-3 form-group">
        <label>Other Name</label>
        <input type="text" className="form-control" name="other_name"  value={details.other_name} onChange={handleDetails} />
        <span className="red">{details.error_list.other_name}</span>
        </div>

        <div className="col-md-3 form-group">
        <label>Last Name <span className="red">*</span></label>
        <input type="text" className="form-control" name="last_name"  value={details.lastst_name} onChange={handleDetails}  />
        <span className="red">{details.error_list.last_name}</span>
        </div>

        <div className="col-md-3 form-group">
        <label>Organization</label>
        <input type="text" className="form-control" name="organization"  value={details.organization} onChange={handleDetails}  />
        <span className="red">{details.error_list.organization}</span>
        </div>

        <div className="col-md-3 form-group">
        <label>Phone <span className="red">*</span></label>
        <input type="text" className="form-control" name="phone"  value={details.phone} onChange={handleDetails}  />
        <span className="red">{details.error_list.phone}</span>
        </div>

        <div className="col-md-3 form-group">
        <label>Email <span className="red">*</span></label>
        <input type="text" className="form-control" name="email"  value={details.email} onChange={handleDetails}  />
        <span className="red">{details.error_list.email}</span>
        </div>

        <div className="col-md-3 form-group">
        <label>ID Number <span className="red">*</span></label>
        <input type="text" className="form-control" name="id_number" value={details.id_number} onChange={handleDetails}  />
        <span className="red">{details.error_list.id_number}</span>
        </div>

        <div className="col-md-3 form-group">
        <label>KRA PIN <span className="red">*</span></label>
        <input type="text" className="form-control" name="kra"  value={details.kra} onChange={handleDetails}  />
        <span className="red">{details.error_list.kra_pin}</span>
        </div>

        <div className="col-md-12 form-group">
        <label>Postal Address</label>
        <input type="text" className="form-control" name="postal_address"  value={details.postal_address} onChange={handleDetails}  />
        <span className="red">{details.error_list.postal_address}</span>
        </div>

        <div className="col-md-12 form-group">
        <input type="submit" value="Save Developer" className="btn btn-primary btn-sm"></input>
        </div>

        </div>
        </form>

        </div>

        <div className="card-footer">

        </div>
        </div>
            
        </div>
        </div>
    )
}

export default NewStakeholder;