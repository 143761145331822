import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

function Kyc({kyc,initializeKyc,setNewKycFlag,fetchKyc,profile}){

const deleteKyc=(id)=>{

const data = {
    id:id
}

axios.post(`api/delete_kyc`,data).then((res)=>{

    if(res.data.status === 200)
    {
        swal('Success',res.data.message,"success")
        fetchKyc()
    }

})

}

const addButton=()=>{
    var role = localStorage.getItem('auth_role')

    if(role == "CEO" || role == "System Administrator")
    {
        return(
            <span className='btn btn-primary btn-sm' style={{float:'right'}} onClick={()=>setNewKycFlag(true)} >New Document</span>
        )
    }
    else
    {
        if(profile.edit_flag == "0")
        {
            return(
                <span className='btn btn-primary btn-sm' style={{float:'right'}} onClick={()=>setNewKycFlag(true)} >New Document</span>
            )
        }
    }    
}

const kyc_table=()=>{

    var role = localStorage.getItem('auth_role')

    if(role == "CEO" || role == "System Administrator")
    {
        return(
            <table className="table">
            <thead>
            <tr className='small-row' >
            <td>#</td>
            <td>Document</td>
            <td>Number</td>
            <td>Edit</td>
            <td>Delete</td>
            </tr>
            </thead>
            <tbody>
            {kyc.map((info,index)=>{
            return(
                <tr className='small-row' key={index}>
                <td>{index+1}</td>
                <td><span className='fa fa-file-invoice' ></span> <span className='label-holder'>{info.document} </span></td>
                <td>{info.document_number}</td>
                <td><span  style={{color:'green'}} onClick={()=>initializeKyc(info.id)}><span className='fa fa-edit'></span> Edit</span></td>
                <td><span style={{color:'red'}} onClick={()=>deleteKyc(info.id)} ><span className='fa fa-trash'></span> Delete</span></td>
                </tr>
            )
            })}
            </tbody>
            </table>
        )
    }
    else
    {
        return(
            <table className="table">
            <thead>
            <tr className='small-row' >
            <td>#</td>
            <td>Document</td>
            <td>Number</td>
            </tr>
            </thead>
            <tbody>
            {kyc.map((info,index)=>{
            return(
                <tr className='small-row' key={index}>
                <td>{index+1}</td>
                <td><span className='fa fa-file-invoice' ></span> <span className='label-holder'>{info.document} </span></td>
                <td>{info.document_number}</td>
                </tr>
            )
            })}
            </tbody>
            </table>
        )
    }  

}

return (
    <div class="card card-primary card-outline">
    <div class="card-header">
    <h5><span className='fa fa-file-invoice'></span> KYC Data
    {addButton()}
    </h5>
    </div>
    <div class="card-body box-profile contact-box">

    <div className='row' >
    <div className='col-md-12' >

    {kyc_table()}

    </div>
    </div>

    

    </div>

    <div className='card-footer'></div>

    </div>
)

}

export default Kyc;