import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import CardBody from '../elements/cards/CardBody';

import NewPermission from '../elements/modals/permissions/NewPermission';

function Permissions()
{

    const [open,setOpen] = useState(false)
    const effectRun = useRef(false)
    const [permissions,setPermissions] = useState([])
    const [categories,setCategories] = useState([])

    const [details,setDetails] = useState({
        code:'',
        exclusive:'no',
        exclusive_to:'',
        category:'',
        permission:'',
        description:'',
        error_list: [],
    })

    const onClose=()=>{
    setOpen(false)
    setDetails({
        code:'',
        exclusive:'no',
        exclusive_to:'',
        category:'',
        permission:'',
        description:'',
        error_list: [],
    })
    }

    const fetchPermissions=()=>{

        axios.post(`api/permissions`).then((res)=>{
            if(res.data.status == 200)
            {
                setPermissions(res.data.permissions)
            }
        })

    }

    const fetchCategories=()=>{
    
        axios.post(`api/permissions_categories`).then((res)=>{
            if(res.data.status === 200)
            {
                setCategories(res.data.categories)
            }
        })

    }

    const handleDetails=(e)=>{
        setDetails({...details, [e.target.name]: e.target.value})
    }

    const savePermission=(e)=>{
        e.preventDefault()
        const data = {
            code: details.code,
            exclusive: details.exclusive,
            exclusive_to: details.exclusive_to,
            category: details.category,
            permission: details.permission,
            description: details.description,
        }

        axios.post(`api/new_permission`,data).then((res)=>{
            
            if(res.data.status == 200)
            {
                onClose()
                fetchPermissions()
                swal("Success",res.data.message,'success')
            }
            else
            {
                setDetails({error_list:res.data.validation_errors})
            }

        })
    }

    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchPermissions()
        fetchCategories()
    }

    return()=>{
    effectRun.current = true
    }

    },[])
   
    return (
        <div className="wrapper">
        
        <NewPermission open={open} onClose={onClose} details={details} handleDetails={handleDetails} savePermission={savePermission} categories={categories} />
        <Nav/>
        <Aside/>

        <div className="content-wrapper">

        <ContentHeader title="System Settings" />

        <div className="content">
        <div className="container">
        <div className="row">
            
        <div className="col-lg-3"  >
        
        <div className="card card-widget widget-user-2"  >
        <CardBody/>
        </div>

        </div>

        <div className = "col-lg-9" id = "centralSettings">
        
        <div className="card card-primary card-outline">
        <div className="card-header">
        <h5 className="card-title m-0"> <span className='fa fa-lock' ></span> System Permissions 
        <span className='btn btn-xs btn-primary' style={{float:'right'}} onClick={()=>setOpen(true)} ><span className='fa fa-plus-circle'></span> New Permission</span>
        </h5>
        </div>

        <div className="card-body">

        <table className='table'>
        <thead>
        <tr className='small-row'>
            <th>#</th>
            <th><span className='fa fa-lock' ></span> Permission</th>
            <th>Code</th>
            <th>Description</th>
        </tr>
        </thead>

        <tbody>
            {permissions.map((item,index)=>{
                return(
                <tr className='small-row' key={index}>
                    <td>{index+1}</td>
                    <td><span className='fa fa-lock' ></span>  {item.permission}</td>
                    <td>{item.code}</td>
                    <td>{item.description}</td>
                </tr>
                )
            })}
        </tbody>

        </table>
                
        </div>

        <div className='card-footer' ></div>

        </div>

        </div>

        </div>
        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default Permissions;