function Footer(){
    return (
    <footer className="main-footer">
    <div className="float-right d-none d-sm-inline">
    Powered by ADS
    </div>
    <strong>Copyright &copy; 2023 </strong>
  </footer>
    );
}

export default Footer;