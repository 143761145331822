import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import swal from 'sweetalert';

import user_image from './../../img/user1-128x128.jpg';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

import Cards from '../elements/cards/Cards';
import BarGraph from '../elements/charts/BarGraph';
import PerfomanceToggle from '../elements/PerfomanceToggle';
import PerfomanceCard from '../elements/PerfomanceCard';

function Perfomance()
{
    const [mode,setMode] = useState(false)
    const [subject,setSubject] = useState('')
    const [staff,setStaff] = useState([])
    const [departments, setDepartments] = useState([])
    const [menu, setMenu] = useState([])
    const [label, setLabel] = useState('Individual Perfomance')

    const [disbursment, setDisbursment] = useState([0,0,0,0,0,0,0,0,0,0,0,0])

    const [segments, setSegments] = useState([])

    const [summary,setSummary] = useState({
      loans: 0,
      leads: 0,
      valuations:0,
      leads_rate: '',
      valuation_rate: '',
      loans_rate: '',
      Q1:0,
      Q2:0,
      Q3:0,
      Q4:0,
      total_loans:0,
      q1_rates:'',
      q2_rates:'',
      q3_rates:'',
      q4_rates:'',
      male:0,
      female:0,
      male_rate:'',
      female_rate: '',
    })

    useEffect(()=>{

    refreshUsers()
    fetchDepartments()

    },[])

    //  Refresh users list when an update or addittion is done
    const refreshUsers = ()=>{

        axios.post(`api/users`).then(res => {
    
            if(res.data.status === 200)
            {
                let persons = res.data.users
                setStaff(persons)
                setMenu(persons)         

            }

      })

     }

     //  Fetch summary
    const fetchSummary = (person_id,name)=>{

      const data = {
        id: person_id
      }

      axios.post(`api/user_perfomance`,data).then(res => {
  
          if(res.data.status === 200)
          {
              let summ = res.data.summary
              let disbursments = res.data.disbursments

              setDisbursment(disbursments)              
              setSummary(summ)
              setSubject(name)

          }

    })

    axios.post(`api/user_segments`,data).then(res => {
  
      if(res.data.status === 200)
      {
         setSegments(res.data.segments)
      }

    })

}

//  Fetch summary
const fetchDeptSummary = (person_id,name)=>{

  const data = {
    id: person_id
  }

  axios.post(`api/department_perfomance`,data).then(res => {

      if(res.data.status === 200)
      {
          let summ = res.data.summary
          let disbursments = res.data.disbursments

          setDisbursment(disbursments)              
          setSummary(summ)
          setSubject(name)

      }

})

axios.post(`api/department_segments`,data).then(res => {
  
  if(res.data.status === 200)
  {
      setSegments(res.data.segments)
  }

})

}

  const fetchDepartments=()=>{

    axios.post(`api/departments`).then((res)=>{
      if(res.data.status === 200)
      {
        setDepartments(res.data.departments)
      }
    })

  }

   // Toggle state

   const changeMode=()=>{
        setMode(!mode)
        setDisbursment([0,0,0,0,0,0,0,0,0,0,0,0])
        setSegments([])

        setSummary({
          loans: 0,
          leads: 0,
          valuations:0,
          leads_rate: '',
          valuation_rate: '',
          loans_rate: '',
          Q1:0,
          Q2:0,
          Q3:0,
          Q4:0,
          total_loans:0,
          q1_rates:'',
          q2_rates:'',
          q3_rates:'',
          q4_rates:'',
          male:0,
          female:0,
          male_rate:'',
          female_rate: '',
        })

        if(mode)
        {
          setMenu(staff)
          setLabel('Individuals')
        }
        else
        {
          setMenu(departments)
          setLabel('Departments')
        }

   }
   
    return (
        <div className="wrapper">

        <Nav/>
        <Aside/>

        <div className="content-wrapper">

        <ContentHeader title="Perfomance Analysis" />

        <div className="content">
        <div className="container">
        <div className="row">
            
        <div className="col-lg-3"  >
        
        <div className="card card-widget widget-user-2 card-primary card-outline "  >

        <div className='card-header' >
        <span className='badge badge-default left' style={{display:'inlineBlock', fontSize:'16px'}} >{label}</span>
        <PerfomanceToggle  mode={mode} changeMode={changeMode} />
        </div>

        <PerfomanceCard mode={mode} items={menu} fetchSummary={fetchSummary} fetchDeptSummary={fetchDeptSummary} />
        <div className='card-footer' ></div>
        </div>

        </div>

        <div className = "col-lg-9" id = "centralSettings">
        
        <div className="card card-primary card-outline">

        <div className="card-header">
        <h5 className="card-title m-0">
        <span className = "fa fa-chart-bar" ></span> {subject}
        </h5>
        </div>

        <div className="card-body">
        
        <div className='row' >
        
        <Cards title='Leads' data = {summary.leads} size = 'col-md-4' icon='fa fa-spinner' stat={summary.leads_rate} />
        <Cards title='Valuations' data = {summary.valuations} size = 'col-md-4' icon='fa fa-balance-scale' stat={summary.valuations_rate} />
        <Cards title='Loans' data = {summary.loans} size = 'col-md-4' icon='fa fa-money-bill' stat={summary.loans_rate} />

        </div>

        <div className='row' >

        <div className='col-md-5'>

        <div className="card card-dark card-outline ">
              
        <div className="card-body table-responsive p-0">
        <table className="table table-striped table-valign-middle">
        <thead>
        <tr style={{fontSize:'14px'}} >
        <th><span className='fa fa-chart-pie' ></span> Quarter</th>
        <th>Loans</th>
        <th><span className='badge badge-success' style={{width:'45px'}} >...%</span></th>
        </tr>
        </thead>
        <tbody>

        <tr  style={{fontSize:'14px'}} >
        <td>Q1</td>
        <td>KES {summary.Q1}</td>
        <td><span className='badge badge-success' style={{width:'45px'}} >{summary.q1_rates}</span></td>
        </tr>

        <tr  style={{fontSize:'14px'}} >
        <td>Q2</td>
        <td>KES {summary.Q2}</td>
        <td><span className='badge badge-success' style={{width:'45px'}} >{summary.q2_rates}</span></td>
        </tr>

        <tr  style={{fontSize:'14px'}} >
        <td>Q3</td>
        <td>KES {summary.Q3}</td>
        <td><span className='badge badge-success' style={{width:'45px'}} >{summary.q3_rates}</span></td>
        </tr>

        <tr style={{fontSize:'14px'}} >
        <td>Q4</td>
        <td>KES {summary.Q4}</td>
        <td><span className='badge badge-success' style={{width:'45px'}} >{summary.q4_rates}</span></td>
        </tr>

        <tr style={{fontWeight:'bold', fontSize:'14px'}} >
        <td>Total</td>
        <td>KES  {summary.total_loans}</td>
        <td><span className='badge badge-success' style={{width:'45px'}} >100 %</span></td>
        </tr>
                 
        </tbody>
        </table>
        </div>
        <div className='card-footer' ></div>
        </div>

            <div className="card card-dark card-outline ">
              
              <div className="card-body table-responsive p-0">
                <table className="table table-striped table-valign-middle">
                  <thead>
                  <tr style={{fontSize:'14px'}} >
                    <th> <span className='fa fa-chart-pie' ></span>Segments</th>
                    <th>Amount</th>
                    <th><span className='badge badge-success' style={{width:'35px'}} >... %</span></th>
                  </tr>
                  </thead>
                  <tbody>

                    {segments.map((number,index)=>{
                      return(
                        <tr key={number[0]} style={{fontSize:'13px'}} >
                        <td>{number[0]}</td>
                        <td>KES {number[1]}</td>
                        <td><span className='badge badge-success' style={{width:'35px'}} >{number[2]}</span></td>
                      </tr>
                      )
                  })}                      

                </tbody>
                </table>
              </div>
              <div className='card-footer' ></div>
            </div>

        </div>

        <div className='col-md-7'>

            <div className='card card-primary card-outline' >
            <div className='card-header' >
            <span className='fa fa-chart-bar' ></span> Loans Disbursed 2022
            </div>
            <div className='card-body' >
            <BarGraph loans={disbursment} />
            </div>
            <div className='card-footer' ></div>
            </div>

            <div className='card card-dark card-outline' >
            <div className='card-header' >
            <span className='fa fa-venus-mars' ></span> Loan disbursment | Gender - 2022
            </div>

            <div className='card-body' >

            <div className='row' >
            <Cards title='Male' data = {summary.male} size = 'col-md-6' icon='fa fa-mars' stat={summary.male_rate} />
            <Cards title='Female' data = {summary.female} size = 'col-md-6' icon='fa fa-venus' stat={summary.female_rate} />
            </div>

            </div>
            </div>

        </div>

        </div>

        </div>

        </div>
        </div>

        </div>
        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default Perfomance;