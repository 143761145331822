import { useEffect, useState, useRef } from 'react';
import logo from './../../logo.jpg';
import user_image from './../../img/user1-128x128.jpg';
import { Link } from 'react-router-dom';
import RoleNav from './RoleNav';
import PermissionNav from './PermissionNav';

import FinanceMenu from './FinanceMenu';
import HrMenu from './HrMenu';
import ReportMenu from './ReportMenu';

function Aside()
{
    const [permissions,setPermissions] = useState(JSON.parse(localStorage.getItem('auth_permissions')))

    useEffect(() => {
        const trees = window.$('[data-widget="treeview"]');
        trees.Treeview('init');
        }, []);

    return(
        <aside className="main-sidebar sidebar-dark-dark elevation-4" style={{backgroundColor:'rgba(0,0,0,1)'}} >

        <a  className="brand-link">
        <img src={logo} className="brand-image img-circle elevation-3" />
        <span className="brand-text font-weight-light">Northwave Credit</span>
        </a>

        <div className="sidebar">
        
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        <div className="image">
        <img src={user_image} className="img-circle elevation-2" alt="User Image" />
        </div>
        <div className="info">
        <a href="#" className="d-block">{localStorage.getItem('auth_name')}</a>
        </div>
        </div>


        <nav className="mt-2">
        
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        
        <li className="nav-item">
        <Link to="/index"  className="nav-link"  >
        <i className="nav-icon fas fa-home"></i> Home</Link>
        </li>

        <li className="nav-item has-treeview" data-widget="treeview" >

        <a href="#" className="nav-link">
        <i className="nav-icon fas fa-users"></i>
        <p>
        CRM
        <i className="fas fa-angle-right right"></i>
        </p>
        </a>

        <ul className="nav nav-treeview">
        
        <li className="nav-item">
        <Link to = "/leads"  className="nav-link">
        <i className="far fa-circle nav-icon"></i>
        <p>Leads</p>
        </Link>
        </li>

        <RoleNav page="Contacts" link="/contacts" icon="nav-icon far fa-circle" roles={['CEO','System Administrator','Finance','Relationship Officer']} />


        </ul>

        </li>

        <FinanceMenu />
        <HrMenu/>
        <ReportMenu />
        
        <PermissionNav name="Loans" page="/loans"  permissions={permissions} allowed="view_loans" />
        <PermissionNav name="Payday" page="/payday"  permissions={permissions} allowed="view_payday" />
        <PermissionNav name="Valuations" page="/valuations"  permissions={permissions} allowed="view_valuations" />
        <PermissionNav name="Collaterals" page="/collaterals"  permissions={permissions} allowed="view_collaterals" />
        <PermissionNav name="Requisitions" page="/requisitions"  permissions={permissions} allowed="view_requisitions" />

        <li className="nav-item">
        <Link to="/calculator" className="nav-link">
        <i className="nav-icon fas fa-layer-group"></i>
        <p>Calculator</p>
        </Link>
        </li>

        <RoleNav page="Settings" link="/settings" icon="nav-icon fas fa-cog" roles={['CEO','System Administrator']} />

        <li className="nav-item">
        <Link to="/change_password" className="nav-link">
        <i className="nav-icon fa fa-user-shield"></i>
        <p>Change password</p>
        </Link>
        </li>

        <RoleNav page="User logs" link="/logs" icon="nav-icon fa fa-users" roles={['CEO','System Administrator']} />

        </ul>

        </nav>
        </div>

        </aside>
    );
}

export default Aside;