import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import Cards from '../elements/cards/Cards';
import PrintLoans from '../elements/print/PrintLoans';

function Loans(){

    const [loans,setLoans] = useState([])
    const [stats,setStats] = useState({})
    const navigate = useNavigate()
    const effectRan = useRef(false)
    const [links,setLinks] = useState([])

    const [open,setOpen] = useState(false)

    const onClose=()=>{
        setOpen(false)
    }

    const [phrase,setPhrase] = useState('')

    const handlePhrase=(e)=>{
    setPhrase(e.target.value)
    }

    const searchLoans=(e)=>{
        e.preventDefault()
        const url = 'api/search_loans/'+phrase

        axios.get(url).then((res)=>{
            setLoans(res.data.loans.data)
            setLinks(res.data.loans.links)
        })
    }

    // Fetch Loans
    const fetchLoans=()=>{
    
    axios.post(`api/fetch_loans`).then((res)=>{

    if(res.data.status === 200)
    {
        setLoans(res.data.loans.data)
        setLinks(res.data.loans.links)
    }

    }).catch(error=>{
        if(error == "AxiosError: Request failed with status code 403")
        {
          navigate('/unauthorized');
        }
    })

    }

    const adminStats=()=>{

        const role = localStorage.getItem('auth_role')
        if(role == "System Administrator" || role == "CEO")
        {
            return(
                <>
                <Cards title='All Loans' data ={stats.total} size = 'col-md-4' icon='fas fa-layer-group' stat='100 %' />            
                <Cards page='/disbursed' title='Active Loans' data ={stats.disbursed} size = 'col-md-4' icon='fas fa-layer-group' stat={stats.disbursed_percentage} />
                <Cards page='/rejected' title='Rejected Loans' data ={stats.rejected} size = 'col-md-4' icon='fas fa-layer-group' stat={stats.rejected_percentage} />
                </>
            )
        }

    }

    // Fetch Stats
    const fetchStats=()=>{

    axios.post(`api/loan_stats`).then((res)=>{
        
    if(res.data.status === 200)
    {
        setStats(res.data.stats)
    }

    })

    }

    const fecthPage=(label)=>{
        var url = "api/fetch_loans?page="+label
        axios.post(url).then((res)=>{
            if(res.data.status === 200)
            {
                setLoans(res.data.loans.data)
                setLinks(res.data.loans.links)
            }
            }).catch(error=>{
                if(error == "AxiosError: Request failed with status code 401")
                {
                  localStorage.clear()
                  navigate('/login');
                }
            })
    } 

    useEffect(()=>{
    
    if(effectRan.current === false)
    {
        fetchLoans()
        fetchStats()
    }

    return()=>{
        effectRan.current = true
    }
    
    },[])

    return (
        <div className="wrapper">

                <PrintLoans open ={open} onClose={onClose} statement={loans}></PrintLoans>
                <Nav/>
                <Aside/>

                <div className="content-wrapper">

                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>Loans</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3" onSubmit={(e)=>searchLoans(e)} >
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" value={phrase} onChange={handlePhrase} />
                <div className="input-group-append">
                <button className="btn btn-dark btn-navbar-dark" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
              </div>
            </div>
            </div>

            <div className='content' >
            <div className='container-fluid' >

            <div className='row'>
            
            {adminStats()}
            <Cards page='/pending' title='Pending Aprroval' data ={stats.pending} size = 'col-md-4' icon='fas fa-layer-group' stat={stats.pending_percentage} />
            <Cards page='/approved' title='Pending Disbursment' data ={stats.approved} size = 'col-md-4' icon='fas fa-layer-group' stat={stats.approved_percentage} />
            <Cards page='/due_list' title='Due List' data = '' size = 'col-md-4' icon='fas fa-layer-group' stat='' />
            </div>

            <div className='row'>
            <div className='col-md-12' >

            <div className='card card-primary card-outline'>

            <div className='card-header'>
            <span className='btn btn-sm btn-dark' onClick={()=>setOpen(true)} ><span className='fa fa-print'></span>  Print Loans</span>
            </div>
            
            <div className='card-body'>

            <table className='table'>
            <thead>
            <tr className='small-row' >
            <th>#</th>
            <th>Loan #</th>
            <th>Client</th>
            <th>Amount </th>
            <th>Period</th>
            <th>Interest</th>
            <th>Arrears</th>
            <th>Approval</th>
            <th>Status</th>
            </tr>
            </thead>

            <tbody>
            {loans.map((loan,index)=>{
            return(
                <tr key={index} className='small-row' >
                <td>{index+1}</td>
                <td><Link to={`/loan/${loan.id}`}><span style={{color:'blue'}} >{loan.loan_code}</span></Link></td>
                <td>{loan.first_name} {loan.other_name} {loan.last_name}</td>
                <td>KES {parseInt(loan.amount_loanable).toLocaleString('en-US')}</td>
                <td>{loan.period} Months</td>
                <td>{loan.interest_rate} %</td>
                <td>KES {parseInt(loan.amount_due).toLocaleString('en-US')}</td>
                <td>{loan.approval}</td>
                <td>{loan.status}</td>
                </tr>
            )
            })}
            </tbody>

            </table>

            </div>

            <div className='card-footer'>
            <ul className="pagination pagination-sm m-0 float-left">
                    {links.map((item,index)=>{
                    if(item.url !== null)
                    {
                        if(index !== (links.length-1))
                        {
                           if(item.active)
                           {
                            return(<li className="page-item active" key={index} onClick={()=>fecthPage(item.label)}  ><a className="page-link">{index}</a></li>)
                           }
                           else
                           {
                            return(<li className="page-item" key={index} onClick={()=>fecthPage(item.label)}  ><a className="page-link">{index}</a></li>)
                           }
                        }
                    }
                    })}
                    </ul>
            </div>

            </div>

            </div>
            </div>

            </div>
            </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default Loans;