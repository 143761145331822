import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate,Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import Modal from 'react-bootstrap/Modal';

import ProfileHolder from '../users_components/contacts/ProfileHolder';
import SummaryHolder from '../users_components/contacts/SummaryHolder';
import ProfileData from '../users_components/contacts/ProfileData';
import Scans from '../users_components/contacts/Scans';
import Kyc from '../users_components/contacts/Kyc';
import Kin from '../users_components/contacts/Kin';
import Settlements from '../users_components/contacts/Settlements';


import EditVehicle from '../elements/modals/contacts/EditVehicle';
import NewScan from '../elements/modals/contacts/NewScan';
import NewVehicle from '../elements/modals/contacts/NewVehicle';
import VehicleDocuments from '../elements/modals/contacts/VehicleDocuments';
import NewValuation from '../elements/modals/contacts/NewValuation';
import DisplayValuation from '../elements/modals/contacts/DisplayValuation';
import EditValuation from '../elements/modals/contacts/EditValuation';
import NewLoan from '../elements/modals/contacts/NewLoan';
import NewInsurance from '../elements/modals/contacts/NewInsurance';
import EditInsurance from '../elements/modals/contacts/EditInsurance';
import LoanDetails from '../elements/modals/contacts/LoanDetails';
import UploadPicture from '../elements/modals/contacts/UploadPicture';

function Profile(){

    const person = useParams().id;
    const effectRan = useRef(false)
    const navigate = useNavigate()

    const [serviceProviders,setProviders] = useState({
        insurance:[],
        valuers:[],
    })

    const [aggregated,setAggregated] = useState({})

    const [open,setContact] = useState(false)                   // Keep track of EditProfile - modal state
    const [kin_flag,setKinFlag] = useState(false)               // Keep track of EditKin - modal state
    const [new_kin_flag,setNewKin] = useState(false)
    const [kyc_flag,setKycFlag] = useState(false)               // Keep track of EditKyc - modal state
    const [scan_flag,setScanFlag] = useState(false)             // Keep Track of New Scan Upload Modal
    const [vehicleFlag,setVehicleFlag] = useState(false)        // Keep track of New Vehicle Modal
    const [editVehicleFlag,setEditVehicleFlag] = useState(false)
    const [vehicles,setVehicles] = useState([])                 // Keep track of vehicle list for the particular contact
    const [valuations,setValuations] = useState([])
    const [carFlag,setCarFlag] = useState(false)                // Keep track of car modal
    const [newValuationFlag,setValuationFlag] = useState(false) // Keep track of New Valuation Modal
    const [valuationDisplayFlag,setValuationDisplayFlag] = useState(false)
    const [kin_info,setKin] = useState([])                      // Keep track of Kin Information
    const [users,setUsers] =useState([])                        // Save use list
    const [tab,setTab] = useState('profile')                    // Keep track of current tab
    const [summaryTab,setSummary] = useState('statement')           // Keep track of current tab in the summary 
    const [contact,setInfo] = useState([])                      // List of all contacts
    const [kyc,setKyc] = useState([])                           // kyc data
    const [NewKycFlag,setNewKycFlag] = useState(false)          // Keep track of new kyc document modal
    const [upload,setUpload] = useState(false)                  // Keep track of uploads
    const [statement,setStatement] = useState([])               // Keep track of statement
    
    const [aggregation,setAggregation] = useState({
        credits:0,
        debits:0,
        balance:0
    })

    const [documents,setDocuments] = useState([])               // Keep track of documents
    const [uploads,setUploads] = useState([])                   // Keep track of vehicle uploaded documents
    const [editValuationFlag,setEditValuationFlag] = useState(false)
    const [valuationDocuments,setValuationDocuments] = useState([])
    const [newLoanFlag,setNewLoanFlag] = useState(false);
    const [loanEditFlag,setLoanEditFlag] = useState(false)

    const [insuranceFlag,setInsuranceFlag] = useState(false)
    const [editInsuranceFlag,setEditInsuranceFlag] = useState(false)

    const [policies,setPolicies] = useState([])
    const [loanProducts,setLoanProducts] = useState([])
    const [loanTypes,setLoanTypes] = useState([])
    const [loans,setLoans] = useState([])
    const [checks,setChecks] = useState([])
    const [checkStatus,setCheckStatus] = useState('')

    const [logo,setLogo] = useState('../../dist/img/default-150x150.png')

    const [uploadDetails,setUploadDetails] = useState({
    document:'',
    scan:null,
    error_list: [],
    })

    // Keep track of loan initials
    const [loanInitials,setLoanInitials] = useState({
    loan_id:'na',
    customer:person,
    loan_product:'',
    loan_type:'',
    amount_loanable:'',
    interest_rate:'',
    period:'',
    collateral:'',
    loan_officer:'',
    created_by: localStorage.getItem('auth_id'),
    error_list: [],
    })

    const [loanDetails,setLoanDetails] = useState({
    date_applied:'',
    loan_product:'',
    loan_type:'',
    amount_loanable:'',
    interest_rate:'',
    period:'',
    collateral:'',
    processing_fee:0,
    risk_fund:0,
    principal:0,
    interest_amount:0,
    rtgs_fee:0,
    tracking_fee:0,
    service_fee:0,
    total_repayable:0,
    loan_officer:''
    })

    const fetchLoanDetails=(id)=>{
    
    const data = {
    loan:id,
    }

    axios.post(`api/loan_details`,data).then((res)=>{
        if(res.data.status === 200)
        {
            const info = res.data.details
            const figures = res.data.figures

            loanDetails.loan_product = info.loan_product
            loanDetails.loan_type = info.loan_type
            loanDetails.amount_loanable = info.amount_loanable
            loanDetails.interest_rate = info.interest_rate
            loanDetails.period = info.period
            loanDetails.date_applied = info.date_applied
            loanDetails.loan_officer = info.loan_officer
            loanDetails.processing_fee = figures.processing_fee
            loanDetails.risk_fund = figures.risk_fund
            loanDetails.principal = figures.principal
            loanDetails.rtgs_fee = figures.rtgs_fee
            loanDetails.tracking_fee = figures.tracking_fee
            loanDetails.service_fee = figures.service_fee
            loanDetails.total_repayable = figures.total_repayable
            loanDetails.interest_amount = figures.interest_amount
            setLoanEditFlag(true)
        }
    }).catch(error=>{
        if(error == "AxiosError: Request failed with status code 401")
        {
          
        }
        else{
            console.log()
        }
    })

    }

    const [loanDocsInitial,setLoanDocsInitial] = useState({
    customer:'',
    loan:'',
    created_by:localStorage.getItem('auth_id'),
    document:'',
    scan:null,
    error_list: [],
    })

    const [profile,setProfile] = useState({                     // Keep track of Contacts Profile Details
        id:0,
        id_no: '',
        initials:'Mr',
        first_name:'',
        last_name: '',
        other_name:'',
        phone: '',
        email:'',
        city:'',
        postal_address:'',
        huduma_no:'',
        country:'Kenya',
        annual_turnover:0,
        hcs:'',
        branch:'Nairobi',
        industry:'',
        status:'Warm',
        source:'',
        team_lead:'',
        settlement_mode:'',
        edit_flag:'',
        error_list: [],
    })

    const [mpesa,setMpesa] = useState({
        id:'',
        mpesa_account:'',
        error_list:[]
    })

    const [bankAccount,setBankAccount] = useState({
        id:'',
        bank:'',
        account_name:'',
        account_number:'',
        branch:'',
        swift_code:'',
        bank_code:'',
        branch_code:'',
        error_list:[]
    })

    const handleMpesa=(e)=>{
        setMpesa({...mpesa, mpesa_account: e.target.value})
    }

    const handleBank=(e)=>{
        setBankAccount({...bankAccount, [e.target.name]:e.target.value})
    }

    const saveMpesa=(e)=>{

        e.preventDefault()

        const data={
            client:person,
            mpesa_account: mpesa.mpesa_account
        }

        axios.post(`api/client_mpesa`,data).then((res)=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success")
            }
            else
            {
                setMpesa({...mpesa, error_list: res.data.validation_errors})
            }
        })

    }

    const saveBank=(e)=>{

        e.preventDefault()

        const data = {
            client:person,
            bank: bankAccount.bank,
            account_name: bankAccount.account_name,
            account_number: bankAccount.account_number,
            branch: bankAccount.branch,
            swift_code: bankAccount.swift_code,
            bank_code: bankAccount.bank_code,
            branch_code: bankAccount.branch_code
        }

        axios.post(`api/client_bank`,data).then((res)=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success")
            }
            else
            {
                setBankAccount({...bankAccount, error_list:res.data.validation_errors})
            }
        })
    }

    const [kin,initializeKin] = useState({            // Keep track of stage two entries
        set_id:0,
        contact: person,
        name:'',
        phone:'',
        email:'',
        id:'',
        huduma:'',
        physical_address:'',
        relation:'',
        error_list: [],
    })

    const [kycData,setKycData] = useState({
        id:'',
        document:'',
        document_number:'',
        error_list: [],
    })

    const [vehicleDetails,setVehicleDetails] = useState({
        id:'',
        registration:'',
        make:'',
        model:'',
        year:'',
        color:'',
        transmission:'',
        body:'',
        log_book:'',
        engine_no:'',
        error_list: [],
    })

    const [insuranceDetails,setInsuranceDetails] = useState({
        customer:person,
        vehicle:'',
        company:'',
        policy:'',
        collateral:'',
        premium:'',
        start_date:'',
        expiry_date:'',
        payment:'pending',
        error_list: [],
    })

    const [insuranceHolder,setInsuranceHolder] = useState({
        id:'',
        customer:person,
        vehicle:'',
        company:'',
        policy:'',
        collateral:'',
        premium:'',
        start_date:'',
        expiry_date:'',
        payment:'pending',
        error_list: [],
    })

    const closeUpload=()=>{
    setUpload(false)
    }

    const handleInsurance=(e)=>{
    e.persist()
    setInsuranceDetails({...insuranceDetails, [e.target.name]: e.target.value})
    }

    const handleInsuranceHolder=(e)=>{
    e.persist()
    setInsuranceHolder({...insuranceHolder, [e.target.name]: e.target.value})
    }

    const fetchAggregated=()=>{

    const data = {
        id:person
    }
    
    axios.post(`api/aggregated_profile`,data).then((res)=>{
    
    // Set profile
    const info = res.data.profile
    const mpesa_info = res.data.mpesa
    const bank_info = res.data.bank

    setProfile({
    id: info.id,
    id_no :info.id_no,
    initials : info.salutation,
    first_name : info.first_name,
    last_name : info.last_name,
    other_name : info.other_name,
    phone : info.phone,
    email : info.email,
    city : info.city,
    postal_address : info.postal_address,
    huduma_no : info.huduma_no,
    country : info.country,
    annual_turnover : info.annual_tov,
    hcs : info.team_lead,
    branch : info.branch,
    industry : info.industry,
    source : info.customer_source,
    team_lead:info.team_lead,
    status:info.status,
    settlement_mode: info.settlement_mode,
    edit_flag: info.edit_flag,
    error_list: [],
    })

    if(mpesa_info !== null)
    {
        setMpesa({...mpesa, mpesa_account: mpesa_info.mpesa})
    }

    if(bank_info !== null)
    {
        setBankAccount({bank: bank_info.bank,
            account_name: bank_info.account_name,
            account_number: bank_info.account_number,
            branch: bank_info.branch,
            bank_code: bank_info.bank_code,
            branch_code: bank_info.branch_code,
            swift_code: bank_info.swift_code,
            error_list:[]
        })
    }

    setLoanInitials({...loanInitials,loan_officer:info.team_lead})

    //const url = "http://127.0.0.1:8000/"+res.data.profile.picture;
    const url = "https://laravel.northwaveapp.co.ke/"+res.data.profile.picture;
        
    if(url.length > 40)
    {
        setLogo(url)
    }
    

    // Set Documents
    setDocuments(res.data.documents)

    // Set vehicles
    setVehicles(res.data.vehicles)

    // Set valuations
    setValuations(res.data.valuations)

    // Set Kyc
    setKyc(res.data.kyc)

    // Set Policies
    setPolicies(res.data.policies)

    // Set Loans
    setLoans(res.data.loans)

    // set kin
    setKin(res.data.kin)

    // set checks
    setChecks(res.data.checks)
    setCheckStatus(res.data.check_summary)

    }).catch(error=>{
      if(error == "AxiosError: Request failed with status code 401")
      {
        
      }else{
        console.log(error)
      }

    })    

    }

    const fetchProviders = ()=>{
    
    axios.post(`api/segmented_providers`).then((res)=>{
    
    if(res.data.status === 200)
    {
        setProviders({
            insurance:res.data.providers[0].insurance,
            valuers:res.data.providers[1].valuers,
        })
    }

    })

    }

    const fetchValuations=()=>{
    
        const data = {
            customer:person,
        }

        axios.post(`api/customers_valuations`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
           
            setValuations(res.data.valuations)
        }

        })

    }

    const fetchVehicleDetails=(id)=>{

        const data = {
            id:id
        }
        
        axios.post(`api/vehicle_details`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
            const info = res.data.details
            
            vehicleDetails.id = info.id
            vehicleDetails.registration = info.registration
            vehicleDetails.make = info.make
            vehicleDetails.model = info.model
            vehicleDetails.year = info.year
            vehicleDetails.color = info.color
            vehicleDetails.transmission = info.transmission
            vehicleDetails.body = info.body
            vehicleDetails.engine_no = info.engine_no
            vehicleDetails.chasis_no = info.chasis_no
            vehicleDetails.log_book = info.log_book
            setEditVehicleFlag(true)

        }
        
        }).catch(error=>{
            if(error == "AxiosError: Request failed with status code 401")
            {
              
            }else{
                console.log(error)
            }
        })
        
        }

    const initializeKyc=(id)=>{
    
    const data = {
        id:id,
    }

    axios.post(`api/initialize_kyc`,data).then((res)=>{

        if(res.data.status === 200)
        {
            kycData.id = res.data.id
            kycData.document = res.data.document
            kycData.document_number = res.data.document_number
            setKycFlag(true)
        }

    })

    }

    const [carDetails,setCarDetails] = useState({
        id:'',
        customer: '',
        registration:'',
        make:'',
        model:'',
        year:'',
        color:'',
        transmission:'',
        body:'',
        log_book:'',
        error_list: [],
    })

    const fetchCarDetails=(id)=>{
        const data={
        id:id
        }
    
        axios.post(`api/vehicle_details`,data).then((res)=>{
    
            if(res.data.status === 200)
            {
            const info = res.data.details
                
            carDetails.id = info.id
            carDetails.customer = info.customer_id
            carDetails.registration = info.registration
            carDetails.make = info.make
            carDetails.model = info.model
            carDetails.year = info.year
            carDetails.color = info.color
            carDetails.transmission = info.transmission
            carDetails.body = info.body
            carDetails.log_book = info.log_book
            
            }
    
        })

        fetchUploads(id)
        setCarFlag(true)
    
        }

    // Fetch vehicle documents
    const fetchUploads=(id)=>{

        const data = {
            id:id
        }
        
        axios.post(`api/vehicleDocuments`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
            setUploads(res.data.uploads)
        }
        
        })
        
        }

        const [valuationDetails,setValuationDetails] = useState({
            created_by: '',
            valuation_id:'',
            customer_id:'',
            vehicle_id:'',
            vehicle:'',
            valuer: '',
            cost:'',
            contact:'',
            phone:'',
            notes:'',
            market_value:0,
            fixed_value:0,
            paid:0,
            balance:0,
            payment:'',
            status:'',
            notes:'',
            })

            const [valuationRaw,setValuationRaw] = useState({
                valuation:'',
                vehicle:'',
                valuer: '',
                cost:'',
                contact:'',
                phone:'',
                notes:'',
                market_value:0,
                forced_value:0,
                notes:'',
                status:'',
                error_list:[]
                })

            const fetchValuationDetails=(id)=>{
        
            const data = {
                id:id
            }
    
            axios.post(`api/valuation_display`,data).then((res)=>{
    
            if(res.data.status === 200)
            {
                const info = res.data.details

                valuationDetails.vehicle_id = info.vehicle_id
                valuationDetails.customer_id = info.customer_id
                valuationDetails.valuation_id = info.valuation_id
                valuationDetails.vehicle = info.vehicle
                valuationDetails.valuer = info.valuer
                valuationDetails.cost = info.cost
                valuationDetails.contact = info.contact
                valuationDetails.phone = info.phone
                valuationDetails.notes = info.notes
                valuationDetails.paid = info.paid
                valuationDetails.balance = info.balance
                valuationDetails.payment = info.payment
                valuationDetails.status = info.status
                valuationDetails.market_value = info.market_value
                valuationDetails.forced_value = info.forced_value
                valuationDetails.date = info.date

                setValuationDisplayFlag(true)
            }
    
            })

            fetchValuationDocuments(id)
        
        }

        const closeDisplay=()=>{
        setValuationDisplayFlag(false)
        }

        const closeLoan=()=>{
        setNewLoanFlag(false)
        clearLoanInitials()
        }

        const closeLoanEdit=()=>{
            setLoanEditFlag(false)
        }

    
        const handleDocument=(e)=>{
        e.persist();
        setUploadDetails({...uploadDetails, [e.target.name]: e.target.value})
        }
        
        const handleScan=(e)=>{
        e.persist();
        setUploadDetails({...uploadDetails, [e.target.name]: e.target.files[0]})
        }

        const clearData=()=>{

        setUploadDetails({
        document:'',
        scan:null,
        error_list: [],
        })
            
        setUploads([])
            
        }

        const clearLoanInitials=()=>{
        setLoanInitials({
            loan_id:'na',
            customer:person,
            loan_product:'',
            loan_type:'',
            amount_loanable:'',
            interest_rate:'',
            period:'',
            collateral:'',
            created_by: localStorage.getItem('auth_id'),
            error_list: [],
            })
        }

        const closeModal =()=>{                                 // Set Edit Profile modal state to false
        setContact(false)
        }

        const closeKin =()=>{
        setKinFlag(false)
        }

        const closeNewKin =()=>{
        setNewKin(false)
        }
    

        const closeKyc=()=>{
        setKycFlag(false)
        }

        const closeScan=()=>{
        setScanFlag(false)
        }

        const closeVehicle=()=>{
        setVehicleFlag(false)
        }

        const closeEditVehicle=()=>{
        setEditVehicleFlag(false)
        }

        const closeCar=()=>{
        setCarFlag(false)
        }

        const closeValuation=()=>{
        
        setValuationFlag(false)
        }

        const closeEditValuation=()=>{
        setEditValuationFlag(false)
        }

        const closeInsurance=()=>{
        setInsuranceFlag(false)
        }

        const closeEditInsurance=()=>{
        setEditInsuranceFlag(false)
        }

        const viewLoan=(id)=>{
        fetchLoanDetails(id)
        }

        const updateValuation=(id)=>{
    
        const data = {
        id:id,
        }

        axios.post(`api/raw_details`,data).then((res)=>{

        if(res.data.status === 200)
        {
        const info = res.data.raw
        
        setValuationRaw({
            valuation:id,
            vehicle: info.car_id,
            valuer:info.valuer,
            cost:info.cost,
            contact:info.contact,
            phone:info.phone,
            notes:info.notes,
            market_value:info.market_value,
            forced_value:info.forced_value,
            notes:info.notes,
            status:info.status,
            error_list: []
        })
        
        }

        })

    setEditValuationFlag(true)

    }

    const fetchStatement=()=>{

    axios.get(`api/account_statement/`+person).then((res)=>{
        if(res.data.status == 200)
        {
            setStatement(res.data.statement)
        }
    })

    }

    const handleProfile=(e)=>{
        e.persist();
        setProfile({...profile, [e.target.name]: e.target.value})
    }

    const handleKin=(e)=>{
        e.persist();
        initializeKin({...kin, [e.target.name]: e.target.value})
    }

    const handleKyc=(e)=>{
        e.persist();
        setKycData({...kycData, [e.target.name]: e.target.value})
    }

    const handleVehicle=(e)=>{
        e.persist();
        setVehicleDetails({...vehicleDetails, [e.target.name]: e.target.value})
    }

    const handleValuation=(e)=>{
        e.persist()
        setValuationRaw({...valuationRaw, [e.target.name]: e.target.value})
    }

    // handle Loan Initials
    const handleLoanInitials=(e)=>{
        e.persist()
        setLoanInitials({...loanInitials, [e.target.name]: e.target.value})
        }

    // Fetch Contact profile information
    const fetchProfile=()=>{
    
        const data = {
            id: person,
        }

        axios.post(`api/profile`,data).then((res)=>{
    
            if(res.data.status === 200)
            {
                setInfo(res.data.contact)
                const info = res.data.contact

                profile.id = info.id
                profile.id_no = info.id_no
                profile.initials = info.salutation
                profile.first_name = info.first_name
                profile.last_name = info.last_name
                profile.other_name = info.other_name
                profile.phone = info.phone
                profile.email = info.email
                profile.city = info.city
                profile.postal_address = info.postal_address
                profile.huduma_no = info.huduma_no
                profile.country = info.country
                profile.annual_turnover = info.annual_tov
                profile.hcs = info.team_lead
                profile.branch = info.branch
                profile.industry = info.industry
                profile.source = info.customer_source
                profile.edit_flag = info.edit_flag               
               
            }
            else{
                swal('Error',res.data.message,"error")
            }
        
        })

    }

    // Fetch Kin information
    const fetchKin=(id)=>{

        const data = {
            id: id,
        }
        
        axios.post(`api/kin`,data).then((res)=>{
    
            if(res.data.status === 200)
            {
                const info = res.data.kin
                initializeKin({            // Keep track of stage two entries
                    set_id: info.id,
                    contact: person,
                    name: info.names,
                    phone: info.phone,
                    email: info.email,
                    id: info.id_no,
                    huduma: info.huduma_no,
                    physical_address: info.address,
                    relation: info.relation,
                    error_list: [],
                })
                setKinFlag(true)
            }
    
        })

    }

    // Fetch Kyc 
    const fetchKyc=()=>{
    
        const data = {
            id: person,
        }
        
        axios.post(`api/kyc`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
            setKyc(res.data.info)
        }

    })

    }

    // Fetch Documents 
    const fetchDocuments=()=>{

        const data = {
            id: person,
            }
        
        axios.post(`api/documents`,data).then((res)=>{
            if(res.data.status === 200)
            {
                setDocuments(res.data.documents)
            }
        })
    
    }

    // Fetch Vehicles
    const fetchVehicles=()=>{

    const data = {
        id:person,
    }

    axios.post(`api/customer_vehicles`,data).then((res)=>{
    
        if(res.data.status === 200)
        {
            const cars = res.data.vehicles
            setVehicles(cars)
        }        

    })

    }

    const fetchValuationDocuments=(id)=>{

        const data = {
            id:id,
        }
        
        axios.post(`api/valuation_documents`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
            setValuationDocuments(res.data.documents)
        }
        
        })
        
        }
    
    const fetchPolicies=()=>{

        const data = {
            customer:person,
        }

        axios.post(`api/query_policies`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
            setPolicies(res.data.policies)
        }

        })

    }

    const fetchLoanTypes=()=>{
    
    axios.post(`api/loan_types`).then((res)=>{
    
    if(res.data.status === 200)
    {
        setLoanTypes(res.data.loan_types)
        
    }

    }).catch(error=>{
        if(error == "AxiosError: Request failed with status code 401")
        {
          
        }
        else{
            console.log()
        }
    })

    }

    const fetchLoanProducts=()=>{
    
    axios.post(`api/loan_products`).then((res)=>{

    if(res.data.status === 200)
    {
        setLoanProducts(res.data.products)
    }

    }).catch(error=>{
        if(error == "AxiosError: Request failed with status code 401")
        {
          
        }
        else{
            console.log()
        }
    })

    }

    const fetchChecks=()=>{
    
    const data = {
        id:person,
    }

    axios.post(`api/smart_screen`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        setChecks(res.data.checks)
        setCheckStatus(res.data.check_summary)
    }

    })

    }

    const fetchLoans=()=>{
    
    const data = {
    customer:person,
    }

    axios.post(`api/query_loan`,data).then((res)=>{

    if(res.data.status === 200)
    {
    setLoans(res.data.loans)
    }

    })

    }
    
    const startLoan=()=>{
    setNewLoanFlag(true)
    }

    useEffect(()=>{
    
    if(effectRan.current === false)
    {

    fetchAggregated()
    fetchProviders()
    fetchLoanTypes()
    fetchLoanProducts()
    fetchStatement()
    //fetchProfile()
    //fetchDocuments()
    //fetchVehicles()
    //fetchValuations()
    //fetchKyc()
    //fetchPolicies()
    //fetchLoans()
    //fetchKin()  
    //fetchChecks()  
    
    

        axios.post(`api/users`).then((res)=>{
        if(res.data.status === 200)
        {
        setUsers(res.data.users)
        }
        }).catch(error=>{
            if(error == "AxiosError: Request failed with status code 401")
            {
              
            }
            else{
                console.log()
            }
        })

        return()=>{
        effectRan.current = true
        }

    }
    
    },[])

    // Function for updating user profile
    const updateProfile=(e)=>{
        e.preventDefault()
        const data = {
            id: profile.id,
            id_no: profile.id_no,
            initials: profile.initials,
            first_name: profile.first_name,
            last_name: profile.last_name,
            other_name: profile.other_name,
            phone: profile.phone,
            email: profile.email,
            city: profile.city,
            postal_address: profile.postal_address,
            huduma: profile.huduma_no,
            country: profile.country,
            annual_turnover: profile.annual_turnover,
            hcs: profile.hcs,
            branch: profile.branch,
            industry: profile.industry,
            status: profile.status,
            source: profile.source,
            settlement_mode: profile.settlement_mode
        }

        axios.post(`api/update_profile`,data).then((res)=>{

            if(res.data.status === 200)
            {
                swal('Success',res.data.message,"success")
                fetchProfile()
                closeModal()


            }else if(res.data.status === 403){
                swal('Error',res.data.message,"error")
            }
            else{
                setProfile({...profile, error_list: res.data.validation_errors})
            }

        })

    }

    const updateInsurance=(id)=>{
    
    const data = {
        id:id,
    }

    axios.post(`api/policy_details`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        const info = res.data.policy

        insuranceHolder.id = info.id
        insuranceHolder.vehicle = info.vehicle
        insuranceHolder.company = info.company
        insuranceHolder.policy = info.policy
        insuranceHolder.collateral = info.collateral
        insuranceHolder.premium = info.premium
        insuranceHolder.start_date = info.start_date
        insuranceHolder.expiry_date = info.expiry_date
        insuranceHolder.payment = info.payment

        setEditInsuranceFlag(true)
    }

    })

    
    }


    // Function to effect policy
    const effectPolicy=(e)=>{
        e.preventDefault()
        const data = {
        id: insuranceHolder.id,
        vehicle: insuranceHolder.vehicle,
        company: insuranceHolder.company,
        policy: insuranceHolder.policy,
        collateral: insuranceHolder.collateral,
        premium: insuranceHolder.premium,
        start_date: insuranceHolder.start_date,
        expiry_date: insuranceHolder.expiry_date,
        payment: insuranceHolder.payment,
        }
    
        axios.post(`api/update_policy`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
            swal("Success",res.data.message,'success')
            closeEditInsurance()
            fetchPolicies()
        }
        else
        {
            setInsuranceHolder({...insuranceHolder, error_list: res.data.validation_errors})
        }
        
    
        })
    
        }

    // Function to update kin information
    const updateKin=(e)=>{
    e.preventDefault()
    const data = {            // Keep track of stage two entries
        set_id:kin.set_id,
        name:kin.name,
        phone:kin.phone,
        email:kin.email,
        id:kin.id,
        huduma:kin.huduma,
        physical_address:kin.physical_address,
        relation:kin.relation,
    }

    axios.post(`api/update_kin`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        swal('Success',res.data.message,"success")
        setKinFlag(false)

    }else if(res.data.status === 403){
        swal('Error',res.data.message,"error")
    }
    else{
        initializeKin({...kin, error_list: res.data.validation_errors})
    }

    })

    }

    // Function to update kin information
    const saveKin=(e)=>{
        
        e.preventDefault()
        const data = {            // Keep track of stage two entries
            contact:person,
            name:kin.name,
            phone:kin.phone,
            email:kin.email,
            id:kin.id,
            huduma:kin.huduma,
            physical_address:kin.physical_address,
            relation:kin.relation,
        }
    
        axios.post(`api/new_kin`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
            swal('Success',res.data.message,"success")
            setNewKin(false)
    
        }else if(res.data.status === 403){
            swal('Error',res.data.message,"error")
        }
        else{
            initializeKin({...kin, error_list: res.data.validation_errors})
        }
    
        })
    
        }

    // Function to update KYC
    const updateKyc = (e)=>{
        e.preventDefault()
        const data = {
            id: kycData.id,
            document: kycData.document,
            document_number: kycData.document_number
        }

        axios.post(`api/update_kyc`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
            swal('Success',res.data.message,"success")
            fetchKyc()
            closeKyc()

        }else if(res.data.status === 403){
            swal('Error',res.data.message,"error")
        }
        else{
            initializeKin({...kin, error_list: res.data.validation_errors})
        }

        })

    }

    const updateVehicle=(e)=>{
        e.preventDefault()
        const data = {
        id:vehicleDetails.id,
        registration: vehicleDetails.registration,
        make: vehicleDetails.make,
        model: vehicleDetails.model,
        year: vehicleDetails.year,
        color: vehicleDetails.color,
        transmission:vehicleDetails.transmission,
        body: vehicleDetails.body,
        engine_no: vehicleDetails.engine_no,
        chasis_no: vehicleDetails.chasis_no,
        log_book: vehicleDetails.log_book,
        }

        axios.post(`api/update_vehicle`,data).then((res)=>{
        
            if(res.data.status === 200)
            {
                swal('Success',res.data.message,"success")
                closeEditVehicle()
                fetchVehicles()
    
            }else if(res.data.status === 403){
                swal('Error',res.data.message,"error")
            }
            else{
                setVehicleDetails({...vehicleDetails, error_list: res.data.validation_errors})
            }

        })

    }

    const [file,setFile] = useState({
    upload:'',
    file_error:''
    });

    const handleFile=(e)=>{
        setFile({...file, upload: e.target.files[0]})
    }

    const saveFile=(e)=>{
        
        e.preventDefault()
        
        if(file.upload == '')
        {
            setFile({...file, file_error: 'File upload required !'})
        }
        else
        {
            
            setFile({...file, file_error: ''})

            const formData = new FormData()
    
            formData.append('person',person)
            formData.append('file',file.upload,file.upload.name)
            axios.post(`api/upload_picture`,formData).then((res)=>{
                
                if(res.data.status === 200)
                {
                    swal('Success',res.data.message,'success')
                    closeUpload()
                }
                else
                {
                    swal('Error','Upload failed','error')
                }

            })

        }
        

    }

    const [details,setDetails] = useState({
        contact:contact,
        document: '',
        document_number: '',
        error_list: [],
    })

    const handleDetails =(e)=>{
        e.persist();
        setDetails({...details, [e.target.name]: e.target.value})
    }

    const saveKyc=(e)=>{
    e.preventDefault()
    const data = {
    contact: person,
    document: details.document,
    document_number: details.document_number,
    }

    axios.post(`api/add_kyc`,data).then((res)=>{

    if(res.data.status === 200)
    {
        swal('Success',res.data.message,"success")
        setNewKycFlag(false)
        fetchKyc()
    }else if(res.data.status === 403){
        swal('Error',res.data.message,"error")
    }
    else{
        setDetails({...details, error_list: res.data.validation_errors})
    }

    })

    }

    const settlementTab=()=>{

        var role = localStorage.getItem('auth_role')

        if(role == "CEO" || role == "System Administrator")
        {
            return(
                <>
                    <li class="list-group-item" onClick={()=>setSummary('settlements')} >
                    <b><span className='fa fa-file-invoice' ></span> Settlement Account</b>
                    <span className="float-right badge badge-primary" style={{width:'30px',color:'white'}}>0</span>
                    </li>

                    <li class="list-group-item">
                    <b><span className='fa fa-walking' ></span> Office Visits</b>
                    <span className="float-right badge badge-primary" style={{width:'30px',color:'white'}}>0</span>
                    </li>
                </>
            )
        }
    }

    return (
        <div className="wrapper">

            <Modal
            show={upload}
            onHide={closeUpload}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title>Upload photo</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <form className="form" encType="multipart/form-data" onSubmit={saveFile}>

                <div className="form-group">
                <label>Picture</label>
                <input className="form-control" type="file" onChange={handleFile} ></input>
                <span className="error_holder">{file.file_error}</span>
                </div>

                <div className="form-group">
                <input type="submit" className="btn btn-primary btn-sm" value="Upload Photo"></input>
                </div>

                </form>

            </Modal.Body>
            </Modal>

            <Modal
            show={open}
            onHide={closeModal}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title>Edit profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <form className="form" onSubmit={updateProfile} >
            <div className="row" >

            <div className="col-md-3 form-group" >
            <label>Initials <span className='red' >*</span></label>
            <select className="form-control" name = "initials" value={profile.initials} onChange={handleProfile}>
            <option>Mr</option>
            <option>Ms</option>
            </select>
            <span className="error_holder"  >{profile.error_list.initials}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>First Name <span className='red' >*</span></label>
            <input type = "text" className="form-control" name="first_name" value={profile.first_name} onChange={handleProfile} />
            <span className="error_holder"  >{profile.error_list.first_name}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Last Name <span className='red' >*</span></label>
            <input type = "text" className="form-control" name="last_name" value={profile.last_name} onChange={handleProfile} />
            <span className="error_holder"  >{profile.error_list.last_name}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Other Name <span className='red' >*</span></label>
            <input type = "text" className="form-control" name="other_name" value={profile.other_name} onChange={handleProfile} />
            <span className="error_holder"  >{profile.error_list.other_name}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Phone <span className='red' >*</span></label>
            <input type = "text" className="form-control" name ="phone" value={profile.phone} onChange={handleProfile} />
            <span className="error_holder"  >{profile.error_list.phone}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Email <span className='red' >*</span></label>
            <input type = "text" className="form-control" name="email" value={profile.email} onChange={handleProfile} />
            <span className="error_holder"  >{profile.error_list.email}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>City / Town <span className='red' >*</span></label>
            <input type = "text" className="form-control" name="city" value={profile.city} onChange={handleProfile} />
            <span className="error_holder"  >{profile.error_list.city}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Postal Address <span className='red' >*</span></label>
            <input type = "text" className="form-control" name="postal_address" value={profile.postal_address} onChange={handleProfile} />
            <span className="error_holder"  >{profile.error_list.postal_address}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>ID No <span className='red' >*</span></label>
            <input type = "text" className="form-control" name="id_no"  value={profile.id_no} onChange={handleProfile} />
            <span className="error_holder"  >{profile.error_list.id_no}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Huduma No <span className='red' >*</span></label>
            <input type = "text" className="form-control" name="huduma_no"  value={profile.huduma_no} onChange={handleProfile} />
            <span className="error_holder"  >{profile.error_list.huduma}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Country <span className='red' >*</span></label>
            <input type = "text" className="form-control" name = "country" value={profile.country} onChange={handleProfile} />
            <span className="error_holder"  >{profile.error_list.country}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Annual Turnover <span className='red' >*</span></label>
            <input type = "number" className="form-control" name="annual_turnover" value={profile.annual_turnover} onChange={handleProfile} />
            <span className="error_holder"  >{profile.error_list.annual_turnover}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>HCS / Team Leader <span className='red' >*</span></label>
            <select className="form-control" name="hcs" value={profile.hcs} onChange={handleProfile} >
            <option value = "not set">Select HCS </option>
            {users.map((user,index)=>{
            return(
            <option value={user.id} key={index}>{user.name}</option>
            )
            })}
            </select>
            <span className="error_holder"  >{profile.error_list.hcs}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Branch <span className='red' >*</span></label>
            <select className="form-control" name="branch" value={profile.branch} onChange={handleProfile} >
            <option>Nairobi</option>
            <option>Mombasa</option>
            </select>
            <span className="error_holder"  >{profile.error_list.branch}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Industry / Market <span className='red' >*</span></label>
            <input type = "text" className="form-control" name="industry" value={profile.industry} onChange={handleProfile} />
            <span className="error_holder"  >{profile.error_list.industry}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Status <span className='red' >*</span></label>
            <select className="form-control" name="status" value={profile.status} onChange={handleProfile} >
            <option>Hot</option>
            <option>Warm</option>
            <option>Cold</option>
            </select>
            <span className="error_holder"  >{profile.error_list.status}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Settlement mode <span className='red' >*</span></label>
            <select className="form-control" name="settlement_mode" value={profile.settlement_mode} onChange={handleProfile} >
            <option>bank</option>
            <option>mpesa</option>
            </select>
            <span className="error_holder"  >{profile.error_list.settlement_mode}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Source <span className='red' >*</span></label>
            <input type = "text" className="form-control" name="source" value={profile.source} onChange={handleProfile} />
            <span className="error_holder"  >{profile.error_list.source}</span>
            </div>

            </div>

            <div className='row' >
            <div className='col-md-12 form-group'>

            <input type = "submit" className="btn btn-primary btn-sm" value = "Update contact" />

            </div>
            </div>
            </form>

            </Modal.Body>
            </Modal>

            <Modal
            show={kin_flag}
            onHide={closeKin}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title>Edit kin</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <form className="form" onSubmit={updateKin} >

            <div className="row"  >

            <div className="col-md-3 form-group" >
            <label>Name <span className='red' >*</span></label>
            <input type = "text" className="form-control" name="name" value={kin.name} onChange={handleKin} />
            <span className="error_holder"  >{kin.error_list.name}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Phone <span className='red' >*</span></label>
            <input type = "text" className="form-control" name="phone" value={kin.phone} onChange={handleKin}  />
            <span className="error_holder"  >{kin.error_list.phone}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Email <span className='red' >*</span></label>
            <input type = "text" className="form-control"  name="email"  value={kin.email}  onChange={handleKin} />
            <span className="error_holder"  >{kin.error_list.email}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>ID / Passport <span className='red' >*</span></label>
            <input type = "text" className="form-control"  name="id"  value={kin.id}  onChange={handleKin}  />
            <span className="error_holder"  >{kin.error_list.id}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Huduma # <span className='red' >*</span></label>
            <input type = "text" className="form-control"  name="huduma"  value={kin.huduma}  onChange={handleKin}  />
            <span className="error_holder"  >{kin.error_list.huduma}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Physical Address <span className='red' >*</span></label>
            <input type = "text" className="form-control"  name="physical_address"  value={kin.physical_address}  onChange={handleKin}  />
            <span className="error_holder"  >{kin.error_list.physical_address}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Relation to kin <span className='red' >*</span></label>
            <input type = "text" className="form-control"  name="relation"  value={kin.relation}  onChange={handleKin}  />
            <span className="error_holder"  >{kin.error_list.relation}</span>
            </div>

            </div>

            <div className="row" >
            <div className="col-md-12">

            <input type = "submit" className="btn btn-primary btn-sm" value="Update kin" />

            </div>
            </div>

            </form>
            
            </Modal.Body>
            </Modal>

            <Modal
            show={new_kin_flag}
            onHide={closeNewKin}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title>New kin</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <form className="form" onSubmit={saveKin} >
            <div className="row"  >

            <div className="col-md-3 form-group" >
            <label>Name <span className='red' >*</span></label>
            <input type = "text" className="form-control" name="name" value={kin.name} onChange={handleKin} />
            <span className="error_holder"  >{kin.error_list.name}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Phone <span className='red' >*</span></label>
            <input type = "text" className="form-control" name="phone" value={kin.phone} onChange={handleKin}  />
            <span className="error_holder"  >{kin.error_list.phone}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Email <span className='red' >*</span></label>
            <input type = "text" className="form-control"  name="email"  value={kin.email}  onChange={handleKin} />
            <span className="error_holder"  >{kin.error_list.email}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>ID / Passport <span className='red' >*</span></label>
            <input type = "text" className="form-control"  name="id"  value={kin.id}  onChange={handleKin}  />
            <span className="error_holder"  >{kin.error_list.id}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Huduma # <span className='red' >*</span></label>
            <input type = "text" className="form-control"  name="huduma"  value={kin.huduma}  onChange={handleKin}  />
            <span className="error_holder"  >{kin.error_list.huduma}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Physical Address <span className='red' >*</span></label>
            <input type = "text" className="form-control"  name="physical_address"  value={kin.physical_address}  onChange={handleKin}  />
            <span className="error_holder"  >{kin.error_list.physical_address}</span>
            </div>

            <div className="col-md-3 form-group" >
            <label>Relation to kin <span className='red' >*</span></label>
            <input type = "text" className="form-control"  name="relation"  value={kin.relation}  onChange={handleKin}  />
            <span className="error_holder"  >{kin.error_list.relation}</span>
            </div>

            </div>

            <div className="row" >
            <div className="col-md-12">
            
            <input type = "submit" value = "New kin" className="btn btn-sm btn-primary" />

            </div>
            </div>
            </form>
            
            </Modal.Body>
            </Modal>

            <Modal
            show={kyc_flag}
            onHide={closeKyc}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title>Edit KYC</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <form className="form" onSubmit={updateKyc} >
            <div className="row" >

            <div className = "col-md-6 form-group" >
            <label>Document</label>
            <input type = "text" className='form-control' name ="document" value={kycData.document} onChange={handleKyc} />
            <span className="error_holder"  >{kycData.error_list.document}</span>
            </div>

            <div className = "col-md-6 form-group" >
            <label>Document #</label>
            <input type = "text" className='form-control' name="document_number" value={kycData.document_number} onChange={handleKyc} />
            <span className="error_holder"  >{kycData.error_list.document_number}</span>
            </div>

            </div>

            <div className="row" >
            <div className = "col-md-6 form-group" >
            <input type="submit" className = "btn btn-sm btn-primary" value="Update Kyc" />
            </div>
            </div>
            </form>

            </Modal.Body>
            </Modal>

            <Modal
            show={NewKycFlag}
            onHide={setNewKycFlag}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title>New Kyc</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            
            <form className = "form" onSubmit={saveKyc} >
            <div className='row' >
    
            <div className='col-md-6 form-group'>
            <label>Document</label>
            <input type="text" className='form-control' name = 'document' onChange={handleDetails} />
            <span className="error_holder"  >{details.error_list.document}</span>
            </div>

            <div className='col-md-6 form-group'>
            <label>Document Number</label>
            <input type="text" className='form-control' name = 'document_number'  onChange={handleDetails} />
            <span className="error_holder"  >{details.error_list.document_number}</span>
            </div>

            </div>

            <div className='row' >
            <div className='col-md-12' >
            <input type = "submit" className = "btn btn-primary btn-sm" value="New Kyc" />
            </div>
            </div>
            </form>

            </Modal.Body>
            </Modal>            
            
            <NewValuation newValuationFlag={newValuationFlag} closeValuation={closeValuation} person={person} fetchValuations={fetchValuations} vehicles={vehicles} valuers={serviceProviders.valuers} />
            <NewVehicle vehicleFlag={vehicleFlag} closeVehicle={closeVehicle} person={person} fetchVehicles={fetchVehicles} />
            <EditVehicle editVehicleFlag={editVehicleFlag} closeEditVehicle={closeEditVehicle} handleVehicle={handleVehicle} vehicleDetails={vehicleDetails} updateVehicle={updateVehicle} />
            <NewScan scan_flag={scan_flag} closeScan={closeScan} person={person} fetchDocuments={fetchDocuments}/>
            <VehicleDocuments carFlag={carFlag} closeCar={closeCar} carDetails={carDetails} uploadDetails={uploadDetails} handleDocument={handleDocument} handleScan={handleScan} uploads={uploads} setUploadDetails={setUploadDetails}  fetchUploads={ fetchUploads} clearData={clearData}/>
            
            <DisplayValuation valuationDisplayFlag={valuationDisplayFlag} closeDisplay={closeDisplay} valuationDetails={valuationDetails} valuationDocuments={valuationDocuments} fetchValuationDocuments={fetchValuationDocuments} />
            <EditValuation editValuationFlag={editValuationFlag} closeEditValuation={closeEditValuation}  valuationRaw={valuationRaw} vehicles={vehicles} handleValuation={handleValuation} setValuationRaw={setValuationRaw} fetchValuations={fetchValuations} valuers={serviceProviders.valuers}  />
            <NewInsurance insuranceFlag={insuranceFlag} closeInsurance={closeInsurance} vehicles={vehicles} insuranceDetails={insuranceDetails} handleInsurance={handleInsurance} setInsuranceDetails={setInsuranceDetails} fetchPolicies={fetchPolicies} insuranceCompanies={serviceProviders.insurance}/>
            <EditInsurance editInsuranceFlag={editInsuranceFlag} closeEditInsurance={closeEditInsurance} insuranceHolder={insuranceHolder} handleInsuranceHolder={handleInsuranceHolder} vehicles={vehicles} setInsuranceHolder={setInsuranceHolder} effectPolicy={effectPolicy} insuranceCompanies={serviceProviders.insurance}/>
            <NewLoan newLoanFlag={newLoanFlag} closeLoan={closeLoan} loanProducts={loanProducts} loanTypes={loanTypes} vehicles={vehicles} loanInitials={loanInitials} handleLoanInitials={handleLoanInitials} setLoanInitials={setLoanInitials} loanDocsInitial={loanDocsInitial} setLoanDocsInitial={setLoanDocsInitial} checks={checks} checkStatus={checkStatus} users={users} profile={profile} />
            <LoanDetails loanEditFlag={loanEditFlag} closeLoanEdit={closeLoanEdit} loanDetails={loanDetails} />

            <Nav/>
            <Aside/>

            <div className="content-wrapper">

            <div className="content-header">
            <div className="container">
            <div className="row mb-2">
            <div className="col-sm-6">

            <Link to="/contacts">
            <span className='btn btn-sm btn-dark'  >
            <span className='fa fa-arrow-left' ></span> Back
            </span>
            </Link>

            </div>

            </div>
            </div>
            </div>

            <div className='content' >
            <div className='container-fluid' >

            <div className='row'>
            
            <div className='col-md-4' >
            
            <div className="card card-primary card-outline">
            
            <div className="card-body box-profile">
                
            <div className="text-center" style={{height:'200px',overFlow:'hidden'}} >
            <img className=" img-fluid " src={logo} style={{width:'180px'}} />
            </div>

            <div className="card-header">
            
            <span className="btn btn-success btn-sm float-right m-1" onClick={()=>setUpload(true)} >
            <span className="fa fa-upload"></span> Upload photo
            </span>
            
            </div>

                <h3 className="profile-username text-center">{contact.salutation} {contact.first_name} {contact.last_name}</h3>

                <ul className="list-group list-group-unbordered mb-3">
                <li className="list-group-item" onClick={()=>setTab("profile")}>
                <b>Profile</b>
                </li>

                <li className="list-group-item" onClick={()=>setTab("kin")} >
                <b>Next of Kin</b>
                </li>

                <li className="list-group-item" onClick={()=>setTab("kyc")} >
                <b>KYC</b>
                </li>

                <li className="list-group-item" onClick={()=>setTab("scans")} >
                <b>KYC Uploads</b>
                </li>

                </ul>
                </div>
              
            </div>

            </div>

            <div className='col-md-8' >
            
            <ProfileHolder ProfileData={ProfileData} Kin={Kin}  Kyc={Kyc} Scans={Scans} tab={tab} id={person} setContact={setContact} profile={profile} kin_info={kin_info} setKinFlag={setKinFlag}  fetchKin={fetchKin} kyc={kyc} setKycFlag={setKycFlag} initializeKyc={initializeKyc} NewKycFlag={NewKycFlag} setNewKycFlag={setNewKycFlag} fetchKyc={fetchKyc} setScanFlag={setScanFlag} documents={documents} fetchDocuments={fetchDocuments} setNewKin={setNewKin} />

            </div>

            </div>

            <div className='row'>
            
            <div className='col-md-4' >

            <div class="card card-primary card-outline">
            
            <div class="card-body box-profile">
            <ul class="list-group list-group-unbordered mb-3">
            
            <li class="list-group-item" onClick={()=>setSummary('statement')} >
            <b><span className='fa fa-file' ></span> Account Statement</b>
            </li>

            <li className="list-group-item"  onClick={()=>setSummary('loans')} >
            <b><span className='fa fa-file'></span> Loans</b>
            <span className="float-right badge badge-primary" style={{width:'30px',color:'white'}}>{loans.length}</span>
            </li>

            <li className="list-group-item" onClick={()=>setSummary('valuations')} >
            <b><span className='fa fa-balance-scale' ></span> Valuations</b>
            <span className="float-right badge badge-primary" style={{width:'30px',color:'white'}}>{valuations.length}</span>
            </li>

            <li className="list-group-item" onClick={()=>setSummary('vehicles')}>
            <b><span className='fa fa-car' ></span> Vehicles</b>
            <span className="float-right badge badge-primary" style={{width:'30px',color:'white'}}>{vehicles.length}</span>
            </li>

            <li className="list-group-item" onClick={()=>setSummary('insurance')} >
            <b><span className='fa fa-file-invoice' ></span> Insurance</b>
            <span className="float-right badge badge-primary" style={{width:'30px',color:'white'}}>{policies.length}</span>
            </li>

            {settlementTab()}

            </ul>
            </div>
            </div>

            </div>

            <div className='col-md-8' >
            <SummaryHolder summaryTab={summaryTab} setVehicleFlag={setVehicleFlag} vehicles={vehicles} fetchVehicleDetails={fetchVehicleDetails} fetchCarDetails={fetchCarDetails} setValuationFlag={setValuationFlag} valuations={valuations} fetchValuationDetails={fetchValuationDetails} updateValuation={updateValuation} newLoanFlag={newLoanFlag} startLoan={startLoan} setInsuranceFlag={setInsuranceFlag} policies={policies} updateInsurance={updateInsurance} fetchPolicies={fetchPolicies} loans={loans} viewLoan={viewLoan} Settlements={Settlements} mpesa={mpesa} handleMpesa={handleMpesa} saveMpesa={saveMpesa} bankAccount={bankAccount} handleBank={handleBank} saveBank={saveBank} statement={statement} profile={profile}/>
            </div>

            </div>

            </div>
            </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default Profile;