import StageMonitor from "../../elements/StageMonitor";

const LoanSummary=({details,approvals,customer,setEdit})=>{

return (
    <div className="card card-primary">

    <div className="card-header">
    <h5><span className="fa fa-clipboard"></span> Loan Notes  </h5>
    </div>

    <div className="card-body">

    <div className="row bg-dark" style={{height:'90px',marginBottom:'20px',paddingTop:'10px'}}>
    
    <div className="col-md-12" >
            
            <div className="details-container" >
            
            <StageMonitor status={approvals.level_one} stage="1" />
            <p ><span className="fa fa-user-circle" ></span> Relationship Officer</p>
            </div>

            <div className="details-container" >
            <StageMonitor status={approvals.level_two} stage="2" />
            <p><span className="fa fa-user-circle" ></span> HCS</p>
            </div>
    
            <div className="details-container" >
            <StageMonitor status={approvals.level_three} stage="3" />
            <p><span className="fa fa-user-shield" ></span> Finance</p>
            </div>

            <div className="details-container" >
            <StageMonitor status={approvals.level_four} stage="4" />
            <p><span className="fa fa-user-shield" ></span> C.E.O</p>
            </div>
    
            <span className="progress-line"></span>
    
            </div>

    </div>

    <div className="row">

    <div className="col-md-6">

    <p className="small-font" >
    <span className="label-holder" >Date Disbursed:</span> {details.date_disbursed}
    </p>

    <p className="small-font" >
    <span className="label-holder" >Due Date:</span> {details.due_date}
    </p>

    <p className="small-font" >
    <span className="label-holder" >Loan No:</span> {details.loan_code}
    </p>
    
    <p className="small-font" >
    <span className="label-holder" >Loan Product:</span> {details.loan_product}
    </p>

    <p className="small-font" >
    <span className="label-holder" >Loan Type:</span> {details.loan_type}
    </p>

    <p className="small-font" >
    <span className="label-holder" >Installment:</span>KES {parseInt(details.installment).toLocaleString('en-US')}
    </p>

    </div>

    <div className="col-md-6">

    <p className="small-font" >
    <span className="label-holder" >Over payment: </span> KES {parseInt(details.credit).toLocaleString('en-US')}
    </p>

    <p className="small-font" >
    <span className="label-holder" ><b>Arrears: </b></span><b>KES {parseInt(details.amount_due).toLocaleString('en-US')}</b>
    </p>

    <p className="small-font" >
    <span className="label-holder" >Loan Balance:</span>KES {parseInt(details.balance-details.credit).toLocaleString('en-US')}
    </p>

    <p className="small-font" >
    <span className="label-holder" >Approval:</span>{details.approval}
    </p>    

    <p className="small-font" >
    <span className="label-holder" >Payment mode:</span>{details.pay_mode}
    </p>

    <p className="small-font" >
    <span className="label-holder" >Status:</span>{details.status}
    </p>

    </div>
   

    </div>

    </div>
    </div>
)

}

export default LoanSummary;