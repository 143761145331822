import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

import EditLeadModal from '../elements/modals/leads/EditLeadModal';
import NewLeadModal from '../elements/modals/leads/NewLeadModal';
import CommentsModal from '../elements/modals/leads/CommentsModal';

import StatusDecoration from '../elements/decorations/StatusDecoration';
import MoneyDecoration from '../elements/decorations/MoneyDecoration';
import DateDecoration from '../elements/decorations/DateDecoration';
import Pagination from '../elements/Pagination';


function ValuationsView()
{
    const [valuations,setValuations] = useState([])
    const effectRun = useRef(false)
    const navigate = useNavigate();

    const fetchValuations=()=>{
    
    axios.post(`api/valuations`).then((res)=>{
        if(res.data.status === 200)
        {
            setValuations(res.data.valuations)
        }
    }).catch(error=>{

        if(error == "AxiosError: Request failed with status code 403")
        {
          navigate('/unauthorized');
        }
    })

    }

    // Use effect to initialize critical data
    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchValuations()
    }

    return()=>{
    effectRun.current = true
    }

    },[])

   

    return (

        <div className="wrapper">
        
        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        

        <div className="content">
        <div className="container">

        <div className="row" style={{marginTop:'20px'}} >
        <div className = "col-lg-12" >

        <div className="card card-primary card-outline">
        <div className="card-header">
        <h6 className="card-title">Valuations</h6>
        </div>
        <div className="card-body">

        <table className="table" >

        <thead>
        <tr className='small-row' >
        <th>#</th>
        <th>Date</th>
        <th>Contact</th>
        <th>Valuer</th>
        <th>Vehicle</th>
        <th>Forced Value</th>
        <th>Market Value</th>
        <th>Payment</th>
        <th>Status</th>
        </tr>
        </thead>

        <tbody>
        {valuations.map((item,index)=>{
            return(
            <tr className='small-row' key={index} >
                <td>{index+1}</td>
                <td>{item.created_at.substr(0,10)}</td>
                <td>{item.first_name} {item.last_name}</td>
                <td>{item.name} <br></br><small>({item.organization})</small> </td>
                <td>{item.make} <br></br> <small>{item.registration}</small> </td>
                <td>KES {parseInt(item.forced_value).toLocaleString('en-US')}</td>
                <td>KES {parseInt(item.market_value).toLocaleString('en-US')}</td>
                <td>{item.payment} <br></br> <small>KES {parseInt(item.cost).toLocaleString('en-US')}</small> </td>
                <td>{item.status}</td>
            </tr>
            )
        })}
        </tbody>

        </table>    
                
        </div>

        <div className='card-footer' >
        
        </div>

        </div>

        </div>
        </div>

        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default ValuationsView;