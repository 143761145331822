import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import swal from 'sweetalert';

import user_image from './../../img/user1-128x128.jpg';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

import CardHeader from '../elements/cards/CardHeader';
import CardBody from '../elements/cards/CardBody';

import NewDepartmentModal from '../elements/modals/departments/NewDepartmentModal';
import EditDepartmentModal from '../elements/modals/departments/EditDepartmentModal';

function Departments()
{
    // Keep track of all departments from the database
    const [departments, setDepartments] = useState([]);

    // Keep track of department modal state
    const[open,setOpen] = useState(false);
    
    // Keep track of edit department modal state
    const[openEdit,setOpenEdit] = useState(false);

    // Keep track of all departments fields
    const [departmentDetails, setDepartmentDetails] = useState({
        id:'',
        department:'',
        description:'',
        error_list:[],
    });

     // Close New Department modal
     const onClose = () =>{
        setOpen(false)
        setDepartmentDetails({
            id:'',
            department:'',
            description:'',
        })
    }

    // Close Edit Department modal
    const onCloseEdit = () =>{
        setOpenEdit(false)
        setDepartmentDetails({
            id:'',
            department:'',
            description:'',
        })
    }

    // Function to edit the selected department
    const editDepartment = (id)=>{

        // Open the edit modal
        setOpenEdit(true);

        setDepartmentDetails({
            id:departments[id].id,
            department:departments[id].department,
            description:departments[id].description,
            error_list:[],
        })

    }

    // Function to update department
    const updateDepartment = (e) =>{

        e.preventDefault()

        const data = {
            id:departmentDetails.id,
            department: departmentDetails.department,
            description: departmentDetails.description,
        }

        axios.get(`sanctum/csrf-cookie`).then(request =>{
            axios.post(`api/update_department`,data).then(res=>{
                if(res.data.status === 200)
                {
                    swal('Success',res.data.message,"success")
                    setDepartmentDetails({
                        department:'',
                        description:'',
                        error_list:[],
                    })

                    setOpenEdit(false)
                    refreshDepartments()
                }
                else{

                    setDepartmentDetails({...departmentDetails, error_list: res.data.validation_errors})

                }
            })
        });

    }

    // Fetch and set derpatments from database

    const refreshDepartments = ()=>{

        axios.get(`sanctum/csrf-cookie`).then(request=>{

            axios.post(`api/departments`,).then(res=>{
                
                if(res.data.status === 200)
                {
                    setDepartments(res.data.departments)
                }

            });
    
        });

    }

    useEffect(()=>{
        refreshDepartments()
    },[]);


    // Update department details state
    const handleDepartment = (e)=>{
        setDepartmentDetails({...departmentDetails, [e.target.name]: e.target.value})
    }

    // Save department
    const saveDepartment = (e) =>{

        e.preventDefault()

        const data = {
            department:departmentDetails.department,
            description:departmentDetails.description,
        }

        axios.get(`sanctum/csrf-cookie`).then(request=>{
                axios.post(`api/new_department`,data).then(req=>{

                if(req.data.status === 200)
                {
                   
                    setOpen(false)
                    swal('Success',req.data.message,"success")
                    setDepartmentDetails({
                        department:'',
                        description:'',
                        error_list:[],
                    })
                    refreshDepartments()

                }else{
                    setDepartmentDetails({...departmentDetails, error_list: req.data.validation_errors})
                }

                })
        });

    }
   
    return (
        <div className="wrapper">
        
        <EditDepartmentModal open={openEdit} onClose={onCloseEdit} departmentDetails={departmentDetails} handleDepartment={handleDepartment} saveDepartment={updateDepartment} />
        <NewDepartmentModal open={open} onClose={onClose} departmentDetails={departmentDetails} handleDepartment={handleDepartment} saveDepartment={saveDepartment} />
        
        <Nav/>
        <Aside/>

        <div className="content-wrapper">

        <ContentHeader title="Departments" />

        <div className="content">
        <div className="container">
        <div className="row">
            
        <div className="col-lg-3"  >
        
        <div className="card card-widget widget-user-2"  >
        <CardBody/>
        </div>

        </div>

        <div className = "col-lg-9" id = "centralSettings">
        
        <div className="card card-primary card-outline">
        <div className="card-header">
        <h5 className="card-title m-0"> <span className='fa fa-building' ></span> Departments <span className='right btn btn-xs btn-primary' onClick={()=>setOpen(true)} ><span className='fa fa-plus-circle' ></span> New Department</span></h5>
        </div>

        <div className="card-body">

        <table className="table" id="example1">

        <thead>
        <tr style= {{fontWeight : 'bold', fontSize: '14px'}} >
        <td>#</td>
        <td>Department</td>
        <td>Descriptions</td>
        <td> <span className="fa fa-edit" ></span> </td>
        <td> <span className="fa fa-chart-bar" ></span> </td>
        </tr>
        </thead>

        <tbody >

        {departments.map((val,index)=>{
            return(<tr style= {{fontSize: '14px'}} key={val.id} >
                <td>{index+1}</td>
                <td>{val.department}</td>
                <td>{val.description}</td>
                <td> <span className='fa fa-edit' onClick={()=>editDepartment(index)}></span> </td>
                <td> <span className='fa fa-chart-bar' ></span> </td>
                </tr>);
        })}

        </tbody>

        <tfoot></tfoot>

        </table>    
                
        </div>

        <div className='card-footer' ></div>

        </div>

        </div>

        </div>
        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default Departments;