const OrganizationSummary=({details,manager,setEditOrg})=>{

return(
    <div className="card card-primary card-outline">

    <div className="card-header">
    <span className="fa fa-clipboard"></span> Summary
    <span className="btn btn-success btn-sm" style={{float:'right'}} onClick={()=>setEditOrg(true)}><span className="fa fa-edit"></span> Edit Organization</span>
    </div>
    <div className="card-body">

    <div className="row">
    
    <div className="col-md-6">
    <p className="label-block">
    <span className="label-holder">Organization</span> {details.name}
    </p>

    <p className="label-block">
    <span className="label-holder">Phone</span>{details.phone}
    </p>

    <p className="label-block">
    <span className="label-holder">Email</span>{details.email}
    </p>

    <p className="label-block">
    <span className="label-holder">Website</span>{details.website}
    </p>
    </div>

    <div className="col-md-6">
    <p className="label-block">
    <span className="label-holder">Postal Address</span>{details.postal_address}
    </p>

    <p className="label-block">
    <span className="label-holder">City</span>{details.city}
    </p>

    <p className="label-block">
    <span className="label-holder">Account Manager</span> {manager.name}
    </p>

    <p className="label-block">
    <span className="label-holder">Status</span>{details.status}
    </p>
    </div>

    </div>


    </div>
    </div>
)

}

export default OrganizationSummary;