import React, { useEffect, useState } from 'react';
import axios from "axios";
import swal from "sweetalert";
import ApproveAction from '../../elements/ApproveAction';

const LoanComments=({loan,comments,fetchComments,approver,fetchApprovals})=>{

const [comment,setComment] = useState('')

const handleComment=(e)=>{
    e.persist()
    setComment(e.target.value)
}

// Function to create a new comment
const newComment=()=>{

const data = {
    loan:loan,
    message: comment,
    person:localStorage.getItem('auth_id')
}

if(data.comment === "")
{
    swal("Warning","Comment cannot be blank","warning")
}
else{

    axios.post(`api/new_loan_comment`,data).then((res)=>{

        if(res.data.status === 200)
        {
            swal("Success",res.data.message,"success")
            setComment('')
            fetchComments()
        }
        
        })

}

}

// Function to delete comment
const deleteComment=(id)=>{

const data = {
    comment:id,
    commentor:localStorage.getItem('auth_id')
}

    axios.post(`api/delete_loan_comment`,data).then((res)=>{
    if(res.data.status === 200)
    {
        swal("Success",res.data.message,'success')
        fetchComments()
    }
    else
    {
        swal("Warning",res.data.message,'warning')
    }

})

}


return (
<div className="card card-primary">
<div className="card-header">
<h5><span className="fa fa-comment"></span> Comments & Approval</h5>
</div>

<div className="card-body">     

<ApproveAction loan={loan} approver={approver} fetchApprovals={fetchApprovals} fetchComments={fetchComments} />

<div className="card card-dark card-outline">

<div className="direct-chat-messages">

{comments.map((item,index)=>{

return(
<div className="direct-chat-msg left"  style={{marginBottom:'30px'}} >
<div className="direct-chat-infos clearfix">
<span className="direct-chat-name float-left"> <b>{item.commentor}</b></span>
<span className="direct-chat-timestamp float-right">{item.entry_time} <span className='fa fa-trash' style={{marginLeft:'10px',color:'red',fontSize:'13px'}} onClick={()=>deleteComment(item.id)} ></span></span>
</div>
                      
<img className="direct-chat-img" src="../../dist/img/user4-128x128.jpg" alt="message user image" />
<div className="direct-chat-text">{item.comment}</div>
</div>
)

})}

</div>

<form className="form">

<div className="col-md-12 form-group">
<label>Comment</label>
<input type = "text" className="form-control" name="comment" value={comment} onChange={handleComment} />
</div>

<div className="col-md-12 form-group">
<span className="btn btn-primary btn-md" onClick={()=>newComment()}>Post comment</span>
</div>

</form>

</div>

</div>

<div className="card-footer">
</div>
</div>
)
}
export default LoanComments;