import Loan_One from "./LoanStages/Loan_One";
import Loan_Two from "./LoanStages/Loan_Two";
import Stage_Zero from "./LoanStages/Stage_Zero";

const FetchLoanStage=({stage,loanProducts,loanTypes,vehicles,loanInitials,handleLoanInitials,handleDocument,handleScan,loanDocsInitial,uploadDocument,loanDocuments,fetchDocuments,checks,checkStatus,users,profile})=>{

if(stage === 0)
{
    return(<Stage_Zero checks={checks} checkStatus={checkStatus} />)
}else if(stage === 1)
{
    return(<Loan_One loanProducts={loanProducts} loanTypes={loanTypes} vehicles={vehicles} loanInitials={loanInitials} handleLoanInitials={handleLoanInitials} users={users} profile={profile} />)
}else if(stage === 2)
{
    return(<Loan_Two handleDocument={handleDocument} handleScan={handleScan} loanDocsInitial={loanDocsInitial} uploadDocument={uploadDocument} loanDocuments={loanDocuments} fetchDocuments={fetchDocuments}/>)
}

}

export default FetchLoanStage;