import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import SettlementModal from '../elements/modals/payday/Settlement/SettlementModal';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

const PendingDisbursal=()=>{

    const [open,setOpen] = useState(false)
    const [reference,setReference] = useState('')
    const [list,setList] = useState([])
    const [mode,setMode] = useState('')
    const [loan,setLoan] = useState('')

    const [bankMode,setBankMode] = useState({
        bank:'',
        account_name:'',
        account_number:'',
        branch:'',
        bank_code:'',
        branch_code:'',
        swift_code:'',
    })

    const [mpesa,setMpesa] = useState({
        mpesa_number:''
    })

    const handleReference=(e)=>{
    setReference(e.target.value)
    }

    const onClose=()=>{
    setOpen(false)

    setBankMode({
        bank:'',
        account_name:'',
        account_number:'',
        branch:'',
        bank_code:'',
        branch_code:'',
        swift_code:'',
    })

    setMpesa({
        mpesa_number:''
    })

    setReference('')
    setLoan('')

    }

    const fetchSettlement=(id)=>{
    
    const data = {
    id:id
    }

    axios.post(`api/settlement_account`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        const info = res.data.details

        setBankMode({
        bank: info.bank,
        account_name: info.account_name,
        account_number: info.account_number,
        branch: info.branch,
        bank_code: info.bank_code,
        branch_code: info.branch_code,
        swift_code: info.swift_code,
        })

        setOpen(true)

    }

    })

    }

    const fetchMpesa=(id)=>{
    
        const data = {
        id:id
        }
    
        axios.post(`api/settlement_mpesa`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
            const info = res.data.details
            setMpesa({
            mpesa_number:info.phone_number
            })
            setOpen(true)
    
        }
    
        })
    
        }

    const activateModal=(staff_mode,staff_id,loan)=>{
        
    setMode(staff_mode)
    setLoan(loan)
    
    if(staff_mode == "bank")
    {
        fetchSettlement(staff_id)
    }else if(staff_mode == "mpesa")
    {
        fetchMpesa(staff_id)
    }

    }

    const fetchList=()=>{
    
    axios.post(`api/disbursal_list`).then((res)=>{
    
    if(res.data.status === 200)
    {
        setList(res.data.loans)
    }

    })

    }

    const recordDisbursal=()=>{

    const data = {
        loan: loan,
        mode: mode,
        reference: reference
    }

    axios.post(`api/record_disbursal`,data).then((res)=>{
        if(res.data.status === 200)
        {
            swal("Success",res.data.message,"success")
            onClose()
            fetchList()
        }
    })

    }

    useEffect(()=>{
    fetchList()
    },[])

    return (
        <div className="wrapper">

            <SettlementModal open={open} onClose={onClose} mode={mode} bankMode={bankMode} mpesa={mpesa} reference={reference} handleReference={handleReference} recordDisbursal={recordDisbursal} />

            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>Pending Disbursals</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='row'>
                <div className='col-md-12'>

                <div className='card card-primary card-outline'>
                <div className='card-header'>
                Disbursal List
                </div>

                <div className='card-body'>
                <table className='table'>
                    <thead>
                    <tr className='small-row'>
                    <th>#</th>
                    <th>Date</th>
                    <th>Loan</th>
                    <th>Organization</th>
                    <th>Staff</th>
                    <th>Amount</th>
                    <th>Rate</th>
                    <th>Period</th>
                    <th>Installment</th>
                    <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>

                    {list.map((item,index)=>{
                    
                    return(
                    <tr className='small-row' key={index}>
                    <td>{index+1}</td>
                    <td>{item.created_at.substr(0,10)}</td>
                    <td>{item.loan_code}</td>
                    <td>{item.name}</td>
                    <td>{item.first_name} {item.other_name} {item.last_name}</td>
                    <td>KES {parseInt(item.amount_loanable).toLocaleString('en-US')}</td>
                    <td>{item.interest_rate} %</td>
                    <td>{item.period} Months</td>
                    <td>KES {parseInt(item.installment).toLocaleString('en-US')}</td>
                    <td><span className='btn btn-primary btn-xs' onClick={()=>activateModal(item.settlement_mode,item.staff_id,item.id)}> Disburse</span></td>
                    </tr>
                    )

                    })}

                    </tbody>
                </table>
                </div>

                </div>

                </div>
                </div>

                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}



export default PendingDisbursal;