import DateDecoration from '../elements/decorations/DateDecoration';
import StatusDecoration from '../elements/decorations/StatusDecoration';

function LeadsTable({leads,lowerLimit,upperLimit,editLead,StatusDecoration,MoneyDecoration,openComments})
{
    return(
        <table className="table" >

        <thead>
        <tr style= {{fontWeight : 'bold', fontSize: '14px'}} >
        <td>#</td>
        <td>Date</td>
        <td>Prospect</td>
        <td>Phone</td>
        <td>Type</td>
        <td>Amount Requested</td>
        <td>Collateral</td>
        <td>Source</td>
        <td> <span className="fa fa-edit" ></span> </td>
        <td> <span className='fa fa-comment-alt' ></span> </td>
        </tr>
        </thead>

        <tbody>

    
        {leads.map((val,index)=>{

        let marker = index+1
        
        if(marker >= lowerLimit && marker <= upperLimit)
        {
        
            return(
                <tr style={{fontSize:'14px'}} key={index} >
                <td>{index+1}</td>
                <td>{DateDecoration(val.created_at)}</td>
                <td>{StatusDecoration(val.prospect_type)} {val.prospect}</td>
                <td>{val.phone}</td>
                <td>{val.loan_type}</td>
                <td>{MoneyDecoration(val.req_amount)}</td>
                <td>{val.collateral}</td>
                <td>{val.source}</td>
                <td> <span className='fa fa-edit' onClick={()=>editLead(index)} ></span> </td>
                <td> <span className='fa fa-comment-alt' onClick={()=>openComments(index)} ></span> </td>
            </tr>
            )

        }

        })}
        </tbody>

        </table>
    )
}

export default LeadsTable;