const Settlements =({mpesa,handleMpesa,saveMpesa,bankAccount,handleBank,saveBank})=>{

return(
    <div className='card card-primary card-outline'>

    <div className='card-header'>
    <h5> <span className="fa fa-file-invoice" ></span> Settlement Accounts
    </h5>
    </div>

    <div className='card-body'>

        <div className="row mt-2">
            <div className="col-md-12">
                <div className="card card-primary card-outline">
                    <div className="card-header">
                        <h6>Mpesa</h6>
                    </div>
                    <div className="card-body">
                        <form className="form" onSubmit={saveMpesa}>

                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label>Mpesa Number <span className="red">*</span></label>
                                    <input type="text" className="form-control" name="mpesa_account" value={mpesa.mpesa_account} onChange={(e)=>handleMpesa(e)}></input>
                                    <span className="error_holder">{mpesa.error_list.mpesa_account}</span>
                                </div>

                                <div className="col-md-12 form-group">
                                    <input type="submit" className="btn btn-primary btn-sm" value="Update Mpesa"></input>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="card-footer"></div>
                </div>
            </div>
        </div>

        <div className="row mt-1">
            <div className="col-md-12">
            <div className="card card-outline card-primary">
            <div className="card-header">
                <h6>Bank Details</h6>
            </div>
            <div className="card-body">
            <form className="form" onSubmit={saveBank}>

                <div className="row">

                <div className="col-md-4 form-group">
                   <label>Bank  <span className="red">*</span></label>
                   <input type="text" className="form-control" name="bank" value={bankAccount.bank} onChange={handleBank} ></input>
                   <span className="error_holder">{bankAccount.error_list.bank}</span>
               </div>

               <div className="col-md-4 form-group">
                   <label>Account Name  <span className="red">*</span></label>
                   <input type="text" className="form-control" name="account_name" value={bankAccount.account_name} onChange={handleBank}></input>
                   <span className="error_holder">{bankAccount.error_list.account_name}</span>
               </div>

               <div className="col-md-4 form-group">
                   <label>Account Number  <span className="red">*</span></label>
                   <input type="text" className="form-control"  name="account_number" value={bankAccount.account_number} onChange={handleBank} ></input>
                   <span className="error_holder">{bankAccount.error_list.account_number}</span>
               </div>

               <div className="col-md-4 form-group">
                   <label>Branch</label>
                   <input type="text" className="form-control"  name="branch" value={bankAccount.branch} onChange={handleBank} ></input>
               </div>

               <div className="col-md-4 form-group">
                   <label>Bank code</label>
                   <input type="text" className="form-control"  name="bank_code" value={bankAccount.bank_code} onChange={handleBank} ></input>
               </div>

               <div className="col-md-4 form-group">
                   <label>Branch Code</label>
                   <input type="text" className="form-control"  name="branch_code" value={bankAccount.branch_code} onChange={handleBank} ></input>
               </div>

               <div className="col-md-4 form-group">
                   <label>Swift Code</label>
                   <input type="text" className="form-control"  name="swift_code" value={bankAccount.swift_code} onChange={handleBank} ></input>
               </div>

               <div className="col-md-12 form-group">
                   <input type="submit" className="btn btn-primary btn-sm" value="Update Details"></input>
               </div>

                </div>
            </form>
            </div>
            <div className="card-footer"></div>
            </div>
            </div>
        </div>

    </div>

    <div className='card-footer'>
        
    </div>

    </div>
)

}
export default Settlements;