import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

function WatchList(){

    const effectRan = useRef(false)
    const [loans, setLoans] = useState([])

    const analysis=()=>{

        axios.get(`api/watchlist`).then((res)=>{
            if(res.data.status === 200)
            {
                setLoans(res.data.loans)
            }
        })

    }

    useEffect(()=>{
        
        if(effectRan.current === false)
        {
            analysis()
        }

        return()=>{
        effectRan.current = true
        }

    },[])

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>Loan Anaysis</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='row' >
                    <div className='col-md-12' >
                        <div className='card card-primary card-outline'>
                            <div className='card-body' >
                            <table className='table'>
                            <thead>
                                <tr className='small-row' >
                                    <td>#</td>
                                    <td>Customer</td>
                                    <td>Loan</td>
                                    <td>Initial Balance</td>
                                    <td>Current Balance</td>
                                    <td>Difference</td>
                                    <td>Current Arrears</td>
                                    <td>Debits</td>
                                    <td>Credits</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loans.map((loan, index)=>{
                                        return(
                                            <tr key={index} className='small-row'>
                                            <td>{index+1}</td>
                                            <td>{loan.client}</td>
                                            <td>{loan.loan}</td>
                                            <td style={{color:'white', backgroundColor:'grey'}} >KES {parseInt(loan.initial_balance).toLocaleString('en-US')}</td>
                                            <td style={{color:'white', backgroundColor:'green'}} >KES {parseInt(loan.current_balance).toLocaleString('en-US')}</td>
                                            <td>KES {parseInt(loan.difference).toLocaleString('en-US')}</td>
                                            <td style={{color:'white', backgroundColor:'green'}}  >KES {parseInt(loan.current_arrears).toLocaleString('en-US')}</td>
                                            <td>KES {parseInt(loan.invoices).toLocaleString('en-US')}</td>
                                            <td>KES {parseInt(loan.payments).toLocaleString('en-US')}</td> 
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                            </div>
                        </div>
                    </div>
                </div>

                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default WatchList;