import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

import NewContactModal from '../elements/modals/contacts/NewContactModal';
import { FormCheck } from 'react-bootstrap';

function Contacts(){

    const navigate = useNavigate();
    const effectRun = useRef(false)
    const [open,setContact] = useState(false)               // State to track modal
    const [contactList,setContactList] = useState([])       // Array of all listed contacts
    const [links,setLinks] = useState([])
    const [phrase,setPhrase] = useState('')

    const handlePhrase=(e)=>{
    setPhrase(e.target.value)
    }

    const searchContacts=(e)=>{
        e.preventDefault()
        const url = 'api/search_contacts/'+phrase

        axios.get(url).then((res)=>{
            setContactList(res.data.contacts.data)
            setLinks(res.data.contacts.links)
        })
    }
    
    const closeModal =()=>{                                 // Set modal state to false
        setContact(false)
    }

    const fetchContacts=()=>{                               // Set contacts
    
        axios.post(`api/contacts`).then((res)=>{
        if(res.data.status === 200)
        {
            setContactList(res.data.contacts.data)
            setLinks(res.data.contacts.links)
        }
        }).catch(error=>{
            if(error == "AxiosError: Request failed with status code 401")
            {
              localStorage.clear()
              navigate('/login');
            }
        })

    }

    const fecthPage=(label)=>{
        var url = "api/contacts?page="+label
        axios.post(url).then((res)=>{
            if(res.data.status === 200)
            {
                setContactList(res.data.contacts.data)
                //setLinks(res.data.contacts.links)
            }
            }).catch(error=>{
                if(error == "AxiosError: Request failed with status code 401")
                {
                  localStorage.clear()
                  navigate('/login');
                }
            })
    }   

    useEffect(()=>{

    if(effectRun.current === false)
    {
        fetchContacts()
        return()=>{
            effectRun.current = true
        }
    }

    },[])

    return (
        <div className="wrapper">

            <NewContactModal open={open} closeModal={closeModal} fetchContacts={fetchContacts} />

            <Nav/>
            <Aside/>

            <div className="content-wrapper">

            <div className="content-header">
            <div className="container">
            <div className="row mb-2">
            <div className="col-sm-6">
              
            </div>

            <div className="col-sm-6">
            <div className="float-sm-right">
            <form className="form-inline ml-0 ml-md-3">
            <div className="form-group input-group-sm">
            <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" value={phrase} onChange={handlePhrase} />
            <div className="input-group-append">
            <button className="btn btn-dark btn-navbar-dark" type="submit" onClick={(e)=>searchContacts(e)} >
            <i className="fas fa-search"></i>
            </button>
            </div>
            </div>
            </form>
            </div>
            </div>

          </div>
        </div>
      </div>

            <div className='content' >
                <div className='container-fluid' >
                <div className='col-md-12' >

                    <div className='card card-default' >

                    <div className='card-header' >
                    <h5 className="m-0 "><span className='fa fa-users' ></span> Contacts</h5>
                    </div>

                    <div className='card-body' >
                    <div className='container'>

                    <div className='row' >
                    <div className='col-md-12'>
                    <div className='card card-primary card-outline' >
                    <div className='card-header' >
                    <h6 style={{width:'100%'}} >Listed contacts 
                    <span style={{float:'right'}} >
                    <span className='btn btn-sm btn-success' style={{margin:'1px'}} onClick={()=>setContact(true)} > <span className='fa fa-plus-circle' ></span> New contact</span> 
                    
                    </span></h6>
                    </div>

                    <div className='card-body' >
                        <table className='table' >
                            <thead>
                                <tr className='small-row' >
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>ID</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Listed by</th>
                                    <th> <span className='fa fa-eye'></span> More</th>
                                </tr>
                            </thead>

                            <tbody>
                            {contactList.map((person,index)=>{

                            let marker = index+1
        
                            return(
                                <tr key={index}  className='small-row'  >
                                <td>{person.id}</td>
                                <td>{person.first_name} {person.other_name} {person.last_name}</td>
                                <td>{person.id_no}</td>
                                <td>{person.phone}</td>
                                <td>{person.email}</td>
                                <td>{person.name}</td>
                                <td><Link to={`/profile/${person.id}`} ><span className='fa fa-eye'></span> More </Link></td>
                                </tr>
                            )

                            })}
                            </tbody>
                            
                        </table>
                        
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    
                    <div className='card-footer' >
                    <ul className="pagination pagination-sm m-0 float-left">
                    {links.map((item,index)=>{
                    if(item.url !== null)
                    {
                        if(index !== (links.length-1))
                        {
                           if(item.active)
                           {
                            return(<li className="page-item active" key={index} onClick={()=>fecthPage(item.label)}  ><a className="page-link">{index}</a></li>)
                           }
                           else
                           {
                            return(<li className="page-item" key={index} onClick={()=>fecthPage(item.label)}  ><a className="page-link">{index}</a></li>)
                           }
                        }
                    }
                    })}
                    </ul>
                    </div>

                    </div>

                </div>
                </div>
            </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )

       
}

export default Contacts;