import React, {useState, useEffect} from 'react';
import useAuth from '../../hooks/useAuth';

import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import swal from 'sweetalert';

function Login(){

    const navigate = useNavigate();
    const {setAuth} = useAuth()

    useEffect(() =>{
        if(localStorage.getItem('auth_token'))
        {
             navigate('/')
        }
    },[])
    
    const [loginInput, setLogin] = useState({
        email: '',
        password: '',
        error_list: [],
    });
    
    const handleInput = (e) => {
        e.persist();
        setLogin({...loginInput, [e.target.name]: e.target.value})
    }

    const togglePassword=()=>{
    
        const myElement = document.getElementById("password");
        const attribute = myElement.getAttribute("type");

        const eye = document.getElementById("eye");

        if(attribute == "password")
        {
            myElement.setAttribute("type","text")
            eye.className = "fa fa-eye-slash"
        }
        else
        {
            myElement.setAttribute("type","password")
            eye.className = "fa fa-eye"
        }

    }
    
    const loginSubmit = (e) => {
        e.preventDefault();
    
    const data = {
        email: loginInput.email,
        password: loginInput.password
    }
    
        axios.post(`api/login`,data).then(res => {
    
                if(res.data.status === 200)
                {
                    localStorage.clear()
                    
                    const user_token = res.data.token
                    const user_name = res.data.username
                    const user_id = res.data.id
                    const user_role = res.data.role
                    const user_mode = res.data.mode
                    const permissions = res.data.permissions

                    localStorage.setItem('auth_token',user_token)
                    localStorage.setItem('auth_name',user_name)
                    localStorage.setItem('auth_role',user_role)
                    localStorage.setItem('auth_id',user_id)
                    localStorage.setItem('auth_mode',user_mode)
                    localStorage.setItem('auth_type','yes')
                    localStorage.setItem('auth_permissions',permissions) 

                    swal('Success',res.data.message,"success")
                                       
                    navigate('/index')

                }
                else if(res.data.status === 401)
                {
                      swal('Warning',res.data.message,"warning")
                }
                else
                {
                      setLogin({...loginInput, error_list: res.data.validation_errors})
                }
    
          })
    
    }

return (
    <div className="hold-transition login-page">
    <div className="login-box">

        <div className="login-logo">
        <a href="#"><b>Northwave</b> Credit</a>
        </div>

        <div className = "card" >
        <div className="card-body login-card-body">

            <p className="login-box-msg">Sign in to start your session</p>    
            <form onSubmit={loginSubmit} >

            <div className="input-group mb-3">
            <input type="email" className="form-control" placeholder="Email" name = "email" onChange={handleInput} value={loginInput.email} />
            <div className="input-group-append">
            <div className="input-group-text">
            <span className="fas fa-envelope"></span>
            </div>
            </div>
            <span className="error_holder" >{loginInput.error_list.email}</span>
            </div>

            <div className="input-group mb-3">
            <input type="password" className="form-control" id="password" placeholder="Password" name = "password"  onChange={handleInput} value={loginInput.password} />
            <div className="input-group-append" >
            <div className="input-group-text">
            <span className="fa fa-eye" id= "eye" onClick={togglePassword} ></span>
            </div>
            </div>
            <span className="error_holder" >{loginInput.error_list.password}</span>
            </div>

            <div className="row" >
                <div className ="col-md-12" >
                <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                </div>
            </div>

            </form>

            <p className="mb-3">
            <Link to='/reset' >Forgot password ?</Link>
            </p>

        </div>
        </div>
    </div>
    </div>
);

}

export default Login;