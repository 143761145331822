import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import Footer from '../nav/Footer';
import ControlSidebar from '../nav/ControlSidebar';
import PrintMpesa from '../elements/print/PrintMpesa';

import Modal from 'react-bootstrap/Modal';

function Mpesa()
{
    const effectRun = useRef(false)
    const [mpesa,setMpesa] = useState([])
    const [links,setLinks] = useState([])
    const [flag,setFlag] = useState(false)
    const [ceo_flag,setCeoFlag] = useState(false)
    const [open,setOpen] = useState(false)

    const [pay_id,setId] = useState(null)
    const navigate = useNavigate()

    const [details,setDetails] = useState({
        id:null,
        contact:'',
        error_list: []
    })

    const [searchDetails,setSearchDetails] = useState({
        start_date:'2023-01-01',
        end_date:'',
        error_list:[]
    })

    const handleDetails=(e)=>{
        setSearchDetails({...searchDetails, [e.target.name]: e.target.value})
    }

    const searchMpesa=(e)=>{
        e.preventDefault()

        const data = {
            start_date: searchDetails.start_date,
            end_date: searchDetails.end_date
        }

        axios.post(`api/search_mpesa`,data).then((res)=>{
            if(res.data.status == 200)
            {
                setMpesa(res.data.payments.data)
                setLinks(res.data.payments.links)
                setSearchDetails({...searchDetails, error_list: []})
            }
            else{
                setSearchDetails({...searchDetails, error_list:res.data.validation_errors})
            }
        })
    }

    const onClose=()=>{
        setFlag(false)
        setDetails({...details, id:null, contact:''})
    }

    const closeMpesa=()=>{
        setOpen(false)
    }

    const initiate=(id)=>{
        setFlag(true)
        setDetails({...details, id:id})
    }

    const initiateCeo=(id)=>{
        setCeoFlag(true)
        setDetails({...details, id:id})
    }

    const closeCeo=()=>{
        setCeoFlag(false)
        setDetails({...details, id:null, contact:''})
    }

    const handleContact=(e)=>{
        setDetails({...details, contact:e.target.value})
    }

    const updatePayment=(e)=>{

        e.preventDefault()

        const data = {
            id: details.id,
            customer: details.contact
        }

        axios.post(`api/confirm_mpesa`,data).then((res)=>{

            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success")
                onClose()
                fetchMpesa()
            }else if(res.data.status === 404)
            {
                swal("Warning",res.data.message,"warning")
                onClose()
            }else if(res.data.status === 403)
            {
                setDetails({...details, error_list:res.data.validation_errors})
            }

        }).catch(error=>{
            onClose()
            if(error.message == "Request failed with status code 403")
            {
                swal("Warning","You are not authorized to perfom this action","warning")
            }
            else
            {
                swal("Warning",error.message,"warning")
            }
            
        })

    }

    const rejectMpesa=(id)=>{
    
    axios.get(`api/reject_mpesa/`+id).then((res)=>{
        if(res.data.status === 200)
        {
            swal("Success",res.data.message,"success")
            fetchMpesa()
            onClose()
        }
    }).catch(error=>{
        onClose()
        if(error.message == "Request failed with status code 403")
        {
            swal("Warning","You are not authorized to perfom this action","warning")
        }
        else
        {
            swal("Warning",error.message,"warning")
        }
        
    })

    }

    const ceoConfirm=(id)=>{
    
    axios.get(`api/ceo_confirm/`+id).then((res)=>{
        if(res.data.status === 200)
        {
            swal("Success",res.data.message,"success")
            fetchMpesa()
            closeCeo()
        }
    }).catch(error=>{
        onClose()
        if(error.message == "Request failed with status code 403")
        {
            swal("Warning","You are not authorized to perfom this action","warning")
        }
        else
        {
            swal("Warning",error.message,"warning")
        }
        
    })

    }

    const ceoReject=(id)=>{
    
        axios.get(`api/ceo_reject/`+id).then((res)=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success")
                fetchMpesa()
                closeCeo()
            }
        }).catch(error=>{
            onClose()
            if(error.message == "Request failed with status code 403")
            {
                swal("Warning","You are not authorized to perfom this action","warning")
            }
            else
            {
                swal("Warning",error.message,"warning")
            }
            
        })
    
        }

    const fecthPage=(label)=>{
        var url = "api/mpesa_payments?page="+label
        axios.get(url).then((res)=>{
            if(res.data.status === 200)
            {
                setMpesa(res.data.payments.data)
                setLinks(res.data.payments.links)
            }
            }).catch(error=>{
                if(error == "AxiosError: Request failed with status code 401")
                {
                  localStorage.clear()
                  navigate('/login');
                }
            })
    }

    const showStatus=(status,id)=>{
        if(status == "cleared")
        {
            return(
                <td> <span style={{color:'green'}} > <span className='fa fa-check-circle'></span> {status} </span> </td>
            )
        }else if(status == "rejected")
        {
            return(
                <td> <span style={{color:'red'}} > <span className='fa fa-times-circle'></span> {status} </span> </td>
            )
        }
        else
        {
            return(
                <td> <span onClick={()=>initiate(id)}><span className='fa fa-user-shield'></span> Action</span></td>
            )
        }
    }

    const ceoStatus=(status,id)=>{
        if(status == "cleared")
        {
            return(
                <td> <span style={{color:'green'}} > <span className='fa fa-check-circle'></span> {status} </span> </td>
            )
        }else if(status == "rejected")
        {
            return(
                <td> <span style={{color:'orange'}} > <span className='fa fa-times-circle'></span> {status} </span> </td>
            )
        }
        else if(status == "pending")
        {
            return(
                <td> <span style={{color:'red'}} onClick={()=>initiateCeo(id)}><span className='fa fa-user-shield'></span> Action</span>  </td>
            )
        }else
        {
            return(
                <td>na</td>
            )
        }
    }

    const fetchMpesa=()=>{

        axios.get(`api/mpesa_payments`).then((res)=>{

            if(res.data.status === 200)
            {
                setMpesa(res.data.payments.data)
                setLinks(res.data.payments.links)
            }

        }).catch(error=>{

            if(error == "AxiosError: Request failed with status code 403")
            {
              navigate('/unauthorized');
            }
        })

    }

    // Use effect to initialize critical data
    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchMpesa()
    }

    return()=>{
    effectRun.current = true
    }

    },[])

    return (

        <div className="wrapper">
        
        <Modal
        show={flag}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        >
        <Modal.Header closeButton>
        <Modal.Title>Confirm Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <form className='form' onSubmit={updatePayment} >
        <div className='form-group'>
        <label>Customer ID / Passport <span className='red' >*</span> </label>
        <input type = "text" className='form-control' value={details.contact} onChange={handleContact}></input>
        <span className='error_holder'>{details.error_list.customer} *</span>
        </div>
        <div className='form-group'>
        <input type="submit" className='btn btn-primary btn-sm' value="Confirm payment"></input>    <span className='btn btn-danger btn-sm' onClick={()=>rejectMpesa(details.id)}> Reject Payment</span>        
        </div>
        </form>

        </Modal.Body>
        </Modal> 

        <Modal
        show={ceo_flag}
        onHide={closeCeo}
        backdrop="static"
        keyboard={false}
        size="lg"
        >
        <Modal.Header closeButton>
        <Modal.Title>Confirm Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <span className='btn btn-success btn-sm m-2' onClick={()=>ceoConfirm(details.id)} ><span className='fa fa-check-circle'></span> Confirm payment</span>
        <span className='btn btn-danger btn-sm m-2' onClick={()=>ceoReject(details.id)} ><span className='fa fa-times-circle'></span> Reject payment</span>

        </Modal.Body>
        </Modal>
        
        <PrintMpesa open={open} onClose={closeMpesa} statement={mpesa}></PrintMpesa>
    
        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        

        <div className="content">
        <div className="container">

        <div className="row" style={{marginTop:'20px'}} >
        <div className = "col-lg-12" >

        <div className="card card-primary card-outline">
        <div className="card-header">
        <h6 className="card-title"> <span className='fa fa-coins'></span> Mpesa Payments</h6>
        </div>
        <div className="card-body">

        
        <form className='form' method="post" onSubmit={searchMpesa}>

        <div className='row' style={{marginBottom:'20px'}} >

            <div className='col-md-4'>
                <div className='form-group'>
                    <label><span className='fa fa-calendar-alt'></span> Start Date</label>
                    <input type = 'date' className='form-control' name= 'start_date' value={searchDetails.start_date} onChange={handleDetails}  min='2023-01-01' ></input>
                    <span className='error_holder'>{searchDetails.error_list.start_date}</span>
                </div>
            </div>

            <div className='col-md-4'>
                <div className='form-group'>
                    <label><span className='fa fa-calendar-alt'></span> End Date</label>
                    <input type = 'date' className='form-control' name= 'end_date' value={searchDetails.end_date} onChange={handleDetails} min={searchDetails.start_date} ></input>
                    <span className='error_holder'>{searchDetails.error_list.end_date}</span>
                </div>
            </div> 

            <div className='col-md-4'>
            <div className='form-group' style={{marginTop:'35px'}} >
            <input type = 'submit' className='btn btn-sm btn-success m-1' value='Search Mpesa' ></input>
            <span className='btn btn-dark btn-sm m-1' onClick={()=>setOpen(true)} > <span className='fa fa-print'></span> Print Mpesa</span>
            </div>
            </div>            

            </div>

        </form>
        

        <table className="table" >

        <thead>
        <tr className='small-row' >
        <th>#</th>
        <th>Timestamp</th>
        <th>Transaction</th>
        <th>Paid By</th>       
        <th>Account</th>
        <th>Phone</th>
        <th>Amount</th>
        <th><span className='fa fa-user-shield'></span> Finance </th>
        <th><span className='fa fa-user-shield'></span> CEO </th>
        </tr>
        </thead>

        <tbody>
        {mpesa.map((item,index)=>{
        
        return(
            <tr key={index} className='small-row'>
                <td>{index+1}</td>
                <td>{item.created_at}</td> 
                <td>{item.TransID}</td>                
                <td>{item.FirstName} {item.MiddleName} {item.LastName}</td>
                <td>{item.BillRefNumber}</td>
                <td>{item.MSISDN}</td>                
                <td>KSH {parseInt(item.TransAmount).toLocaleString('en-US')}</td>
                {showStatus(item.status,item.id)}
                {ceoStatus(item.ceo,item.id)}
                <td></td>
            </tr>
            )

        })}
        </tbody>

        </table>    
                
        </div>

        <div className='card-footer' >
        <ul className="pagination pagination-sm m-0 float-left">
        {links.map((item,index)=>{
        if(item.url !== null)
        {
            if(index !== (links.length-1))
            {
                if(item.active)
                {
                return(<li className="page-item active" key={index} onClick={()=>fecthPage(item.label)}  ><a className="page-link">{index}</a></li>)
                }
                else
                {
                return(<li className="page-item" key={index} onClick={()=>fecthPage(item.label)}  ><a className="page-link">{index}</a></li>)
                }
            }
        }
        })}
        </ul>
        </div>

        </div>

        </div>
        </div>

        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default Mpesa;