import React, { useEffect, useState,useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
//import Cards from '../elements/cards/Cards';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

import NewOrganization from '../elements/modals/payday/NewOrganization';

const Organizations=()=>{
    const effectRun = useRef(false)
    const [open,setOpen] = useState(false);
    const [users,setUsers] = useState([])
    const [roles,setRoles] = useState([])
    const [organizations,setOrganizations] = useState([])

    const onClose=()=>{
    setOpen(false)
    }

    // Fetch Users
    const fetchOrganizations=()=>{
    
    axios.post(`api/organizations`).then((res)=>{

    if(res.data.status === 200)
    {
    setOrganizations(res.data.organizations)
    }

    })

    }

    // Fetch Users
    const fetchUsers=()=>{

    axios.post(`api/users`).then((res)=>{
    if(res.data.status)
    {
    setUsers(res.data.users)
    }
    })

    }

    const fetchRoles=()=>{
        axios.post(`api/roles`).then((res)=>{
            if(res.data.status === 200)
            {
                setRoles(res.data.roles)
            }
        })
    }

    useEffect(()=>{
    

    if(effectRun.current === false)
    {
    fetchUsers()
    fetchOrganizations()
    fetchRoles()
    return()=>{
        effectRun.current = true
    }

    }

    },[])

    return (
        <div className="wrapper">

            <NewOrganization open={open} onClose={onClose} users={users} fetchOrganizations={fetchOrganizations} roles={roles} />

            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>Payday Organizations</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='row'>
                <div className='col-md-12'>
                
                <div className='card card-primary '>

                <div className='card-header'>
                <span className='fa fa-landmark' ></span> Organization
                <span style={{float:'right'}} className='btn btn-dark btn-sm' onClick={()=>setOpen(true)} ><span className='fa fa-plus'></span>  New Organization</span>
                </div>

                <div className='card-body'>
                <table className='table'>
                <thead>
                <tr className='small-row' >
                <th>#</th>
                <th>Organization</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Manager</th>
                <th>Code</th>
                <th>Status</th>
                <th><span className='fa fa-eye' ></span> view</th>
                </tr>
                </thead>

                <tbody>
                {organizations.map((item,index)=>{
                return(
                <tr key={index}  className='small-row' >
                <td>{index+1}</td>
                <td>{item.name}</td>
                <td>{item.phone}</td>
                <td>{item.email}</td>
                <td>{item.manager}</td>
                <td>{item.code}</td>
                <td>{item.status}</td>        
                <td><Link to={`/organization/${item.id}`} ><span className='fa fa-eye' ></span> view</Link></td>
                </tr>
                )
                })}
                </tbody>

                </table>
                </div>

                <div className='card-footer'></div>

                </div>

                </div>
                </div>

                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default Organizations;