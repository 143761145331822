import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';


function Invoices(){

    const [invoices,setInvoices] = useState([])
    const effectRun = useRef(false);
    const [links,setLinks] = useState([])
    const navigate = useNavigate()

    const [searchDetails,setSearchDetails] = useState({
        start_date:'2023-01-01',
        end_date:'',
        error_list:[]
    })

    const handleDetails=(e)=>{
        setSearchDetails({...searchDetails, [e.target.name]: e.target.value})
    }

    const searchInvoices=(e)=>{

        e.preventDefault()
        const data = {
            start_date: searchDetails.start_date,
            end_date: searchDetails.end_date
        }

        axios.post(`api/search_invoices`,data).then((res)=>{
            if(res.data.status == 200)
            {
                setInvoices(res.data.invoices.data)
                setLinks(res.data.invoices.links)
                setSearchDetails({...searchDetails, error_list: []})
            }
            else
            {
                setSearchDetails({...searchDetails, error_list: res.data.validation_errors})
            }
        })

    }

    const fetchInvoices=()=>{
        axios.get(`api/loan_invoices`).then((res)=>{
            if(res.data.status === 200)
            {
                setInvoices(res.data.invoices.data)
                setLinks(res.data.invoices.links)
            }
        }).catch(error=>{
            if(error.message == "Request failed with status code 403")
                {
                    swal("Warning","You are not authorized to perfom this action","warning")
                }
                else
                {
                    swal("Warning",error.message,"warning")
                }
        })
    }

    const fecthPage=(label)=>{
        var url = "api/loan_invoices?page="+label
        axios.get(url).then((res)=>{
            if(res.data.status === 200)
            {
                setInvoices(res.data.invoices.data)
                setLinks(res.data.invoices.links)
            }
            }).catch(error=>{
                if(error == "AxiosError: Request failed with status code 401")
                {
                  localStorage.clear()
                  navigate('/login');
                }
            })
    }

    

    useEffect(()=>{
        if(effectRun.current === false)
        {
            fetchInvoices()
        }

        return()=>{
            effectRun.current = true
        }
    })

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>...</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >
                <div className='row'>
                    <div className='col-md-12'>
                       <div className='card card-primary card-outline'>
                        <div className='card-header'>
                            <h5> <span className='fa fa-file-invoice'></span> Invoices</h5>
                        </div>
                        <div className='card-body'>

                        <form className='form' method = "post" onSubmit={searchInvoices} >

                        <div className='row' style={{marginBottom:'20px'}} >

                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label><span className='fa fa-calendar-alt'></span> Start Date</label>
                                    <input type = 'date' className='form-control' name= 'start_date' value={searchDetails.start_date} onChange={handleDetails}  min='2023-01-01' ></input>
                                    <span className='error_holder'>{searchDetails.error_list.start_date}</span>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className='form-group'>
                                    <label><span className='fa fa-calendar-alt'></span> End Date</label>
                                    <input type = 'date' className='form-control' name= 'end_date' value={searchDetails.end_date} onChange={handleDetails} min={searchDetails.end_date} ></input>
                                    <span className='error_holder'>{searchDetails.error_list.end_date}</span>
                                </div>
                            </div> 

                            <div className='col-md-4'>
                                <div className='form-group' style={{marginTop:'35px'}} >
                                    <input type = 'submit' className='btn btn-sm btn-success m-1' value='Search Invoices' ></input>
                                    <span className='btn btn-dark btn-sm m-1'> <span className='fa fa-print'></span> Print Invoices</span>
                                </div>
                            </div>            

                            </div>

                        </form>

                        <table className='table'>
                            <thead>
                                <tr className='small-row' >
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Client</th>
                                    <th>Loan</th>
                                    <th>Amount</th>
                                    <th>Balance</th>
                                    <th>Status</th>
                                    <th>Description</th>
                                </tr>
                            </thead>

                            <tbody>
                            {invoices.map((invoice,index)=>{
                            
                            if(invoice.status == "paid")
                            {
                                return(
                                    <tr className='small-row' key={index} >
                                    <td>{index+1}</td>
                                    <td>{invoice.year}-{invoice.month}-{invoice.day}</td>
                                    <td>{invoice.first_name} {invoice.other_name} {invoice.last_name}</td>
                                    <td>{invoice.loan_code}</td>
                                    <td>KES {parseInt(invoice.amount).toLocaleString('en-US')}</td>
                                    <td>KES {parseInt(invoice.balance).toLocaleString('en-US')}</td>
                                    <td style={{color:'green'}} > <span className='fa fa-check-circle'></span> {invoice.status}</td>
                                    <td>{invoice.description}</td>
                                    </tr>
                                    )
                            }else
                            {
                                return(
                                    <tr className='small-row' key={index} >
                                    <td>{index+1}</td>
                                    <td>{invoice.year}-{invoice.month}-{invoice.day}</td>
                                    <td>{invoice.first_name} {invoice.other_name} {invoice.last_name}</td>
                                    <td>{invoice.loan_code}</td>
                                    <td>KES {parseInt(invoice.amount).toLocaleString('en-US')}</td>
                                    <td>KES {parseInt(invoice.balance).toLocaleString('en-US')}</td>
                                    <td>{invoice.status}</td>
                                    <td>{invoice.description}</td>
                                    </tr>
                                    )
                            }

                            })}
                            </tbody>

                        </table>
                        </div>

                        <div className='card-footer' >
                        <ul className="pagination pagination-sm m-0 float-left">
                        {links.map((item,index)=>{
                            if(item.url !== null)
                            {
                                if(index !== (links.length-1))
                                {
                                    if(item.active)
                                    {
                                    return(<li className="page-item active" key={index} onClick={()=>fecthPage(item.label)}  ><a className="page-link">{index}</a></li>)
                                    }
                                    else
                                    {
                                    return(<li className="page-item" key={index} onClick={()=>fecthPage(item.label)}  ><a className="page-link">{index}</a></li>)
                                    }
                                }
                            }
                        })}
                        </ul>
                        </div>

                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default Invoices;