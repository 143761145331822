function DateDecoration(input){
let months = ['','Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
let short_format = input.substr(0,10);

let year = input.substr(0,4);
let month = input.substr(5,2);
let day = input.substr(8,2);

let date = day+", "+months[month]+" "+year

return date;
}

export default DateDecoration;