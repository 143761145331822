import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import CardHeader from '../elements/cards/CardHeader';

import BarGraph from '../elements/charts/BarGraph';
import LineGraph from '../elements/charts/LineGraph';
import Cards from '../elements/cards/Cards';
import PerfomanceButton from '../elements/PerfomanceButton';


function SalesDashboard()
{
    const effectRun = useRef(false)
    const [summary,setSummary] = useState([]);
    const [annual,setAnnual] = useState([]);
    const [segments, setSegments] = useState([])

    const [weeklyGraph,setWeeklyGraph] = useState([0,0,0,0,0,0,0]);

    const [leadButton,setLeadButton] = useState('Leads')

    const [leadsGoals,setLeadsGoals] = useState({
        daily:0,
        weekly:0,
        monthly:0,
        annual:0
});

    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        const data = {
            person: localStorage.getItem('auth_id')
        }

        axios.post(`api/personal_summary`,data).then(res=>{
            if(res.data.status === 200)
            {
                setSummary(res.data.summary)
            }
        })

        const annual_data = {
            person: localStorage.getItem('auth_id'),
            year: 2023
        }

        axios.post(`api/annual_loans`,annual_data).then(res=>{
            if(res.data.status === 200)
            {
                setAnnual(res.data.loans)
            }
        })

       leadStats()
       fetchSegments()
    }

    return()=>{
    effectRun.current = true
    }

    },[])

    const leadStats=()=>{

        const data = {
            person: localStorage.getItem('auth_id')
        }

        axios.post(`api/leads_card`,data).then(res=>{
            if(res.data.status === 200)
            {
                setLeadsGoals(res.data.leads)
                setWeeklyGraph(res.data.leads_weeklyGraph)
            }
        })

    }

    const valuationStats=()=>{

        const data = {
            person: localStorage.getItem('auth_id')
        }

        axios.post(`api/leads_card`,data).then(res=>{
            if(res.data.status === 200)
            {
                setLeadsGoals(res.data.valuations)
                setWeeklyGraph(res.data.valuations_weeklyGraph)
            }
        })

    }

    const loanStats=()=>{

        const data = {
            person: localStorage.getItem('auth_id')
        }

        axios.post(`api/leads_card`,data).then(res=>{
            if(res.data.status === 200)
            {
                setLeadsGoals(res.data.loans)
                setWeeklyGraph(res.data.loans_weeklyGraph)
            }
        })

    }

    const changeMetric=(label)=>{
    setLeadButton(label)

    if(label === 'Leads'){
        leadStats()
    }

    if(label === 'Valuations'){
        valuationStats()
    }

    if(label === 'Loans'){
        loanStats()
    }

    }

    const fetchSegments=()=>{

        const data = {
            id: localStorage.getItem('auth_id')
        }

        axios.post(`api/user_segments`,data).then(res=>{

            if(res.data.status === 200)
            {
                setSegments(res.data.segments)
            }

        })

    }

    return (
        <div className="wrapper">
        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        <ContentHeader title="Perfomance Dashboard !" />

        <div className="content">
        <div className="container">
        <div className="row">
            
        <div className="col-lg-4"  >
        
        <div className="card card-widget widget-user-2"  >

        <CardHeader/>

        <div className='card-body' >

        <p className="text-left">
        <strong> <span className='fa fa-bullseye' ></span> Goals Completion</strong>
        </p>
        
        <div className="progress-group">
        Daily
        <span className="float-right badge badge-success "><b>{leadsGoals.daily}</b></span>
        <div className="progress progress-sm">
        <div className="progress-bar bg-success" style={{ width: leadsGoals.daily }}></div>
        </div>
        </div>

        <div className="progress-group">
        Weekly
        <span className="float-right badge badge-primary"><b>{leadsGoals.weekly}</b></span>
        <div className="progress progress-sm">
        <div className="progress-bar bg-primary" style={{ width: leadsGoals.weekly }} ></div>
        </div>
        </div>

        <div className="progress-group">
        Monthly
        <span className="float-right badge badge-warning "><b>{leadsGoals.monthly}</b></span>
        <div className="progress progress-sm">
        <div className="progress-bar bg-warning" style={{ width: leadsGoals.monthly }} ></div>
        </div>
        </div>

        <div className="progress-group">
        Annual
        <span className="float-right badge badge-danger"><b>{leadsGoals.annual}</b></span>
        <div className="progress progress-sm">
        <div className="progress-bar bg-danger" style={{ width: leadsGoals.annual  }} ></div>
        </div>
        </div>

        <div className="progress-group">
        <PerfomanceButton current={leadButton} label='Leads' onClick={()=>changeMetric('Leads')}/>
        <PerfomanceButton current={leadButton} label='Valuations' onClick={()=>changeMetric('Valuations')}/>
        <PerfomanceButton current={leadButton} label='Loans' onClick={()=>changeMetric('Loans')}/>
        </div>

        </div>

        <div>
    
        </div>

        <div className='card-footer' ></div>

        </div>

        <div className='card card-primary card-outline' >
        <div className='card-header' >
        <span className='fa fa-chart-bar' ></span> Weekly overview
        </div>
        <div className='card-body'>
        <LineGraph graphData={weeklyGraph} graphLabel={leadButton} />
        </div>
        </div>

        <div className='card card-primary card-outline' >

        <div className='card-body'>
        
        <table className='table' >
            <thead style = {{fontSize:'13px'}} >
                <tr>
                <th> <span className='fa fa-chart-pie' ></span> Quarter</th>
                <th>Loans</th>
                <th><span className='badge badge-success' style={{width:'45px'}} >%</span></th>
                </tr>
            </thead>
            <tbody>
                <tr style = {{fontSize:'13px'}} >
                    <td>Q1</td>
                    <td>KES {summary.Q1}</td>
                    <td><span className='badge badge-success' style={{width:'45px'}} >{summary.q1_rates}</span></td>
                </tr>
                <tr style = {{fontSize:'13px'}} >
                    <td>Q2</td>
                    <td>KES {summary.Q2}</td>
                    <td><span className='badge badge-success' style={{width:'45px'}} >{summary.q2_rates}</span></td>
                </tr>
                <tr style = {{fontSize:'13px'}} >
                    <td>Q3</td>
                    <td>KES {summary.Q3}</td>
                    <td><span className='badge badge-success' style={{width:'45px'}} >{summary.q3_rates}</span></td>
                </tr>
                <tr style = {{fontSize:'13px'}} >
                    <td>Q4</td>
                    <td>KES {summary.Q4}</td>
                    <td><span className='badge badge-success' style={{width:'45px'}} >{summary.q4_rates}</span></td>
                </tr>
                <tr style = {{fontSize:'13px'}} >
                    <td><b>Total</b></td>
                    <td> <b>KES {parseInt(summary.total_loans).toLocaleString('en-US')}</b> </td>
                    <td><span className='badge badge-success' style={{width:'45px'}} >100 %</span></td>
                </tr>
            </tbody>
        </table>

        </div>

        </div>

        </div>

            <div className = "col-lg-8" >

            <div className="card card-primary card-outline">
            <div className="card-header">
            <h5 className="card-title m-0"><i className="fa fa-chart-line"></i> Perfomance overview</h5>
            </div>
            <div className="card-body">

            <div className="row" >

            <Cards title='Leads' data = {summary.leads} size = 'col-md-4' icon='fa fa-spinner' />
            <Cards title='Valuations' data = {summary.valuations} size = 'col-md-4' icon='fa fa-balance-scale' />
            <Cards title='Loans' data = {summary.loans} size = 'col-md-4' icon='fa fa-money-bill' />

            </div>                
                    
            </div>
            </div>

            <div className='card card-primary card-outline ' >
            <div className='card-header' >Disbursed loans for the year 2023</div>
            <div className='card-body' >
            <div className='row' >

            <div className='col-md-12' >
            <BarGraph loans={annual} />
            </div>

            </div>
            </div>
            </div>

            <div className='card card-primary card-outline ' >

            <div className='card-body' >
            <div className='row' >

            <div className='col-md-12' >
                <table className='table' >
                    <thead>
                        <tr style={{fontWeight:'bold'}} >
                            <td> <span className='fa fa-chart-pie' ></span> Segment</td>
                            <td> Loans</td>
                            <td> <span className='badge badge-success'  style={{width:'40px'}} > % </span> </td>
                        </tr>
                    </thead>

                    <tbody>
                    {segments.map((number,index)=>{
                      return(
                        <tr key={number[0]} style={{fontSize:'13px'}} >
                        <td>{number[0]}</td>
                        <td>KES {number[1]}</td>
                        <td><span className='badge badge-success' style={{width:'35px'}} >{number[2]}</span></td>
                      </tr>
                      )
                  })}  
                    </tbody>

                </table>
            </div>
            
            </div>
            </div>

            <div className='card-footer' ></div>

            </div>

        </div>

        </div>
        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default SalesDashboard;