import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';

function Scans({documents,setScanFlag,fetchDocuments,profile}){

    

    const DownloadFile=(id,name)=>{

    const data = {
        id: id,
    }
    
        axios({
            url: 'api/download_scan', 
            method: 'POST',
            data: data,
            responseType: 'blob', // important
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
          });

    }

const deleteScan=(doc)=>{

const data = {
    id:doc,
}

axios.post(`api/delete_document`,data).then((res)=>{
    if(res.data.status === 200)
    {
        swal('Success',res.data.message,"success")
        fetchDocuments()
    }
})

}

const addButton=()=>{

    var role = localStorage.getItem('auth_role')

    if(role == "CEO" || role == "System Administrator")
    {
        return(
            <span className='btn btn-success btn-sm' style={{float:'right'}} onClick={()=>setScanFlag(true)} ><span className='fa fa-upload' ></span> New Upload</span>
        )
    }
    else
    {
        if(profile.edit_flag == "0")
        {
            return(
                <span className='btn btn-success btn-sm' style={{float:'right'}} onClick={()=>setScanFlag(true)} ><span className='fa fa-upload' ></span> New Upload</span>
            )
        }
    }     
}

const scansTable=()=>{

    var role = localStorage.getItem('auth_role')

    if(role == "CEO" || role == "System Administrator")
    {
        return(
            <table className='table'>
            <thead>
            <tr style={{fontSize:'13px'}} >
            <td>#</td>
            <td><b>Document Type</b></td>
            <td><b>Document Name</b></td>
            <td><span style={{float:'right'}} ><span className='fa fa-download' ></span> Download</span></td>
            <td style={{color:'red'}} > <span style={{float:'right'}} ><span className='fa fa-trash' ></span> Delete</span> </td>
            </tr>
            </thead>

            <tbody>
            {documents.map((doc,index)=>{
        
            return(
            <tr key={index} style={{fontSize:'13px'}} >
            <td>{index+1}</td>
            <td>{doc.document}</td>
            <td>{doc.name}</td>
            
            <td>                    
            <span style={{float:'right'}} onClick={()=>DownloadFile(doc.id,doc.name)} >
            <span className='fa fa-download' ></span> Download
            </span>
            </td>

            <td><span style={{float:'right',marginLeft:'10px', color:'red'}} onClick={()=>deleteScan(doc.id)} >
            <span className='fa fa-trash' ></span> Delete
            </span>
            </td>
            </tr>
            )
            })}
            </tbody>
            </table>
        )  
    }
    else
    {
    
            return(
                <table className='table'>
                <thead>
                <tr style={{fontSize:'13px'}} >
                <td>#</td>
                <td><b>Document Type</b></td>
                <td><b>Document Name</b></td>
                </tr>
                </thead>
    
                <tbody>
                {documents.map((doc,index)=>{
            
                return(
                <tr key={index} style={{fontSize:'13px'}} >
                <td>{index+1}</td>
                <td>{doc.document}</td>
                <td>{doc.name}</td>
                
                </tr>
                )
                })}
                </tbody>
                </table>
            )
        
    }     
}

return (
    <div class="card card-primary card-outline">
            <div class="card-header">
            <h5><span className='fa fa-file-invoice'></span> KYC Document Scans
            {addButton()}
            </h5>
            </div>
            <div class="card-body box-profile contact-box">

            <div className='row' >            
            <div className='col-md-12' >
            {scansTable()}               
            </div>
            </div>

            </div>

            <div className='card-footer'>                
            </div>

            </div>
)

}

export default Scans;