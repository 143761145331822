import { Modal } from "react-bootstrap";

const EditDesignated=({editContact,closeEditContact,contact,handleContact,updateContact,roles})=>{
if(!editContact) return false;

return(

<Modal
show={editContact}
onHide={closeEditContact}
backdrop="static"
keyboard={false}
size="lg"
>
<Modal.Header closeButton>
<Modal.Title>Edit staff</Modal.Title>
</Modal.Header>

<Modal.Body>
<form className="form" onSubmit={updateContact}>
<div className="row">

<div className="form-group col-md-4">
<label>First Name</label>
<input type="text" name="first_name" className="form-control" value={contact.first_name} onChange={handleContact} />
<span className="error_holder"  >{contact.error_list.first_name}</span>
</div>

<div className="form-group col-md-4">
<label>Other Name</label>
<input type="text" name="other_name" className="form-control"  value={contact.other_name} onChange={handleContact}  />
<span className="error_holder"  >{contact.error_list.other_name}</span>
</div>

<div className="form-group col-md-4">
<label>Last Name</label>
<input type="text" name="last_name" className="form-control"  value={contact.last_name} onChange={handleContact}  />
<span className="error_holder"  >{contact.error_list.last_name}</span>
</div>

<div className="form-group col-md-4">
<label>Phone</label>
<input type="text" name="phone" className="form-control"  value={contact.phone} onChange={handleContact}  />
<span className="error_holder"  >{contact.error_list.phone}</span>
</div>

<div className="form-group col-md-4">
<label>Email</label>
<input type="text" name="email" className="form-control"  value={contact.email} onChange={handleContact}  />
<span className="error_holder"  >{contact.error_list.email}</span>
</div>

<div className="form-group col-md-4">
<label>Role </label>
<select className="form-control" name="role_id" value={contact.role_id} onChange={handleContact} >
{roles.map((item,index)=>{
    if(item.category === "payday")
    {
        return(
            <option value={item.id} key={index} >{item.role}</option>
        )
    }

    })}
</select>
<span className="error_holder"  >{contact.error_list.designation}</span>

</div>

<div className="form-group col-md-12">
<input type = "submit" className="btn btn-primary btn-sm" value="Update staff"></input>
</div>

</div>
</form>
</Modal.Body>

<Modal.Footer>
</Modal.Footer>

</Modal>

)
}
export default EditDesignated;