const Action=({action,takeAction})=>{

if(action === "true")
{
    return(<div className='card card-dark'>
    <div className='card-header'>
    <h6><span className='fa fa-user-shield'></span> Action required !</h6>
    </div>
    <div className='card-body'>
    <span className='btn btn-success btn-sm' style={{width:'130px',margin:'2px'}} onClick={()=>takeAction('approved')} > <span className='fa fa-check-circle'></span> Approve</span>
    <span className='btn btn-danger btn-sm' style={{width:'130px',margin:'2px'}} onClick={()=>takeAction('rejected')} ><span className='fa fa-times-circle'></span> Reject</span>
    </div>
    </div>)
}
else
{
    return(<></>)
}

}

export default Action;