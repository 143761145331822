import { useReactToPrint } from "react-to-print";
    import { useRef } from "react";

    import Table from 'react-bootstrap/Table';
    import Container from 'react-bootstrap/Container';
    import Row from 'react-bootstrap/Row';
    import Col from 'react-bootstrap/Col';
    import Stack  from 'react-bootstrap/Stack';
    import Image  from 'react-bootstrap/Image';

    import logo from '../../../llogo.jpeg'

    const  PrintCharge = ({open,onClose,client,details,dueDate}) =>{

    const marginTop="10px"
    const marginBottom="10px"
    const marginRight="70px"
    const marginLeft="70px"
       
    
    const getPageMargins = () => {
        return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    };

    const componentRef = useRef()

    const handlePrint = useReactToPrint({
    content:()=> componentRef.current,
    documentTitle: 'Demand',
    onAfterPrint:()=>onClose()
    })

    let today = new Date().toUTCString().slice(5, 16);
    if(!open) return null;
    return(
    <div className='overlay' onClick={onClose} >
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className = "card card-primary" >
        <div className="card-header" >
        <span className='closeModal btn btn-sm btn-default' onClick={handlePrint} style={{margin:'2px'}} > <span className='fa fa-print' ></span> Print Demand </span>
        </div>
            
        <div className="card-body">

        <div ref={componentRef} style={{width: '100%'}}  >
        <style>{getPageMargins()}</style>
            <Container>
            <Row className="mt-5" style={{height:'100px'}}>
            <Col>

            <div>            
            </div>

            <div className="mt-3" >
            <h6><b></b></h6>
            </div>

            </Col>

            <Col style={{textAlign:'right'}} >            
            </Col>

            </Row> 


            <Row>
                <Col style = {{fontSize:'15px'}} >
                <div className="mt-3">
                <Stack gap={1}>
                <div>Our Ref: {details.loan_code}</div>
                <div>{client.first_name} {client.other_name} {client.last_name}</div>
                <div>{client.phone}</div>
                <div>{client.email}</div>
                </Stack>

                <p></p>

                <p>Dear Sir / Madam</p>

                <p style={{textAlign:'justify'}} >
                <b>                    
                RE: OUTSTANDING ARREARS BALANCE OF KSH {parseInt(details.amount_due).toLocaleString('en-US')} OWED TO NORTHWAVE CREDIT LTD
                </b>
                </p>

                <p>
                We refer to the above,
                </p>

                <p style={{textAlign:'justify'}} >
                You secured a loan facility with us for <b>KSH {parseInt(details.amount_loanable).toLocaleString('en-US')}</b> disbursed on <b>{details.date_disbursed.substr(0,10)}</b> which was to run <b>{details.period} </b> months from the disbursement date and has not yet been fully setteled.
                </p>

                <p style={{textAlign:'justify'}} >
                This loan has continued to accrue additional interest and non-payment charges despite several reminders to regularize the account. As at <b>{dueDate}</b> the outstanding Arrears balance inclusive of charges is <b>KSH {parseInt(details.amount_due).toLocaleString('en-US')}</b>.
                </p>

                <p style={{textAlign:'justify'}} >
                <b>WE HEREBY PUT YOU ON A FINAL NOTICE</b> that unless we receive full payment of arrears sum of <b>KSH {parseInt(details.amount_due).toLocaleString('en-US')} within the next (14) days</b> from the date of this letter, we shall institute due legal recovery proceedings at your own expense and without further reference to yourself recall the whole loan balance.
                Further, this account will continue to accrue non-payment charges and interest and any other charge incidental to repocession and disposal of the charged security in accordance with provisions of the terms and conditions of the loan contract.
                </p>

                <p>
                Please be advised that no further reminders or notices will be sent and that any proposals made, <b>IF ACCEPTABLE</b> and any installments paid by yourself subsequent to the date of this Notice will be accepted without prejudice to the Lenders rights.
                </p>

                <Stack gap={1}>
                <div style={{marginTop:'20px'}} ><b>Risk and Operations Department</b></div>
                <div><b>risk@northwavecredit.com</b></div>
                <div><b>Northwave Credit Limited</b></div>
                </Stack>

                </div>
                </Col>
            </Row>

            </Container>       

        </div>

        </div>

        <div className='card-footer' >        
        </div>

        </div>   

        </div>
    </div>
    );
}

export default PrintCharge;