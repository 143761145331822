import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';


function Requisitions()
{
    const effectRun = useRef(false)
    const navigate = useNavigate()

    const [requisitions,setRequisitions] = useState([])

    const fetchRequisitions =()=>{
    
    axios.post(`api/requisitions`).then((res)=>{
        if(res.data.status === 200)
        {
           setRequisitions(res.data.requisitions) 
        }
    }).catch(error=>{
        if(error == "AxiosError: Request failed with status code 403")
        {
          navigate('/unauthorized');
        }
    })

    }

    // Use effect to initialize critical data
    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchRequisitions()
    }

    return()=>{
    effectRun.current =  true
    }

    },[])

    return (

        <div className="wrapper">
        
        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        

        <div className="content">
        <div className="container">

        <div className="row" style={{marginTop:'20px'}} >
        <div className = "col-lg-12" >

        <div className="card card-primary card-outline">
        <div className="card-header">
        <h6 className="card-title">Requisitions <Link to='/new_requisition' > <span className='btn btn-primary btn-xs float-right'>New Requisition</span> </Link> </h6>
        </div>
        <div className="card-body">

        <table className="table" >

        <thead>
        <tr className='small-row' >
        <th>#</th>
        <th>Date</th>
        <th>Req #</th>
        <th>Author</th>
        <th>Description</th>
        <th>Amount</th>
        <th>Status</th>
        <th><span className='fa fa-eye'></span> More</th>
        </tr>
        </thead>

        <tbody>
        {requisitions.map((item,index)=>{
            return(
            <tr key={index} className='small-row' >
                <td>{index+1}</td>
                <td>{item.created_at.substr(0,10)}</td>
                <td>{item.code}</td>
                <td>{item.name}</td>
                <td>{item.title}</td>
                <td>KES {parseFloat(item.totals,2).toLocaleString('en-US')}</td>
                <td>{item.status}</td>
                <td> <Link to={`/view_requisition/${item.id}`}><span className='fa fa-eye'></span> More</Link> </td>
            </tr>
            )
        })}
        </tbody>

        </table>    
                
        </div>

        <div className='card-footer' >
        
        </div>

        </div>

        </div>
        </div>

        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default Requisitions;