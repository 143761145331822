import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

import LoanIncomeGraph from '../elements/charts/LoanIncomeGraph';
import PaydayIncomeChart from '../elements/charts/PaydayIncomeChart';
import PaydayIncomePrint from '../elements/print/PaydayIncomePrint';

function PaydayIncome(){

    const effectRun = useRef(false)
    const [entries,setEntries] = useState([])
    const [year,setYear] = useState(new Date().getFullYear())
    const [organizations,setOrganizations] = useState([])
    const [loans,setLoans] = useState([0,0,0,0,0,0,0,0,0,0,0,0])
    const [segments,setSegments] = useState([0,0,0,0])
    const [open,setOpen] = useState(false)
    const navigate = useNavigate()

    const [summary,setSummary] = useState({
        'principal':0,
        'interest':0,
        'processing':0,
        'charges':0,
        'total_summary':0
    })

    const onClose=()=>{
        setOpen(false)
    }

    const [details,setDetails] = useState({
        start_date:'2023-01-01',
        end_date:'',
        organization:'All',
        error_list: []
    })

    const handleChange=(e)=>{
        setDetails({...details,[e.target.name]: e.target.value})
    }

    const fetchOrganizations=()=>{
        axios.post(`api/query_organizations`).then((res)=>{
            if(res.data.status === 200)
            {
                setOrganizations(res.data.organizations)
            }
        })
    }

    const fetchEntries=()=>{
    
    axios.post(`api/payday_income_report`).then((res)=>{
        if(res.data.status === 200)
        {
            setEntries(res.data.invoices)
            setSummary(res.data.summary)
            setDetails({...details, end_date: res.data.current_date})
        }
    }).catch(error=>{
        if(error == "AxiosError: Request failed with status code 403")
        {
          navigate('/unauthorized');
        }
    })

    }

    const queryEntries=()=>{

        const data = {
            start_date: details.start_date,
            end_date: details.end_date,
            organization: details.organization
        }
    
        axios.post(`api/query_payday_report`,data).then((res)=>{
            if(res.data.status === 200)
            {
                setEntries(res.data.invoices)
                setDetails({...details, error_list:[]})
                setSummary(res.data.summary)
            }
            else
            {
                setDetails({...details, error_list:res.data.validation_errors})
            }
        })
    
        }

    const fetchLoans=(year)=>{

        axios.get(`api/payday_income_visuals/${year}`).then((res)=>{
            if(res.data.status === 200)
            {
                setLoans(res.data.monthly)
                setSegments(res.data.segments)
            }
        })
    }

    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchEntries()
        fetchLoans(year)
        fetchOrganizations()

        return()=>{
        effectRun.current = true
        }
    }

    })

    return (
        <div className="wrapper">

            <PaydayIncomePrint open={open} onClose={onClose} statement={entries} summary={summary} details={details} ></PaydayIncomePrint>
            
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>...</h5>
                </div>
    
                <div className="col-sm-6">
                
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >
                
                <div className='row'>
                <div className='col-md-12'>
                    <div className='card card-primary card-outline'>

                        <div className='card-header'>
                        Payday Loans Income Overview
                        </div>

                        <div className='card-body'>
                        <div className='row'>
                        
                        <div className='col-md-8'>
                        <div className='card card-primary card-outline'>
                        <div className='card-header'>
                        <h6>Annual Payday income overview</h6>
                        </div>
                        <div className='card-body'>
                        <LoanIncomeGraph loans={loans} ></LoanIncomeGraph> 
                        </div>
                        </div>
                        </div>

                        <div className='col-md-4'>
                        <div className='card card-primary card-outline'>
                        <div className='card-header'>
                        <h6>Payday Income Segmentation</h6>
                        </div>
                        <div className='card-body'>
                        <PaydayIncomeChart segments={segments}></PaydayIncomeChart>
                        </div>
                        </div>
                        </div>

                        </div>
                        </div>

                        <div className='card-footer'></div>
                    </div>
                </div>
                </div>

                <div className='row'>
                <div className='col-md-12'>
                <div className='card card-primary card-outline'>
                <div className='card-header'>
                <h6>
                Payday Income Particulars
                <span className='btn btn-dark btn-sm m-1 float-right' onClick={()=>setOpen(true)} > <span className='fa fa-print'></span> Print Report</span>
                <span className='btn btn-dark btn-sm m-1 float-right' onClick={()=>fetchEntries()}> <span className='fa fa-search'></span> Clear</span>
                <span className='btn btn-dark btn-sm m-1 float-right' onClick={()=>queryEntries()}> <span className='fa fa-search'></span> Search</span>
                
                </h6>
                </div>
                <div className='card-body'>

                    <div className='row'>
                    
                    <div className='col-md-4 form-group'>
                        <label>Start Date</label>
                        <input type="date" className='form-control' name="start_date" value={details.start_date} onChange={handleChange} ></input>
                        <span className='error_holder'>{details.error_list.start_date}</span>
                    </div>

                    <div className='col-md-4 form-group'>
                        <label>End Date</label>
                        <input type="date" className='form-control'  name="end_date" value={details.end_date}  onChange={handleChange} ></input>
                        <span className='error_holder'>{details.error_list.end_date}</span>
                    </div>

                    <div className='col-md-4 form-group'>
                    <label>Organization</label>
                    <select className='form-control'  name="organization"  value={details.organization}  onChange={handleChange}  >
                        <option>All</option>
                        {organizations.map((item,index)=>{
                        return(
                        <option value={item.id} key={index} >{item.name}</option>
                        )
                        })}
                    </select>
                    <span className='error_holder'>{details.error_list.organization}</span>
                    </div>

                    </div>

                    <table className='table'>
                    <thead>
                    <tr className='small-row'>
                        <th>#</th>
                        <th>Date</th>
                        <th>Organization</th>
                        <th>Principal</th>
                        <th>Interest</th>
                        <th>Processing Fee</th>
                        <th>Charges</th>
                        <th>Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {entries.map((items,index)=>{
                    return(
                    <tr className='small-row' key={index} >
                        <td>{index+1}</td>
                        <td>{items.date.substr(0,10)}</td>
                        <td>{items.name}</td>
                        <td>KES {parseInt(items.principal).toLocaleString('en-US')}</td>
                        <td>KES {parseInt(items.interest).toLocaleString('en-US')}</td>
                        <td>KES {parseInt(items.processing).toLocaleString('en-US')}</td>
                        <td>KES {parseInt(items.charges).toLocaleString('en-US')}</td>
                        <td>KES {parseInt(items.total).toLocaleString('en-US')}</td>
                    </tr>
                    )
                    })}
                    </tbody>
                    <tfoot>
                    <tr  className='small-row' style={{fontWeight:'bold'}} >
                    <td></td>
                    <td></td>
                    <td><b>Total</b> </td>
                    <td>KES {parseInt(summary.principal).toLocaleString('en-US')}</td>
                    <td>KES {parseInt(summary.interest).toLocaleString('en-US')}</td>
                    <td>KES {parseInt(summary.processing).toLocaleString('en-US')}</td>
                    <td>KES {parseInt(summary.charges).toLocaleString('en-US')}</td>
                    <td>KES {parseInt(summary.total_summary).toLocaleString('en-US')}</td>
                    </tr>
                    </tfoot>
                    </table>

                </div>
                <div className='card-footer'>

                </div>
                </div>
                </div>
                </div>
                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default PaydayIncome;