const NewPayment=({payFlag,closePayFlag,payment,handlePayment,recordPayment})=>{

if(!payFlag) return false;
return(
<div className='overlay' onClick={()=>closePayFlag()} >
<div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

<div className="card card-primary card-outline">
    <div className="card-header">
        <h5><i className="nav-icon fas fa-money-bill" ></i> Record payment</h5>
    </div>

    <div className="card-body">
    <form className="form">
    <div className="row">

        <div className="form-group col-md-6">
        <label>Entry date <span className="red">*</span></label>
        <input type="date" className="form-control" name="date" value ={payment.date} onChange={handlePayment} />
        <span className="red">{payment.error_list.date}</span>
        </div>

        <div className="form-group col-md-6">
        <label>Amount <span className="red">*</span></label>
        <input type="number" className="form-control" name="amount" value ={payment.amount} min="1" onChange={handlePayment}  />
        <span className="red">{payment.error_list.amount}</span>
        </div>

        <div className="form-group col-md-6">
        <label>Mode <span className="red">*</span></label>
        <select className="form-control" name = "mode" value ={payment.mode} onChange={handlePayment}  >
            <option value = ''> Select mode</option>
            <option value = 'Bank Deposit'>Bank Deposit</option>
            <option value = 'Cheque'>Cheque</option>
        </select>
        <span className="red">{payment.error_list.mode}</span>
        </div>

        <div className="form-group col-md-6">
        <label>Reference <span className="red">*</span></label>
        <input type="text" className="form-control" name="reference"  value ={payment.reference}  onChange={handlePayment}  />
        <span className="red">{payment.error_list.reference}</span>
        </div>

        <div className="form-group col-md-12">
        <span className="btn btn-primary btn-sm" onClick={()=>recordPayment()}>Save Payment</span>
        </div>

    </div>
    </form>
    </div>

    <div className="card-footer">
    </div>

</div>

</div>
</div>
)

}
export default NewPayment;