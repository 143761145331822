import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import CardBody from '../elements/cards/CardBody';
import NewKpi from '../elements/modals/kpis/NewKpi';

function Kpis()
{
    const [open,setOpen] = useState(false)
    const [kpis,setKpis] = useState([])
    const effectRun = useRef(false)

    const [details,setDetails]= useState({
        id:'',
        kpi:'',
        description:'',
        error_list: [],
    })

    const onClose=()=>{
        setOpen(false)
        clearKpi()
    }

    const handleKpi=(e)=>{
    setDetails({...details, [e.target.name]: e.target.value})
    }

    const clearKpi=()=>{

        setDetails({
            id:'',
            kpi:'',
            description:'',
            error_list: [],
        })

    }

    const saveKpi=(e)=>{
        e.preventDefault()
        const data = {
            kpi: details.kpi,
            description: details.description
        }

        axios.post(`api/new_kpi`,data).then((res)=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success")
                onClose()
                fetchKpis()
            }
            else
            {
                setDetails({error_list:res.data.validation_errors})
            }
        })

    }

    const fetchKpis=()=>{

        axios.post(`api/all_kpis`).then((res)=>{
            if(res.data.status === 200)
            {
                setKpis(res.data.kpis)
            }
        })

    }

    useEffect(()=>{
        
        if(effectRun.current === false)
        {
            fetchKpis()
        }

        return()=>{
        effectRun.current = true
        }

    })

    return (
        <div className="wrapper">
        
        <NewKpi open={open} onClose={onClose} details={details} handleKpi={handleKpi} saveKpi={saveKpi}/>
        <Nav/>

        <Aside/>

        <div className="content-wrapper">

        <ContentHeader title="System Settings" />

        <div className="content">
        <div className="container">
        <div className="row">
            
        <div className="col-lg-3"  >
        
        <div className="card card-widget widget-user-2"  >
        <CardBody/>
        </div>

        </div>

        <div className = "col-lg-9" id = "centralSettings">
        
        <div className="card card-primary card-outline">
        <div className="card-header">
        <h5 className="card-title m-0"> <span className='fa fa-chart-bar' ></span> KPI's <span className='btn btn-primary btn-xs' style={{float:'right'}} onClick={()=>setOpen(true)}><span className='fa fa-plus-circle'></span> New KPI</span> </h5>
        </div>

        <div className="card-body">

        <table className='table'>
            <thead>
                <tr className='small-row'>
                    <th>#</th>
                    <th>Kpi</th>
                    <th>Description</th>
                    <th><span className='fa fa-edit'></span></th>
                    <th><span className='fa fa-trash'></span></th>
                </tr>
            </thead>
            <tbody>
                {kpis.map((item,index)=>{
                return(
                <tr key={index} className='small-row'>
                <td>{index+1}</td>
                <td>{item.kpi}</td>
                <td>{item.description}</td>
                <td><span className='fa fa-edit'></span></td>
                <td><span className='fa fa-trash'></span></td>
                </tr>)
                })}
            </tbody>
        </table>

        </div>

        <div className='card-footer' >

        </div>

        </div>

        </div>

        </div>
        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default Kpis;