import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

import LoanSettlement from '../elements/modals/loans/LoanSettlement';

function ApprovedLoans(){

    const [approved,setApproved] = useState([])
    const effectRun = useRef(false)

    const [open,setOpen] = useState(true)
    const [mode,setMode] = useState('')
    const [loan,setLoan] = useState('')

    const [mpesa,setMpesa] = useState({
        mpesa_account:''
    })

    const [bankMode,setBankMode] = useState({
        bank:'',
        account_name:'',
        account_number:'',
        branch:'',
        bank_code:'',
        branch_code:'',
        swift_code:''
    })

    const onClose=()=>{

        setOpen(false)
        setLoan('')
        setMode('')

        setBankMode({
            bank:'',
            account_name:'',
            account_number:'',
            branch:'',
            bank_code:'',
            branch_code:'',
            swift_code:''
        })

        setMpesa({
            mpesa_account:''
        })
    }

    // Fetch Mode
    const fetchMode=(customer,mode,loan)=>{

        if(mode == "mpesa")
        {
            const data = {
                client: customer
            }

            axios.post(`api/mpesa_info`,data).then((res)=>{
                if(res.data.status === 200)
                {
                    setMpesa({...mpesa, mpesa_account:res.data.mpesa.mpesa})
                    setOpen(true)
                    setMode("mpesa")
                    setLoan(loan)
                }
                else if(res.data.status === 305)
                {
                    swal("Warning",res.data.message,"warning")
                }
            })
        }
        else if(mode == "bank")
        {
            const data = {
                client: customer
            }

            axios.post(`api/bank_info`,data).then((res)=>{
                if(res.data.status === 200)
                {
                    const info = res.data.bank

                    setBankMode({
                        bank: info.bank,
                        account_name: info.account_name,
                        account_number: info.account_number,
                        branch: info.branch,
                        bank_code: info.bank_code,
                        branch_code: info.branch_code,
                        swift_code: info.swift_code
                    })

                    setOpen(true)
                    setMode("bank")
                    setLoan(loan)
                }
                else if(res.data.status === 305)
                {
                    swal("Warning",res.data.message,"warning")
                }
            })
        }

    }

    // Fetch Approved
    const fetchApproved=()=>{
    
    axios.post(`api/approved_loans`).then((res)=>{

        if(res.data.status === 200)
        {
            setApproved(res.data.approved)
        }

    })

    }

    // Disburse function
    const disburseLoan=(loan,reference,mode)=>{
    
    const data = {
        loan:loan,
        person: localStorage.getItem("auth_id"),
        reference: reference,
        mode:mode
    }

    if(loan == "")
    {
        swal("Warning","Disbursement failed try again.","warning")
    }
    else
    {
        axios.post(`api/disburse`,data).then((res)=>{
    
            if(res.data.status === 200)
            {
                swal('Success',res.data.message,'success')
            }
        
            })
    }

    }

    // Call useEffect()

    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchApproved()
        return()=>{
        effectRun.current = true
        }
    }

    },[])

    return (
        <div className="wrapper">

            <LoanSettlement open={open} onClose={onClose} mode={mode} mpesa={mpesa} bankMode={bankMode} loan={loan} disburseLoan={disburseLoan} ></LoanSettlement>

            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5><span className='fa fa-spinner'></span> Pending Disbursment</h5>
                </div>
    
                <div className="col-sm-6">
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='row'>
                <div className='col-md-12'>

                <div className='card card-primary card-outline'>
            <div className='card-body'>

            <table className='table'>
            <thead>
            <tr className='small-row' >
            <th>#</th>
            <th>Loan #</th>
            <th>Client</th>
            <th>Loan Type</th>
            <th>Amount </th>
            <th>Period</th>
            <th>Interest</th>
            <th>Stage</th>
            <th>Disburse Loan</th>
            </tr>
            </thead>

            <tbody>
            {approved.map((loan,index)=>{
            return(
                <tr key={index} className='small-row' >
                <td>{index+1}</td>
                <td><Link to={`/loan/${loan.id}`}><span style={{color:'blue'}} >{loan.loan_code}</span></Link></td>
                <td>{loan.first_name} {loan.last_name}</td>
                <td>{loan.type}</td>
                <td>KES {parseInt(loan.amount_loanable).toLocaleString('en-US')}</td>
                <td>{loan.period} Months</td>
                <td>{loan.interest_rate} %</td>
                <td>{loan.approval}</td>
                <td><span className='btn btn-xs btn-primary' onClick={()=>fetchMode(loan.customer,loan.settlement_mode,loan.id)} >Disburse Loan</span></td>
                </tr>
            )
            })}
            </tbody>

            </table>

            </div>
            </div>

                </div>
                </div>

                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default ApprovedLoans;