import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import PrintAging from '../elements/print/PrintAging';

function Aging()
{
    const [aging,setAging] = useState([])
    const [open,setOpen] = useState(false)

    const onClose=()=>{
        setOpen(false)
    }

    const fetchAging=()=>{

    axios.get(`api/aging`).then((res)=>{
        if(res.data.status === 200)
        {
            setAging(res.data.aging)
        }
    })

    }

    // Use effect to initialize critical data
    useEffect(()=>{
    
    fetchAging()

    },[])

    return (

        <div className="wrapper">
        <PrintAging open={open} onClose={onClose} statement={aging} ></PrintAging>
        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        

        <div className="content">
        <div className="container">

        <div className="row" style={{marginTop:'20px'}} >
        <div className = "col-lg-12" >

        <div className="card card-primary card-outline">
        <div className="card-header">
        <h6 className="card-title">Aging Report
        <span className='btn btn-dark btn-sm' style={{float:'right'}} onClick={()=>setOpen(true)} > <span className='fa fa-print'></span> Print Report</span>
        </h6>
        </div>
        <div className="card-body">

        <div className='row'>
            <div className='col-md-12'>
                <table className='table'>
                    <thead>
                        <tr className='small-row' >
                            <th>#</th>
                            <th>Client</th>
                            <th>Loan</th>
                            <th>0 -30</th>
                            <th>31 - 90</th>
                            <th>91 - 180</th>
                            <th>181 - 360</th>
                            <th>361 > </th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                    {aging.map((item,index)=>{
                        return(
                            <tr className='small-row' >
                                <td>{index+1}</td>
                                <td>{item.customer}</td>
                                <td>{item.loan}</td>
                                <td>{parseInt(item.one).toLocaleString('en-US')}</td>
                                <td>{parseInt(item.two).toLocaleString('en-US')}</td>
                                <td>{parseInt(item.three).toLocaleString('en-US')}</td>
                                <td>{parseInt(item.four).toLocaleString('en-US')}</td>
                                <td>{parseInt(item.five).toLocaleString('en-US')}</td>
                                <td>{parseInt(item.total).toLocaleString('en-US')}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
                
        </div>

        <div className='card-footer' >
        
        </div>

        </div>

        </div>
        </div>

        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default Aging;