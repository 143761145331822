const StageMonitor=({status,stage})=>{

if(status == 'pending')
{
    return (<span className="event_holder bg-dark" >{stage}</span>)
}else if(status === 'approved')
{
    return (<span className="event_holder bg-dark" style={{padding:'2px'}}><span className="fa fa-check"></span></span>)
}else if(status === 'rejected')
{
    return (<span className="event_holder bg-dark" style={{padding:'2px'}} ><span className="fa fa-times"></span></span>)
}else if(status === 'deffered')
{
    return (<span className="event_holder bg-dark" style={{padding:'2px'}} ><span className="fa fa-arrow-left"></span></span>)
}

}

export default StageMonitor;