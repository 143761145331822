import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';

const EditInsurance=({editInsuranceFlag,closeEditInsurance,insuranceHolder,handleInsuranceHolder,vehicles,effectPolicy,insuranceCompanies})=>{
   

    if(!editInsuranceFlag) return false;

    return(
    
        <Modal
        show={editInsuranceFlag}
        onHide={closeEditInsurance}
        backdrop="static"
        keyboard={false}
        size="lg"
        >
        <Modal.Header closeButton>
        <Modal.Title>Edit Insurance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <form className='form' onSubmit={effectPolicy} >
        <div className="row" >
        
        <div className="form-group col-md-3">
        <label>Vehicle</label>
        <select className="form-control" name = "vehicle" onChange={handleInsuranceHolder} value={insuranceHolder.vehicle} >
        <option value='' >Select vehicle</option>
        {vehicles.map((vehicle,index)=>{
        return(
        <option value={vehicle.id} key={index} >{vehicle.registration}</option>
        )
        })}
        </select>
        <span className="error_holder"  >{insuranceHolder.error_list.vehicle}</span>
        </div>
        
        <div className="form-group col-md-3">
        <label>Company</label>
        <select className="form-control" name="company" onChange={handleInsuranceHolder}  value={insuranceHolder.company} >
        <option value=''  >Select company</option>
        {insuranceCompanies.map((provider,index)=>{
        return (<option value = {provider.id} key={index} >{provider.name}</option>)
        })}
        </select>
        <span className="error_holder"  >{insuranceHolder.error_list.company}</span>
        </div>
        
        <div className="form-group col-md-3">
        <label>Policy Number</label>
        <input type="text" className="form-control" name="policy" value={insuranceHolder.policy} onChange={handleInsuranceHolder} />
        <span className="error_holder"  >{insuranceHolder.error_list.policy}</span>
        </div>
        
        <div className="form-group col-md-3">
        <label>Collateral Amount</label>
        <input type="number" className="form-control" name="collateral" onChange={handleInsuranceHolder}   value={insuranceHolder.collateral} />
        <span className="error_holder"  >{insuranceHolder.error_list.collateral}</span>
        </div>
        
        <div className="form-group col-md-3">
        <label>Premium</label>
        <input type="number" className="form-control" name="premium"  onChange={handleInsuranceHolder}  value={insuranceHolder.premium} />
        <span className="error_holder"  >{insuranceHolder.error_list.premium}</span>
        </div>
        
        <div className="form-group col-md-3">
        <label>Start Date</label>
        <input type="date" className="form-control" name="start_date"  onChange={handleInsuranceHolder}  value={insuranceHolder.start_date} />
        <span className="error_holder"  >{insuranceHolder.error_list.start_date}</span>
        </div>
        
        <div className="form-group col-md-3">
        <label>Expiry Date</label>
        <input type="date" className="form-control" name="expiry_date"  onChange={handleInsuranceHolder}  value={insuranceHolder.expiry_date} />
        <span className="error_holder"  >{insuranceHolder.error_list.expiry_date}</span>
        </div>
        
        <div className="form-group col-md-3">
        <label>Payment Status</label>
        <select className="form-control" name="payment"  onChange={handleInsuranceHolder}  value={insuranceHolder.payment} >
        <option>pending</option>
        <option>cleared</option>
        </select>
        <span className="error_holder"  >{insuranceHolder.error_list.payment}</span>
        </div>
        
        <div className="form-group col-md-3">
        <input type="submit" value="Save Policy" className="btn btn-primary btn-sm" />
        </div>
        
        </div>
        </form>
        
        </Modal.Body>
        </Modal>

    )

}

export default EditInsurance;