function Sandbox({open,closeModal,simulateLoan,handleChange,info,MoneyDecoration,meta,doNothing})
{

    if(!open) return false;

    return(
        <div className='overlay' onClick={closeModal} >
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className="card card-primary card-outline" >
            <div className="card-header" >
                Sand Box
                <span className='closeModal btn btn-xs btn-danger' onClick={()=>closeModal()} > <span className='fa fa-times-circle' ></span> Close modal</span>
            </div>

            <div className='card-body' >
            
            <div className='row' >
        <div className='col-lg-8' >

<div className="card card-primary card-outline">
<div className="card-header">
<h5 className="card-title"> <span className='fa fa-chart-line' ></span> Loan repayment simulator</h5>
</div>

<div className="card-body">

<div className='row' >

    <div className='form-group col-md-6' >
        <label>Loan amount</label>
        <input className='form-control' value={info.loan_amount} name = "loan_amount" onChange={(e)=>handleChange(e)} />
        <span className="error_holder" >{info.error_list.loan_amount}</span>
    </div>

    <div className='form-group col-md-6' >
        <label>Interest rate %</label>
        <input className='form-control' value={info.interest_rate}  name = "interest_rate" onChange={(e)=>handleChange(e)} />
        <span className="error_holder" >{info.error_list.interest_rate}</span>
    </div>

    <div className='form-group col-md-6' >
        <label>Repayment period</label>
        <input className='form-control'  value={info.repayment_period}  type='number' name = "repayment_period" onChange={(e)=>handleChange(e)} />
        <span className="error_holder" >{info.error_list.repayment_period}</span>
    </div>

    <div className='form-group col-md-6' >
    <label>Loan Type</label>
    <select className="form-control" name='loan_type' onChange={(e)=>handleChange(e)} >
        <option value='fixed'>Fixed</option>
        <option value='compound'>Compound</option>
    </select>
    </div>

</div>

<div className='row' >

        <div className='form-group col-md-6' >
        <label>Disbursment date</label>
        <input className='form-control' type = "date"  value={info.disbursment_date}  name = "disbursment_date" onChange={(e)=>handleChange(e)} />
        <span className="error_holder" >{info.error_list.disbursment_date}</span>
        </div>

        <div className='form-group col-md-6' >
        <label>Current date</label>
        <input className='form-control' type = "date" value={info.current_date}  name = "current_date" onChange={(e)=>handleChange(e)} />
        <span className="error_holder" >{info.error_list.current_date}</span>
        </div>

</div>

<div className='row' >

<div className='form-group col-md-12' >
<span className='btn btn-primary btn-sm' onClick={(e)=>simulateLoan(e)} > <span className='fa fa-calculator' ></span> Simulate loan repayment </span>
</div>

</div>

</div>

<div className='card-footer' >

</div>

</div>

</div>

<div className='col-md-4' >

<div className='card card-dark card-outline' >
<div className='card-header' >
<span className='fa fa-chart-pie' ></span> Results
</div>

<div className='card-body' >

<div className='form-group' >
<label>Total repayable amount</label>
<input className='form-control' value={MoneyDecoration(meta.repayable_amount)} onChange={doNothing} />
</div>

<div className='form-group' >
<label>Total Interest</label>
<input className='form-control' value={MoneyDecoration(meta.interest_amount)} onChange={doNothing} />
</div>

<div className='form-group' >
<label>Installment Amount</label>
<input className='form-control' value={MoneyDecoration(meta.installment_amount)} onChange={doNothing} />
</div>

</div>

<div className='card-footer' ></div>

</div>
</div>
</div>
</div>

<div className="card-footer">
</div>

</div>
</div>
</div>
    )

}

export default Sandbox;