import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import CardBody from '../elements/cards/CardBody';


function Settings()
{

    const [charges,setCharges] = useState([])
    const effectRun = useRef(false)

    const systemCharges=()=>{

        axios.get(`api/system_charges`).then((res)=>{
            if(res.data.status === 200)
            {
                setCharges(res.data.charges)
            }
        })

    }

    useEffect(()=>{
    
        if(effectRun.current === false)
        {
        
        
        systemCharges()
    
        return()=>{
        effectRun.current = true
        }
    
        }
    
        },[])

    return (
        <div className="wrapper">
        
        
        <Nav/>
        <Aside/>

        <div className="content-wrapper">

        <ContentHeader title="System Settings" />

        <div className="content">
        <div className="container">
        <div className="row">
            
        <div className="col-lg-3"  >
        
        <div className="card card-widget widget-user-2"  >
        <CardBody/>
        </div>

        </div>

        <div className = "col-lg-9" id = "centralSettings">
        
        <div className="card card-primary card-outline">
        <div className="card-header">
        <h5 className="card-title m-0"> <span className='fa fa-cog' ></span> System Charges </h5>
        </div>

        <div className="card-body">

        <table className='table' >
            <thead>
                <tr  className='small-row' >
                    <td>#</td>
                    <td>Charge</td>
                    <td><span className='fa fa-edit'></span></td>
                </tr>
            </thead>
            <tbody>
                {charges.map((item,index)=>{
                    return(<tr className='small-row' >
                        <td>{index+1}</td>
                        <td>{item.charge}</td>
                        <td><span className='btn btn-sm btn-success' >Edit</span>  </td>
                    </tr>)
                })}
            </tbody>
        </table>
                
        </div>

        <div className='card-footer' ></div>

        </div>

        </div>

        </div>
        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default Settings;