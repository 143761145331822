import { Link } from "react-router-dom";
import RoleNav from "./RoleNav";

const FinanceMenu=()=>{
    const permissions = JSON.parse(localStorage.getItem('auth_permissions'));
    const permission = "access_finance";

    if(permissions.includes(permission))

return(<li className="nav-item has-treeview">

<a href="#" className="nav-link">
<i className="nav-icon fa fa-money-bill"></i>
<p>
Finance
<i className="fas fa-angle-right right"></i>
</p>
</a>

<ul className="nav nav-treeview">
<RoleNav page="Mpesa" link="/mpesa" icon="nav-icon far fa-circle" roles={['CEO','System Administrator','Finance']} />
<RoleNav page="Payments" link="/payments" icon="nav-icon far fa-circle" roles={['CEO','System Administrator','Finance']} />
<RoleNav page="Invoices" link="/invoices" icon="nav-icon far fa-circle" roles={['CEO','System Administrator']} />
<RoleNav page="Cheque Register" link="/cheque_register" icon="nav-icon far fa-circle" roles={['CEO','System Administrator','Finance']} />
<RoleNav page="Suppliers" link="/suppliers" icon="nav-icon far fa-circle" roles={['CEO','System Administrator','Finance']} />
<RoleNav page="Insurance" link="/insurance" icon="nav-icon far fa-circle" roles={['CEO','System Administrator','Finance']} />
<RoleNav page="Chart of Accounts" link="/accounts" icon="nav-icon far fa-circle" roles={['CEO','System Administrator','Finance']} />
<RoleNav page="Repocessions" link="/repocessions" icon="nav-icon far fa-circle" roles={['CEO','System Administrator','Finance']} />
</ul>

</li>)

}

export default FinanceMenu;