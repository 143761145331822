const NewPermission=({open,onClose,details,handleDetails,savePermission,categories})=>{
if(!open) return null;
return(
<div className='overlay' onClick={onClose}>
<div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

<div className = "card card-primary" >
    <div className="card-header">
        <span className="fa fa-lock"></span> New Permission
    </div>

    <div className="card-body">
    <form className="form" onSubmit={savePermission}>

    <div className="row">
    
    <div className="form-group col-md-4">
            <label>Category</label>
            <select className="form-control" name="category" value={details.category} onChange={(e)=>handleDetails(e)} >
                <option value = ''>Select Category</option>
                {categories.map((item,index)=>{
                return(<option key={index}>{item.category}</option>)
                })}
               
            </select>
            <span className="red">{details.error_list.category}</span>
        </div>

        <div className="form-group col-md-4">
        <label>Permission Code</label>
        <input type="text" name="code" className="form-control" value={details.code} onChange={(e)=>handleDetails(e)} />
        </div>

        <div className="form-group col-md-4" >
            <label>Permission </label>
            <input type="text" className="form-control" name="permission" value={details.permission} onChange={(e)=>handleDetails(e)}/>
            <span className="red">{details.error_list.permission}</span>
        </div>

        <div className="form-group col-md-4">
            <label>Description</label>
            <input type="text" className="form-control" name="description" value={details.description} onChange={(e)=>handleDetails(e)}/>
        </div>

        <div className="form-group col-md-4">
        <label>Exclusive</label>
        <select className="form-control" name="exclusive" value={details.exclusive} onChange={(e)=>handleDetails(e)} >
        <option >no</option>
        <option >yes</option>
        </select>
        </div>

        <div className="form-group col-md-4">
        <label>Exclusive to</label>
        <input type="text" className="form-control" name="exclusive_to"  value={details.exclusive_to} onChange={(e)=>handleDetails(e)} />
        </div>


        <div className="form-group col-md-12">
            <input type = "submit" value="Save Permission" className="btn btn-primary btn-sm"></input>
        </div>

    </div>
        
    </form>
    </div>

    <div className="card-footer"></div>
</div>

</div>
</div>
)

}
export default NewPermission;