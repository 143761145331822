const Stage_Zero=({checks,checkStatus})=>{

return (

<div className="row" >
<div className="col-md-12">

<table className="table">
<thead>
<tr>
<th>#</th>
<th>Check</th>
<th>Result</th>
</tr>
</thead>

<tbody>

<tr>
<td>1</td>
<td><span className="fa fa-clipboard" ></span> Collateral</td>
<td>
{checks.Collateral}
</td>
</tr>

<tr>
<td>2</td>
<td><span className="fa fa-clipboard" ></span> Insurance</td>
<td>
{checks.Insurance}
</td>
</tr>

<tr>
<td>3</td>
<td><span className="fa fa-clipboard" ></span> Kin Information</td>
<td>
{checks.Kin}
</td>
</tr>

<tr>
<td>4</td>
<td><span className="fa fa-clipboard" ></span> KYC Information</td>
<td>
{checks.Kyc}
</td>
</tr>

<tr>
<td>5</td>
<td><span className="fa fa-clipboard" ></span> KYC Documents</td>
<td>
{checks.Scans}
</td>
</tr>

<tr>
<td>6</td>
<td><span className="fa fa-clipboard" ></span> Valuation </td>
<td>
{checks.Valuations}
</td>
</tr>

<tr>
<td>7</td>
<td><span className="fa fa-clipboard" ></span> Valuation Documents </td>
<td>
{checks.Valuation_documents}
</td>
</tr>

</tbody>

</table>

<p>
<b>Smart Check: {checkStatus}</b>
</p>

<p>
<i>Note</i>: This is a basic minimum check, kindly ensure you follow the Customer verification policy.
</p>

</div>
</div>

)

}
export default Stage_Zero;