import React, { useEffect, useState } from 'react';

function Kin({kin_info,setKinFlag,fetchKin,setNewKin,profile}){

    const addButton=()=>{
    
    var role = localStorage.getItem('auth_role')

    if(role == "CEO" || role == "System Administrator")
    {
        return(
            <span className='btn btn-primary btn-sm' onClick={()=>setNewKin('true')} style = {{float:'right'}} >Add Kin</span>
        )
    }
    else
    {
        if(profile.edit_flag == 0)
        {
            return(
                <span className='btn btn-primary btn-sm' onClick={()=>setNewKin('true')} style = {{float:'right'}} >Add Kin</span>
            )
        }
    }

    }

    const kinRows=()=>{

    var role = localStorage.getItem("auth_role")

    if(role == "System Administrator" || role == "CEO")
    {
        return(
            kin_info.map((item,index)=>{
            
                return(
                <tr className='small-row' >
                <td>{index+1}</td>
                <td>{item.names}</td>
                <td>{item.id_no}</td>
                <td>{item.phone}</td>
                <td>{item.relation}</td>
                <td>{item.address}</td>
                <td> <span className='fa fa-edit' onClick={()=>fetchKin(item.id)} ></span> </td>
                </tr>
                )
    
                })
        )
    }
    else
    {
        return(
            kin_info.map((item,index)=>{
            
                return(
                <tr className='small-row' >
                <td>{index+1}</td>
                <td>{item.names}</td>
                <td>{item.id_no}</td>
                <td>{item.phone}</td>
                <td>{item.relation}</td>
                <td>{item.address}</td>
                <td>..</td>
                </tr>
                )
    
                })
        )
    }

    }

    return (
        <div class="card card-primary card-outline">
            <div class="card-header">
            <h5><span className='fa fa-users'></span> Next of Kin 
            {addButton()}
            </h5>
            </div>
            <div class="card-body box-profile contact-box ">

            <div className='row' >
            
            <div className='col-md-12' >

           <table className='table'>
            <thead>
            <tr className='small-row'>
            <th>#</th>
            <th>Name</th>
            <th>ID #</th>
            <th>Phone</th>
            <th>Relation</th>
            <th>Address</th>
            <th><span className='fa fa-edit'></span> </th>
            </tr>
            </thead>

            <tbody>
            {kinRows()}
            </tbody>

            <tfoot>                
            </tfoot>

           </table>

            </div>

            </div>

            </div>

            <div className='card-footer'></div>

            </div>
    )
}

export default Kin;