import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  function LineGraph({graphData,graphLabel}){

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: '',
          },
        },
      };
      
      const labels = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat','Sun'];
      
      const data = {
        labels,
        datasets: [
          {
            label: graphLabel,
            data: graphData,
            borderColor: 'rgba(53, 162, 235, 0.9)',
            backgroundColor: 'rgba(53, 162, 235, 0.9)',
          },
          
        ],
      };

      return (<Line options={options} data={data} />)

  }

  export default LineGraph;
  
 