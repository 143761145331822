import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

function LoanIncomeChart({segments}){

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'right',
          },
          title: {
            display: true,
            text: '',
          },
        },
      };

    const data = {
        labels: ['Principal', 'Interest', 'Tracking Fee', 'Service Charge', 'Other Charges'],
        datasets: [
          {
            label: '# of Votes',
            data: segments,
            backgroundColor: [
              '#01153e',
              'rgba(53, 162, 235, 0.9)',
              'rgba(255, 206, 86, 0.9)',
              'rgba(153, 102, 255, 0.9)',
              '#ff6700',
            ],
            borderColor: [
              '#01153e',
              'rgba(53, 162, 235, 0.9)',
              'rgba(255, 206, 86, 0.9)',
              'rgba(153, 102, 255, 0.9)',
              '#ff6700',
            ],
            borderWidth: 1,
          },
        ],
      };
    
      return <Pie data={data} options={options} />;

}

export default LoanIncomeChart;