const AddPermission=({openPermission,closePermission,categories,permissions,assignPermission})=>{
if(!openPermission) return null;

return(
    <div className='overlay' onClick={closePermission}>
    <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

    <div className="card card-primary card-outline">
        <div className="card-header">
            <h6>Add Permissions to role</h6>
        </div>

        <div className="card-body">

        <table className="table">
            <thead>
                <tr className="small-row">
                    <th>#</th>
                    <th>Category</th>
                    <th>Permissions</th>
                </tr>
            </thead>
            <tbody>
                {categories.map((item,index)=>{
                return(
                    <tr key={index} className="small-row">
                        <td>{index+1}</td>
                        <td>{item.category}</td>
                        <td>
                            {permissions.map((perm,element)=>{
                                {if(perm.category == item.category){
                                    return(<span className="btn btn-dark btn-xs" key={element} style={{width:'100px',margin:'2px',fontSize:'9px'}}><input type="checkbox" style={{display:'inline'}} onClick={()=>assignPermission(perm.id)} ></input> <span style={{display:'inline'}}>{perm.permission} </span></span>)
                                }}
                            })}
                        </td>
                    </tr>
                )
                })}
            </tbody>
        </table>

        </div>

        <div className="card-footer"></div>
    </div>

    </div>
    </div>
)

}
export default AddPermission;