const Vehicles =({setVehicleFlag,vehicles,fetchVehicleDetails,fetchCarDetails,profile})=>{

    const addButton=()=>{

        var role = localStorage.getItem('auth_role')
    
        if(role == "CEO" || role == "System Administrator")
        {
            return(
                <span className='btn btn-primary btn-sm' style={{float:'right'}} onClick={()=>setVehicleFlag(true)} >New vehicle</span>
            )
        }
        else
        {
            if(profile.edit_flag == "0")
            {
                return(
                    <span className='btn btn-primary btn-sm' style={{float:'right'}} onClick={()=>setVehicleFlag(true)} >New vehicle</span> 
                )
            }
        }     
    }

    const vehiclesTable=()=>{

        var role = localStorage.getItem('auth_role')
    
        if(role == "CEO" || role == "System Administrator")
        {
                return(
                    <table className='table' >
                    <thead>
                        <tr style={{fontSize:'12px'}}>
                            <th>#</th>
                            <th>Registration</th>
                            <th>Make</th>
                            <th>Model</th>
                            <th>Year</th>
                            <th>Engine #</th>
                            <th>Chasis #</th>
                            <th>Log book #</th>
                            <th><span className='fa fa-edit' ></span></th>
                        </tr>
                    </thead>
            
                    <tbody>
                    {vehicles.map((car,index)=>{
                        return(<tr key={index} style={{fontSize:'11px'}} >
                            <td>{index+1}</td>
                            <td onClick={()=>fetchCarDetails(car.id)} style={{color:'blue'}} > {car.registration} </td>
                            <td>{car.make}</td>
                            <td>{car.model}</td>
                            <td>{car.year}</td>
                            <td>{car.engine_no}</td>
                            <td>{car.chasis_no}</td>
                            <td>{car.log_book}</td>
                            <td> <span className='fa fa-edit' onClick={()=>fetchVehicleDetails(car.id)} ></span> </td>
                            </tr>)
                    })}
                    </tbody>
            
                </table>  
                )
        }
        else
        {
           
            if(profile.edit_flag == "0")
            {
                return(
                    <table className='table' >
                    <thead>
                    <tr style={{fontSize:'12px'}}>
                    <th>#</th>
                    <th>Registration</th>
                    <th>Make</th>
                    <th>Model</th>
                    <th>Year</th>
                    <th>Engine #</th>
                    <th>Chasis #</th>
                    <th>Log book #</th>
                    <th><span className='fa fa-edit' ></span></th>
                    </tr>
                    </thead>
                    <tbody>
                    {vehicles.map((car,index)=>{
                    return(<tr key={index} style={{fontSize:'11px'}} >
                    <td>{index+1}</td>
                    <td onClick={()=>fetchCarDetails(car.id)} style={{color:'blue'}} > {car.registration} </td>
                    <td>{car.make}</td>
                    <td>{car.model}</td>
                    <td>{car.year}</td>
                    <td>{car.engine_no}</td>
                    <td>{car.chasis_no}</td>
                    <td>{car.log_book}</td>
                    <td> <span className='fa fa-edit' onClick={()=>fetchVehicleDetails(car.id)} ></span> </td>
                    </tr>)
                    })}
                    </tbody>
                    </table>
                    )
            }
            else
            {
                return(
                    <table className='table' >
                    <thead>
                    <tr style={{fontSize:'12px'}}>
                    <th>#</th>
                    <th>Registration</th>
                    <th>Make</th>
                    <th>Model</th>
                    <th>Year</th>
                    <th>Engine #</th>
                    <th>Chasis #</th>
                    <th>Log book #</th>
                    </tr>
                    </thead>
                    <tbody>
                    {vehicles.map((car,index)=>{
                    return(<tr key={index} style={{fontSize:'11px'}} >
                    <td>{index+1}</td>
                    <td onClick={()=>fetchCarDetails(car.id)} style={{color:'blue'}} > {car.registration} </td>
                    <td>{car.make}</td>
                    <td>{car.model}</td>
                    <td>{car.year}</td>
                    <td>{car.engine_no}</td>
                    <td>{car.chasis_no}</td>
                    <td>{car.log_book}</td>
                    </tr>)
                    })}
                    </tbody>
                    </table>
                    )
            }
            
        }     
    }

return(
    <div className='card card-primary card-outline'>

    <div className='card-header'>
    <h5> <span className="fa fa-car" ></span> Vehicles
    {addButton()}
    </h5>
    </div>

    <div className='card-body'>
    {vehiclesTable()}
    </div>

    <div className='card-footer'>
        
    </div>

    </div>
)

}
export default Vehicles;