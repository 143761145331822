import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

import BarGraph from '../elements/charts/BarGraph';
import PieChart from '../elements/charts/PieChart';

import Cards from '../elements/cards/Cards';


function Main()
{
    const [annual,setAnnual] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
    const [summary, setSummary] = useState([]);
    const [segments, setSegments] = useState([])

    useEffect(()=>{
        fetchSummary()
        fetchSegments()
    },[])

    const fetchSummary = () =>{
    
    axios.post(`api/main`).then(res=>{
        setSummary(res.data.summary)
        setAnnual(res.data.summary.loans)
    })

    }

    const fetchSegments=()=>{
      axios.post(`api/loan_segments`).then(res=>{
        setSegments(res.data.segments)
    })
    }

    return (
        <div className="wrapper">
        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        <ContentHeader title="Central Dashboard !" />

        <div className="content">
        <div className="container">

        <div className='card card-primary card-outline ' >
        <div className='card-body' >
        <div className='row'>

        <Cards title='All Leads' data = {summary.leads} size = 'col-md-3' icon='fa fa-users' stat='100%' />
        <Cards title='Pending Leads' data = {summary.pending} size = 'col-md-3' icon='fa fa-spinner' stat={summary.pending_rate} page='/all_leads' />
        <Cards title='Valuations' data = {summary.valuation} size = 'col-md-3' icon='fa fa-balance-scale' stat={summary.valuation_rate} page='/all_valuations' />
        <Cards title='Loans' data = {summary.disbursed} size = 'col-md-3' icon='fa fa-money-bill' stat={summary.disbursed_rate} page='/all_loans' />
        
        <Cards title='Departments' data = {summary.departments} size = 'col-md-3' icon='fa fa-university' page='/departments' />
        <Cards title='Staff' data = {summary.staff} size = 'col-md-3' icon='fa fa-users' page='/staff' />
        <Cards title='Sales Agents' data = '0' size = 'col-md-3' icon='fa fa-users' page='/all_agents' />
        <Cards title='Perfomance' data = 'all' size = 'col-md-3' icon='fa fa-chart-line' page='/perfomance_analysis' />
        
        
        </div>

        </div>
        </div>

        </div>

        <div className='container' >
        <div className='row' >

            <div className='col-md-5' >

            <div className='card card-dark card-outline' >
            <div className='card-header' >
            <span className='fa fa-venus-mars' ></span> Loan disbursment | Gender - 2022
            </div>

            <div className='card-body' >

            <div className='row' >
            <Cards title='Male' data = {summary.male} size = 'col-md-6' icon='fa fa-mars' stat={summary.male_rate} />
            <Cards title='Female' data = {summary.female} size = 'col-md-6' icon='fa fa-venus' stat={summary.female_rate} />
            </div>

            </div>
            </div>

            <div className="card card-dark card-outline ">
              
              <div className="card-body table-responsive p-0">
                <table className="table table-striped table-valign-middle">
                  <thead>
                  <tr>
                    <th> <span className='fa fa-calendar-alt' ></span> Quarter</th>
                    <th>Loans Disbursed</th>
                    <th><span className='badge badge-success' style={{width:'45px'}} >%</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Q1</td>
                    <td>KES {summary.q1}</td>
                    <td><span className='badge badge-success' style={{width:'45px'}} >{summary.q1_rate}</span></td>
                  </tr>

                  <tr>
                    <td>Q2</td>
                    <td>KES {summary.q2}</td>
                    <td><span className='badge badge-success' style={{width:'45px'}} >{summary.q2_rate}</span></td>
                  </tr>

                  <tr>
                    <td>Q3</td>
                    <td>KES {summary.q3}</td>
                    <td><span className='badge badge-success' style={{width:'45px'}} >{summary.q3_rate}</span></td>
                  </tr>

                  <tr>
                    <td>Q4</td>
                    <td>KES {summary.q4}</td>
                    <td><span className='badge badge-success' style={{width:'45px'}} >{summary.q4_rate}</span></td>
                  </tr>

                  <tr style={{fontWeight:'bold'}} >
                    <td>Total</td>
                    <td>KES {summary.total_loans}</td>
                    <td><span className='badge badge-success' style={{width:'45px'}} >100 %</span></td>
                  </tr>
                 
                  </tbody>
                </table>
              </div>
              <div className='card-footer' ></div>
            </div>

            <div className="card card-dark card-outline ">
              
              <div className="card-body table-responsive p-0">
                <table className="table table-striped table-valign-middle">
                  <thead>
                  <tr>
                    <th><span className='fa fa-chart-pie' ></span> Loan Segments</th>
                    <th>Amount</th>
                    <th><span className='badge badge-success' style={{width:'45px'}} >... %</span></th>
                  </tr>
                  </thead>
                  <tbody>

                  {segments.map((number,index)=>{
                      return(
                        <tr key={number[0]} >
                        <td>{number[0]}</td>
                        <td>KES {number[1]}</td>
                        <td><span className='badge badge-success' style={{width:'45px'}} >{number[2]}</span></td>
                      </tr>
                      )
                  })}

                </tbody>
                </table>
              </div>
              <div className='card-footer' ></div>
            </div>

            </div>

            <div className='col-md-7' >
            
            <div className='card card-primary card-outline' >
            <div className='card-header' >
            <span className='fa fa-chart-bar' ></span> Loans Disbursed 2022
            </div>
            <div className='card-body' >
            <BarGraph loans={annual} />
            </div>
            <div className='card-footer' ></div>
            </div>

            </div>

        </div>
        </div>

        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default Main;