import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';


function NewRequisition()
{
    const navigate = useNavigate();
    const effectRun = useRef(false)
    const [items,setItems] = useState([])
    const [total,setTotal] = useState(0)
    const [accounts,setAccounts] = useState([])

    const [errors,setErrors] = useState({
    item:'',
    description:'',
    quantity:'',
    cost:'',
    })

    const [details,setDetails] = useState({
    item:'',
    description:'',
    quantity: 1,
    cost:1,
    })

    const [info,setInfo] = useState({
    title: '',
    account: '',
    items:'',
    total:0,
    error_list: []
    })

    const handleInfo=(e)=>{
    setInfo({...info, [e.target.name]: e.target.value})
    }

    const findTotal=()=>{

    var sum = 0;
    items.map((item)=>{
        sum = (sum+item[4])
    })

    setTotal(sum)
    setInfo({...info, total:sum})
    }

    const handleDetails=(e)=>{
    setDetails({...details, [e.target.name]: e.target.value})
    }

    const addItem=(e)=>{
        e.preventDefault()
    
        var proceed = true

        var error = {
            item:'',
            description:'',
            quantity: '',
            cost:''
        }

        if(details.item== '')
        {
            error.item = 'Item required'
            proceed = false
        }
        else
        {
            error.item = ''
        }

        if(details.description == '')
        {
            error.description = 'Description required'
            proceed = false
        }
        else
        {
            error.description = ''
        }

        if(parseInt(details.cost) == '' || parseInt(details.cost) <1 )
        {
            error.cost = 'Cost required and cannot be less than 1'
            proceed = false
        }
        else
        {
            error.cost = ''
        }

        if(parseInt(details.quantity) == '' || parseInt(details.quantity)<1)
        {
            error.quantity = 'Quantiy required and cannot be less than 1'
            proceed = false
        }
        else
        {
            error.quantity = ''
        }

        if(proceed)
        {
            const holder = items
            const total = parseInt(details.quantity)*parseInt(details.cost)
            const data = [details.item,details.description,details.quantity,details.cost,total]
            holder.push(data)
            setItems(holder)
            findTotal()
            swal("Success","Item added successfully","success")

            setDetails({
            item:'',
            description:'',
            quantity:1,
            cost:1,
            })

            var sum = 0;
            
            items.map((item)=>{
            sum = (sum+item[4])
            })

            setErrors(error)
            setInfo({...info, items: JSON.stringify(items), total:sum})

        }
        else
        {
            setErrors(error)
        }

    }

    const checkItems=(ind)=>{
    
    const empty = []
    var sum = 0;
    
    items.map((item,index)=>{

        if(index !== ind)
        {
            empty.push(item)
            sum = (sum+item[4])
        }

    })
    
    setTotal(sum)
    setItems(empty)
    setInfo({...info, items: JSON.stringify(empty), total:sum})
    swal("Success","Item removed successfuly !","success")
    }

    const fetchAccounts=()=>{
    
    axios.post(`api/accounts`).then((res)=>{
        if(res.data.status === 200)
        {
            setAccounts(res.data.accounts)
        }
    })

    }

    // Function to save requisition

    const saveRequisition=(e)=>{
    e.preventDefault()

    if(items.length<1)
    {
        swal("Warning","Add item to requisition","warning")
    }
    else
    {
        const data = {
            title: info.title,
            items: info.items,
            description: info.description,
            total: info.total,
            account: info.account,
        }
    
        axios.post(`api/create_requisition`,data).then((res)=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success")
                setInfo({
                    title: '',
                    account: '',
                    items:'',
                    total:0,
                    error_list: []
                    })
        
                setItems([])
                setTotal(0)
                navigate('/requisitions')
            }
            else
            {
                setInfo({...info, error_list:res.data.validation_errors})
            }
        })

        
    }

    }

    // Use effect to initialize critical data
    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchAccounts()
    }

    return()=>{
    effectRun.current = true
    }

    },[])

    return (

        <div className="wrapper">
        
        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        

        <div className="content">
        <div className="container">

        <div className="row" style={{marginTop:'20px'}} >
        <div className = "col-lg-12" >

        <div className="card card-outline card-primary">
        <div className="card-header">
        <h6 className="card-title">New Requisition </h6>
        </div>
        <div className="card-body">

        <div className='row' >

        <div className='col-md-8'>

        <div className='card card-primary'>
        <div className='card-header'>
            <h6>Requisition Items</h6>
        </div>

        <div className='card-body'>
            <table className='table'>

            <thead>
                <tr className='small-row'>
                    <th>#</th>
                    <th>Item</th>
                    <th>Description</th>
                    <th>Quant</th>
                    <th>Cost @ item</th>
                    <th>Total</th>
                    <th><span className='fa fa-trash'></span></th>
                </tr>
            </thead>

            <tbody>
            {items.map((item,index)=>{
            return(<tr className='small-row' key={index}>
                    <td>{index+1}</td>
                    <td>{item[0]}</td>
                    <td>{item[1]}</td>
                    <td>{item[2]}</td>
                    <td>KES {parseInt(item[3]).toLocaleString('en-US')}</td>
                    <td>KES {parseInt(item[4]).toLocaleString('en-US')}</td>
                    <td> <span className='fa fa-trash red' onClick={()=>checkItems(index)} ></span> </td>
                </tr>)
                })
            }
            <tr className='small-row' >
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td> <b>KES {parseInt(total).toLocaleString('en-US')}</b> </td>
                <td>...</td>
            </tr>
            </tbody>

            </table>

            <div className='row' style={{marginTop:'35px'}} >
            <div className='col-md-12'>

            <form className='form' onSubmit={saveRequisition}>

            <div className='row'>

            <div className='col-md-6 form-group'>
            <label>Requisition Title <span className='red'>*</span></label>
            <input type="text" className='form-control' name="title" value ={info.title} onChange={handleInfo} />
            <span className='error_holder'>{info.error_list.title}</span>
            </div>

            <div className='col-md-6 form-group'>
            <label>Account <span className='red'>*</span></label>
            <select className='form-control' name="account"  value ={info.account} onChange={handleInfo}  >
            <option value='' >Select account</option>
            {accounts.map((item,index)=>{
            return(
            <option value={item.id} key={index} >{item.account_name}</option>
            )
            })}
            </select>
            <span className='error_holder'>{info.error_list.account}</span>
            </div>

            <div className='col-md-12 form-group'>
            <input type="submit" value="Save requisition" className='btn btn-primary' />
            </div>

            </div>

            </form>

            </div>
            </div>

        </div>

        <div className='card-footer'>
           
        </div>

        </div>

        

        </div>

        <div className='col-md-4'>
        <div className='card card-primary'>
            <div className='card-header'>
            <h6>Add Item</h6>
            </div>

            <div className='card-body'>
            <form className='form' onSubmit={addItem}>
            <div className='row'>

            <div className='col-md-12 form-group'>
            <label>Item</label>
            <input type="text" className='form-control' name="item" value={details.item} onChange={handleDetails} />
            <span className='error_holder'>{errors.item}</span>
            </div>

            <div className='col-md-12 form-group'>
            <label>Description</label>
            <input type="text" className='form-control' name="description" value={details.description} onChange={handleDetails}  />
            <span className='error_holder'>{errors.description}</span>
            </div>

            <div className='col-md-6 form-group'>
            <label>Quantity</label>
            <input type="number" className='form-control' name="quantity" value={details.quantity} onChange={handleDetails}  min="1" />
            <span className='error_holder'>{errors.quantity}</span>
            </div>

            <div className='col-md-6 form-group'>
            <label>Cost per item</label>
            <input type="number" className='form-control' name="cost" value={details.cost} onChange={handleDetails} min="1" />
            <span className='error_holder'>{errors.cost}</span>
            </div>

            <div className='col-md-12 form-group'>
            <input type="submit" className='btn btn-primary btn-sm' value="Add Item" />
            </div>

            </div>
            </form>
            </div>

            <div className='card-footer'>
            
            </div>
        </div>
        </div>

        </div>  
                
        </div>

        <div className='card-footer' >
        
        </div>

        </div>

        </div>
        </div>

        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default NewRequisition;