

const OrganizationStatement=({statement,details,setOpen})=>{

return(
<div className="card card-primary card-outline">
    <div className="card-header">
    <span className="fa fa-file-invoice"></span> Statement
    <span className="btn btn-dark btn-sm" style={{float:'right'}} onClick={()=>setOpen(true)} > <span className="fa fa-print" ></span> Print Statement</span>
    </div>
    <div className="card-body">
    <table className="table">
    <thead>
        <tr className="small-row" >
            <th>#</th>
            <th>Date</th>
            <th>Item</th>
            <th>Debit</th>
            <th>Credit</th>
            <th>Balance</th>
        </tr>
    </thead>
    <tbody>
        {statement.map((item,index)=>{
        if(item.type == "credit")
        {
            return(
            <tr className="small-row" >
            <td>{index+1}</td>
            <td>{item.date.substr(0,10)}</td>
            <td>{item.description}</td>
            <td> -- </td>
            <td>KES {parseInt(item.amount).toLocaleString('en-US')}</td>
            <td>KES {parseInt(item.account_balance).toLocaleString('en-US')}</td>
            </tr>
            )
        }
        else
        {
            return(
                <tr className="small-row" >
                <td>{index+1}</td>
                <td>{item.date.substr(0,10)}</td>
                <td>{item.description}</td>
                <td>KES {parseInt(item.amount).toLocaleString('en-US')}</td>
                <td>--</td>
                <td>KES {parseInt(item.account_balance).toLocaleString('en-US')}</td>
                </tr>
                )
        }
        })}
    </tbody>
    </table>
    </div>
    <div className="card-footer"></div>
</div>)

}
export default OrganizationStatement;