const EditTarget=({openTarget,closeTarget,target,handleTarget,updateKpi})=>{
    if(!openTarget) return null;

    return(
        <div className='overlay' onClick={closeTarget}>
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

            <div className="card card-primary card">
                <div className="card-header">
                <h4>Edit Target</h4>
                </div>

                <div className="card-body">

               <div className="row">
                <div className="col-md-12">
                <form className="form" onSubmit={updateKpi}>

                <div className="form-group">
                    <label>Target</label>
                    <input name="target" className="form-control" value={target} onChange={handleTarget} />
                </div>

                <div className="form-group">
                    <input type="submit"  value="Update target" className="btn btn-primary btn-sm"/>
                </div>

                </form>
                </div>
               </div>

                </div>

                <div className="card-footer"></div>
            </div>

        </div>
        </div>
    )

}
export default EditTarget;