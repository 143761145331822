import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import { Modal } from 'react-bootstrap';

const NewInsurance=({insuranceFlag,closeInsurance,vehicles,insuranceDetails,handleInsurance,setInsuranceDetails,fetchPolicies,insuranceCompanies})=>{

const createInsurance=(e)=>{
e.preventDefault()
const data = {
customer: insuranceDetails.customer,
vehicle: insuranceDetails.vehicle,
company: insuranceDetails.company,
policy: insuranceDetails.policy,
collateral: insuranceDetails.collateral,
premium: insuranceDetails.premium,
start_date: insuranceDetails.start_date,
expiry_date: insuranceDetails.expiry_date,
payment: insuranceDetails.payment,
}

axios.post(`api/record_policy`,data).then((res)=>{

if(res.data.status === 200)
{
    swal("Success",res.data.message,'success')
    clearInsurance()
    closeInsurance()
    fetchPolicies()
}
else
{
    setInsuranceDetails({...insuranceDetails, error_list:res.data.validation_errors})
}

})

}

const clearInsurance=()=>{
    setInsuranceDetails({
        id:'',
        registration:'',
        make:'',
        model:'',
        year:'',
        color:'',
        transmission:'',
        body:'',
        log_book:'',
        error_list: [],
    })
}



if(!insuranceFlag) return false;

return(

<Modal
show={insuranceFlag}
onHide={closeInsurance}
backdrop="static"
keyboard={false}
size="lg"
>
<Modal.Header closeButton>
<Modal.Title>New Insuarance</Modal.Title>
</Modal.Header>
<Modal.Body>
<form className='form' onSubmit={createInsurance} >
<div className="row" >

<div className="form-group col-md-3">
<label>Vehicle</label>
<select className="form-control" name = "vehicle" onChange={handleInsurance} value={insuranceDetails.vehicle} >
<option value='' >Select vehicle</option>
{vehicles.map((vehicle,index)=>{
return(
<option value={vehicle.id} key={index} >{vehicle.registration}</option>
)
})}
</select>
<span className="error_holder"  >{insuranceDetails.error_list.vehicle}</span>
</div>

<div className="form-group col-md-3">
<label>Company</label>
<select className="form-control" name="company" onChange={handleInsurance}  value={insuranceDetails.company} >
<option value=''  >Select company</option>
{insuranceCompanies.map((provider,index)=>{
return (<option value = {provider.id} key={index} >{provider.name}</option>)
})}
</select>
<span className="error_holder"  >{insuranceDetails.error_list.company}</span>
</div>

<div className="form-group col-md-3">
<label>Policy Number</label>
<input type="text" className="form-control" name="policy" value={insuranceDetails.policy} onChange={handleInsurance} />
<span className="error_holder"  >{insuranceDetails.error_list.policy}</span>
</div>

<div className="form-group col-md-3">
<label>Collateral Amount</label>
<input type="number" className="form-control" name="collateral" onChange={handleInsurance}   value={insuranceDetails.collateral} />
<span className="error_holder"  >{insuranceDetails.error_list.collateral}</span>
</div>

<div className="form-group col-md-3">
<label>Premium</label>
<input type="number" className="form-control" name="premium"  onChange={handleInsurance}  value={insuranceDetails.premium} />
<span className="error_holder"  >{insuranceDetails.error_list.premium}</span>
</div>

<div className="form-group col-md-3">
<label>Start Date</label>
<input type="date" className="form-control" name="start_date"  onChange={handleInsurance}  value={insuranceDetails.start_date} />
<span className="error_holder"  >{insuranceDetails.error_list.start_date}</span>
</div>

<div className="form-group col-md-3">
<label>Expiry Date</label>
<input type="date" className="form-control" name="expiry_date"  onChange={handleInsurance}  value={insuranceDetails.expiry_date} />
<span className="error_holder"  >{insuranceDetails.error_list.expiry_date}</span>
</div>

<div className="form-group col-md-3">
<label>Payment Status</label>
<select className="form-control" name="payment"  onChange={handleInsurance}  value={insuranceDetails.payment} >
<option>pending</option>
<option>cleared</option>
</select>
<span className="error_holder"  >{insuranceDetails.error_list.payment}</span>
</div>

<div className="form-group col-md-3">
<input type = "submit" className="btn btn-primary btn-sm" value = "Save Policy" />
</div>

</div>
</form>
</Modal.Body>
</Modal> 

)

}

export default NewInsurance;