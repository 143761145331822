function StaffCards({users,lowerLimit,upperLimit,editUser}){

    return(
        <div className="row d-flex align-items-stretch">
            { users.map((val,index)=>{

let marker = index+1

if(marker >= lowerLimit && marker <= upperLimit)
{
return (
    <div className="col-md-4" key={index} >
           
    <div className="card card-widget widget-user-2">
            
    <div className="widget-user-header bg-default">
    <img src="../../dist/img/user1-128x128.jpg" alt="" className="img-circle img-fluid" style={{display: 'block', width:'50px', margin:'3px'}} />
    
    <div style={{display: 'block'}} >
    <h6 className="">{val.name}</h6>
    <h6 className="">{val.role}</h6>
    </div>

    </div>
    <div className="card-footer p-0">

                <ul className="nav flex-column">
                <li className="nav-item">
                <a href="#" className="nav-link">
                 {val.email}
                </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                     <span className='fa fa-telephone'></span> {val.phone}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                     {val.department}
                    </a>
                  </li>
                  <li className="nav-item" style={{ padding: '5px' }} >
                    <span className='btn btn-xs btn-success' style={{float: 'left' }} onClick={()=>editUser(index)} > <span className='fa fa-edit' ></span> Edit staff</span>
                    <span className='btn btn-xs btn-dark' style={{float: 'right' }} > <span className='fa fa-chart-bar' ></span> View perfomance</span>
                  </li>
                </ul>
              </div>
            </div>
           
          </div>
);
}


})}
</div>

);

}

export default StaffCards;