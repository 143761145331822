import React, { useEffect, useState, useRef } from 'react';
import {Link} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

import Table  from 'react-bootstrap/Table';
import NewTeam from '../elements/modals/teams/NewTeam';

function Teams(){

    const effectRun = useRef(false)

    const [teams,setTeams] = useState([])
    const [open,setOpen] = useState(false)
    const [users,setUsers] = useState([])

    const fetchUsers=()=>{
        axios.post(`api/users`).then((res)=>{
            if(res.data.status === 200)
            {
                setUsers(res.data.users)
            }
        })
    }

    const [details,setDetails] = useState({
        team:'',
        leader:'',
        description:'',
        error_list:[]
    })

    const handleChange=(e)=>{
        setDetails({...details, [e.target.name]: e.target.value})
    }

    const saveTeam=(e)=>{
        e.preventDefault()

        const data = {
            team: details.team,
            description: details.description,
            leader: details.leader
        }

        axios.post('api/create_team',data).then((res)=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success")
                onClose()
                fetchTeams()
            }
            else
            {
                setDetails({...details, error_list: res.data.validation_errors})
            }
        })

    }

    const fetchTeams=()=>{
        axios.post(`api/teams`).then((res)=>{
            if(res.data.status === 200)
            {
                setTeams(res.data.teams)
            }
        })
    }

    useEffect(()=>{
        if(effectRun.current === false)
        {
            fetchTeams()
            fetchUsers()
        }

        return()=>{
            effectRun.current = true
        }
    })

    const onClose=()=>{
        setOpen(false)
    }

    return (
        <div className="wrapper">

            <NewTeam open={open} onClose={onClose} users={users} details={details} handleChange={handleChange} saveTeam={saveTeam} ></NewTeam>

            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5><span className='fa fa-users'></span> Teams</h5>
                </div>
    
                    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card card-primary card-outline'>
                                <div className='card-header'>
                                    <h6>Listed Teams
                                    <span className='btn btn-dark btn-xs m-1 float-right' onClick={()=>setOpen(true)} ><span className='fa fa-plus-circle'></span>  Create team</span>
                                    </h6>
                                </div>
                                <div className='card-body'>
                                    <Table striped hover bordered >
                                        <thead>
                                            <tr className='small-row'>
                                                <th>#</th>
                                                <th>Created on</th>
                                                <th><span className='fa fa-users'></span> Team</th>
                                                <th>Description</th>
                                                <th><span className='fa fa-user-shield'></span> Team Lead</th>
                                                <th><span className='fa fa-eye'></span> More</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {teams.map((item,index)=>{
                                                return(
                                                    <tr className='small-row' key={index}>
                                                        <td>{index+1}</td>
                                                        <td>{item.created_at.substr(0,10)}</td>
                                                        <td>{item.team_name}</td>
                                                        <td>{item.description}</td>
                                                        <td>{item.name}</td>
                                                        <td><Link to={`/team_info/${item.id}`} ><span className='fa fa-eye'></span> More</Link></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default Teams;