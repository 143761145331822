const LoanSettlement=({open,onClose,mode,bankMode,mpesa,reference,handleReference,loan,disburseLoan})=>{

    if(!open) return false;

    if(mode == "bank")
    {
        return(
            <div className='overlay' onClick={onClose} >
            <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >
            
            <div className='card card-primary' >

            <div className="card-header">
            <h6>Disburse Loan</h6>
            </div>

            <div className='card-body'>
            
            <div className="row">
            <div className="col-md-6">

            <p className="label-block">
            <span className="label-holder" >Bank</span> {bankMode.bank}
            </p>

            <p  className="label-block">
            <span className="label-holder" >Account Name</span> {bankMode.account_name}
            </p>

            <p  className="label-block">
            <span className="label-holder" >Account Number</span> {bankMode.account_number}
            </p>

            <p  className="label-block">
            <span className="label-holder" >Branch</span>  {bankMode.branch}
            </p>

            </div>
            <div className="col-md-6">
            
            <p  className="label-block">
            <span className="label-holder" >Bank Code</span>  {bankMode.bank_code}
            </p>

            <p  className="label-block">
            <span className="label-holder" >Branch Code</span>  {bankMode.branch_code}
            </p>

            <p  className="label-block">
            <span className="label-holder" >Swift Code</span>  {bankMode.swift_code}
            </p>

            </div>
            </div>

            <div className="row">
            <div className="col-md-12">

            <div className="form-group">
            <label>Payment Reference</label>
            <input type="text" className="form-control" value={reference} onChange={handleReference} />
            </div>

            </div>

            <div className="col-md-12">
            <p> <b>Are you sure ?</b> </p>
            <span className="btn btn-success btn-sm" style={{width:'100px',margin:'2px'}} onClick={()=>disburseLoan(loan,reference,mode)} >Yes</span> 
            <span className="btn btn-danger btn-sm" style={{width:'100px',margin:'2px'}} onClick={()=>onClose()} >No</span>
            </div>
            </div>

            </div>
            
            </div>
            
            </div>
            </div>
            )
    }
    else if(mode == "mpesa")
    {
        return(
            <div className='overlay' onClick={onClose} >
            <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >
            
            <div className='card card-primary' >

            <div className="card-header">
            <h6>Disburse Loan</h6>
            </div>

            <div className='card-body'>
            
            <div className="row">
            <div className="col-md-12">

            <p className="label-block">
            <span className="label-holder" >Mpesa </span> {mpesa.mpesa_account}
            </p>
            </div>

            </div>

            <div className="row">
            <div className="col-md-12">

            <div className="form-group">
            <label>Payment Reference</label>
            <input type="text" className="form-control"  value={reference} onChange={handleReference}  />
            </div>

            </div>

            <div className="col-md-12">
            <p> <b>Are you sure ?</b> </p>
            <span className="btn btn-success btn-sm" style={{width:'100px',margin:'2px'}} onClick={()=>disburseLoan(loan,reference,mode)}>Yes</span> 
            <span className="btn btn-danger btn-sm" style={{width:'100px',margin:'2px'}} onClick={()=>onClose()} >No</span>
            </div>
            </div>

            </div>
            
            </div>
            
            </div>
            </div>
            )
    }

}

export default LoanSettlement;