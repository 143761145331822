const NewTeam=({open,onClose,users,details,handleChange,saveTeam})=>{

    if(!open) return null;
    return(
    
    <div className='overlay' onClick={onClose}>
    <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >
    
    <div className="card card-primary card-outline">
    <div className="card-header">
    <h5><span className="fa fa-users"></span> New Team</h5>
    </div>
    <div className="card-body">
    
    <form className="form" onSubmit={(e)=>saveTeam(e)} >

        <div className="row">

            <div className="form-group col-md-6">
                <label>Team Name <span className="red">*</span></label>
                <input type="text" className="form-control" name="team" value={details.team} onChange={handleChange} ></input>
                <span className="error_holder">{details.error_list.team}</span>
            </div>

            <div className="form-group col-md-6">
                <label>Team Leader <span className="red">*</span></label>
                <select className="form-control" name="leader"  value={details.leader} onChange={handleChange}>
                    <option value=''>Select user</option>
                    {users.map((item,index)=>{
                        return(<option key={index} value={item.id}>{item.name}</option>)
                    })}
                </select>
                <span className="error_holder">{details.error_list.leader}</span>
            </div>

            <div className="form-group col-md-12">
                <label>Description</label>
                <input type="text" className="form-control" name="description"  value={details.description} onChange={handleChange} ></input>
                
            </div>

        </div>

        <div className="row">
        <div className="form-group col-md-12">
        <input type="submit" className="btn btn-primary btn-sm" value="Save Team"/>
        </div>
        </div>

    </form>

    </div>

    <div className="card-footer"></div>
    </div>

    </div>
    </div>

    )

}

export default NewTeam;