import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import swal from 'sweetalert';
import axios from 'axios';
import LoanHolder from './LoanHolder';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import Modal from 'react-bootstrap/Modal';

import BriefLoanStatement from '../elements/print/BriefLoanStatement';
import PrintLoanStatement from '../elements/print/PrintLoanStatement';

import EditLoanModal from '../elements/modals/loans/EditLoanModal';
import PrintCharge from '../elements/print/PrintCharge';
import PrintRepocession from '../elements/print/PrintRepocession';

function LoanDetails({}){

    const parameters = useParams()
    const effectRun = useRef(false)
    const loan = parameters.id

    const [mode,setMode] = useState('summary')
    const [documents,setDocuments] = useState([])
    const [valuations,setValuations] = useState([])
    const [collaterals,setCollateral] = useState([])
    const [details,setDetails] = useState({})
    const [figures,setFigures] = useState({})
    const [approvals,setApprovals] = useState({})
    const [comments,setComments] = useState([])
    const [approver,setApprover] = useState({})
    const [statement,setStatement] = useState([])
    const [vehicles,setVehicles] = useState([])
    const [loanSummary,setSummary] = useState({})
    const [manualPay,setManualPay] = useState(false)
    const [edit,setEdit] = useState(false)
    const [open,setOpen] = useState(false)
    const [brief,setBrief] = useState(false)
    const [charge,setInvoiceCharge] = useState(false)
    const [charge_id,setId] = useState(null)
    const [repo_charge,setRepo] = useState(false)
    const [others,setOthers] = useState(false)
    const [confirm,setConfirm] = useState(false)
    const [openConfirm,setOpenConfirm] = useState(false)
    const [charges,setCharges] = useState([])

    const [customer,setCustomer] = useState('')
    const [client,setClient] = useState({})
    const [profile,setProfile] = useState('../../dist/img/default-150x150.png')
    const [charge_insuarance,setInsuaranceCharge] = useState(false)
    const [charge_repocession,setRepocessionCharge] = useState(false)
    const [reconcile,setReconcile] = useState(false)
    const [terminate,setTerminate] = useState(false)

    const [repocession,setRepocession] = useState('')

    const [insuarance,setInsuarance] = useState({
    amount:0,
    validation_errors:[]
    })

    const [due_date,setDate] = useState('')

    const [editDetails,setEditDetails] = useState({
    id:loan,
    pay_mode:'',
    error_list: []
    })

    const [item,setItem] = useState({
        date:'',
        charge: 'Demand',
        amount:0
    })

    const [chargeItem,setChargeItem] = useState({
        date:'',
        description:'',
        amount:0,
        validation_errors: []
    })

    const systemCharges=()=>{

        axios.get(`api/system_charges`).then((res)=>{
            if(res.data.status === 200)
            {
                setCharges(res.data.charges)
            }
        })

    }

    // Initialize the Charge Confirmation Process

    const initializeConfirm=(id)=>{    
        setConfirm(true)
        setId(id)
    }

    const closeDemand=()=>{
        setOpenConfirm(false)
    }

    const closeConfirm=()=>{
        setConfirm(false)
        setId(null)
    }

    const handleCharge=(e)=>{
    setChargeItem({...chargeItem, [e.target.name]: e.target.value})
    }

    const handleInsuarance=(e)=>{
    setInsuarance({...insuarance, amount: e.target.value})
    }

    const handleRepocession=(e)=>{
    setRepocession(e.target.value)
    }

    const handleItem=(e)=>{
        setItem({...item, [e.target.name]:e.target.value})
    }

    const chargeInsuarance=(e)=>{
        e.preventDefault()

        const data = {
            loan: loan,
            amount: insuarance.amount
        }

        axios.post(`api/charge_insuarance`,data).then((res)=>{
        
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success")
                fetchStatement()
                closeInsuarance()
            }
            else
            {
                setInsuarance({...insuarance, validation_errors:res.data.validation_errors})
            }

        }).catch(error=>{
            if(error.message == "Request failed with status code 403")
                {
                    swal("Warning","You are not authorized to perfom this action","warning")
                }
                else
                {
                    swal("Warning",error.message,"warning")
                }
        })

    }

    const chargeRepocession=(e)=>{
        e.preventDefault()

        const data = {
            loan:loan,
            date: repocession
        }

        axios.post(`api/charge_repocession`,data).then((res)=>{
            if(res.data.status == 200)
            {
                swal("Success",res.data.message,"success")
                fetchStatement()
                setRepocession('')
                closeRepocession()
            }
            else{
                swal("Warning","Date cannot be empty","warning")
            }
        }).catch(error=>{
            if(error.message == "Request failed with status code 403")
                {
                    swal("Warning","You are not authorized to perfom this action","warning")
                }
                else
                {
                    swal("Warning",error.message,"warning")
                }
                closeRepocession()
        })
    }

    const updateLoan=(e)=>{
    e.preventDefault()
    const data = {
        loan: editDetails.id,
        pay_mode: editDetails.pay_mode,
    }

    axios.post(`api/update_loan`,data).then((res)=>{

    if(res.data.status === 200)
    {
        swal("Success",res.data.message,"success")
        fetchDetails() 
        closeEdit()
    }
    else
    {
        setEditDetails({...editDetails, error_list:res.data.validation_errors})
    }

    })

    }

    const saveCharge=(e)=>{
        e.preventDefault()

        const data = {
            loan: loan,
            date: chargeItem.date,
            description: chargeItem.description,
            amount: chargeItem.amount
        }

        axios.post(`api/other_charge`,data).then((res)=>{

        if(res.data.status == 200)
        {
            swal("Success",res.data.message,"success")  // Display feedback
            closeOthers()                               // Close the charge modal
            fetchStatement()                            // Fetch the statement
            fetchDetails()                              // Fetch Loan details
        }
        else
        {
            setChargeItem({...chargeItem, validation_errors: res.data.validation_errors})
        }

        }).catch(error=>{
            if(error.message == "Request failed with status code 403")
            {
                swal("Warning","You are not authorized to perfom this action","warning")
            }
            else
            {
                swal("Warning",error.message,"warning")
            }            
            closeOthers()
        })

    }

    const handleDetails=(e)=>{
        setEditDetails({...editDetails, [e.target.name]: e.target.value })
    }
    
    const onClose=()=>{
    setManualPay(false)
    }

    const closeInvoiceCharge=()=>{
        setInvoiceCharge(false)
    }

    const closeRepoCharge=()=>{
        setRepo(false)
    }

    const showDemand=(date)=>{
        setInvoiceCharge(true)
        setDate(date)
    }

    const showRepo=(date)=>{
        setRepo(true)
        setDate(date)
    }

    const closeEdit=()=>{
    setEdit(false)
    }

    const closeInsuarance=()=>{
        setInsuaranceCharge(false)
        setInsuarance({...insuarance, amount:0})
    }

    const closeRepocession=()=>{
        setRepocessionCharge(false)
    }

    const closePrint=()=>{
    setOpen(false)
    }

    const closeBrief=()=>{
    setBrief(false)
    }

    const switchPrint=()=>{
    
        if(open)
        {
            setOpen(false)
            setBrief(true)
        }
        else
        {
            setOpen(true)
            setBrief(false)
        }

    }

        const chargeDemand=()=>{
        
        axios.get(`api/charge_demand/`+loan).then((res)=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success")
                fetchDetails()
                fetchStatement()
                closeDemand()
            }
        }).catch(error=>{
            if(error.message == "Request failed with status code 403")
                {
                    swal("Warning","You are not authorized to perfom this action","warning")
                }
                else
                {
                    swal("Warning",error.message,"warning")
                }
        })

        }

        const closeOthers=()=>{
            setOthers(false)
            setChargeItem({...chargeItem, description:'', amount:0 })
        }

        const reconcileLoan=()=>{

        axios.get(`api/reconcile_loan/`+loan).then((res)=>{

            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success")
                fetchStatement()
                setReconcile(false)
            }

        }).catch(error=>{
            if(error.message == "Request failed with status code 403")
                {
                    swal("Warning","You are not authorized to perfom this action","warning")
                }
                else
                {
                    swal("Warning",error.message,"warning")
                }
        })

        }

        const closeLoan=()=>{

            axios.get(`api/close_loan/`+loan).then((res)=>{
    
                if(res.data.status === 200)
                {
                    swal("Success",res.data.message,"success")
                    setTerminate(false)
                }
    
            }).catch(error=>{
                if(error.message == "Request failed with status code 403")
                    {
                        swal("Warning","You are not authorized to perfom this action","warning")
                    }
                    else
                    {
                        swal("Warning",error.message,"warning")
                    }
            })
    
            }

    const deleteCharge=(id,closeConfirm)=>{
    
    axios.get(`api/delete_charge/`+id).then((res)=>{
        if(res.data.status === 200)
        {
            swal("Success",res.data.message,"success")
            fetchStatement()
            closeConfirm()
        }

        if(res.data.status === 403)
        {
            swal("Warning",res.data.message,"warning")
        }

    }).catch(error=>{
        if(error.message == "Request failed with status code 403")
            {
                swal("Warning","You are not authorized to perfom this action","warning")
            }
            else
            {
                swal("Warning",error.message,"warning")
            }
    })

    }

    // Fetch Loan Statement
    const fetchStatement=()=>{

        const data = {
            loan:loan
        }

        axios.post(`api/statement`,data).then((res)=>{
        
            if(res.data.status === 200)
            {
                setStatement(res.data.statement)
                setSummary(res.data.loan_summary)
            }

        })

    }

    // Fetch Loan Documents
    const fetchDocuments=()=>{
    
    const data = {
        loan:loan
    }

    axios.post(`api/loan_documents`,data).then((res)=>{
        if(res.data.status === 200)
        {
            setDocuments(res.data.documents)
        }
    })

    }

    // Fetch Loan Valuations
    const fetchValuations=()=>{

        const data = {
            loan:loan
        }

        axios.post(`api/loan_valuations`,data).then((res)=>{

        if(res.data.status === 200)
        {
            setValuations(res.data.valuations)
        }

        })

    }

    // Fetch Loan Collaterals
    const fetchCollaterals=()=>{
    
    const data = {
    loan:loan
    }

    axios.post(`api/loan_collaterals`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        setCollateral(res.data.collaterals)
    }

    })

    }

    // Fetch Loan Details
    const fetchDetails=()=>{
    
    const data = {
        loan:loan,
    }

    axios.post(`api/loan_details`,data).then((res)=>{
        if(res.data.status === 200)
        {
            const info = res.data.details

            setDetails(res.data.details)
            setFigures(res.data.figures)
            setVehicles(res.data.vehicles)
            setCustomer(res.data.details.customer)
            setClient(res.data.client)
            setEditDetails({...editDetails, pay_mode:info.pay_mode})

            if(res.data.client.picture !== null)
            {
                const base = axios.defaults.baseURL
                const url = base+(res.data.client.picture)
                setProfile(url)
            }
        }
    })

    }

    // Fetch Loan Approvals
    const fetchApprovals=()=>{
    
    const data = {
        loan:loan,
    }

    axios.post(`api/loan_approvals`,data).then((res)=>{
    setApprovals(res.data.approvals)
    setApprover(res.data.approver)
    })

    }

    // Fetch Comments
    const fetchComments=()=>{
    
        const data = {
            loan:loan,
        }

        axios.post(`api/loan_comments`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
            setComments(res.data.comments)
        }

        })

    }

    const criticalAction=()=>{
        const role = localStorage.getItem('auth_role')

        if(role == "CEO" || role == "System Administrator")
        {
            return(
                <div className='card card-primary card-outline'>
                <div className='card-header'>
                <h5>Action pane</h5>
                </div>
                <div className='card-body'>
                
                <ul class="list-group list-group-unbordered mb-3">
               
                <li class="list-group-item" style={{paddingLeft:'20px'}} onClick={()=>setReconcile(true)} >
                Reconcile loan
                </li>

                <li class="list-group-item" style={{paddingLeft:'20px'}} onClick={()=>setTerminate(true)} >
                Close loan
                </li>

                </ul>

                </div>
                </div>
            )
        }

    }

    const actionPane=()=>{
    
    const role = localStorage.getItem('auth_role')

        if(role == "CEO" || role == "System Administrator" || role == "Finance")
        {
            return(
                <div className='col-md-4'>

                <div className='card card-danger card-outline'>
                <div className='card-header'>
                <h5> <span className='fa fa-coins'></span> Loan Charges</h5>
                </div>
                <div className='card-body'>
                
                <ul class="list-group list-group-unbordered mb-3">

                <li class="list-group-item" style={{paddingLeft:'20px'}} onClick={()=>setOpenConfirm(true)} >
                <span className='fa fa-coins'></span> Charge Demand
                </li>

                <li class="list-group-item" style={{paddingLeft:'20px'}} onClick={()=>setInsuaranceCharge(true)} >
                <span className='fa fa-coins'></span> Charge Insuarance
                </li>
                
                <li class="list-group-item" style={{paddingLeft:'20px'}}  onClick={()=>setRepocessionCharge(true)}  >
                <span className='fa fa-coins'></span> Charge Repocession
                </li>

                <li class="list-group-item" style={{paddingLeft:'20px'}}  onClick={()=>setOthers(true)}  >
                <span className='fa fa-coins'></span> Other charges
                </li>

                </ul>

                </div>
                </div>

                {criticalAction()}

                </div>
            )
        }

    }

    const displayStatement=()=>{
        const role = localStorage.getItem('auth_role')

        if(role == "CEO" || role == "System Administrator")
        {
            return(
                <div className='col-md-8'>
                <div className='card card-primary card-outline'>
                <div className='card-header'>
                <h5 style={{width:'100%'}} ><span className='fa fa-file-invoice'></span> Loan Statement
                    <span className='btn btn-primary btn-sm' style={{float:'right'}} onClick={()=>setOpen(true)} ><span className='fa fa-print'></span>  Print Statement</span> 
                    <span className='btn btn-dark btn-sm' style={{float:'right',marginRight:'5px'}} onClick={()=>setManualPay(true)}><span className='fa fa-credit-card'></span> Make Payment</span>
                </h5>
                </div>

                <div className='card-body'>
                <div>

                <table className='table table-stripped' >
                <thead>
                <tr className='small-row'>
                <th>#</th>
                <th style={{width:'90px'}} >Date</th>
                <th>Description</th>
                <th>Debit</th>
                <th>Credit</th>
                <th>Loan Balance</th>
                <th><span style={{color:'red'}} className = "fa fa-trash" ></span> </th>
                </tr>
                </thead>
                <tbody>
                
                {statement.map((item,index)=>{

                    if(item.type === "debit")
                    {
                        if(item.description == "Demand")
                        {
                            return(
                                <tr className='small-row' key={index} >
                                    <td>{index+1}</td>
                                    <td style={{width:'90px'}} >{item.date.substr(0,10)}</td>
                                    <td> <b><span onClick={()=>showDemand(item.date.substr(0,10))}> <span className='fa fa-print'></span> {item.description} </span></b> </td>
                                    <td><span style={{display:'inline-block', width:'8px', height:'8px',backgroundColor:'red', borderRadius:'50%'}}></span> {item.amount}</td>
                                    <td>--</td>
                                    <td>{item.loan_balance}</td>
                                    <td> <span style={{color:'red'}} className = "fa fa-trash" onClick={()=>initializeConfirm(item.id)}  ></span>  </td>
                                </tr>
                            )
                        }
                        else if(item.description == "Repocession")
                        {
                            return(
                                <tr className='small-row' key={index} >
                                    <td>{index+1}</td>
                                    <td style={{width:'90px'}} >{item.date.substr(0,10)}</td>
                                    <td> <b><span onClick={()=>showRepo(item.date.substr(0,10))}> <span className='fa fa-print'></span> {item.description} </span></b> </td>
                                    <td><span style={{display:'inline-block', width:'8px', height:'8px',backgroundColor:'red', borderRadius:'50%'}}></span> {item.amount}</td>
                                    <td>--</td>
                                    <td>{item.loan_balance}</td>
                                    <td> <span style={{color:'red'}} className = "fa fa-trash" onClick={()=>initializeConfirm(item.id)}  ></span>  </td>
                                </tr>
                            )
                        }
                        else
                        {
                            if(item.id  == null )
                            {
                                return(
                                    <tr className='small-row' key={index} >
                                        <td>{index+1}</td>
                                        <td style={{width:'90px'}} >{item.date.substr(0,10)}</td>
                                        <td>{item.description}</td>
                                        <td><span style={{display:'inline-block', width:'8px', height:'8px',backgroundColor:'red', borderRadius:'50%'}}></span> {item.amount}</td>
                                        <td>--</td>
                                        <td>{item.loan_balance}</td>
                                        <td></td>
                                    </tr>
                                )
                            }
                            else
                            {
                                return(
                                    <tr className='small-row' key={index} >
                                        <td>{index+1}</td>
                                        <td style={{width:'90px'}} >{item.date.substr(0,10)}</td>
                                        <td>{item.description}</td>
                                        <td><span style={{display:'inline-block', width:'8px', height:'8px',backgroundColor:'red', borderRadius:'50%'}}></span> {item.amount}</td>
                                        <td>--</td>
                                        <td>{item.loan_balance}</td>
                                        <td> <span style={{color:'red'}}    onClick={()=>initializeConfirm(item.id)}  className = "fa fa-trash" ></span>  </td>
                                    </tr>
                                )
                            }
                        }
                    }
                    else
                    {
                        return(
                        <tr className='small-row' key={index}>
                        <td>{index+1}</td>
                        <td style={{width:'90px'}} >{item.date.substr(0,10)}</td>
                        <td>{item.description}</td>
                        <td>--</td>
                        <td><span style={{display:'inline-block', width:'10px', height:'10px',backgroundColor:'green', borderRadius:'50%'}}></span>  {item.amount}</td>
                        <td>{item.loan_balance}</td>
                        <td></td>
                        </tr>
                        )
                    }

                })}

                </tbody>
                <tfoot>
                    
                </tfoot>
                </table>

                </div>
                </div>

                </div>
                </div>
            )
        }
        else if(role == "Finance")
        {
            return(
                <div className='col-md-8'>
                <div className='card card-primary card-outline'>
                <div className='card-header'>
                <h5 style={{width:'100%'}} ><span className='fa fa-file-invoice'></span> Loan Statement
                    <span className='btn btn-primary btn-sm' style={{float:'right'}} onClick={()=>setOpen(true)} ><span className='fa fa-print'></span>  Print Statement</span> 
                    <span className='btn btn-dark btn-sm' style={{float:'right',marginRight:'5px'}} onClick={()=>setManualPay(true)}><span className='fa fa-credit-card'></span> Make Payment</span>
                </h5>
                </div>

                <div className='card-body'>
                <div>

                <table className='table table-stripped' >
                <thead>
                <tr className='small-row'>
                <th>#</th>
                <th style={{width:'90px'}} >Date</th>
                <th>Description</th>
                <th>Debit</th>
                <th>Credit</th>
                <th>Loan Balance</th>
                </tr>
                </thead>
                <tbody>
                
                {statement.map((item,index)=>{

                    if(item.type === "debit")
                    {
                        if(item.description == "Demand")
                        {
                            return(
                                <tr className='small-row' key={index} >
                                    <td>{index+1}</td>
                                    <td style={{width:'90px'}} >{item.date.substr(0,10)}</td>
                                    <td> <b><span onClick={()=>showDemand(item.date.substr(0,10))}> <span className='fa fa-print'></span> {item.description} </span></b> </td>
                                    <td><span style={{display:'inline-block', width:'8px', height:'8px',backgroundColor:'red', borderRadius:'50%'}}></span> {item.amount}</td>
                                    <td>--</td>
                                    <td>{item.loan_balance}</td>
                                </tr>
                            )
                        }
                        else if(item.description == "Repocession")
                        {
                            return(
                                <tr className='small-row' key={index} >
                                    <td>{index+1}</td>
                                    <td style={{width:'90px'}} >{item.date.substr(0,10)}</td>
                                    <td> <b><span onClick={()=>showRepo(item.date.substr(0,10))}> <span className='fa fa-print'></span> {item.description} </span></b> </td>
                                    <td><span style={{display:'inline-block', width:'8px', height:'8px',backgroundColor:'red', borderRadius:'50%'}}></span> {item.amount}</td>
                                    <td>--</td>
                                    <td>{item.loan_balance}</td>
                                </tr>
                            )
                        }
                        else
                        {
                            if(item.id  == null )
                            {
                                return(
                                    <tr className='small-row' key={index} >
                                        <td>{index+1}</td>
                                        <td style={{width:'90px'}} >{item.date.substr(0,10)}</td>
                                        <td>{item.description}</td>
                                        <td><span style={{display:'inline-block', width:'8px', height:'8px',backgroundColor:'red', borderRadius:'50%'}}></span> {item.amount}</td>
                                        <td>--</td>
                                        <td>{item.loan_balance}</td>
                                    </tr>
                                )
                            }
                            else
                            {
                                return(
                                    <tr className='small-row' key={index} >
                                        <td>{index+1}</td>
                                        <td style={{width:'90px'}} >{item.date.substr(0,10)}</td>
                                        <td>{item.description}</td>
                                        <td><span style={{display:'inline-block', width:'8px', height:'8px',backgroundColor:'red', borderRadius:'50%'}}></span> {item.amount}</td>
                                        <td>--</td>
                                        <td>{item.loan_balance}</td>
                                    </tr>
                                )
                            }
                        }
                    }
                    else
                    {
                        return(
                        <tr className='small-row' key={index}>
                        <td>{index+1}</td>
                        <td style={{width:'90px'}} >{item.date.substr(0,10)}</td>
                        <td>{item.description}</td>
                        <td>--</td>
                        <td><span style={{display:'inline-block', width:'10px', height:'10px',backgroundColor:'green', borderRadius:'50%'}}></span>  {item.amount}</td>
                        <td>{item.loan_balance}</td>
                        </tr>
                        )
                    }

                })}

                </tbody>
                <tfoot>
                    
                </tfoot>
                </table>

                </div>
                </div>

                </div>
                </div>
            )
        }
        else
        {
            return(
                <div className='col-md-12'>
                <div className='card card-primary card-outline'>
                <div className='card-header'>
                <h5 style={{width:'100%'}} ><span className='fa fa-file-invoice'></span> Loan Statement
                    <span className='btn btn-primary btn-sm' style={{float:'right'}} onClick={()=>setOpen(true)} ><span className='fa fa-print'></span>  Print Statement</span> 
                    <span className='btn btn-dark btn-sm' style={{float:'right',marginRight:'5px'}} onClick={()=>setManualPay(true)}><span className='fa fa-credit-card'></span> Make Payment</span>
                </h5>
                </div>

                <div className='card-body'>
                <div>

                <table className='table table-stripped' >
                <thead>
                <tr className='small-row'>
                <th>#</th>
                <th style={{width:'90px'}} >Date</th>
                <th>Description</th>
                <th>Debit</th>
                <th>Credit</th>
                <th>Loan Balance</th>
                </tr>
                </thead>
                <tbody>
                
                {statement.map((item,index)=>{

                    if(item.type === "debit")
                    {
                        if(item.description == "Demand")
                        {
                            return(
                                <tr className='small-row' key={index} >
                                    <td>{index+1}</td>
                                    <td style={{width:'90px'}} >{item.date.substr(0,10)}</td>
                                    <td> <b><span onClick={()=>showDemand(item.date.substr(0,10))}> <span className='fa fa-print'></span> {item.description} </span></b> </td>
                                    <td><span style={{display:'inline-block', width:'8px', height:'8px',backgroundColor:'red', borderRadius:'50%'}}></span> {item.amount}</td>
                                    <td>--</td>
                                    <td>{item.loan_balance}</td>
                                </tr>
                            )
                        }
                        else if(item.description == "Repocession")
                        {
                            return(
                                <tr className='small-row' key={index} >
                                    <td>{index+1}</td>
                                    <td style={{width:'90px'}} >{item.date.substr(0,10)}</td>
                                    <td> <b><span onClick={()=>showRepo(item.date.substr(0,10))}> <span className='fa fa-print'></span> {item.description} </span></b> </td>
                                    <td><span style={{display:'inline-block', width:'8px', height:'8px',backgroundColor:'red', borderRadius:'50%'}}></span> {item.amount}</td>
                                    <td>--</td>
                                    <td>{item.loan_balance}</td>                                    
                                </tr>
                            )
                        }
                        else
                        {
                            if(item.id  == null )
                            {
                                return(
                                    <tr className='small-row' key={index} >
                                        <td>{index+1}</td>
                                        <td style={{width:'90px'}} >{item.date.substr(0,10)}</td>
                                        <td>{item.description}</td>
                                        <td><span style={{display:'inline-block', width:'8px', height:'8px',backgroundColor:'red', borderRadius:'50%'}}></span> {item.amount}</td>
                                        <td>--</td>
                                        <td>{item.loan_balance}</td>
                                    </tr>
                                )
                            }
                            else
                            {
                                return(
                                    <tr className='small-row' key={index} >
                                        <td>{index+1}</td>
                                        <td style={{width:'90px'}} >{item.date.substr(0,10)}</td>
                                        <td>{item.description}</td>
                                        <td><span style={{display:'inline-block', width:'8px', height:'8px',backgroundColor:'red', borderRadius:'50%'}}></span> {item.amount}</td>
                                        <td>--</td>
                                        <td>{item.loan_balance}</td>
                                    </tr>
                                )
                            }
                        }
                    }
                    else
                    {
                        return(
                        <tr className='small-row' key={index}>
                        <td>{index+1}</td>
                        <td style={{width:'90px'}} >{item.date.substr(0,10)}</td>
                        <td>{item.description}</td>
                        <td>--</td>
                        <td><span style={{display:'inline-block', width:'10px', height:'10px',backgroundColor:'green', borderRadius:'50%'}}></span>  {item.amount}</td>
                        <td>{item.loan_balance}</td>
                        </tr>
                        )
                    }

                })}

                </tbody>
                <tfoot>
                    
                </tfoot>
                </table>

                </div>
                </div>

                </div>
                </div>
            )
        }

    }

    const handlePayment=(e)=>{
        setPayments({...payments, [e.target.name]: e.target.value})
        }
        
        const savePayment=(e)=>{

        e.preventDefault()
        
        const data = {
            customer: customer,
            amount:payments.amount,
            entry_by: payments.entry_by,
            reference:payments.reference,
            entry_date:payments.entry_date,
            payment_mode: payments.payment_mode,
        }
        
        axios.post(`api/manual_payment`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
            swal('Success',res.data.message,'success')
            clearPayments()
            onClose()
            fetchStatement()
            fetchDetails()
        }
        else
        {
            setPayments({...payments, error_list: res.data.validation_errors})
        }
        
        })
        
        }

    const [payments,setPayments] = useState({
        entry_by: localStorage.getItem('auth_id'),
        payment_mode:'',
        entry_date:'',
        amount:1,
        reference:'',
        error_list: [],
        })
        
        const clearPayments =()=>{
        
        setPayments({
            entry_by: localStorage.getItem('auth_id'),
            payment_mode:'',
            entry_date:'',
            amount:1,
            reference:'',
            error_list: [],
            })
        
        }

    useEffect(()=>{
    
    if(effectRun.current === false)
    {
    
        fetchDetails()
        fetchDocuments()
        fetchValuations()
        fetchCollaterals()
        fetchApprovals()
        fetchComments()
        fetchStatement()
        systemCharges()

        return()=>{
        effectRun.current = true
        }

    }

    },[])

    return (
        <div className="wrapper">

            <Modal
            show={openConfirm}
            onHide={closeDemand}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title>Charge Demand ?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <span className='btn btn-md btn-success m-1' onClick={chargeDemand} > Yes</span>
            <span className='btn btn-md btn-dark m-1' onClick={closeDemand} > No</span>
            </Modal.Body>
            <Modal.Footer>
          
            </Modal.Footer>
            </Modal>

            <Modal
            show={charge_insuarance}
            onHide={closeInsuarance}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title>Charge insurance</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <form className="form" onSubmit={chargeInsuarance} >
                <div className="row" >

                <div className="col-md-12 form-group ">
                    <label>Amount <span className='red' >*</span> </label>
                    <input type ='text' className='form-control' name="period"  value={insuarance.amount} onChange={handleInsuarance}  />
                    <span className="error_holder">{insuarance.validation_errors.amount}</span>         
                </div>

                <div className="col-md-12 form-group ">
                <input type ='submit' className='btn btn-primary btn-sm' value = 'Charge Insuarance' />
                </div>

                </div>
                </form>

            </Modal.Body>
            <Modal.Footer>
          
            </Modal.Footer>
            </Modal>

            <Modal
            show={charge_repocession}
            onHide={closeRepocession}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title>Charge Repocession</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <form className="form" onSubmit={chargeRepocession} >
                <div className="row" >

                <div className="col-md-12 form-group ">
                    <label>Date <span className='red' >*</span> </label>
                    <input type ='date' className='form-control' name="date"  value={repocession} onChange={handleRepocession}  />       
                </div>

                <div className="col-md-12 form-group ">
                <input type ='submit' className='btn btn-primary btn-sm' value = 'Charge Repocession' />
                </div>

                </div>
                </form>

            </Modal.Body>
            <Modal.Footer>
          
            </Modal.Footer>
            </Modal>

            <Modal
            show={confirm}
            onHide={closeConfirm}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title>Are you sure</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <span className="btn btn-sm btn-success m-1" onClick={()=>deleteCharge(charge_id,closeConfirm)} > <span className="fa fa-check-circle"></span> Yes</span>
            <span className="btn btn-sm btn-danger m-1" onClick={()=>closeConfirm()} > <span className="fa fa-times-circle"></span> No</span>

            </Modal.Body>
            <Modal.Footer>
          
            </Modal.Footer>
            </Modal>


            <Modal
            show={others}
            onHide={closeOthers}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title>Other Charges</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <form className="form" onSubmit={saveCharge}>
        
            <div className='row'>

            <div className="form-group col-md-6">
                    <label>Date <span style={{color:'red'}} >*</span> </label>
                    <input className="form-control" name = "date" type="date" value={chargeItem.date} onChange={handleCharge} ></input>
                    <span className="error_holder">{chargeItem.validation_errors.date}</span>
                </div>

                <div className="form-group col-md-6">
                    <label>Charge / Description <span style={{color:'red'}} >*</span> </label>
                    <select className="form-control" name = "description"  value={chargeItem.description} onChange={handleCharge} >
                        {charges.map((item,index)=>{
                            return(
                                <option>{item.charge}</option>
                            )
                        })}
                    </select>
                    <span className="error_holder">{chargeItem.validation_errors.description}</span>
                </div>

                <div className="form-group col-md-12">
                    <label>Amount  <span style={{color:'red'}} >*</span> </label>
                    <input className="form-control" name = "amount" type = "number"  value={chargeItem.amount} onChange={handleCharge} ></input>
                    <span className="error_holder">{chargeItem.validation_errors.amount}</span>
                </div>

                <div className="form-group col-md-12">
                    <input type= "submit" className="btn btn-primary" value="Save charge"></input>
                </div>

            </div>

            </form>

            </Modal.Body>
            <Modal.Footer>
          
            </Modal.Footer>
            </Modal>

            <Modal
            show={reconcile}
            onHide={()=>setReconcile(false)}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title>Are you sure ?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <span className='btn btn-success btn-sm m-1' onClick={()=>reconcileLoan()} > <span className='fa fa-check-circle'></span> Yes</span>
            <span className='btn btn-danger btn-sm m-1' onClick={()=>setReconcile(false)}> <span className='fa fa-times-circle'></span> No</span>
            </Modal.Body>
            </Modal>

            <Modal
            show={terminate}
            onHide={()=>setTerminate(false)}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title>Are you sure ?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <span className='btn btn-success btn-sm m-1' onClick={()=>closeLoan()} > <span className='fa fa-check-circle'></span> Yes</span>
            <span className='btn btn-danger btn-sm m-1' onClick={()=>setTerminate(false)}> <span className='fa fa-times-circle'></span> No</span>
            </Modal.Body>
            </Modal>

            <Modal
            show={manualPay}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title>Record payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            
                <form className="form" onSubmit={savePayment} >
                <div className = "row">

                <div className="form-group col-md-6">
                <label>Date</label>
                <input type='date' name='entry_date' className="form-control" value={payments.entry_date} onChange={handlePayment} />
                <span className="error_holder"  >{payments.error_list.entry_date}</span>
                </div>

                <div className="form-group col-md-6">
                <label>Payment mode</label>
                <select className="form-control" name="payment_mode"  value={payments.payment_mode} onChange={handlePayment}  >
                <option value=''>Select mode</option>
                <option value='NCBA BANK'>NCBA BANK</option>
                <option value="PRIME BANK">PRIME BANK</option>
                <option value="MPESA">MPESA</option>
                <option value="Cash">Cash</option>
                </select>
                <span className="error_holder"  >{payments.error_list.payment_mode}</span>
                </div>

                <div className="form-group col-md-6">
                <label>Amount</label>
                <input name='amount' type='number' className="form-control"  value={payments.amount} onChange={handlePayment} min="1" />
                <span className="error_holder"  >{payments.error_list.amount}</span>
                </div>

                <div className="form-group col-md-6">
                <label>Reference</label>
                <input name='reference' type='text' className="form-control"  value={payments.reference} onChange={handlePayment}  />
                <span className="error_holder"  >{payments.error_list.reference}</span>
                </div>

                <div className="form-group col-md-12">
                <input type="submit" className="btn btn-primary btn-sm" value="Record payment" />
                </div>

                </div>
                </form>

            </Modal.Body>
            </Modal>
        
            <PrintCharge open={charge} onClose={closeInvoiceCharge} client={client} details={details} dueDate={due_date} ></PrintCharge>
            <PrintRepocession repo_charge={repo_charge} closeRepoCharge={closeRepoCharge} client={client} details={details} dueDate={due_date} vehicles={vehicles}></PrintRepocession>
            <EditLoanModal edit={edit} closeEdit={closeEdit} details={editDetails} handleDetails={handleDetails} updateLoan={updateLoan}  ></EditLoanModal>
            <BriefLoanStatement open={brief} onClose={closeBrief} client={client} details={details} switchPrint={switchPrint} ></BriefLoanStatement>
            <PrintLoanStatement open={open} onClose={closePrint} statement={statement} client={client} details={details}  switchPrint={switchPrint} ></PrintLoanStatement>

            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>...</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >
                <div className='row'>
                
                <div className='col-md-12'>
                <div className='card card-primary card-outline'>
                <div className='card-header'>
                <h5>Loan Details</h5>
                </div>
                <div className='card-body'>
                
                <div className='row'>
                
                <div className='col-md-4'>
                <div class="card card-primary card-outline">
                
                <div class="card-body box-profile">
                
                <div className="text-center" style={{height:'200px',overFlow:'hidden'}} >
                <img className=" img-fluid " src={profile} style={{width:'180px'}} />
                </div>

                <h6 class="profile-username text-center">{client.first_name} {client.other_name} {client.last_name}</h6>

                <ul class="list-group list-group-unbordered mb-3">

                    <li class="list-group-item" onClick={()=>setMode('summary')} >
                    <b>Summary</b>
                    </li>

                    <li class="list-group-item" onClick={()=>setMode('collaterals')}  >
                    <b>Collaterals</b>
                    <a className="float-right badge badge-primary" style={{width:'30px',color:'white'}}>{collaterals.length}</a>
                    </li>

                    <li class="list-group-item"  onClick={()=>setMode('valuations')}  >
                    <b>Valuations</b>
                    <a className="float-right badge badge-primary" style={{width:'30px',color:'white'}}>{valuations.length}</a>
                    </li>

                    <li class="list-group-item"  onClick={()=>setMode('documents')}  >
                    <b>Loan Documents</b>
                    <a className="float-right badge badge-primary" style={{width:'30px',color:'white'}}>{documents.length}</a>
                    </li>

                    <li class="list-group-item" onClick={()=>setMode('comments')} >
                    <b>Comments & Approval</b>
                    <a className="float-right badge badge-primary" style={{width:'30px',color:'white'}}>{comments.length}</a>
                    </li>


                </ul>

                </div>

                </div>
                </div>

                <div className='col-md-8'>
               
                <LoanHolder mode={mode} documents={documents} valuations={valuations} collaterals={collaterals} details={details} approvals={approvals} loan={loan} comments={comments} fetchComments={fetchComments} approver={approver} fetchApprovals={fetchApprovals} customer={customer} setEdit={setEdit} />
               
                </div>

                </div>

                <div className='row'>

                {actionPane()}
                {displayStatement()}

                </div>

                </div>
                </div>
                </div>

                </div>
                </div>
                </div>

                </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )

}

export default LoanDetails;