import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {Outlet, Navigate } from 'react-router-dom';

function DynamicRoutes(){
    
   if(localStorage.getItem('auth_role') == "Relationship Officer")
   {
        return(<Navigate to="/perfomance" />)

   }else if(localStorage.getItem('auth_role') == "HR Manager")
   {
        return(<Navigate to="/perfomance_analysis" />)
   }else if(localStorage.getItem('auth_role') == "HCS" || localStorage.getItem('auth_role') == "Finance")
   {
     return(<Navigate to="/loans" />)
   }
   else
   {
        return(<Outlet />)
   }

}

export default DynamicRoutes;