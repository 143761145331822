function ContentHeader(props)
{
    return (
        <div className="content-header">
        <div className="container">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 text-dark">{props.title}</h1>
            </div>

            <div className="col-sm-6">
            <div className="float-sm-right">
            <form className="form-inline ml-0 ml-md-3">
            <div className="form-group input-group-sm">
            <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
            <div className="input-group-append">
            <button className="btn btn-navbar" type="submit">
            <i className="fas fa-search"></i>
            </button>
            </div>
            </div>
            </form>
            </div>
            </div>

          </div>
        </div>
      </div>
    );
}

export default ContentHeader;