import DateDecoration from "../../decorations/DateDecoration";


function Pay({open,closeModal,payments,MoneyDecoration,onPay, paymentsDetails,makePayment})
{

    if(!open) return false;

    return(
        <div className='overlay' onClick={closeModal} >
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className="card card-primary card-outline" >
        <div className="card-header" >
        Payments Records
        <span className='closeModal btn btn-xs btn-danger' onClick={()=>closeModal()} > <span className='fa fa-times-circle' ></span> Close modal</span>
        </div>

        <div className='card-body' >

        <div className="row" >
        <div className="col-md-12" >

        <div className="card card-success card-outline" >
                    <div className="card-header" >
                        <h6>Make payment</h6>
                    </div>

                    <div className="card-body" >
                    <form className="form" onSubmit={(e)=>makePayment(e)}>

                    <div className="row" >

                    <div className="form-group col-md-6" >
                    <label>Payment Date</label>
                    <input type = "date" className="form-control" name = "date" onChange={onPay} />
                    <span className="error_holder"  >{paymentsDetails.error_list.date}</span>
                    </div>

                    <div className="form-group col-md-6" >
                    <label>Amount</label>
                    <input type = "number" className="form-control" name = "amount" onChange={onPay} />
                    <span className="error_holder"  >{paymentsDetails.error_list.amount}</span>
                    </div>

                    </div>

                    <div className="row" >
                        <div className="col-md-12" >
                            <input type = "submit" className="btn btn-success btn-sm" value="Save Payment" />
                        </div>
                    </div>

                    </form>
                    </div>

                    </div>

            
                    </div>
                    </div>

        <div className='row' >        
        <div className="col-md-12" >
        
        <div className="card card-primary card-outline" >
            <div className="card-header" >
                <h6>Payments History</h6>
            </div>
            <div className="card-body" >
            <table className="table" >
            <thead>
                <tr>
                    <td>#</td>
                    <td>Date</td>
                    <td>Amount</td>
                </tr>
            </thead>
            <tbody>
                {payments.map((pay,index)=>{
                    return(
                        <tr key={index} >
                            <td>{index+1}</td>
                            <td>{pay.payment_date}</td>
                            <td>{MoneyDecoration(pay.amount)}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
            </div>
        </div>

        </div>
        </div>

        </div>




<div className="card-footer">
</div>

</div>
</div>
</div>
    )

}

export default Pay;