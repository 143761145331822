import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';
import Table  from 'react-bootstrap/Table';

function DisbursedLoans(){

    const effectRan = useRef(false)
    const [loans,setLoans] = useState([])

    const fetchLoans=()=>{
        axios.post(`api/active_loans`).then((res)=>{
        
        if(res.data.status === 200)
        {
            setLoans(res.data.active)
        }

        }).catch(error=>{
            console.log(error)
        })
    }

    useEffect(()=>{

        if(effectRan.current === false)
        {
            fetchLoans()
        }

        return()=>{
            effectRan.current = true
        }

    })

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>Active Loans</h5>
                </div>

    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >
                <div className="row">
                    <div className="col-md-12">
                    <div className='card card-primary card-outline'>
                        <div className='card-body'>
                        <Table striped hover bordered>
                        <thead>
                            <tr>
                                <td>#</td>
                                <td>Loan #</td>
                                <td>Client</td>
                                <td>Loan Type</td>
                                <td>Amount</td>
                                <td>Period</td>
                                <td>Interest</td>
                                <td>Approval</td>
                                <td>Status</td>
                            </tr>
                        </thead>
                        <tbody>
                            {loans.map((loan,index)=>{
                                return(
                                <tr key={index} className='small-row' >
                                <td>{index+1}</td>
                                <td><Link to={`/loan/${loan.id}`}><span style={{color:'blue'}} >{loan.loan_code}</span></Link></td>
                                <td>{loan.first_name} {loan.last_name}</td>
                                <td>{loan.type}</td>
                                <td>KES {parseInt(loan.amount_loanable).toLocaleString('en-US')}</td>
                                <td>{loan.period} Months</td>
                                <td>{loan.interest_rate} %</td>
                                <td>{loan.approval}</td>
                                <td>{loan.status}</td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    </div>
                    </div>
                    </div>
                </div>
                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default DisbursedLoans;