import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import swal from 'sweetalert';

const CustomerInsurance=({setInsuranceFlag,policies,updateInsurance,fetchPolicies,profile})=>{

const deletePolicy=(id)=>{

const data = {
id:id,
}

axios.post(`api/delete_policy`,data).then((res)=>{

if(res.data.status === 200)
{
    swal("Success",res.data.message,"success")
    fetchPolicies()
}

})

}

const addButton=()=>{

    var role = localStorage.getItem('auth_role')

    if(role == "CEO" || role == "System Administrator")
    {
        return(
            <span className="btn btn-primary btn-sm" style={{float:'Right'}} onClick={()=>setInsuranceFlag(true)}><span className="fa fa-plus" ></span> New Insurance</span>
        )
    }
    else
    {
        if(profile.edit_flag == "0")
        {
            return(
                <span className="btn btn-primary btn-sm" style={{float:'Right'}} onClick={()=>setInsuranceFlag(true)}><span className="fa fa-plus" ></span> New Insurance</span> 
            )
        }
    }     
}

const insuranceTable=()=>{

    var role = localStorage.getItem('auth_role')

    if(role == "CEO" || role == "System Administrator")
    {
        return(
            <table className="table">
<thead>
<tr style={{fontSize:'13px'}} >
<th>#</th>
<th>Company</th>
<th>Vehicle </th>
<th>Collateral</th>
<th>Premium</th>
<th>Expiry</th>
<th>Payment</th>
<th><span className="fa fa-edit" style={{color:'green'}} ></span></th>
<th><span className="fa fa-trash" style={{color:'red'}}></span></th>
</tr>
</thead>

<tbody>
{policies.map((item,index)=>{

return(
<tr style={{fontSize:'13px'}} key={index} >
<td>{index+1}</td>
<td>{item.name} <span className="block" ><b>Policy #</b>: {item.policy}</span> </td>
<td>{item.registration}</td>
<td>{parseFloat(item.collateral).toLocaleString('en-US')}</td>
<td>{parseFloat(item.premium).toLocaleString('en-US')}</td>
<td>{item.expiry_date}</td>
<td>{item.payment}</td>
<td><span className="fa fa-edit"  style={{color:'green'}} onClick={()=>updateInsurance(item.id)}></span></td>
<td><span className="fa fa-trash"  style={{color:'red'}} onClick={()=>deletePolicy(item.id)} ></span></td>
</tr>
)

})}
</tbody>
</table>
        )
    }
    else
    {
        if(profile.edit_flag == "0")
        {
            return(
                <table className="table">
<thead>
<tr style={{fontSize:'13px'}} >
<th>#</th>
<th>Company</th>
<th>Vehicle </th>
<th>Collateral</th>
<th>Premium</th>
<th>Expiry</th>
<th>Payment</th>
<th><span className="fa fa-edit" style={{color:'green'}} ></span></th>
<th><span className="fa fa-trash" style={{color:'red'}}></span></th>
</tr>
</thead>

<tbody>
{policies.map((item,index)=>{

return(
<tr style={{fontSize:'13px'}} key={index} >
<td>{index+1}</td>
<td>{item.name} <span className="block" ><b>Policy #</b>: {item.policy}</span> </td>
<td>{item.registration}</td>
<td>{parseFloat(item.collateral).toLocaleString('en-US')}</td>
<td>{parseFloat(item.premium).toLocaleString('en-US')}</td>
<td>{item.expiry_date}</td>
<td>{item.payment}</td>
<td><span className="fa fa-edit"  style={{color:'green'}} onClick={()=>updateInsurance(item.id)}></span></td>
<td><span className="fa fa-trash"  style={{color:'red'}} onClick={()=>deletePolicy(item.id)} ></span></td>
</tr>
)

})}
</tbody>
</table>
)
}
else
{
return(
<table className="table">
<thead>
<tr style={{fontSize:'13px'}} >
<th>#</th>
<th>Company</th>
<th>Vehicle </th>
<th>Collateral</th>
<th>Premium</th>
<th>Expiry</th>
<th>Payment</th>
</tr>
</thead>

<tbody>
{policies.map((item,index)=>{

return(
<tr style={{fontSize:'13px'}} key={index} >
<td>{index+1}</td>
<td>{item.name} <span className="block" ><b>Policy #</b>: {item.policy}</span> </td>
<td>{item.registration}</td>
<td>{parseFloat(item.collateral).toLocaleString('en-US')}</td>
<td>{parseFloat(item.premium).toLocaleString('en-US')}</td>
<td>{item.expiry_date}</td>
<td>{item.payment}</td>
</tr>
)

})}
</tbody>
</table>
)
}
}     
}

return(
<div className="card card-primary card-outline" >
<div className="card-header">
<h5><span className="fa fa-file-invoice" ></span> Insurance
{addButton()}
</h5>
</div>

<div className="card-body">
{insuranceTable()}
</div>

<div className="card-footer"></div>
</div>
)

}
export default CustomerInsurance;