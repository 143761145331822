const EditLoanModal=({edit,closeEdit,details,handleDetails,updateLoan})=>{

    if(!edit) return false;
    
    return(
    <div className='overlay' onClick={closeEdit} >
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className = "card card-primary" >
        <div className="card-header" ><span className='fa fa-edit' ></span> Edit Loan
        <span className='closeModal btn btn-sm btn-default' onClick={closeEdit} > <span className='fa fa-times-circle' ></span> Close</span>
        </div>

        <div className="card-body login-card-body">

        <form className="form" onSubmit={updateLoan}>
            <div className="row" >

            <div className="col-md-12 form-group ">
                <label>Pay mode <span className='red' >*</span> </label>
                <select className='form-control' name = "pay_mode"  value={details.pay_mode} onChange={handleDetails} >
                    <option>mpesa</option>
                    <option>cheque</option>
                </select>

                <span className="red">{details.error_list.pay_mode}</span>             
            </div>

            <div className="col-md-12 form-group ">
            <input type ='submit' className='btn btn-primary btn-sm' value = 'Update loan' />
            </div>

            </div>
        </form>

        </div>

        <div className='card-footer' style={{color:'red'}}>
        <i>* fields are mandatory </i>
        </div>

        </div>
   

        </div>
    </div>
    );

}

export default EditLoanModal;