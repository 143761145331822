const StepOne=({users,details,handleDetails})=>{

return (
<div className="row">

<div className="form-group col-md-3">
<label>Organization <span className='red'>*</span></label>
<input type="text" className="form-control" name="organization" value = {details.organization} onChange={handleDetails} />
<span className="error_holder"  >{details.error_list.organization}</span>
</div>

<div className="form-group col-md-3">
<label>Phone <span className='red'>*</span></label>
<input type="text" className="form-control" name="phone" value = {details.phone} onChange={handleDetails} />
<span className="error_holder"  >{details.error_list.phone}</span>
</div>

<div className="form-group col-md-3">
<label>Email <span className='red'>*</span></label>
<input type="text" className="form-control" name="email"  value = {details.email} onChange={handleDetails} />
<span className="error_holder"  >{details.error_list.email}</span>
</div>

<div className="form-group col-md-3">
<label>Website</label>
<input type="text" className="form-control" name="website"  value = {details.website} onChange={handleDetails} />
<span className="error_holder"  >{details.error_list.website}</span>
</div>

<div className="form-group col-md-3">
<label>Postal Address <span className='red'>*</span></label>
<input type="text" className="form-control" name="postal_address"  value = {details.postal_address} onChange={handleDetails} />
<span className="error_holder"  >{details.error_list.postal_address}</span>
</div>

<div className="form-group col-md-3">
<label>City / Town <span className='red'>*</span></label>
<input type="text" className="form-control" name="city"  value = {details.city} onChange={handleDetails}/>
<span className="error_holder"  >{details.error_list.city}</span>
</div>

<div className="form-group col-md-3">
<label>Account manager <span className='red'>*</span></label>
<select name="account_manager" className="form-control"  value = {details.account_manager} onChange={handleDetails} >
<option value='' >Select Manager</option>
{users.map((user,index)=>{
return(<option value={user.id} key={index} >{user.name}</option>)
})}
</select>
<span className="error_holder"  >{details.error_list.account_manager}</span>
</div>

<div className="form-group col-md-3">
<label>Interest Rate <span className='red'>*</span></label>
<input className="form-control" name='interest_rate' type="text" value={details.interest_rate} onChange={handleDetails} />
<span className="error_holder"  >{details.error_list.interest_rate}</span>
</div>

<div className="form-group col-md-3">
<label>Max Duration <span className='red'>*</span></label>
<input name='max_duration' className='form-control' type='number' min="1" value={details.max_duration} onChange={handleDetails} />
<span className="error_holder"  >{details.error_list.max_duration}</span>
</div>

</div>
)

}
export default StepOne;