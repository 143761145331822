import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import swal from 'sweetalert';

import user_image from './../../img/user1-128x128.jpg';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import CardHeader from '../elements/cards/CardHeader';
import CardBody from '../elements/cards/CardBody';
import StaffTable from '../elements/StaffTable';
import StaffCards from '../elements/cards/StaffCards';
import NewStaffModal from '../elements/modals/staff/NewStaffModal';
import EditStaffModal from '../elements/modals/staff/EditStaffModal';

import Pagination from '../elements/Pagination';

function Staff()
{
    // useState to store all users
    const [users,setUsers] = useState([]);
    const [userResults,setUserResults] = useState([]);
    const effectRun = useRef(false)
    // Set departments
    const [departments,setDepartments] = useState([]);

    // Set roles
    const [roles, setRoles] = useState([]);

    // useState to store modal state ( true / false )
    const [openModal,setOpenModal] = useState(false);

    // useState to store Edit modal state ( true / false )
    const [openEditModal,setEditOpenModal] = useState(false);

    // useState to store new staff details
    const [staffDetails,setStaffDetails] = useState({
        id: '',
        name: '',
        phone: '',
        email: '',
        gender: '',
        department: '',
        role: '',
        status:'',
        perfomance:'',
        payroll:'',
        error_list: [],
    });

    // Set the default lisiting of users
    const[defaultView,setDefaultView] = useState('table');

    const[currentPage,setCurrentPage] = useState(1);
    const[pageLimit,setPageLimit] = useState(10);

    const[upperLimit,setUpper] = useState(pageLimit*currentPage);
    const[lowerLimit,setLower] = useState(upperLimit-pageLimit+1);

    const nextPage=(id)=>{
    setCurrentPage(id)
    setUpper(pageLimit*id)
    setLower((pageLimit*id)-pageLimit+1) 
    }

    // Function to switch between cards and tables
    const switchView = (view) =>{

        if(view === 'table')
        {
            setPageLimit(10)
        }else{
            nextPage(1)
            setPageLimit(12)
        }

        setDefaultView(view)
    }

    const renderView = () =>{
        if(defaultView === 'table')
        {
            
            return(<StaffTable users={users} lowerLimit={lowerLimit} upperLimit = {upperLimit} editUser={editUser} />)
        }else if(defaultView === 'card'){
            
            return(<StaffCards users={users} lowerLimit={lowerLimit} upperLimit = {upperLimit} editUser={editUser} />)
        }else if(defaultView === 'search'){
            return(<StaffTable users={userResults} lowerLimit={lowerLimit} upperLimit = {upperLimit} editUser={editUser} />)
        }
    }

    const searchResults = (items,parameter)=>{
       
        setDefaultView('search')

        const holder = []

        items.map((item,index)=>{

            if(item.name.includes(parameter) || item.phone.includes(parameter) || item.email.includes(parameter) )
            {
                holder.push(items[index])
            }

        })

        setUserResults(holder)

    }

    // handle input on saving staff details
    const handleInput = (e) => {
        e.persist();
        setStaffDetails({...staffDetails, [e.target.name]: e.target.value})
    }


    // Function to register staff
    const RegisterStaff = (e) =>{

        e.preventDefault()
       
        // Set the required POST data
        const data = {
            name:staffDetails.name,
            phone:staffDetails.phone,
            email:staffDetails.email,
            gender:staffDetails.gender,
            role:staffDetails.role,
            department:staffDetails.department,
            perfomance: staffDetails.perfomance,
            status: staffDetails.status,
            payroll: staffDetails.payroll,
        }

        axios.get(`sanctum/csrf-cookie`).then(response => {
        
            axios.post(`api/register`,data).then(res=>{

                if(res.data.status === 200)
                {
                    swal('Success',res.data.message,"success")
                    setStaffDetails({
                        id: '',
                        name: '',
                        phone: '',
                        email: '',
                        gender: '',
                        department: '',
                        role: '',
                        status:'',
                        perfomance:'',
                        payroll:'',
                        error_list: [],
                })

                setOpenModal(false)
                refreshUsers()                

                }
                else if(res.data.status === 401){
                    swal('Warning',res.data.message,"warning")
                }
                else{
                    setStaffDetails({...staffDetails, error_list: res.data.validation_errors})
                }
    
            });

        })

    }
    

    // Hook to initialize crucial data

    useEffect(()=>{
    
    if(effectRun.current == false)
    {
        refreshUsers()
        fetchRoles()
        fetchDepartments()

    }

    return()=>{
    effectRun.current = true
    }

    },[]);

    //  Refresh users list when an update or addittion is done
    const refreshUsers = ()=>{

        axios.post(`api/users`,).then(res => {
    
            if(res.data.status === 200)
            {
                let persons = res.data.users
                setUsers(persons)              

            }

      })

     }

     // Set roles
     const fetchRoles=()=>{

        axios.post(`api/roles`,).then(res=>{

            if(res.data.status === 200)
            {
                let roles = res.data.roles
                setRoles(roles)              

            }

        })

     }

     // Set departments
     const fetchDepartments=()=>{

        axios.post(`api/departments`,).then(res=>{
            
            if(res.data.status === 200)
            {
                let departments = res.data.departments
                setDepartments(departments)              

            }

        });

     }

    // Function to set user details
    const editUser = (id) =>{
        
        setStaffDetails({
            id: users[id].id,
            name: users[id].name,
            phone: users[id].phone,
            email: users[id].email,
            gender: users[id].gender,
            department: users[id].dep_id,
            role: users[id].role_id,
            status: users[id].status,
            perfomance: users[id].perfomance,
            payroll: users[id].payroll,
            error_list: [],
        });
       
        setEditOpenModal(true)
    
    }

    // Function to update user details
    const updateUser = (e) =>{
        e.preventDefault()
       
        const data = {
            id:staffDetails.id,
            name:staffDetails.name,
            phone:staffDetails.phone,
            email:staffDetails.email,
            gender:staffDetails.gender,
            role:staffDetails.role,
            department:staffDetails.department,
            status: staffDetails.status,
            perfomance: staffDetails.perfomance,
            payroll: staffDetails.payroll,
        }
           
            
        axios.post(`api/update_user`,data).then(res=>{

        if(res.data.status === 200)
        {
            swal('Success',res.data.message,"success")
            setEditOpenModal(false)
            refreshUsers()

            setStaffDetails({
                id: '',
                name: '',
                phone: '',
                email: '',
                gender: '',
                department: '',
                role: '',
                status: '',
                perfomance: '',
                payroll: '',
                error_list: [],
                })
            }
            else
            {
                setStaffDetails({...staffDetails, error_list: res.data.validation_errors})
            }

            });
            

    }

    return (
        <div className="wrapper">
        
        <NewStaffModal open={openModal} onClose={()=>setOpenModal(false)} staffDetails={staffDetails} handleInput={handleInput} registerStaff ={RegisterStaff} roles={roles} departments={departments} />
        <EditStaffModal open={openEditModal} onClose={()=>setEditOpenModal(false)} staffDetails={staffDetails} handleInput={handleInput} updateUser ={updateUser} roles={roles} departments={departments} />
        
        <Nav/>
        <Aside/>

        <div className="content-wrapper">

        <ContentHeader title="Staff" />

        <div className="content">
        <div className="container">
        <div className="row">
            
        <div className="col-lg-3"  >
        
        <div className="card card-widget widget-user-2"  >
        <CardBody/>
        </div>

        </div>

        <div className = "col-lg-8" id = "centralSettings">
        
        <div className="card card-primary card-outline">
        <div className="card-header">
        <h5 className="card-title m-0"><span className='fa fa-users' ></span> Northwave Staff <span className='right btn btn-xs btn-primary' onClick={()=>setOpenModal(true)} ><span className='fa fa-plus-circle' ></span> New Staff</span></h5>
        </div>
        <div className="card-body">

        <div className='row' >
        <div className='col-md-12' style={{paddingLeft:'7px', marginBottom:'15px'}}>

        <span className='btn btn-default btn-xs' style={{margin:'2px'}} onClick={()=>switchView('table')} > <i className="fas fa-list"></i> </span>
        <span className='btn btn-default btn-xs' style={{margin:'2px'}} onClick={()=>switchView('card')} ><i className="fas fa-th-large"></i></span>
        <span className='btn btn-default btn-xs' style={{margin:'2px', float:'right'}} onChange={(e)=>searchResults(users,e.target.value)} > <input type="text"  /> </span> 
    
        </div>
        </div>

        {renderView()}
                
        </div>
        
        <div className='card-footer' >
        <Pagination currentPage={currentPage} setCurrentPage={nextPage} pageLimit={pageLimit} totalItems={users.length} />
             
        </div>

        </div>

        </div>

        </div>
        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default Staff;