import { Modal } from "react-bootstrap";
const NewDocument=({createDocument,closeCreateDocument,document,handleScan,handleDocument,uploadDocument})=>{
if(!createDocument) return false;
return(

<Modal
show={createDocument}
onHide={closeCreateDocument}
backdrop="static"
keyboard={false}
size="lg"
>
<Modal.Header closeButton>
<Modal.Title>Upload Document</Modal.Title>
</Modal.Header>

<Modal.Body>
<form className="form" onSubmit={uploadDocument}>
<div className="row">

    <div className="form-group col-md-6">
    <label>Document</label>
    <input type="text" className="form-control" name="name" value={document.name} onChange={handleDocument}/>
    <span className="red">{document.error_list.name}</span>
    </div>
    
    <div className="form-group col-md-6">
    <label>Scan</label>
    <input type="file" className="form-control" onChange={handleScan} />
    </div>

    </div>

    <div className="row">
    <div className="form-group col-md-12">
    <input type="submit" className="btn btn-primary btn-sm" value="Upload Document"></input>
    </div>
    </div>
</form>
</Modal.Body>

<Modal.Footer>
</Modal.Footer>

</Modal>

)

}
export default NewDocument;