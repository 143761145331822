import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

import AddItem from '../elements/modals/requisitions/AddItem';
import EditItem from '../elements/modals/requisitions/EditItem';
import Confirm from '../elements/modals/utility/Confirm';
import Action from '../elements/modals/requisitions/Action';

function ViewRequisition()
{
    const id = new useParams().id

    const effectRun = useRef(false)
    const [items,setItem] = useState([])
    const [requisition,setRequisition] = useState({})
    const [item_id, setItemId] = useState('')
    const [comments,setComments] = useState([])
    const [approvals,setApprovals] = useState([])
    const [action,setAction] = useState("false")

    const [commentInfo,setComment] = useState({
        comment:'',
        error_list:[]
    })

    const handleComment =(e)=> {
    setComment({...commentInfo, comment:e.target.value})
    }

    const [open,setOpen] = useState(false)
    const [openEdit,setOpenEdit] = useState(false)

    const [openConfirm,setOpenConfirm] = useState(false)

    const checkAction=()=>{
    
    const data = {
    requisition:id
    }

    axios.post(`api/check_action`,data).then((res)=>{
        if(res.data.status === 200)
        {
            setAction(res.data.action)
        }
    })

    }

    const deleteItem=(id)=>{        
        
    const data={
        id:id
    }

    axios.post(`api/delete_item`,data).then((res)=>{
        if(res.data.status === 200)
        {
            closeConfirm()
            fetchDetails()
            swal("Success",res.data.message,"success")
        }
    })
        
    }

    const initiateItem=(id)=>{
    setItemId(id)
    setOpenConfirm(true)
    }

    const closeConfirm=()=>{
        setOpenConfirm(false)
    }

    const [details,setDetails] = useState({
        id:'',
        item:'',
        description:'',
        quantity:1,
        cost:1,
        error_list:[],
    })

    const onClose=()=>{
    setOpen(false)
    setOpenEdit(false)
    setDetails({
        id:'',
        item:'',
        description:'',
        quantity:1,
        cost:1,
        error_list:[],
    })
    }

    const handleDetails=(e)=>{
    setDetails({...details, [e.target.name]: e.target.value})
    }

    const saveItem=(e)=>{

    e.preventDefault()
    const data = {
        id: id,
        item: details.item,
        description: details.description,
        quantity: details.quantity,
        cost: details.cost,
    }

    axios.post(`api/add_item`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        onClose()
        fetchDetails()
        swal("Success",res.data.message,"success")
    }
    else
    {
        setDetails({...details, error_list:res.data.validation_errors})
    }

    })

    }

    const updateItem=(e)=>{

    e.preventDefault()

    const data = {
        id: details.id,
        item: details.item,
        description: details.description,
        quantity: details.quantity,
        cost: details.cost,
    }

    axios.post(`api/update_item`,data).then((res)=>{
        if(res.data.status === 200)
        {
            onClose()
            fetchDetails()
            swal("Success",res.data.message,"success")
        }else
        {
            setDetails({...details, error_list:res.data.validation_errors})
        }
    })

    }

    const fetchApprovals=()=>{
    
    const data = {
        requisition:id,
    }

    axios.post(`api/requisition_approvals`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        setApprovals(res.data.approvals)
        
    }

    })

    }

    const fetchComments=()=>{
    
    const data = {
    requisition: id
    }

    axios.post(`api/requisition_comments`,data).then((res)=>{
        if(res.data.status === 200)
        {
            setComments(res.data.comments)
        }
    })

    }

    const fetchDetails=()=>{

    const data = {
    id:id
    }
        
    axios.post(`api/requisition_details`,data).then((res)=>{
        if(res.data.status === 200)
        {
            setItem(res.data.items)
            setRequisition(res.data.requisition)
        }
    })

    }

    const editModal=(index)=>{
    
    var data = {
        id: items[index].id,
        item: items[index].item,
        description: items[index].description,
        cost: items[index].cost,
        quantity: items[index].quantity,
        error_list:[]
    }

    setDetails(data)
    setOpenEdit(true)

    }

    const createComment=(e)=>{
        e.preventDefault()
        const data = {
            requisition:id,
            comment: commentInfo.comment
        }

        axios.post(`api/create_comment`,data).then((res)=>{
            if(res.data.status === 200)
            {
                setComment({...commentInfo, comment:''})
                fetchComments()
                swal("Success",res.data.message,"success")
            }
            else
            {
                setComment({...commentInfo, error_list:res.data.validation_errors})
            }
        })

    }

    const takeAction=(decision)=>{
    
    const data = {
        requisition:id,
        decision: decision
    }

    axios.post(`api/requisition_action`,data).then((res)=>{
        if(res.data.status === 200)
        {
            checkAction()
            fetchApprovals()
            swal("Success",res.data.message,"success")
        }
    })

    }

    // Use effect to initialize critical data
    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchDetails()
        fetchComments()
        fetchApprovals()
        checkAction()
    }

    return()=>{
    effectRun.current = true
    }

    },[])

    return (

        <div className="wrapper">
        <Confirm openConfirm={openConfirm} closeConfirm={closeConfirm} action={deleteItem} id={item_id}/>
        <AddItem open={open} onClose={onClose} details={details} handleDetails={handleDetails} saveItem={saveItem} />
        <EditItem openEdit={openEdit} onClose={onClose} details={details} handleDetails={handleDetails} updateItem={updateItem} />
        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        

        <div className="content">
        <div className="container">

        <div className="row" style={{marginTop:'20px'}} >
        <div className = "col-lg-12" >

        <div className="card card-primary card-outline">
        <div className="card-header">
        <h6 className="card-title">View Requisition</h6>
        </div>
        <div className="card-body">

        <div className='row'>
        
        <div className='col-md-8'>

        <div className='card card-primary'>
        <div className='card-header'>
        <h6> <span className='fa fa-file'></span> Requisition Items <span className='btn btn-default btn-xs float-right' onClick={()=>setOpen(true)} ><span className='fa fa-plus-circle'></span>  Add Item</span> </h6>
        </div>

        <div className='card-body'>
        <table className='table'>
            <thead>
                <tr className='small-row'>
                    <th>#</th>
                    <th>Item</th>
                    <th>Description</th>
                    <th>Quant</th>
                    <th>Cost</th>
                    <th>Total</th>
                    <th> <span className='fa fa-edit' style={{color:'green'}} ></span> </th>
                    <th> <span className='fa fa-trash red'></span> </th>
                </tr>
            </thead>
            <tbody>
            {items.map((item,index)=>{
                return(
                <tr className='small-row' key={index} >
                <td>{index+1}</td>
                <td>{item.item}</td>
                <td>{item.description}</td>
                <td>{item.quantity}</td>
                <td>KES {parseFloat(item.cost).toLocaleString('en-US')}</td>
                <td>KES {parseFloat(item.total).toLocaleString('en-US')}</td>
                <td><span className='fa fa-edit' style={{color:'green'}} onClick={()=>editModal(index)} ></span></td>
                <td> <span className='fa fa-trash red' onClick={()=>initiateItem(item.id)} ></span> </td>
            </tr>
            )
            })}

            <tr className='small-row' >
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><b>KES {parseInt(requisition.totals).toLocaleString('en-US')}</b></td>
            <td>...</td>
            <td>...</td>
            </tr>

            </tbody>
        </table>
        </div>

        <div className='card-footer'></div>
        </div>

        </div>

        <div className='col-md-4'>
        
        <Action action={action} takeAction={takeAction}/>

        <div className='card card-primary'>

        <div className='card-header'>
        <h6><span className='fa fa-user-shield'></span> Requisition Approval</h6>
        </div>

        <div className='card-body'>
        <table className='table'>
            <thead>
                <tr className='small-row'>
                    <th>#</th>
                    <th>Stage</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {approvals.map((item,index)=>{
                return(<tr className='small-row' key={index} ><td>{index+1}</td><td><span className='fa fa-user-shield'></span>  {item.role}</td><td>{item.status}</td></tr>)
                })}
            </tbody>
        </table>
        </div>

        <div className='card-footer'>
        </div>

        </div>

        <div className='card card-primary'>
        <div className='card-header'>
        <h6><span className='fa fa-comment'></span> Requisition Comments</h6>
        </div>
        <div className='card-body'>
        
        <div>
        {comments.map((item,index)=>{
        
        return(
        <div className="direct-chat-msg left" key={index}  style={{display:'block', clear:'both', width:'100%', marginBottom:'15px'}} >
        <div></div>

        <div className="direct-chat-infos clearfix" >
        <span className="direct-chat-name float-left"><b>{item.author}</b></span>
        <span className="direct-chat-timestamp float-right">{item.date}</span>
        </div>
                
        <img className="direct-chat-img" src="../dist/img/user1-128x128.jpg" alt="message user image" />
        <div className="direct-chat-text bg-success">{item.comment}</div>
        
        </div>
        )
        })}
        </div>

        <form className='form' style={{marginTop:'20px'}} onSubmit={createComment}>

        <div className="form-group">
        <label>Comment</label>
        <input type="text" className='form-control' value={commentInfo.comment} onChange={handleComment} ></input>
        <span className='error_holder'>{commentInfo.error_list.comment}</span>
        </div>

        <div className="form-group">
        <input value="Add comment" className='btn btn-primary btn-xs' type="submit" />
        </div>

        </form>

        </div>
        <div className='card-footer'></div>
        </div>

        </div>

        </div>  
                
        </div>

        <div className='card-footer' >
        
        </div>

        </div>

        </div>
        </div>

        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default ViewRequisition;