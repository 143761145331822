import OrganizationSummary from "./OrganizationSummary";
import OrganizationContacts from "./OrganizationContacts";
import OrganizationDocuments from "./OrganizationDocuments";
import OrganizationStatement from "./OrganizationStatement";
import OrganizationInvoices from "./OrganizationInvoices";
import OrganizationLoans from "./OrganizationLoans";

const OrganizationHolder=({tab,details,contacts,documents,manager,setEditOrg,initializeContact,setCreateContact,setCreateDocument,deleteContact,statement,invoices,loans,setOpen,fetchDetails,links,organizationStaff})=>{

if(tab == "summary")
{
    return(<OrganizationSummary details={details} manager={manager} setEditOrg={setEditOrg} />)
}
else if(tab == "contacts")
{
    return(<OrganizationContacts contacts={contacts} initializeContact={initializeContact} setCreateContact={setCreateContact} deleteContact={deleteContact} links={links} organizationStaff={organizationStaff}/>)
}
else if(tab == "documents"){
    return(<OrganizationDocuments documents={documents} setCreateDocument={setCreateDocument} fetchDetails={fetchDetails}/>)    
}else if(tab == "statement" )
{
    return (<OrganizationStatement statement={statement} setOpen={setOpen} />)
}else if(tab == "invoices")
{
    return(<OrganizationInvoices invoices={invoices} ></OrganizationInvoices>)
}else if(tab == "loans")
{
    return (<OrganizationLoans loans={loans} ></OrganizationLoans>)
}

}
 

export default OrganizationHolder;