    import { useReactToPrint } from "react-to-print";
    import { useRef } from "react";

    import Table from 'react-bootstrap/Table';
    import Container from 'react-bootstrap/Container';
    import Row from 'react-bootstrap/Row';
    import Col from 'react-bootstrap/Col';
    import Stack  from 'react-bootstrap/Stack';
    import Image  from 'react-bootstrap/Image';

    import logo from '../../../llogo.jpeg'

    const ValuationsReport = ({open,onClose,entries,details}) =>{

    const componentRef = useRef()

    const handlePrint = useReactToPrint({
    content:()=> componentRef.current,
    documentTitle: 'Valuations Report',
    onAfterPrint:()=>onClose()
    })

    let today = new Date().toUTCString().slice(5, 16);
    if(!open) return null;
    return(
    <div className='overlay' onClick={onClose} >
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className = "card card-primary" >
        <div className="card-header" >
        <span className='closeModal btn btn-sm btn-default' onClick={handlePrint} style={{margin:'2px'}} > <span className='fa fa-print' ></span> Print Report</span>
        </div>
            
        <div className="card-body">

        <div ref={componentRef} style={{width: '100%'}} >
                
            <Container>
            <Row className="mt-5" style={{borderBottom:'1px solid black'}}>
            <Col>
            <div>
            <Image src={logo} style={{width:'150px'}} ></Image>
            </div>

            <div className="mt-3" >
            <h6><b>Valuations Report</b></h6>
            </div>

            </Col>

            <Col style={{textAlign:'right'}} >
            <Stack gap={3}>
            <div>Northwave Credit Ltd</div>
            <div>P.O BOX 2760 - 00100</div>
            <div>Nairobi, Kenya</div>
            <div>Tel: 0703999944</div>
            <div>Email: info@northwavecredit.com</div>
            <div>Website: www.northwavecredit.com</div>
            </Stack>
            </Col>
            </Row> 

            <Row className="mt-3" >
           
            <Col>
            <div>
            <small  ><span className="info-holder">From :</span> {details.start_date}   </small>  
            </div>
            <div>
            <small  ><span className="info-holder">To :</span> {details.end_date}   </small>  
            </div>
            <div>
            <small>Auto generated on :  {today}  </small>     
            </div>
            <Table striped bordered hover className="mt-3 w-80" >
            <thead>
            <tr className="small-row" >
            <th>#</th>
            <th>Date</th>
            <th>Client</th>
            <th>Valuer</th>
            <th>Vehicle</th>
            <th>(F) Value</th>
            <th>(M) Value</th>
            <th>Payment</th>
            <th>Status</th>
            </tr>
            </thead>
            <tbody>
            {entries.map((item,index)=>{
                    return(
                    <tr className='small-row' key={index}>
                        <td>{index+1}</td>
                        <td>{item.created_at.substr(0,10)}</td>
                        <td>{item.first_name} {item.last_name}</td>
                        <td>{item.name} <br></br><small>({item.organization})</small> </td>
                        <td><b>{item.registration}</b> </td>
                        <td>KES {parseInt(item.forced_value).toLocaleString('en-US')}</td>
                        <td>KES {parseInt(item.market_value).toLocaleString('en-US')}</td>
                        <td>{item.payment} <br></br> <small>KES {parseInt(item.cost).toLocaleString('en-US')}</small> </td>
                        <td>{item.status}</td>
                    </tr>
                    )
                    })}
            </tbody>
            </Table>

        </Col>
        </Row>
        </Container>
       

        </div>

        </div>

        <div className='card-footer' >
        

        </div>

        </div>
   

        </div>
    </div>
    );
}

export default ValuationsReport;