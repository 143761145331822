const CustomerValuations=({setValuationFlag,valuations,fetchValuationDetails,updateValuation,profile})=>{

    const addButton=()=>{

        var role = localStorage.getItem('auth_role')
    
        if(role == "CEO" || role == "System Administrator")
        {
            return(
                <span className="btn btn-primary btn-sm" style={{float:'right'}} onClick={()=>setValuationFlag(true)} >New Valuation</span>
            )
        }
        else
        {
            if(profile.edit_flag == "0")
            {
                return(
                    <span className="btn btn-primary btn-sm" style={{float:'right'}} onClick={()=>setValuationFlag(true)} >New Valuation</span>  
                )
            }
        }     
    }

    const editColumn=(item)=>{

        var role = localStorage.getItem('auth_role')
    
        if(role == "CEO" || role == "System Administrator")
        {
            return(
                <td onClick={()=>updateValuation(item.id)} > <span className='fa fa-edit' style={{color:'green'}} ></span> </td>
            )
        }
        else
        {
            if(profile.edit_flag == "0")
            {
                return(
                    <td onClick={()=>updateValuation(item.id)} > <span className='fa fa-edit' style={{color:'green'}} ></span> </td>
                )
            }
            else
            {
                return(
                    <td>...</td>
                )
            }
        }     
    }

return(
    <div className="card card-primary card-outline" >
        <div className="card-header" >
        <h5><span className='fa fa-balance-scale' ></span> Valuations
        {addButton()}
        </h5>
        </div>
        <div className="card-body" >
        <table className="table" >
        <thead>
        <tr style={{fontSize:'14px'}} >
        <th>#</th>
        <th>Date</th>
        <th>Vehicle</th>
        <th>Valuer</th>
        <th>Cost</th>
        <th>Payment</th>
        <th>Status</th>
        <th>Forced Value</th>
        <th> <span className='fa fa-edit' style={{color:'green'}}></span> </th>
        </tr>
        </thead>

        <tbody>
        {valuations.map((item,index)=>{
            return(
                <tr style={{fontSize:'12px'}} key={index} >
                    <td>{index+1}</td>
                    <td>{item.created_at.substr(0,10)}</td>
                    <td onClick={()=>fetchValuationDetails(item.id)} style={{color:'blue'}}>{item.registration}</td>
                    <td>{item.name}</td>
                    <td>KES {parseFloat(item.cost).toLocaleString('en-US')}</td>
                    <td>{item.payment}</td>
                    <td>{item.status}</td>
                    <td>KES {parseFloat(item.forced_value).toLocaleString('en-US')}</td>
                    {editColumn(item)}
                </tr>
            )
        })}
        </tbody>

        </table>
        </div>
        <div className="card-footer" ></div>
    </div>
)

}
export default CustomerValuations;