const FetchStage = ({stageOne,stageTwo,stageThree,stageFour,Stage_One,Stage_Two,Stage_Three,Stage_Four,stage,handleStageOne,handleStageTwo,handleStageThree,handleStageFour,idError,permitError,resolutionError,kraError}) =>{

    if(stage === 1)
    {
        return(
            <Stage_One stageOne={stageOne} handleStageOne={handleStageOne} />
        )
    }

    if(stage === 2)
    {
        return(
            <Stage_Two stageTwo={stageTwo} handleStageTwo={handleStageTwo} />
        )
    }

    if(stage === 3)
    {
        return(
            <Stage_Three stageThree={stageThree} handleStageThree={handleStageThree} />
        )
    }

    if(stage === 4)
    {
        
        return(
            <Stage_Four stageFour={stageFour} handleStageFour={handleStageFour}  idError={idError} permitError={permitError} kraError={kraError} resolutionError={resolutionError}  />
        )
        
    }

}

export default FetchStage;