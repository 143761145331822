const OrganizationLoans=({loans})=>{
    return(
        <div className="card card-primary card-outline">
            <div className="card-header">
            <h6>Loans</h6>
            </div>
            <div className="card-body">
            <table className="table">
            <thead>
            <tr className='small-row' >
                <th>#</th>
                <th>Date</th>
                <th>Loan</th>
                <th>Staff</th>
                <th>Amount</th>
                <th>Rate</th>
                <th>Period</th>
                <th>Installment</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
                {loans.map((items,index)=>{
                    return(
                        <tr  className='small-row'  >
                            <td>{index+1}</td>
                            <td>{items.created_at.substr(0,10)}</td>
                            <td>{items.loan_code}</td>
                            <td>{items.first_name} {items.other_name} {items.last_name} </td>
                            <td>KES {parseInt(items.amount_loanable).toLocaleString('en-US')}</td>
                            <td>{items.interest_rate} %</td>
                            <td>{items.period} Months</td>
                            <td>KES {parseInt(items.installment).toLocaleString('en-US')}</td>
                            <td>{items.status}</td>
                        </tr>
                    )
                })}
            </tbody>
            </table>
            </div>
            <div className="card-footer">           
            </div>
        </div>
    )
}

export default OrganizationLoans;