import React, {useState, useEffect} from 'react';
import useAuth from '../../hooks/useAuth';

import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import swal from 'sweetalert';


function Reset(){

    const navigate = useNavigate();
    const {setAuth} = useAuth()

    useEffect(() =>{
        if(localStorage.getItem('auth_token'))
        {
             navigate('/')
        }
    },[])
    
    const [loginInput, setLogin] = useState({
        email: '',
        error_list: [],
    });
    
    const handleInput = (e) => {
        e.persist();
        setLogin({...loginInput, [e.target.name]: e.target.value})
    }
    
    const loginSubmit = (e) => {
        e.preventDefault();
    
    const data = {
        email: loginInput.email,
    }
    
        axios.post(`api/reset`,data).then(res => {
    
                if(res.data.status === 200)
                {
                    swal('Success',res.data.message,"success")
                }
                else if(res.data.status === 401)
                {
                      swal('Warning',res.data.message,"warning")
                }
                else
                {
                    setLogin({...loginInput, error_list: res.data.validation_errors})
                }
    
          })
    
    }

return (
    <div className="hold-transition login-page">
    <div className="login-box">

        <div className="login-logo">
        <a href="#"><b>Northwave</b> Credit</a>
        </div>

        <div className = "card" >
        <div className="card-body login-card-body">

            <p className="login-box-msg">Enter your email</p>    
            <form onSubmit={loginSubmit} >

            <div className="input-group mb-3">
            <input type="email" className="form-control" placeholder="Email" name = "email" onChange={handleInput} value={loginInput.email} />
            <div className="input-group-append">
            <div className="input-group-text">
            <span className="fas fa-envelope"></span>
            </div>
            </div>            
            </div>
            <span className="error_holder" >{loginInput.error_list.email}</span>            

            <div className="row" >
                <div className ="col-md-12" >
                <button type="submit" className="btn btn-primary btn-block">Proceed</button>
                </div>
            </div>

            </form>

        </div>
        </div>
    </div>
    </div>
);

}

export default Reset;