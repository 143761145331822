
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function TeamLoansBarGraph({loans}){

    const options = {
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
        plugins: {
          title: {
            display: true,
            text: '',
          },
        },
      };
      
      const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sep','Oct','Nov','Dec'];
      
      const data = {
        labels,
        datasets: [
          {
            label: 'Loans Disbursed',
            data: loans,
            backgroundColor: 'rgba(53, 162, 235, 0.9)',
          },
        ],
      };

      return (<Bar options={options} data={data} />)

}

export default TeamLoansBarGraph;


