import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import Table  from 'react-bootstrap/Table';

import LoansDisbursalGraph from '../elements/charts/LoansDisbursalGraph';
import LoanDisbursmentPrint from '../elements/print/LoanDisbursmentPrint';

function DisbursmentReport(){

    const effectRun = useRef(false)

    const [disbursments,setDisbursments] = useState([])
    const [customers,setCustomers] = useState([])
    const [monthlyDisbursal,setMonthlyDisbursal] = useState([0,0,0,0,0,0,0,0,0,0,0,0])
    const [open,setOpen] = useState(false)
    const [year,setYear] = useState(new Date().getFullYear())
    const navigate = useNavigate()
        
    const [details,setDetails] = useState({
    start_date:'2023-01-01',
    end_date:'',
    client: 'All',
    error_list:[]
    })

    const onClose=()=>{
    setOpen(false)
    }

    const queryCustomers=()=>{
        axios.post(`api/query_customers`).then((res)=>{
            if(res.data.status === 200)
            {
                setCustomers(res.data.customers)
            }
        })
    }

    const queryDisbursments=()=>{
    
    const data = {
    start_date: details.start_date,
    end_date: details.end_date,
    client: details.client
    }
    
    axios.post(`api/query_disbursments`,data).then((res)=>{
        if(res.data.status === 200)
        {
            setDisbursments(res.data.disbursments)
            setDetails({...details, error_list:[]})
        }
        else
        {
            setDetails({...details, error_list: res.data.validation_errors})
        }
    }).catch(error=>{
        if(error == "AxiosError: Request failed with status code 403")
        {
          navigate('/unauthorized');
        }
    })
    
    }

    const fetchDisbursments=()=>{
    
    axios.post(`api/all_disbursments`).then((res)=>{
        if(res.data.status === 200)
        {
            setDisbursments(res.data.disbursments)
            setDetails({...details, end_date: res.data.current_date})
        }
    }).catch(error=>{
        if(error == "AxiosError: Request failed with status code 403")
        {
          navigate('/unauthorized');
        }
    })

    }

    const fetchDistribution=(year)=>{
        axios.get(`api/disbursal_distribution/${year}`).then((res)=>{
            if(res.data.status === 200)
            {
                setMonthlyDisbursal(res.data.months)
            }
        })
    }

    const handleSearch=(e)=>{
    setDetails({...details, [e.target.name]: e.target.value })
    }

    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchDisbursments()
        fetchDistribution(year)
        queryCustomers()

        return()=>{
        effectRun.current = true
        }
    }

    })

    return (
        <div className="wrapper">

            <LoanDisbursmentPrint open={open} onClose={onClose} statement={disbursments} details={details} ></LoanDisbursmentPrint>

            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>Loan Disbursments</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

               
                <div className='row'>
                <div className='col-md-12'>
            
                <div className='card card-primary card-outline'>
                <div className='card-header'>
                All Disbursments
                <span className='btn btn-dark btn-sm m-1 float-right' onClick={()=>fetchDisbursments()}><span className='fa fa-eraser'></span> Clear</span>
                <span className="btn btn-dark btn-sm m-1 float-right" onClick={()=>setOpen(true)} ><span className="fa fa-print" ></span> Print Report </span>
                <span className='btn btn-dark btn-sm m-1 float-right' onClick={()=>queryDisbursments()} ><span className='fa fa-search'></span> Search</span>

                
                </div>
                <div className='card-body'>

                <div className="row">
                    <div className='form-group col-md-4'>
                    <label>Start Date</label>
                    <input type="date" className='form-control' value={details.start_date} name="start_date" onChange={handleSearch} ></input>
                    <span className='error_holder' >{details.error_list.start_date}</span>
                    </div>

                    <div className='form-group col-md-4'>
                    <label>End Date</label>
                    <input type="date" className='form-control' value={details.end_date}  name="end_date" onChange={handleSearch} ></input>
                    <span className='error_holder' >{details.error_list.end_date}</span>
                    </div>

                    <div className='form-group col-md-4'>
                    <label>Client</label>
                    <select className='form-control'  value={details.client} name="client" onChange={handleSearch}  >
                    <option>All</option>
                    {customers.map((item,index)=>{
                    return(
                    <option key={index} value={item.id} >{item.first_name} {item.other_name} {item.last_name}</option>
                    )
                    })}
                    </select>
                    <span className='error_holder' >{details.error_list.client}</span>
                    </div>

                </div>

                <Table striped bordered hover >
                <thead>
                <tr  className='small-row' >
                <th>#</th>
                <th>Date</th>
                <th>Client</th>
                <th>Loan</th>
                <th>Loan Amount</th>
                <th>Period</th>
                <th>Interest Rate</th>
                <th>Amount Disbursed</th>
                </tr>
                </thead>
                <tbody>
                {disbursments.map((item,index)=>{
                return(
                <tr className='small-row' key={index} >
                <td>{index+1}</td>
                <td>{item.date_disbursed.substr(0,10)}</td>
                <td>{item.first_name} {item.other_name} {item.last_name}</td>
                <td>{item.loan_code}</td>
                <td>KES {parseInt(item.amount_loanable).toLocaleString('en-US')}</td>
                <td>{item.period} Months</td>
                <td>{item.interest_rate} %</td>
                <td>KES {parseInt(item.total_amount).toLocaleString('en-US')}</td>
                </tr>
                )
                })}
                </tbody>
                </Table>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default DisbursmentReport;