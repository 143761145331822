import LoanSummary from "./components/LoanSummary";
import LoanCollaterals from "./components/LoanCollaterals";
import LoanValuations from "./components/LoanValuations";
import LoanDocuments from "./components/LoanDocuments";
import LoanComments from "./components/LoanComments";

const LoanHolder=({mode,documents,valuations,collaterals,details,approvals,loan,comments,fetchComments,approver,fetchApprovals,customer,setEdit})=>{

    if(mode === "summary")
    {
        return(<LoanSummary details={details} approvals={approvals} customer={customer} setEdit={setEdit} />)
    }else if(mode === "collaterals")
    {
        return(<LoanCollaterals collaterals={collaterals} />)
    }else if(mode === "valuations")
    {
        return(<LoanValuations valuations={valuations} />)
    }else if(mode === "documents")
    {
        return(<LoanDocuments documents={documents}/>)
    }else if(mode === "comments")
    {
        return(<LoanComments loan={loan} comments={comments} fetchComments={fetchComments} approver={approver} fetchApprovals={fetchApprovals} />)
    }



}

export default LoanHolder;