import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

import RegisterCheque from '../elements/modals/payments/RegisterCheque';
import PrintRegister from '../elements/print/PrintRegister';

function ChequeRegister(){

    const effectRan = useRef(false)
    const [flag,setFlag] = useState(false)
    const [unlinked,setUnregistered] = useState([])
    const [open,setOpen] = useState(false)

    const closeRegister=()=>{
        setOpen(false)
    }

    const [details,setDetails] = useState({
        cheque:'',
        payment:'',
        notes:'',
        error_list: []
    })

    const setPayment=(payment,cheque)=>{
        setDetails({...details, 
            payment:payment, 
            cheque: cheque})
        setFlag(true)
    }

    const handleCheque=(e)=>{
        setDetails({...details, [e.target.name]: e.target.value})
    }

    const unregistered=()=>{
        axios.get(`api/unregistered_cheques`).then((res)=>{
            if(res.data.status == 200)
            {
                setUnregistered(res.data.unregistered)
            }
        })
    }

    const saveCheque=(e)=>{
        e.preventDefault()

        const data = {
            payment: details.payment,
            cheque: details.cheque,
            notes: details.notes
        }

        axios.post(`api/register_cheque`,data).then((res)=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success")      // Notifications
                onClose()                                       // Close Modal
                unregistered()                                  // Fetch Unregistered
            }
            else
            {
                setDetails({...details, error_list: res.data.validation_errors})
            }
        })

    }

    const onClose=()=>{
        setFlag(false)
        setDetails({...details, payment:'', cheque:''})
    }

    useEffect(()=>{
        
        if(effectRan.current === false)
        {
           unregistered()
        }

        return()=>{
        effectRan.current = true
        }

    },[])

    return (
        <div className="wrapper">
            <PrintRegister open={open} onClose={closeRegister} statement={unlinked}></PrintRegister>
            <RegisterCheque flag={flag} onClose={onClose} unregistered={unlinked} details={details} handleCheque={handleCheque} saveCheque={saveCheque} ></RegisterCheque>
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5> Cheques Register</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card card-primary card-outline'>
                            <div className='card-header'>
                            <span className='btn btn-sm btn-dark' style={{float:'right'}} onClick={()=>setOpen(true)} ><span className='fa fa-print'></span> Print Register</span>
                            </div>
                            <div className='card-body'>
                                <table className='table'>
                                    <thead>
                                        <tr style={{fontSize:'13px'}}>
                                            <td><b>#</b></td>                                            
                                            <td><b>Contact</b></td>
                                            <td><b>Loan</b></td>
                                            <td><b>Date</b></td>
                                            <td><b>Amount</b></td>
                                            <td><b>Cheque #</b></td>
                                            <td><b>Notes</b></td>
                                            <td><b>Action</b></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {unlinked.map((item,index)=>{
                                            return(
                                                <tr  style={{fontSize:'13px'}} key={index} >
                                                    <td>{index+1}</td>
                                                    <td>{item.first_name} {item.other_name} {item.last_name}</td>
                                                    <td>{item.loan_code}</td>
                                                    <td>{item.date}</td>
                                                    <td>KES {item.amount.toLocaleString('en-US')}</td>
                                                    <td>{item.cheque_no}</td>
                                                    <td>{item.notes}</td>
                                                    <td><span onClick={()=>setPayment(item.payment, item.cheque_no)} className='btn btn-sm btn-success' > <span className='fa fa-edit'></span> Edit</span> </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default ChequeRegister;