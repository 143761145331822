const LoanValuations=({valuations})=>{

    return (
    <div className="card card-primary card-outline">
    <div className="card-header">
        <h5>Valuations</h5>
    </div>
    <div className="card-body">
        <table className="table" >
            <thead>
                <tr className="small-row">
                <th>#</th>
                <th>Date</th>
                <th>Vehicle</th>
                <th>Valuer</th>
                <th>Cost</th>
                <th>Status</th>
                <th>Forced Value</th>
                <th>Market Value</th>
                </tr>
            </thead>
            <tbody>
            {valuations.map((item,index)=>{
            return(
            <tr className="small-row" key={index} >
            <td>{index+1}</td>
            <td>{item.created_at.substr(0,10)}</td>
            <td>{item.registration}</td>
            <td>{item.name}</td>
            <td>{parseInt(item.cost).toLocaleString('en-US')}</td>
            <td>{item.status}</td>
            <td>{parseInt(item.forced_value).toLocaleString('en-US')}</td>
            <td>{parseInt(item.market_value).toLocaleString('en-US')}</td>
            </tr>)
            })}
            </tbody>
        </table>
    </div>
    </div>
    )

}

export default LoanValuations;