const EditAccount=({openEdit,onClose,details,handleDetails,updateAccount})=>{
if(!openEdit) return false;

return(
<div className='overlay' onClick={onClose} >
<div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

<div className="card card-primary card-outline">
    <div className="card-header">
        <h5>Edit Account</h5>
    </div>
    <div className="card-body">
    <form className="form" onSubmit={updateAccount}>
    <div className="row">
    
    <div className="form-group col-md-12">
        <label>Account Name <span className="red">*</span></label>
        <input name="account" className="form-control" value={details.account} onChange={handleDetails} />
        <span className="red">{details.error_list.account}</span>
    </div>

    <div className="form-group col-md-12">
        <label>Description</label>
        <input name="description" className="form-control" value={details.description} onChange={handleDetails} />
        <span className="red">{details.error_list.description}</span>
    </div>

    <div className="form-group col-md-12">
        <label>Account Type  <span className="red">*</span></label>
        <select className="form-control" name="type" value={details.type} onChange={handleDetails}>
            <option value='' >Select Account Type</option>
            <option value='Debit' >Debit</option>
            <option value='Credit' >Credit</option>
        </select>
        <span className="red">{details.error_list.type}</span>
    </div>

    <div className="form-group col-md-12">
        <input className="btn btn-primary btn-sm" type="submit" value="Update Account" />
    </div>

    </div>
    </form>
    </div>
    <div className="card-footer">
        
    </div>
</div>

</div>
</div>
)

}
export default EditAccount;