import React, {useState, useEffect, useRef} from 'react';
import swal from 'sweetalert';
import axios from 'axios';

import useAuth from '../../hooks/useAuth';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import Footer from '../nav/Footer';
import ControlSidebar from '../nav/ControlSidebar';

import NewStakeholder from '../elements/modals/stakeholders/NewStakeholder';

function Developers(){

    const [developers,setDevelopers] = useState([])
    const [open,setOpen] = useState(false)
    const effectRun = useRef(false)

    const [details,setDetails] = useState({
    first_name:'',
    last_name:'',
    other_name:'',
    organization:'',
    phone:'',
    email:'',
    id_number:'',
    kra:'',
    postal_address:'',
    error_list:[],
    })

    const handleDetails=(e)=>{
    setDetails({...details, [e.target.name]: e.target.value})
    }

    const onClose=()=>{

        setDetails({
        first_name:'',
        last_name:'',
        other_name:'',
        organization:'',
        phone:'',
        email:'',
        id_number:'',
        kra:'',
        postal_address:'',
        error_list:[],
        })

        setOpen(false)
    }

    const fetchDevelopers=()=>{
    
    axios.post(`api/developers`).then((res)=>{
        if(res.data.status === 200)
        {
            setDevelopers(res.data.developers)
        }
    })

    }

    const saveDeveloper=(e)=>{
    e.preventDefault()

    const data = {
    first_name: details.first_name,
    other_name: details.other_name,
    last_name: details.last_name,
    phone: details.phone,
    email: details.email,
    id_number: details.id_number,
    kra: details.kra,
    postal_address: details.postal_address
    }

    axios.post(`api/add_developer`,data).then((res)=>{
        if(res.data.status === 200)
        {
            onClose()
            fetchDevelopers()
            swal("Success",res.data.message,'success')
        }
        else
        {
            setDetails({...details, error_list: res.data.validation_errors})
        }
    })

    }

    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchDevelopers()
    }

    return()=>{
    effectRun.current = true
    }

    })

    return (
        <div className="wrapper">

            <NewStakeholder open={open} onClose={onClose} details={details} handleDetails={handleDetails} saveDeveloper={saveDeveloper} />
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5><span className='fa fa-users'></span> Real Estate Developers</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='row'>
                <div className='col-md-12'>
                
                <div className='card card-primary card-outline'>
                <div className='card-header'>
                Developers
                <span className='btn btn-primary btn-xs float-right' onClick={()=>setOpen(true)} > <span className='fa fa-plus-circle'></span> Add Developer</span>
                </div>

                <div className='card-body'>
                <table className='table'>
                    <thead>
                        <tr className='small-row' >
                            <th>#</th>
                            <th>Name</th>
                            <th>Org</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th> <span className='fa fa-eye'></span> More</th>
                        </tr>
                    </thead>

                    <tbody>
                    {developers.map((item,index)=>{
                        return(
                            <tr key={index} className='small-row' >
                                <td>{index+1}</td>
                                <td>{item.first_name} {item.other_name} {item.last_name}</td>
                                <td>{item.organization}</td>
                                <td>{item.phone}</td>
                                <td>{item.email}</td>
                                <td> <span className='fa fa-eye'></span> More</td>
                            </tr>
                        )
                    })}
                    </tbody>

                </table>
                </div>

                </div>

                </div>
                </div>

                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default Developers;