import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import Modal  from 'react-bootstrap/Modal';

const EditValuation=({editValuationFlag,closeEditValuation,valuationRaw,vehicles,handleValuation,setValuationRaw,fetchValuations,valuers})=>{

const saveValuation=(e)=>{
e.preventDefault()
const data={
    id:valuationRaw.valuation,
    vehicle:valuationRaw.vehicle,
    valuer:valuationRaw.valuer,
    cost: valuationRaw.cost,
    contact: valuationRaw.contact,
    phone: valuationRaw.phone,
    notes: valuationRaw.notes,
    market_value:valuationRaw.market_value,
    forced_value: valuationRaw.forced_value,
    status: valuationRaw.status
}

axios.post(`api/update_valuation`,data).then((res)=>{

if(res.data.status === 200)
{
    swal('Success',res.data.message,'success')
    closeEditValuation()
    fetchValuations()
}else if(res.data.status === 403){
    swal('Error',res.data.message,"error")
}
else{
    setValuationRaw({...valuationRaw, error_list: res.data.validation_errors})
}

})

}

if(!editValuationFlag) return false;

return(

            <Modal
            show={editValuationFlag}
            onHide={closeEditValuation}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title>Edit Valuation</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <form className='form' onSubmit={saveValuation} >
            <div className="row" >

            <div className="form-group col-md-3">
            <label>Vehicle</label>
            <select name="vehicle" className='form-control' value={valuationRaw.vehicle} onChange={handleValuation} >
            <option value='' >Select Vehicle</option>
            {vehicles.map((car,index)=>{

            return(
            <option value={car.id} key={index} >{car.registration}</option>
            )

            })}
            </select>
            <span className="error_holder"  >{valuationRaw.error_list.vehicle}</span>
            </div>

            <div className="form-group col-md-3">
            <label>Valuer</label>
            <select name="valuer" className='form-control' value={valuationRaw.valuer} onChange={handleValuation} >
            <option value='' >Select Valuer</option>
            {valuers.map((person,index)=>{

            return(
            <option value={person.id} key={index} >{person.name}</option>
            )

            })}
            </select>
            <span className="error_holder"  >{valuationRaw.error_list.valuer}</span>
            </div>

            <div className="form-group col-md-3">
            <label>Cost</label>
            <input type="number" className='form-control' name="cost" value={valuationRaw.cost} onChange={handleValuation} />
            <span className="error_holder"  >{valuationRaw.error_list.cost}</span>
            </div>

            <div className="form-group col-md-3">
            <label>Contact person</label>
            <input type="text" className='form-control' name="contact" value={valuationRaw.contact} onChange={handleValuation} />
            <span className="error_holder"  >{valuationRaw.error_list.contact}</span>
            </div>

            <div className="form-group col-md-3">
            <label>Phone</label>
            <input type="text" className='form-control' name="phone" value={valuationRaw.phone} onChange={handleValuation} />
            <span className="error_holder"  >{valuationRaw.error_list.phone}</span>
            </div>

            <div className="form-group col-md-3">
            <label>Note to valuer</label>
            <input type="text" className='form-control' name="notes" value={valuationRaw.notes} onChange={handleValuation} />
            <span className="error_holder"  >{valuationRaw.error_list.notes}</span>
            </div>

            <div className="form-group col-md-3">
            <label>Market Value</label>
            <input type="number" className='form-control' name="market_value" value={valuationRaw.market_value} onChange={handleValuation} />
            <span className="error_holder"  >{valuationRaw.error_list.market_value}</span>
            </div>

            <div className="form-group col-md-3">
            <label>Forced Value</label>
            <input type="number" className='form-control' name="forced_value" value={valuationRaw.forced_value} onChange={handleValuation} />
            <span className="error_holder"  >{valuationRaw.error_list.forced_value}</span>
            </div>

            <div className="form-group col-md-12">
            <label>Status</label>
            <select className='form-control' name="status" value={valuationRaw.status} onChange={handleValuation} >
            <option>pending</option>
            <option>complete</option>
            <option>canceled</option>
            </select>
            <span className="error_holder"  >{valuationRaw.error_list.status}</span>
            </div>

            <div className="form-group col-md-12">
            <input type ="submit" className='btn btn-primary btn-sm' value="Update Valuation" />
            </div>

            </div>
            </form>

            </Modal.Body>
            </Modal>
   


)

}
export default EditValuation;

