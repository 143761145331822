import { useEffect, useState, useRef } from 'react';
import {Link, NavLink, useNavigate} from 'react-router-dom';
import axios from 'axios';


import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function CollateralList()
{
    const [collaterals,setCollaterals] = useState([])
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const effectRun = useRef(false)
    const navigate = useNavigate();

    const fetchCollaterals =()=>{
        axios.post(`api/collaterals`).then((res)=>{
            if(res.data.status === 200)
            {
                setCollaterals(res.data.collaterals)
            }
        }).catch(error=>{

            if(error == "AxiosError: Request failed with status code 403")
            {
              navigate('/unauthorized');
            }
        })
    }

    // Use effect to initialize critical data
    useEffect(()=>{

        if(effectRun.current === false)
        {
            fetchCollaterals()
        }

        return()=>{
        effectRun.current = true
        }

    })
    return (

        <div className="wrapper">
        
        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        
       

        <div className="content">
        <div className="container">

        <div className="row" style={{marginTop:'20px'}} >
        <div className = "col-lg-12" >

        <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        >
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          I will not close if you click outside me. Don not even try to press
          escape key.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer>
        </Modal> 

        <div className="card card-primary card-outline">
        <div className="card-header">
        <h6 className="card-title">Collateral List
        
            

        </h6>
        </div>

        <div className="card-body">

           

            <div className='row'>
                <div className='col-md-12'>

                    <form className='form mb-2' >
                        <div className='row'>

                        <div className='form-group col-md-8'>
                        <input type = "text" className='form-control' placeholder="search"></input>
                        </div>

                        <div className='form-group col-md-4'>
                        <input className='btn btn-primary btn-sm m-1' value="Search"></input>
                        <span className='btn btn-dark btn-sm m-1'><span className='fa fa-print'></span> Print List</span>
                        </div>

                        </div>
                    </form>

                    <Table striped hover bordered>
                        <thead>
                            <tr className='small-row' >
                                <td>#</td>
                                <td>Loan</td>
                                <td>Client</td>
                                <td>Loan Value</td>
                                <td>Vehicle Make</td>
                                <td>Model</td>
                                <td>Registration #</td>
                                <td>Log Book #</td>
                                <td>Engine #</td>
                                <td>Chasis #</td>
                            </tr>
                        </thead>
                        <tbody>
                            {collaterals.map((item,index)=>{
                                return(
                                    <tr  className='small-row' key={index} >
                                        <td>{index+1}</td>
                                        <td>{item.loan_code}</td>
                                        <td>{item.first_name} {item.last_name}</td>
                                        <td>KES {parseInt(item.total_amount).toLocaleString('en-US')}</td>
                                        <td>{item.make}</td>
                                        <td>{item.model}</td>
                                        <td>{item.registration}</td>
                                        <td>{item.log_book}</td>
                                        <td>{item.engine_no}</td>
                                        <td>{item.chasis_no}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>

                    <Button variant="primary" onClick={handleShow}>
                    Launch demo modal
                    </Button>

                </div>
            </div>                
        </div>

        <div className='card-footer' >
        
        </div>

        </div>

        </div>
        </div>

        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default CollateralList;