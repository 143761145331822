import { useReactToPrint } from "react-to-print";
    import { useRef } from "react";
    import Table from 'react-bootstrap/Table';
    import Container from 'react-bootstrap/Container';
    import Row from 'react-bootstrap/Row';
    import Col from 'react-bootstrap/Col';
    import Stack  from 'react-bootstrap/Stack';
    import Image  from 'react-bootstrap/Image';
    import logo from '../../../llogo.jpeg'

    const  PrintRepocession = ({repo_charge,closeRepoCharge,client,details,dueDate,vehicles}) =>{

    const marginTop="10px"
    const marginRight="60px"
    const marginBottom="10px"
    const marginLeft="60px"
    
    const getPageMargins = () => {
        return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    };

    const componentRef = useRef()

    const handlePrint = useReactToPrint({
    content:()=> componentRef.current,
    documentTitle: 'Repocession',
    onAfterPrint:()=>closeRepoCharge()
    })

    let today = new Date().toUTCString().slice(5, 16);
    if(!repo_charge) return null;
    return(
    <div className='overlay' onClick={closeRepoCharge} >
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className = "card card-primary" >
        <div className="card-header" >
        <span className='closeModal btn btn-sm btn-default' onClick={handlePrint} style={{margin:'2px'}} > <span className='fa fa-print' ></span> Print Repocession </span>
        </div>
            
        <div className="card-body">

            <div ref={componentRef} style={{width: '100%', fontSize:'22px'}} >
            <style>{getPageMargins()}</style>
            <Container>
            <Row className="mt-5" style={{height:'110px'}}>
            <Col>
            <div>
            
            </div>

            <div className="mt-3" >
            <h6><b></b></h6>
            </div>

            </Col>

            <Col style={{textAlign:'right'}} >
            
            </Col>
            </Row> 


            <Row>
                <Col style = {{fontSize:'15px'}} >
                <div >
                <Stack gap={1} >
                <div>Date: {dueDate}</div>
                <div>Our Ref: {details.loan_code}</div>
                <div>Your Ref: T.B.A</div>
                <div>JULIUS KIMANI</div>
                <div>extramileauctioneers@gmail.com</div>
                <div>0725424007</div>
                <div>EXTRA MILE AUCTIONEERS</div>
                <div>10056-00200</div>
                <div>NAIROBI</div>
                </Stack>

                <p style={{marginTop:'18px'}} >Dear Sir / Madam</p>

                <p>
                <b>                    
                <u>
                RE: REPOCESSION ORDER FOR BORROWER {client.first_name.toUpperCase()} {client.other_name.toUpperCase()} {client.last_name.toUpperCase()}
                </u>
                </b>
                </p>

                <p>
                We refer to the above,
                </p>

                <p style={{textAlign:"justify"}} >
                Kindly note that the above mentioned client whom we refer to as a borrower is in breach of our loan contract dated <b>{details.date_disbursed.substr(0,10)}</b> and has so far neglected and/or ignored our various correspondences.
                </p>

                <p>
                    The borrower has a debt balance of <b>KSH {parseInt(details.balance).toLocaleString('en-US')}</b> as at <b>{dueDate}</b>
                </p>

                <p style={{textAlign:"justify"}} >
                    We do hereby authorize you to repocess the following vehicle (s) from our client <b>{client.first_name} {client.other_name} {client.last_name}</b> as per the terms and conditions of the loan agreement and the chattel instrument duly executed by the borrower.
                </p>

                <Table striped bordered hover className="mt-3" >
                <thead>
                    <tr style={{fontWeight:'bold', fontSize:'14px'}} >
                        <td>#</td>
                        <td>Registration</td>
                        <td>Make</td>
                        <td>Model</td>
                        <td>Year</td>
                        <td>Color</td>
                        <td>Engine</td>
                        <td>Chasis</td>
                        <td>Log Book</td>
                    </tr>
                </thead>
                <tbody>
                    {vehicles.map((item,index)=>{
                        return(
                            <tr style={{fontSize:'14px'}}>
                                <td>{index+1}</td>
                                <td>{item.registration}</td>
                                <td>{item.make}</td>
                                <td>{item.model}</td>
                                <td>{item.year}</td>
                                <td>{item.year}</td>
                                <td>{item.engine_no}</td>
                                <td>{item.chasis_no}</td>
                                <td>{item.log_book}</td>
                            </tr>
                        )
                    })}
                </tbody>
                </Table>

                <p>
                    This repocession is due to <b>Loan Balance Arrears.</b>
                </p>

                <p>
                    The above borrower contact is <b>{client.phone}</b>, home is located in <b>{client.city}</b> area. Please contact the tracking company for location assistance to assist in pursuing the borrower.
                </p>

                <p>Attached please find a copy of log book to assist in the repocession process.</p>

                <p>
                Yours faithfully
                </p>

                <Stack gap={1}>
                <div><b>Risk and Operations Department</b></div>
                <div><b>risk@northwavecredit.com</b></div>
                <div><b>Northwave Credit Limited</b></div>
                </Stack>

                </div>
                </Col>
            </Row>

            </Container>       

        </div>

        </div>

        <div className='card-footer' >        
        </div>

        </div>   

        </div>
    </div>
    );
}

export default PrintRepocession;