import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

import Stage_One from "./Stage_One";
import Stage_Two from './Stage_Two';
import Stage_Three from './Stage_Three';
import Stage_Four from './Stage_Four';
import Previous from './Previous';
import Next from './Next';
import ContactHeader from './ContactHeader';

import FetchStage from './FetchStage';

const NewContactModal = ({open,closeModal,fetchContacts}) =>{

    const [stage,setStage] = useState(1)            // Keep track of current contact stage
    const [idError,setIdError] = useState('')
    const [kraError,setKraError] = useState('')
    const [permitError,setPermitError] = useState('')
    const [resolutionError,setResolutionError] = useState('')
    const [contactId,setContactId] = useState(0)
    const [documentsPath,setDocumentsPath] = useState('')

    const [stageOne,setOne] = useState({            // Keep track of stage one entries
        id:contactId,
        id_no: '',
        initials:'Mr',
        first_name:'',
        last_name: '',
        other_name:'',
        phone: '',
        email:'',
        city:'',
        postal_address:'',
        huduma_no:'',
        country:'Kenya',
        annual_turnover:0,
        hcs:'',
        branch:'Nairobi',
        industry:'',
        status:'Warm',
        source:'',
        error_list: [],
    })

    const [stageTwo,setTwo] = useState({            // Keep track of stage two entries
        set_id:0,
        contact: contactId,
        name:'',
        phone:'',
        email:'',
        id:'',
        huduma:'',
        physical_address:'',
        relation:'',
        error_list: [],
    })

    const [stageThree,setThree] = useState({        // Keep track of stage three entries
        contact: contactId,
        info_id:0,
        id:'',
        kra:'',
        id_key:0,
        kra_key:0,
        error_list: [],
    })

    const [stageFour,setFour] = useState({          // Keep track of stage four entries
    documents_id:0,
    id:null,
    kra:null,
    permit: null,
    resolution:null,
    error_list: [],
    })

    const previousPage = ()=>{                      // Function to move to the previous stage
        setStage(stage-1)
    }

    const nextPage = ()=>{                          // Function to move to the next stage
    
        // Action if stage is one
       if(stage === 1)
       {
        verifyPersonal(stageOne,stage)
       }

       // Action if stage is two
       if(stage === 2)
       {
        verifyKin(stageTwo,stage)
       }

       // Action if stage is three
       if(stage === 3)
       {
        verifyKYC(stageThree,stage)
       }

       // Action if stage is four
       if(stage === 4)
       {
        uploadKYC(stageFour,stage)
       }

    }

    const handleStageOne=(e)=>{                     // Function to handle change on stage one entries

        e.persist();
        setOne({...stageOne, [e.target.name]: e.target.value})

    }

    const handleStageTwo=(e)=>{                     // Function to handle change on stage two entries

        e.persist();
        setTwo({...stageTwo, [e.target.name]: e.target.value})

    }

    const handleStageThree=(e)=>{                     // Function to handle change on stage three entries

        e.persist();
        setThree({...stageThree, [e.target.name]: e.target.value})

    }

    const handleStageFour=(e)=>{                     // Function to handle change on stage four entries

        setFour({...stageFour, [e.target.name]: e.target.files[0]})

    }

    const verifyPersonal =(stageOne,stage)=>{       // Function to verify stage one entries

        const data = {
            id: contactId,
            id_no: stageOne.id_no,
            initials: stageOne.initials,
            first_name: stageOne.first_name,
            last_name: stageOne.last_name,
            other_name: stageOne.other_name,
            phone: stageOne.phone,
            email: stageOne.email,
            city: stageOne.city,
            postal_address: stageOne.postal_address,
            huduma: stageOne.huduma_no,
            country: stageOne.country,
            annual_turnover: stageOne.annual_turnover,
            hcs: stageOne.hcs,
            branch: stageOne.branch,
            industry: stageOne.industry,
            status: stageOne.status,
            source: stageOne.source,
            relation_officer: localStorage.getItem('auth_id'),
        }

        axios.post(`api/personal_verification`,data).then((res)=>{

            if(res.data.status === 200)
            {
                swal('Success',res.data.message,"success")
                setStage(stage+1)

                if(res.data.operation === "create")
                {
                    setContactId(res.data.id)
                    stageOne.id = res.data.id
                    stageTwo.contact = res.data.id
                    setDocumentsPath(res.data.documents_path)
                    fetchContacts()
                }
                else
                {
                    fetchContacts() 
                }

            }else if(res.data.status === 403){
                swal('Error',res.data.message,"error")
            }
            else{
                setOne({...stageOne, error_list: res.data.validation_errors})
            }

        })

    }

    const verifyKin = (stageTwo,stage) =>{          // Function to verify kin information

        const data = {
            set_id: stageTwo.set_id,
            contact: stageTwo.contact,
            name:stageTwo.name,
            phone: stageTwo.phone,
            email: stageTwo.email,
            id_no: stageTwo.id,
            huduma: stageTwo.huduma,
            physical_address: stageTwo.physical_address,
            relation: stageTwo.relation,
        }

        axios.post(`api/kin_verification`,data).then((res)=>{

            if(res.data.status === 200)
            {
                swal('Success',res.data.message,"success")
                setStage(stage+1)

                if(res.data.operation === "create")
                {
                    stageTwo.set_id = res.data.kin_id
                    stageThree.contact = contactId
                }

            }
            else
            {
                setTwo({...stageTwo, error_list: res.data.validation_errors})
            }

        })

    }

    const verifyKYC = (stageThree,stage) =>{          // Function to verify KYC information

    const data = {
        document_path: documentsPath,
        contact: stageThree.contact,
        info_id: stageThree.info_id,
        id: stageThree.id,
        kra: stageThree.kra,
        id_key:stageThree.id_key,
        kra_key:stageThree.kra_key
    }

    axios.post(`api/kyc_verification`,data).then((res)=>{

        if(res.data.status === 200)
        {
            swal('Success',res.data.message,"success")
            setStage(stage+1)

            if(res.data.operation === "create")
            {
                stageThree.info_id = res.data.info_id
                stageThree.id_key = res.data.id_key
                stageThree.kra_key = res.data.kra_key
            }
            else
            {
                
            }

        }
        else
        {
            setThree({...stageThree, error_list: res.data.validation_errors})
        }

    })

    }

    const uploadKYC=(stageFour)=>{          // Function to upload documents
    
    var proceed = true

    for(const key in stageFour)
    {
        if(stageFour[key] === null)
        {
            
            if(key === "id")
            {
                setIdError('This file is required !')
                proceed = false
            }

            if(key === "kra")
            {
                setKraError('This file is required !')
                proceed = false
            }

            if(key === "resolution")
            {
                setResolutionError('This file is required !')
                proceed = false
            }

        }
        else
        {
            if(key === "id")
            {
                setIdError('')
            }

            if(key === "kra")
            {
                setKraError('')
            }


            if(key === "resolution")
            {
                setResolutionError('')
            }
        }
    }

    if(proceed)
    {
        const formData = new FormData()

        formData.append('id',stageFour.id,stageFour.id.name)
        formData.append('kra',stageFour.kra,stageFour.kra.name)
        formData.append('resolution',stageFour.resolution,stageFour.resolution.name)
        formData.append('contact',contactId)
        formData.append('documents_id',stageFour.documents_id)

        axios.post(`api/uploadKyc`,formData).then((res)=>{

            if(res.data.status === 200)
            {
                swal('Success',res.data.message,"success")
                clearData()
                fetchContacts()
            }
            else
            {
                swal('Warning','File upload failed',"warning")
            }
    
        })

    }

    }

    const clearData=()=>{

        setOne({            // Clear stage one entries
                id:0,
                id_no: '',
                initials:'Mr',
                first_name:'',
                last_name: '',
                other_name: '',
                phone: '',
                email:'',
                city:'',
                postal_address:'',
                huduma_no:'',
                country:'Kenya',
                annual_turnover:0,
                hcs:'',
                branch:'Nairobi',
                industry:'',
                status:'Warm',
                source:'',
                error_list: [],
            })

        setTwo({            // clear stage two entries
            set_id:0,
            contact: 0,
            name:'',
            phone:'',
            email:'',
            id:'',
            huduma:'',
            physical_address:'',
            relation:'',
            error_list: [],
        })

        setThree({        // clear stage three entries
            contact: 0,
            info_id:0,
            document_type:'',
            document_number:'',
            id:'',
            kra:'',
            error_list: [],
    })

    setFour({          // clear stage four entries
        documents_id:0,
        id:null,
        kra:null,
        permit: null,
        resolution:null,
        error_list: [],
        })

        setIdError('')
        setKraError('')
        setPermitError('')
        setResolutionError('')
        setContactId(0)
        setDocumentsPath('')
        closeModal()
        setStage(4)

    }


    if(!open) return false;
    
    return(
    <div className='overlay' onClick={closeModal} >
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className = "card card-primary" >

        <div className="card-header" >
        <div className="row" >
        <ContactHeader stage={stage} />
        </div>
        </div>

        <div className="card-body login-card-body">
        <FetchStage stageOne={stageOne} stageTwo={stageTwo} stageThree={stageThree} stageFour={stageFour} Stage_One={Stage_One} Stage_Two={Stage_Two} Stage_Three={Stage_Three} Stage_Four={Stage_Four} stage={stage} handleStageOne={handleStageOne} handleStageTwo={handleStageTwo} handleStageThree={handleStageThree} handleStageFour={handleStageFour} idError={idError} permitError={permitError} kraError={kraError} resolutionError={resolutionError}  />
        </div>

        <div className='card-footer'>
        <Previous stage={stage} previousPage={previousPage} />
        <Next stage={stage} nextPage={nextPage} />
        </div>

        </div>
        </div>
    </div>
    );
}

export default NewContactModal;