import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

function ChangePassword(){

    const effectRan = useRef(false)
    const navigate = useNavigate()

    const [details,setDetails] = useState({
        current:'',
        password:'',
        confirm: '',
        validation_errors: []
    })

    const handleDetails=(e)=>{
        setDetails({...details, [e.target.name]: e.target.value})
    }

    const updatePassword=(e)=>{
        e.preventDefault()

        const data = {
            current: details.current,
            password: details.password,
            confirm: details.confirm
        }

        axios.post(`api/change_password`,data).then((res)=>{

            if(res.data.status === 200)
            {
                swal('Success',res.data.message,'success')
                navigate('/login')
            }else if(res.data.status === 401)
            {
                swal('Warning',res.data.message,'warning')
            }
            else
            {
                setDetails({...details, validation_errors: res.data.validation_errors})
            }

        })
    }

    useEffect(()=>{
        
        if(effectRan.current === false)
        {
           
        }

        return()=>{
        effectRan.current = true
        }

    },[])

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5> <span className='fa fa-user-shield'></span> Change password</h5>
                </div>
    
              
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >
                <div className='row'>
                <div className='col-md-12'>
                
                <div className='card card-primary card-outline'>
                    <div className='card-body'>
                       
                       <form className='form' method="post" onSubmit={updatePassword}>
                       <div className='form-group '>
                            <label> <span className='fa fa-lock'></span> Current password</label>
                            <input type="password" className='form-control' name='current' value={details.current} onChange={handleDetails} ></input>
                            <span className='error_holder' >{details.validation_errors.current}</span>
                        </div>

                        <div className='form-group '>
                            <label> <span className='fa fa-lock'></span> New password</label>
                            <input type="password" className='form-control'  name='password' value={details.password} onChange={handleDetails} ></input>
                            <span className='error_holder' >{details.validation_errors.password}</span>
                        </div>

                        <div className='form-group '>
                            <label> <span className='fa fa-lock'></span> Confirm password</label>
                            <input type="password" className='form-control'  name='confirm' value={details.confirm} onChange={handleDetails} ></input>
                            <span className='error_holder' >{details.validation_errors.confirm}</span>
                        </div>

                        <div className='form-group '>
                            <input type="submit" className='btn btn-primary btn-sm' value='Change password'></input>
                        </div>
                       </form>

                    </div>
                    <div className='card-footer'></div>
                </div>

                </div>
                </div>
                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default ChangePassword;