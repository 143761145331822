import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

const PaydayStaff=()=>{

    const [staff,setStaff] = useState([])

    const fetchStaff=()=>{
        axios.post(`api/payday_staff`).then((res)=>{
            if(res.data.status === 200)
            {
                setStaff(res.data.staff)
            }
        })
    }

    useState(()=>{
    fetchStaff()
    },[])

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5><span className='fa fa-users'></span>  Payday Staff</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='row'>
                <div className='col-md-12'>
                
                <div className='card card-primary card-outline'>
                <div className='card-body'>
                <table className='table'>
                <thead>
                <tr className='small-row' >
                <th>#</th>
                <th>Organization</th>
                <th>Staff</th>
                <th>ID Number</th>
                <th>Payroll</th>
                <th>Phone</th>
                <th>email</th>
                <th>Role</th>
                </tr>
                </thead>
                <tbody>
                {staff.map((item,index)=>{
                return(
                    <tr className='small-row' key={index} >
                        <td>{index+1}</td>
                        <td>{item.name}</td>
                        <td>{item.first_name} {item.other_name} {item.last_name} </td>
                        <td>{item.id_number}</td>
                        <td>{item.payroll}</td>
                        <td>{item.phone}</td>
                        <td>{item.email}</td>
                        <td>{item.role}</td>
                    </tr>
                )
                })}
                </tbody>
                </table>
                </div>
                </div>

                </div>
                </div>

                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default PaydayStaff;