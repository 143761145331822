import { Link } from "react-router-dom";

const PermissionNav=({name,page,permissions,allowed})=>{

    if(permissions.includes(allowed))
    {
        return(
            <li className="nav-item">
            <Link to = {page} className="nav-link">
            <i className="nav-icon fas fa-layer-group"></i>
            <p>{name}</p>
            </Link>
            </li>
        )
    }
    else
    {
        return(<></>)
    }

}

export default PermissionNav;