import React, { useEffect, useState } from 'react';
import axios from "axios";

const Stage_One = ({stageOne,handleStageOne})=>{

    const [users,setUsers] = useState([])

    useEffect(()=>{
    
        axios.post(`api/users`).then((res)=>{

            if(res.data.status === 200)
            {
                setUsers(res.data.users)
            }
    
        })

    },[])


    return(
        <div className="row" >

            <div className="col-md-3 form-group" >
                <label>Initials <span className='red' >*</span></label>
                <select className="form-control" name = "initials" value={stageOne.initials} onChange={handleStageOne}>
                <option>Mr</option>
                <option>Ms</option>
                </select>
                <span className="error_holder"  >{stageOne.error_list.initials}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>First Name <span className='red' >*</span></label>
                <input type = "text" className="form-control" name="first_name" value={stageOne.first_name} onChange={handleStageOne} />
                <span className="error_holder"  >{stageOne.error_list.first_name}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>Other Name <span className='red' >*</span></label>
                <input type = "text" className="form-control" name="other_name" value={stageOne.other_name} onChange={handleStageOne} />
                <span className="error_holder"  >{stageOne.error_list.other_name}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>Last Name <span className='red' >*</span></label>
                <input type = "text" className="form-control" name="last_name" value={stageOne.last_name} onChange={handleStageOne} />
                <span className="error_holder"  >{stageOne.error_list.last_name}</span>
            </div>


            <div className="col-md-3 form-group" >
                <label>Phone <span className='red' >*</span></label>
                <input type = "text" className="form-control" name ="phone" value={stageOne.phone} onChange={handleStageOne} />
                <span className="error_holder"  >{stageOne.error_list.phone}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>Email <span className='red' >*</span></label>
                <input type = "text" className="form-control" name="email" value={stageOne.email} onChange={handleStageOne} />
                <span className="error_holder"  >{stageOne.error_list.email}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>City / Town <span className='red' >*</span></label>
                <input type = "text" className="form-control" name="city" value={stageOne.city} onChange={handleStageOne} />
                <span className="error_holder"  >{stageOne.error_list.city}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>Postal Address <span className='red' >*</span></label>
                <input type = "text" className="form-control" name="postal_address" value={stageOne.postal_address} onChange={handleStageOne} />
                <span className="error_holder"  >{stageOne.error_list.postal_address}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>ID No <span className='red' >*</span></label>
                <input type = "text" className="form-control" name="id_no"  value={stageOne.id_no} onChange={handleStageOne} />
                <span className="error_holder"  >{stageOne.error_list.id_no}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>Huduma No <span className='red' >*</span></label>
                <input type = "text" className="form-control" name="huduma_no"  value={stageOne.huduma_no} onChange={handleStageOne} />
                <span className="error_holder"  >{stageOne.error_list.huduma}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>Country <span className='red' >*</span></label>
                <input type = "text" className="form-control" name = "country" value={stageOne.country} onChange={handleStageOne} />
                <span className="error_holder"  >{stageOne.error_list.country}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>Annual Turnover <span className='red' >*</span></label>
                <input type = "number" className="form-control" name="annual_turnover" value={stageOne.annual_turnover} onChange={handleStageOne} />
                <span className="error_holder"  >{stageOne.error_list.annual_turnover}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>HCS / Team Leader <span className='red' >*</span></label>
                <select className="form-control" name="hcs" value={stageOne.hcs} onChange={handleStageOne} >
                <option value = "not set">Select HCS </option>
                {users.map((person,index)=>{
                    return(<option value={person.id} key={index}>{person.name}</option>)
                })}
                </select>
                <span className="error_holder"  >{stageOne.error_list.hcs}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>Branch <span className='red' >*</span></label>
                <select className="form-control" name="branch" value={stageOne.branch} onChange={handleStageOne} >
                <option>Nairobi</option>
                <option>Mombasa</option>
                </select>
                <span className="error_holder"  >{stageOne.error_list.branch}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>Industry / Market <span className='red' >*</span></label>
                <input type = "text" className="form-control" name="industry" value={stageOne.industry} onChange={handleStageOne} />
                <span className="error_holder"  >{stageOne.error_list.industry}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>Status <span className='red' >*</span></label>
                <select className="form-control" name="status" value={stageOne.status} onChange={handleStageOne} >
                <option>Hot</option>
                <option>Warm</option>
                <option>Cold</option>
                </select>
                <span className="error_holder"  >{stageOne.error_list.status}</span>
            </div>

            <div className="col-md-3 form-group" >
                <label>Source <span className='red' >*</span></label>
                <input type = "text" className="form-control" name="source" value={stageOne.source} onChange={handleStageOne} />
                <span className="error_holder"  >{stageOne.error_list.source}</span>
            </div>

        </div>
    )

}

export default Stage_One;