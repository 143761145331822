import kra from './../../../../kra.jpg';
import shield from './../../../../shield.jpg';
const Stage_Four = ({handleStageFour,idError,kraError,resolutionError}) =>{

    return (
        <div className="row" >

        <div className="col-md-12" >

        <div className='form-group' >
        <label><img src={shield} className="shield" /> ID / Passport Scan <span className='red' >*</span></label>
        <input type = "file" className="form-control" name = "id" onChange={handleStageFour}  />
        <span className="error_holder"  >{idError}</span> 
        </div>   
        
        <div className='form-group' >
        <label><img src={kra} className="kra_image" />  KRA Pin Scan <span className='red' >*</span></label>
        <input type = "file" className="form-control" name = "kra" onChange={handleStageFour}   />
        <span className="error_holder"  >{kraError}</span>
        </div>            

        <div className='form-group' >
        <label><span className="fa fa-file-invoice" ></span> Resolution to borrow Scan <span className='red' >*</span></label>
        <input type = "file" className="form-control" name = "resolution" onChange={handleStageFour}  />
        <span className="error_holder"  >{resolutionError}</span>
        </div>
       
            
        </div>
        </div>
    )

}

export default Stage_Four;