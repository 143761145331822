import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

import UpdateRepocession from '../elements/modals/vehicles/UpdateRepocession';
import PrintRepocessions from '../elements/print/PrintRepocessions';

function Repocessions(){

    const effectRan = useRef(false)
    const [entries,setEntries] = useState([])
    const [open,setOpen] = useState(false)
    const [searchStatus,setStatus] = useState('All')

    const [print,setPrint] = useState(false)

    const closePrint=()=>{
        setPrint(false)
    }
    


    const handleStatus=(e)=>{
        setStatus(e.target.value)
    }

    const searchRepocession=(e)=>{
        e.preventDefault()

        const data = {
            status:searchStatus
        }

        axios.post(`api/search_repocession`,data).then((res)=>{
            setEntries(res.data.entries)
        })
    }

    const [details,setDetails] = useState({
        car:'',
        status:'',
        market_value:'',
        forced_value:'',
        auction_date:'',
        auctioneer:'',
        error_list:[]
    })

    const setupDetails=(id)=>{

        setDetails({...details, 
            car:entries[id].vehicle_id,
            status:entries[id].status,
            market_value: entries[id].mv,
            forced_value: entries[id].fv,
            auctioneer: entries[id].auctioneer,
            auction_date: entries[id].auction_date
        })

        setOpen(true)
    }

    const updateRepo=(e)=>{

        e.preventDefault()

        const data = {
            car: details.car,
            status: details.status,
            market_value: details.market_value,
            forced_value: details.forced_value,
            auctioneer: details.auctioneer,
            auction_date: details.auction_date
        }

        axios.post(`api/update_repo`,data).then((res)=>{

            if(res.data.status == 200)
            {
                swal("Success",res.data.message,"success")
                onClose()
                fetchEntries()
            }

        })

    }

    const handleDetails=(e)=>{
        setDetails({...details, [e.target.name]: e.target.value})
    }

    const onClose=()=>{

        setOpen(false)

        setDetails({
            car:'',
            status:'',
            market_value:'',
            forced_value:'',
            auction_date:'',
            auctioneer:'',
            error_list:[]
        })

    }

    const fetchEntries=()=>{
        axios.get(`api/repocessions`).then((res)=>{
            if(res.data.status == 200)
            {
                setEntries(res.data.entries)
            }
        })
    }

    useEffect(()=>{
        
        if(effectRan.current === false)
        {
            fetchEntries()
        }

        return()=>{
        effectRan.current = true
        }

    },[])

    return (
        <div className="wrapper">
            <PrintRepocessions open={print} onClose={closePrint} statement={entries}></PrintRepocessions>
            <UpdateRepocession open = {open} onClose={onClose} details={details} handleDetails={handleDetails} updateRepo={updateRepo}></UpdateRepocession>
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5></h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card card-primary card-outline'>                            
                        <div className='card-header' >
                        <h5>Repocessions summary</h5>
                        </div>
                        <div className='card-body' >

                            <div className='row' style={{marginBottom:'20px'}} >

                            <form className='form' method = "post" onSubmit={searchRepocession} >

                            <div className='row'>

                            <div className='col-md-8 '>
                                    <select className='form-control' value={searchStatus} onChange={handleStatus}>
                                        <option>All</option>
                                        <option>Issued</option>
                                        <option>At the yard</option>
                                        <option>Auction Valuation</option>
                                        <option>Tracker removed</option>
                                        <option>Sold</option>
                                    </select>
                                </div>

                                <div className='col-md-4'>
                                    <input type = "submit" value = "Search" className='btn btn-sm btn-success'></input>
                                    <span className='btn btn-sm btn-success m-1' onClick={()=>setPrint(true)} > <span className='fa fa-print'></span> Print Report</span>
                                </div>

                            </div>

                            </form>

                            </div>

                            <table className='table'>
                                <thead>
                                    <tr className='small-row' >
                                    <td>#</td>
                                    <td><b>Issued on</b></td>
                                    <td><b>Loan</b> </td>
                                    <td><b>Client</b></td>
                                    <td><b>Vehicle</b></td>
                                    <td><b>Item</b></td>
                                    <td><b>Loan Balance</b></td>
                                    <td><b>M.V</b></td>
                                    <td><b>FSV</b></td>
                                    <td><b>Status</b></td>
                                    <td><b>Auction Date</b></td>
                                    <td><span className='fa fa-edit'></span>  Edit</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {entries.map((item,index)=>{
                                        return(
                                            <tr className="small-row" key={index} >
                                                <td>{index+1}</td>
                                                <td>{item.created_at}</td>
                                                <td>{item.loan_code}</td>
                                                <td>{item.first_name} {item.last_name}</td>
                                                <td>{item.registration}</td>
                                                <td>{item.description}</td>
                                                <td>KES {parseInt(item.balance).toLocaleString('en-US')}</td>
                                                <td>{item.mv}</td>
                                                <td>{item.fv}</td>
                                                <td>{item.status}</td>
                                                <td>{item.auction_date}</td>
                                                <td><span onClick={()=>setupDetails(index)} ><span className='fa fa-edit'></span>  Edit</span>  </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        </div>
                        
                    </div>
                </div>
                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default Repocessions;