const LoanCollaterals=({collaterals})=>{

return (
    <div className="card card-primary">

    <div className="card-header">
    <h5><span className="fa fa-car"></span>  Collaterals</h5>
    </div>

    <div className="card-body">
    <table className="table">
    <thead>
    <tr className="small-row" >
    <th>#</th>
    <th>Registration</th>
    <th>Make</th>
    <th>Model</th>
    <th>Year</th>
    <th>Color</th>
    <th>Transmission</th>
    <th>Log Book</th>
    </tr>
    </thead>

    <tbody>
    {collaterals.map((collateral,index)=>{
    return (
    <tr key={index} className='small-row' >
    <td>{index+1}</td>
    <td>{collateral.registration}</td>
    <td>{collateral.make}</td>
    <td>{collateral.model}</td>
    <td>{collateral.year}</td>
    <td>{collateral.color}</td>
    <td>{collateral.transmission}</td>
    <td>{collateral.log_book}</td>
    </tr>
    )
    })}
    </tbody>

    </table>
    </div>

    <div className="card-footer"></div>
    </div>
)

}

export default LoanCollaterals;