import { Link } from "react-router-dom";
import RoleNav from "./RoleNav";
const ReportMenu=()=>{

    const permissions = JSON.parse(localStorage.getItem('auth_permissions'));
    const permission = "access_reports";

    if(permissions.includes(permission))
    return(
        <li className="nav-item has-treeview">

        <a href="#" className="nav-link">
        <i className="nav-icon fa fa-chart-line"></i>
        <p>
        Reports
        <i className="fas fa-angle-right right"></i>
        </p>
        </a>

        <ul className="nav nav-treeview">

        <li className="nav-item">
        <Link to = '/aging' className="nav-link">
        <i className="far fa-circle nav-icon"></i>
        <p>Aging Report</p>
        </Link>
        </li>

        <RoleNav page="Loan Income" link="/loan_income" icon="nav-icon far fa-circle" roles={['CEO','System Administrator']} />
        <RoleNav page="Payday Income" link="/payday_income" icon="nav-icon far fa-circle" roles={['CEO','System Administrator']} />
        <RoleNav page="Loans Disbursment" link="/disbursment_report" icon="nav-icon far fa-circle" roles={['CEO','System Administrator']} />
        <RoleNav page="Payday Disbursment" link="/payday_disbursment" icon="nav-icon far fa-circle" roles={['CEO','System Administrator']} />
        <RoleNav page="Expenditure Report" link="/expenditure_report" icon="nav-icon far fa-circle" roles={['CEO','System Administrator']} />

        <li className="nav-item">
        <Link to = '/valuations_report' className="nav-link">
        <i className="far fa-circle nav-icon"></i>
        <p>Valuations Report </p>
        </Link>
        </li>

        </ul>

        </li>
    )
}

export default ReportMenu;