function ControlSidebar(){
    return (
      <aside className="control-sidebar control-sidebar-dark">
      <div className="p-3">
      <h5>Title</h5>
      <p>Sidebar content</p>
      </div>
      </aside>

    );
}

export default ControlSidebar;