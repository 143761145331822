import { Link } from "react-router-dom";

function StaffTable({users,lowerLimit,upperLimit,editUser}){

    return(
        <table className="table" id="example1">

    <thead>
    <tr style= {{fontWeight : 'bold', fontSize: '13px'}} >
    <td>#</td>
    <td>Name</td>
    <td>Phone</td>
    <td>Email</td>
    <td>Department</td>
    <td>Role</td>
    <td> <span className="fa fa-edit" ></span> </td>
    <td > <span className="fa fa-eye"></span></td>
    </tr>
    </thead>

    <tbody id="users_data">
    
   { users.map((val,index)=>{

let marker = index+1

if(marker >= lowerLimit && marker <= upperLimit)
{
return (
    <tr key={val.id} style={{fontSize:'13px'}}> 
        <td>{index+1}</td>
        <td>{val.name}</td>
        <td>{val.phone}</td>
        <td>{val.email}</td>
        <td>{val.department}</td>
        <td>{val.role}</td>
        <td><span className='fa fa-edit' onClick={()=>editUser(index)}></span></td>
        <td> <Link to={`/user/${val.id}`} > <span className="fa fa-eye"></span> </Link> </td>
        </tr>
);
}


})}

    </tbody>

    </table> 
    );

}

export default StaffTable;