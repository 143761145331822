import Vehicles from "./Vehicles";
import CustomerValuations from "./CustomerValuations";
import CustomerLoans from "./CustomerLoans";
import CustomerInsurance from "./CustomerInsurance";
import Statement from "./Statement";

const SummaryHolder=({summaryTab,setVehicleFlag,vehicles,fetchVehicleDetails,fetchCarDetails,setValuationFlag,valuations,fetchValuationDetails,updateValuation,editValuationFlag,startLoan,setInsuranceFlag,policies,updateInsurance,fetchPolicies,loans,viewLoan,Settlements,mpesa,handleMpesa,saveMpesa,bankAccount,handleBank,saveBank,statement,profile})=>{

    if(summaryTab === "vehicles")
    {
        return(<Vehicles setVehicleFlag={setVehicleFlag} vehicles={vehicles}  fetchVehicleDetails={fetchVehicleDetails} fetchCarDetails={fetchCarDetails} profile={profile} />)
    }else if(summaryTab === "valuations")
    {
        return(<CustomerValuations setValuationFlag={setValuationFlag} valuations={valuations} fetchValuationDetails={fetchValuationDetails} updateValuation={updateValuation}  profile={profile} />)
    }else if(summaryTab === "loans")
    {
        return (<CustomerLoans startLoan={startLoan} loans={loans} viewLoan={viewLoan}  profile={profile} />)
    }else if(summaryTab === "insurance")
    {
        return(<CustomerInsurance setInsuranceFlag={setInsuranceFlag} policies={policies} updateInsurance={updateInsurance} fetchPolicies={fetchPolicies}  profile={profile} />)
    }else if(summaryTab === "settlements")
    {
        return(<Settlements mpesa={mpesa} saveMpesa={saveMpesa} handleMpesa={handleMpesa} bankAccount={bankAccount} handleBank={handleBank} saveBank={saveBank}  profile={profile} />)
    }else if(summaryTab === "statement")
    {
        return (<Statement statement={statement}  profile={profile} />)
    }

}
export default SummaryHolder;