
const CommentsModal = ({open,onClose,handleComment,commentDetails,comments,saveComment, DateDecoration}) =>{
    if(!open) return null;
    return(
    <div className='overlay' onClick={onClose}>
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className = "card card-primary" >
        <div className="card-header" ><span className='fa fa-comments' ></span> Comments on leads
        <span className='closeModal btn btn-sm btn-default' onClick={onClose}> <span className='fa fa-times-circle' ></span> Close</span>
        </div>
            
        <div className="card-body login-card-body">

        <div className="direct-chat-messages">

        {comments.map((val,index)=>{

          if(val.comment_by == localStorage.getItem('auth_id'))
          {
          return(<div className="direct-chat-msg left" key={index} style={{marginBottom:'25px'}} >
                      <div className="direct-chat-infos clearfix">
                      <span className="direct-chat-name float-left"> <span className='fa fa-circle' style={{color:'green'}} ></span> <b>{val.name}</b></span>
                      <span className="direct-chat-timestamp float-right">{DateDecoration(val.created_at)}</span>
                      </div>
                      
                      <img className="direct-chat-img" src="dist/img/user1-128x128.jpg" alt="message user image" />
                      <div className="direct-chat-text">{val.comment}</div>
                      
                      </div> )
          }else{
            return(<div className="direct-chat-msg right" key={index}  style={{display:'block', clear:'both', width:'100%', marginBottom:'15px'}} >
              <div></div>
                      <div className="direct-chat-infos clearfix" >
                      <span className="direct-chat-name float-right"><b>{val.name}</b></span>
                      <span className="direct-chat-timestamp float-left">{DateDecoration(val.created_at)}</span>
                      </div>
                      
                      <img className="direct-chat-img" src="dist/img/user1-128x128.jpg" alt="message user image" />
                      <div className="direct-chat-text bg-success">{val.comment}</div>
                      
                      </div> )
          }
 
          })}                             

        </div>

        </div>

        <div className='card-footer' >
        
        <form action="#" method="post"  onSubmit={saveComment}>

        <div className="input-group">
        <input type="text" name="comment" placeholder="Type Message ..." className="form-control" onChange={handleComment} />
       
        <span className="input-group-append">
        <button type="submit" className="btn btn-primary">Send</button>
        </span>
        </div>

        <div>
        <span className="error_holder" >{commentDetails.error_list.comment}</span>
        </div>

        </form>

        </div>

        </div>
   

        </div>
    </div>
    );
}

export default CommentsModal;