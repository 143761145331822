import axios from "axios";
import swal from "sweetalert";

const ApproveAction=({loan,approver,fetchApprovals})=>{

    
    const takeAction=(status)=>{
    
    const data = {
    id:approver.id,
    approver:localStorage.getItem('auth_id'),
    status:status,
    }

    axios.post(`api/take_action`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        swal("Success",res.data.message,"success")
        fetchApprovals()
    }else
    {
        swal("Warning",res.data.message,"warning")
    }

    })

    }

    if(approver.approver !== null)
    {
        if(localStorage.getItem('auth_id') == approver.approver)
    {
        return(
            <div className="card card-primary card-outline">
    
            <div className="card-header">
            <h5><span className="fa fa-folder"></span> <span className="fa fa-user-shield"></span> Action required</h5>
            </div>
            
            <div className="card-body">
            <span className="btn btn-sm btn-success" style={{margin:'5px'}} onClick={()=>takeAction('approved')} ><span className="fa fa-user-shield"></span>  Approve Loan</span>
            <span className="btn btn-sm btn-dark" style={{margin:'5px'}} onClick={()=>takeAction('deffered')} ><span className="fa fa-minus-circle"></span> Deffer Loan</span>
            <span className="btn btn-sm btn-danger" style={{margin:'5px'}} onClick={()=>takeAction('rejected')} ><span className="fa fa-user-shield"></span>  Reject Loan</span>
            
            </div>
            
            <div className="card-footer">
            </div>
            
            </div>
        )
    }
    }

}

export default ApproveAction;