    import { useReactToPrint } from "react-to-print";
    import { useRef } from "react";

    import Table from 'react-bootstrap/Table';
    import Container from 'react-bootstrap/Container';
    import Row from 'react-bootstrap/Row';
    import Col from 'react-bootstrap/Col';
    import Stack  from 'react-bootstrap/Stack';
    import Image  from 'react-bootstrap/Image';
    import logo from '../../../llogo.jpeg'
    
    const PrintAging = ({open,onClose,statement}) =>{
    
    const marginLeft="60px"
    const marginRight="60px"
    const marginTop="10px"
    const marginBottom="10px"
    
    const getPageMargins = () => {
        return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    };

    const componentRef = useRef()

    const handlePrint = useReactToPrint({
    content:()=> componentRef.current,
    documentTitle: 'Aging Report',
    onAfterPrint:()=>onClose()
    })

    let today = new Date().toUTCString().slice(5, 16);
    if(!open) return null;
    return(
    <div className='overlay' onClick={onClose} >
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className = "card card-primary" >
        <div className="card-header" >
        <span className='closeModal btn btn-sm btn-default' onClick={handlePrint} style={{margin:'2px'}} > <span className='fa fa-print' ></span> Print Statement </span>
        </div>
            
        <div className="card-body">

        <div ref={componentRef} statement={statement} style={{width: '100%'}} >
        <style>{getPageMargins()}</style>
                
            <Container>
            <Row className="mt-5">
            <Col>
            <div>
            <Image src={logo} style={{width:'250px'}} ></Image>
            </div>

            <div className="mt-3" >
            <h6><b>Aging Report</b></h6>
            </div>

            </Col>

            <Col style={{textAlign:'right'}} >
            <Stack gap={1}>
            <div>Northwave Credit Ltd</div>
            <div>P.O BOX 2760 - 00100</div>
            <div>Nairobi, Kenya</div>
            <div>Tel: 0703999944</div>
            <div>Email: info@northwavecredit.com</div>
            <div>Website: www.northwavecredit.com</div>
            </Stack>
            </Col>
            </Row>       

            <Row className="mt-3" >
           
            <Col>
            <div>
            Auto generated on :  {today}       
            </div>
            <Table striped bordered hover className="mt-3" >
            <thead>
            <tr>
            <th>#</th>
            <th>Client</th>
            <th>Loan</th>
            <th>0-30</th>
            <th>31-90</th>
            <th>91-180</th>
            <th>180-360</th>
            <th>361 > </th>
            <th>Total</th>
            </tr>
            </thead>
            <tbody>
            
            {statement.map((item,index)=>{

            return(
                <tr className='small-row' key={index} >
                    <td>{index+1}</td>
                    <td>{item.customer}</td>
                    <td>{item.loan}</td>
                    <td>{parseInt(item.one).toLocaleString('en-US')}</td>
                    <td>{parseInt(item.two).toLocaleString('en-US')}</td>
                    <td>{parseInt(item.three).toLocaleString('en-US')}</td>
                    <td>{parseInt(item.four).toLocaleString('en-US')}</td>
                    <td>{parseInt(item.five).toLocaleString('en-US')}</td>
                    <td>{parseInt(item.total).toLocaleString('en-US')}</td>
                </tr>
            )

            })}

            </tbody>
            </Table>

            </Col>
            </Row>

            </Container>
       

        </div>
        </div>

        <div className='card-footer' >        
        </div>
        </div>
        </div>
        </div>
    );
}

export default PrintAging;