import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PaydayDisbursmentPrint from '../elements/print/PaydayDisbursmentPrint';

function PaydayDisbursementReport(){

    const effectRun = useRef(false)
    const [disbursments,setDisbursments] = useState([]);
    const [organizations,setOrganizations] = useState([])
    const [staff,setStaff] = useState([])
    const navigate = useNavigate()

    const [details,setDetails] = useState({
    start_date:'2023-01-01',
    end_date:'',
    organization:'All',
    error_list: []
    })

    const handleSearch=(e)=>{
    setDetails({...details, [e.target.name]: e.target.value})
    }

    const [open,setOpen] = useState(false)

    const onClose=()=>{
    setOpen(false)
    }

    const queryDisbursments=()=>{

    const data = {
        start_date: details.start_date,
        end_date: details.end_date,
        organization: details.organization
    }

    axios.post(`api/query_payday_disbursments`,data).then((res)=>{
        if(res.data.status === 200)
        {
            setDisbursments(res.data.disbursments)
            setDetails({...details, error_list: []})
        }
        else
        {
            setDetails({...details, error_list: res.data.validation_errors})
        }
    })
    
    }  

    const fetchDisbursments=()=>{
    
    axios.post(`api/payday_disbursments`).then((res)=>{
        if(res.data.status === 200)
        {
            setDisbursments(res.data.disbursments)
            setDetails({...details, end_date: res.data.current_date})
        }
    }).catch(error=>{
        if(error == "AxiosError: Request failed with status code 403")
        {
          navigate('/unauthorized');
        }
    })

    }

    const fetchOrganizations=()=>{
        axios.post(`api/query_organizations`).then((res)=>{
            if(res.data.status === 200)
            {
                setOrganizations(res.data.organizations)
            }
        }).catch(error=>{
            if(error == "AxiosError: Request failed with status code 403")
            {
              navigate('/unauthorized');
            }
        })
    }

    useEffect(()=>{
    
    if(effectRun.current === false)
    {
    
    fetchDisbursments()
    fetchOrganizations()

    return()=>{
    effectRun.current = true
    }
    }

    })

    return (
        <div className="wrapper">
            <PaydayDisbursmentPrint open={open} onClose={onClose} statement={disbursments} details={details} ></PaydayDisbursmentPrint>
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>Payday Disbursment</h5>
                
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >
                <Row>
                <Col>

                <div className='card card-primary card-outline'>
                <div className='card-header'>
                <h6>
                All payday disbursments
                <span className='btn btn-dark btn-sm float-right m-1' onClick={()=>setOpen(true)} ><span className='fa fa-print'></span> Print Report  </span>
                <span className='btn btn-dark btn-sm float-right m-1' onClick={()=>fetchDisbursments()}><span className='fa fa-eraser'></span> Clear</span>
                <span className='btn btn-dark btn-sm float-right m-1' onClick={()=>queryDisbursments()}><span className='fa fa-search'></span> Search</span>
                                
                </h6>
                </div>
                <div className='card-body'>
                
                <div className='row mb-4'>
                    <div className='col-md-4'>
                        <label>Start date</label>
                        <input type="date" className='form-control' name="start_date" value={details.start_date} onChange={handleSearch} ></input>
                        <span className='error_holder'>{details.error_list.start_date}</span>
                    </div>

                    <div className='col-md-4'>
                        <label>End date</label>
                        <input type="date" className='form-control' name="end_date" value={details.end_date} onChange={handleSearch} ></input>
                        <span className='error_holder'>{details.error_list.end_date}</span>
                    </div>

                    <div className='col-md-4'>
                    <label>Organization</label>
                    <select className='form-control' name = "organization" value={details.organization}  onChange={handleSearch}  >
                    <option>All</option>
                    {organizations.map((item,index)=>{
                    return(
                    <option key={index} value={item.id}>{item.name}</option>
                    )
                    })}
                    </select>
                    <span className='error_holder'>{details.error_list.organization}</span>
                    </div>

                </div>

                <Table striped hover>
                <thead>
                <tr className='small-row'>
                    <th>#</th>
                    <th>Date</th>
                    <th>Organization</th>
                    <th>Client</th>
                    <th>Loan</th>
                    <th>Loan Amount</th>
                    <th>Interest</th>
                    <th>Period</th>
                    <th>Amount Disbursed</th>
                </tr>
                </thead>
                <tbody>
                {disbursments.map((item,index)=>{
                return(
                <tr className='small-row' key={index} >
                    <td>{index+1}</td>
                    <td>{item.created_at.substr(0,10)}</td>
                    <td>{item.name}</td>
                    <td>{item.first_name} {item.other_name} {item.last_name}</td>
                    <td>{item.loan_code}</td>
                    <td>KES {parseInt(item.amount).toLocaleString('en-US')}</td>
                    <td>{item.interest_rate} %</td>
                    <td>{item.period} Months</td>
                    <td>KES {parseInt(item.total_amount).toLocaleString('en-US')}</td>
                </tr>
                )
                })}
                </tbody>
                </Table>
                </div>
                </div>

                </Col>
                </Row>
                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default PaydayDisbursementReport;