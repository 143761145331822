import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import PaydayApprove from '../elements/PaydayApprove';

const PaydayLoan=()=>{

const loan = useParams().id

const [details,setDetails] = useState({
    created_at: ''
})

const effectRun = useRef(false)
const [approvers,setApprovers] = useState([])
const [northwaveApprovers,setNorthwaveApprovers] = useState([])
const [schedule,setSchedule] = useState([])

const [action,setAction] = useState({
    id:'',
    status:''
})

const fetchSchedule=()=>{

const data = {
loan:loan
}

axios.post(`api/payday_schedule`,data).then((res)=>{

if(res.data.status === 200)
{
    setSchedule(res.data.schedule)
}

})

}

const takeAction=(status)=>{

const data = {
id:action.id,
status:status,
}

axios.post(`api/payday_action`,data).then((res)=>{

    if(res.data.status === 200)
    {   
        fetchAction()
        fetchNorthwave()
        fetchApprovers()
        swal("Success",res.data.message,"success")
    }

})

}

const fetchAction=()=>{

const data = {
    loan: loan
}

axios.post(`api/fetch_payday_action`,data).then((res)=>{
    if(res.data.status === 200)
    {
        if(res.data.action !== null)
        {
            setAction(res.data.action)
        }
        else{
            setAction({
                id:'',
                status:'na'
            })
        }
    }
})

}

const fetchNorthwave=()=>{

const data = {
    loan:loan,
}

axios.post(`api/payday_approvals`,data).then((res)=>{

if(res.data.status === 200)
{
    setNorthwaveApprovers(res.data.approvers)
}

})

}

const fetchDetails=()=>{

const data = {
    id:loan
}

axios.post(`api/payday_loan_details`,data).then((res)=>{
    if(res.data.status === 200)
    {
        setDetails(res.data.details)
    }
})

}

const fetchApprovers=()=>{

    const data = {
        id:loan
    }

    axios.post(`api/loan_approvers`,data).then((res)=>{
        if(res.data.status === 200)
        {
            setApprovers(res.data.approvers)
        }
    })

}

const paydayAction=()=>{

}

useEffect(()=>{

if(effectRun.current === false)
{
    fetchDetails()
    fetchApprovers()
    fetchNorthwave()
    fetchAction()
    fetchSchedule()

    return()=>{
    effectRun.current = true
    }

}

},[])

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>Loan Details</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='row'>

                <div className='col-md-8'>

                <div className='card card-primary card-outline'>
                <div className='card-body'>
                
                <div className='row'>
                <div className='col-md-6'>

                <p className='label-block'>
                <span className='label-holder'>Employee</span>
                {details.first_name}  {details.other_name}  {details.last_name}
                </p>

                <p className='label-block'>
                <span className='label-holder'>ID Number</span>
                {details.id_number}
                </p>

                <p className='label-block'>
                <span className='label-holder'>Payroll</span>
                {details.payroll}
                </p>

                <p className='label-block'>
                <span className='label-holder'>Date Applied</span>
                {details.created_at.substr(0,10)}
                </p>

                <p className='label-block'>
                <span className='label-holder'>Loan Number</span>
                {details.loan_code}
                </p>

                <p className='label-block'>
                <span className='label-holder'>Amount</span>
                KES {parseInt(details.amount_loanable).toLocaleString('en-US')}
                </p>

                <p className='label-block'>
                <span className='label-holder'>Interest Rate</span>
                {details.interest_rate} %
                </p>

                </div>

                <div className='col-md-6'>

                <p className='label-block'>
                <span className='label-holder'>Duration</span>
                {details.period} Months
                </p>

                <p className='label-block'>
                <span className='label-holder'>Processing Fee</span>KES {parseInt((details.processing_rate/100)*details.amount_loanable).toLocaleString('en-US')}
                </p>

                <p className='label-block'>
                <span className='label-holder'>Total Amount</span>
                KES {parseInt(details.total_amount).toLocaleString('en-US')}
                </p>

                <p className='label-block'>
                <span className='label-holder'>Installment</span>
                KES {parseInt(details.installment).toLocaleString('en-US')}
                </p>

                <p className='label-block'>
                <span className='label-holder'>Approval</span>
                {details.approval}
                </p>

                <p className='label-block'>
                <span className='label-holder'>Status</span>
                {details.status}
                </p>

                </div>

                
                </div>

        
                </div>
                </div>

                <div className='row'>

                <div className='col-md-6'>
                <div className='card card-primary card-outline'>

                <div className='card-header'>
                <h6>Loan Repayment Schedule</h6>
                </div>

                <div className='card-body'>

                <table className='table'>
                <thead>
                <tr className='small-row' >
                <th>#</th>
                <th>Invoice Date</th>
                <th>Amount</th>
                </tr>
                </thead>

                <tbody>
                {schedule.map((item,index)=>{
                return (
                <tr className='small-row' >
                <td>{index+1}</td>
                <td>{item.day}/{item.month}/{item.year}</td>
                <td>KES {parseInt(item.installment).toLocaleString('en-US')}</td>
                </tr>)
                })}
                </tbody>

                </table>

                </div>

                <div className='card-footer'>
                </div>

                </div>
                </div>

                <div className='col-md-6'>

                <div className='card card-primary card-outline'>

                <div className='card-header'>
                <h6><span className='fa fa-comments'></span> Comments</h6>
                </div>

                <div className='card-body'></div>
                </div>

                </div>

                </div>

                </div>

                <div className='col-md-4'>

                <PaydayApprove action={action} takeAction={takeAction} />

                <div className='card card-primary card-outline'>
                <div className='card-body'>

                <h6><span className='fa fa-user-shield'></span> Internal Approval</h6>

                <table className='table'>
                <thead>
                    <tr className='small-row'>
                        <th>#</th>
                        <th>Approver</th>
                        <th>Designation</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                {approvers.map((item,index)=>{
                
                return(
                <tr key ={index}  className='small-row' >
                <td>{index+1}</td>
                <td>{item.first_name} {item.other_name} {item.last_name}</td>
                <td>{item.designation}</td>
                <td>{item.status}</td>
                </tr>)

                })}
                </tbody>
                </table>

                </div>
                </div>

                

                <div className='card card-primary card-outline'>

                <div className='card-header'>
                <h6><span className='fa fa-user-shield'></span> Northwave Approval</h6>
                </div>

                <div className='card-body'>
                {northwaveApprovers.map((item,index)=>{
                
                return(
                <p key={index} className='small-row'>
                <span className='fa fa-user-shield'></span> {item.name} <span style={{float:'right'}}>{item.status}</span>
                </p>)

                })}
                </div>

                <div className='card-footer'>
                </div>

                </div>

                <div className='card card-outline card-primary'>
                <div className='card-header'><span className='fa fa-folder'></span> Loan Documents</div>
                <div className='card-body'></div>
                <div className='card-footer'></div>
                </div>

                </div>

                </div>

                </div>
                </div>

                </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default PaydayLoan;