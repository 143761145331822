import { useEffect, useState } from 'react';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

import MoneyDecoration from '../elements/decorations/MoneyDecoration';
import DateDecoration from '../elements/decorations/DateDecoration';
import ToggleInterval from '../elements/ToogleInterval';
import Cards from '../elements/cards/Cards';

import Sandbox from '../elements/modals/sandbox/Sandbox';
import Pay from '../elements/modals/sandbox/Pay';
import Invoice from '../elements/modals/sandbox/Invoice';
import swal from 'sweetalert';

function LoanSimulator()
{
    const [meta,setMeta] = useState({
        repayable_amount: 0,
        interest_amount: 0,
        installment_amount: 0,
    });

    const [transactions,setTransactions] = useState([])     // Holds initial simulation
    // const [extended,setExtended] = useState([])          // Hold extensions
    const [payments,setPayments] = useState([])             // Holds payments
    const [cleanList,setCleanList] = useState([])           // Holds processed list of transactions
    const [pay,setPay] = useState(false)
    const [processing_fee,setProcessing] = useState(0);
    const [risk_fund, setRisk] = useState(0);
    const [total_tracking, setTracking] = useState(0);
    const [total_service, setService] = useState(0);
    const [total_penalties, setPenalties] = useState(0)
    const [total_payments,setPayEntries] = useState(0)
    const [gross,setGross] = useState(0)

    const [paymentsDetails,setPaymentsDetails] = useState({
        date: '',
        amount: 0,
        error_list: [],
    })

    const [invoiceDetails,setInvoiceDetails] = useState({
        date: '',
        amount: 0,
        item:'',
        error_list: [],
    })

    const [open,setOpen] = useState(false)
    const [invoice,setInvoice] = useState(false)
    const [interval,setInterval] = useState('fixed');
    const [installments,setInstallments] = useState([]);
    const [amount_due,setAmount] = useState(0)

    const [info,setInfo] = useState({
        loan_amount: 0,
        interest_rate:0,
        repayment_period: 0,
        disbursment_date: '',
        current_date: '',
        loan_type:'fixed',
        error_list: [],
    });

    const handleChange=(e)=>{
        e.persist();
        setInfo({...info, [e.target.name]: e.target.value})
    }

    const onPay=(e)=>{
        e.persist()
        setPaymentsDetails({...paymentsDetails, [e.target.name]: e.target.value })
    }

    const onInvoice=(e)=>{
        e.persist()
        setInvoiceDetails({...invoiceDetails, [e.target.name]: e.target.value })
    }

    const simulateLoan=(e)=>{
    
    e.preventDefault()

    const data = {
        loan_amount: info.loan_amount,
        loan_type: info.loan_type,
        interest_rate: info.interest_rate,
        repayment_period: info.repayment_period,
        disbursment_date: info.disbursment_date,
        current_date: info.current_date,
    }

    axios.post(`api/repayment_schedule`,data).then((res)=>{

        if(res.data.status===200)
        {
            setMeta({
                repayable_amount: res.data.payable_amount,
                interest_amount: res.data.interest_amount,
                installment_amount: res.data.installment_amount,
            })

            setInstallments(res.data.installments)
            setTransactions(res.data.transactions)
            setCleanList(res.data.transactions)
            setAmount(res.data.amount_due)
            setProcessing(res.data.processing_fee)
            setRisk(res.data.risk_fund)
            setTracking(res.data.total_tracking)
            setService(res.data.total_service)
            setPenalties(res.data.total_penalty)
            setPayEntries(res.data.total_payments)
            setGross(res.data.gross_amount)
            //setExtended(res.data.extensions)
            swal('Success',res.data.message,"success")
        }
        else
        {
            setInfo({...info, error_list: res.data.validation_errors})
        }

    })
        
    }


    const closeModal=()=>{
        setOpen(false)
        setPay(false)
        setInvoice(false)
    }

    const makePayment=(e)=>{

        e.preventDefault()

        const data = {
            date:paymentsDetails.date,
            amount:paymentsDetails.amount,
        }

        axios.post(`api/new_payment`,data).then((res)=>{
            if(res.data.status === 200)
            {
                setPaymentsDetails({
                    date: '',
                    amount: 0,
                    error_list: [],
                })

                swal('Success',res.data.message,"success")

            }
            else
            {
                setPaymentsDetails({...paymentsDetails, error_list: res.data.validation_errors})
            }
        })

    }

    const makeInvoice=(e)=>{

        e.preventDefault()

        const data = {
            date:invoiceDetails.date,
            amount:invoiceDetails.amount,
            item:invoiceDetails.item,
        }

        axios.post(`api/new_invoice`,data).then((res)=>{
            if(res.data.status === 200)
            {
                setInvoiceDetails({
                    date: '',
                    amount: 0,
                    item:'',
                    error_list: [],
                })

                swal('Success',res.data.message,"success")

            }
            else
            {
                setInvoiceDetails({...invoiceDetails, error_list: res.data.validation_errors})
            }
        })

    }
    
    const doNothing=()=>{

    }

    const fetchPayments=()=>{

        axios.post(`api/payments`).then((res)=>{

            if(res.data.status === 200)
            {
                setPayments(res.data.payments)
            }

        })

    }

    useEffect(()=>{
        fetchPayments()
    },[])

    return (

        <div className="wrapper">

        <Sandbox open={open} info={info} meta={meta} MoneyDecoration={MoneyDecoration} simulateLoan={simulateLoan} closeModal={closeModal} handleChange={handleChange} doNothing={doNothing} />
        <Pay open={pay} closeModal={closeModal} payments={payments} MoneyDecoration={MoneyDecoration} DateDecoration={DateDecoration} onPay={onPay} paymentsDetails={paymentsDetails} makePayment={makePayment} />
        <Invoice open={invoice} closeModal={closeModal} onInvoice={onInvoice} invoiceDetails={invoiceDetails} makeInvoice={makeInvoice} />

        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        <ContentHeader title="Sandbox" />

        <div className="content">
        <div className="container">

        <div className="row">
        <div className = "col-lg-12" >

        <div className='row' >

            <div className='col-md-4' >

            <div className='card card-outline card-dark' >

            <div className='card-header'>
            <span className='fa fa-info-circle' ></span> Loan Details
            </div>

            <div className='card-body' >
            <table className='table' >
                <thead>
                    <tr style={{fontSize:'13px',fontWeight:'bold'}} >
                        <td>#</td>
                        <td>Item</td>
                        <td>Amount</td>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{fontSize:'13px',backgroundColor:'rgba(0,0,0,0.8)',color:'white'}} >
                        <td>1</td>
                        <td> <span className="fa fa-money-bill" ></span> Loan Amount</td>
                        <td>{MoneyDecoration(info.loan_amount)}</td>
                    </tr>

                    <tr style={{fontSize:'13px',fontWeight:'bold'}} >
                        <td>2</td>
                        <td> Loan Type</td>
                        <td>{info.loan_type}</td>
                    </tr>
                    
                    <tr style={{fontSize:'13px'}} >
                        <td>3</td>
                        <td>Interest Rate</td>
                        <td>{info.interest_rate} %</td>
                    </tr>

                    <tr style={{fontSize:'13px'}} >
                        <td>4</td>
                        <td>Loan Duration</td>
                        <td>{info.repayment_period} months</td>
                    </tr>

                    <tr style={{fontSize:'13px'}} >
                        <td>5</td>
                        <td>Processing Fee</td>
                        <td>{MoneyDecoration(processing_fee)}</td>
                    </tr>

                    <tr style={{fontSize:'13px'}} >
                        <td>6</td>
                        <td>Risk Fund</td>
                        <td>{MoneyDecoration(risk_fund)}</td>
                    </tr>

                    <tr style={{fontSize:'13px'}} >
                        <td>7</td>
                        <td>RTGS Fee</td>
                        <td>KES 2,000</td>
                    </tr>

                    <tr style={{fontSize:'13px',backgroundColor:'rgba(0,0,0,0.9)',color:'white'}} >
                        <td>8</td>
                        <td><span className="fa fa-chart-line" ></span> Total Interest</td>
                        <td>{meta.interest_amount}</td>
                    </tr>

                    <tr style={{fontSize:'13px',backgroundColor:'rgba(0,0,0,0.9)',color:'white'}} >
                        <td>9</td>
                        <td> <span className="fa fa-coins" ></span> Principal Amount</td>
                        <td>{gross}</td>
                    </tr>

                    <tr style={{fontSize:'13px'}} >
                        <td>10</td>
                        <td>Total Service Charge</td>
                        <td>{MoneyDecoration(total_service)}</td>
                    </tr>

                    <tr style={{fontSize:'13px'}} >
                        <td>11</td>
                        <td>Total Tracking Fee</td>
                        <td>{MoneyDecoration(total_tracking)}</td>
                    </tr>

                    <tr style={{fontSize:'13px',backgroundColor:'rgba(0,0,0,0.9)',color:'white'}} >
                        <td>12</td>
                        <td>Total Loan Amount</td>
                        <td>{meta.repayable_amount}</td>
                    </tr>

                    <tr style={{fontSize:'13px'}} >
                    <td>13</td>
                    <td>Monthly Installment</td>
                    <td>{MoneyDecoration(meta.installment_amount)}</td>
                    </tr>

                    <tr style={{fontSize:'13px',backgroundColor:'green',color:'white'}} >
                    <td>14</td>
                    <td>Total Payments</td>
                    <td>{MoneyDecoration(total_payments)}</td>
                    </tr>

                    <tr style={{fontSize:'13px',backgroundColor:'red',color:'white'}} >
                    <td>15</td>
                    <td>Total Penalties</td>
                    <td>{MoneyDecoration(total_penalties)}</td>
                    </tr>


                </tbody>
            </table>
            </div>

            <div className='card-footer' ></div>
            </div>

            <div className='card card-outline card-dark' >
            <div className='card-header' >
            <span className='fa fa-calendar' ></span> Repayment schedule
            </div>

            <div className='card-body' >
            
            <table className='table' >
                <thead>
                    <tr style={{fontSize:'15px'}} >
                        <td>#</td>
                        <td>Due date</td>
                        <td>Installment</td>
                    </tr>
                </thead>
                <tbody>
                {installments.map((inst,index)=>{
                    return(<tr key={index} style={{fontSize:'13px'}} >
                        <td>{index+1}</td>
                        <td>{inst[0]}</td>
                        <td>{MoneyDecoration(inst[1])}</td>
                    </tr>)
                })}
                </tbody>
            </table>

            </div>

        </div>

        </div>

        <div className='col-md-8' >

        <div className='card card-outline card-primary' >
            <div className='card-header' >
            <span className='fa fa-calendar' ></span> Account simulation
            
            <span className='btn btn-xs btn-dark' style={{float:'right',margin:'1px'}} onClick={()=>setInvoice(true)}  > <span className='fa fa-file' ></span> Charge Invoice</span>
            <span className='btn btn-xs btn-dark' style={{float:'right',margin:'1px'}} onClick={()=>setPay(true)} > <span className='fa fa-coins' ></span> Make Payment</span>
            <span className='btn btn-xs btn-dark' style={{float:'right',margin:'1px'}} onClick={()=>setOpen(true)} > <span className='fa fa-chart-line' ></span> Loan Details</span>
            </div>

            <div className='card-body' >
            
            <table className='table' >
                <thead>
                    <tr style={{fontWeight:'bold'}} >
                        <td>#</td>
                        <td>Item</td>
                        <td>Entry Date</td>
                        <td>Amount</td>
                        <td>Amount Due</td>
                    </tr>
                </thead>
                <tbody>
                {cleanList.map((infotec,index)=>{
                
                if(infotec.type === "credit")
                {
                    return(
                        <tr key={index} style={{fontSize:'13px'}} >
                        <td>{index+1}</td>
                        <td>{infotec.description}</td>
                        <td>{infotec.humanDueDate}</td>
                        <td><span className='fa fa-circle' style={{color:'red'}}></span> {MoneyDecoration(infotec.amount)}</td>
                        <td>{MoneyDecoration(infotec.amount_due)}</td>
                        </tr>
                    )
                }
                else
                {
                    return(
                        <tr key={index} style={{fontSize:'13px'}} >
                        <td>{index+1}</td>
                        <td>{infotec.description}</td>
                        <td>{infotec.humanDueDate}</td>
                        <td><span className='fa fa-circle' style={{color:'green'}}></span> {MoneyDecoration(infotec.amount)}</td>
                        <td>{MoneyDecoration(infotec.amount_due)}</td>
                        </tr>
                    )
                }
                })}
                </tbody>
            </table>

            </div>

        </div>

        <div className='card card-primary card-outline' >
            <div className='card-header' >
                <h5>Extenstion</h5>
            </div>

            <div className='card-body' >
           

            </div>

        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default LoanSimulator;