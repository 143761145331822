import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

function DueList(){

    const effectRan = useRef(false)
    const [charges,setCharges] = useState([])
    const [today,setToday] = useState('')
    const [end_day,setEndDay] = useState('')

    const [stats,setStats] = useState({
        all:0,
        mpesa:0,
        cheque:0,
        all_amount:0,
        mpesa_amount:0,
        cheque_amount:0
    })

    const fetchCharges=()=>{

        axios.get('api/due_list').then((res)=>{
            if(res.data.status === 200)
            {
                setCharges(res.data.charges)
                setToday(res.data.today)
                setEndDay(res.data.today)

                setStats({...stats,
                    all:res.data.stats.all,
                    mpesa:res.data.stats.mpesa,
                    cheque:res.data.stats.cheque,
                    all_amount:res.data.stats.all_amount,
                    mpesa_amount:res.data.stats.mpesa_amount,
                    cheque_amount:res.data.stats.cheque_amount,
                })
                
            }
        })
    }

    const searchDue=(e)=>{
        e.preventDefault()

        const data = {
            end_day:end_day
        }

        axios.post(`api/search_due`,data).then((res)=>{

            setCharges(res.data.charges)

            setStats({...stats,
                all:res.data.stats.all,
                mpesa:res.data.stats.mpesa,
                cheque:res.data.stats.cheque,
                all_amount:res.data.stats.all_amount,
                mpesa_amount:res.data.stats.mpesa_amount,
                cheque_amount:res.data.stats.cheque_amount,
            })

        })
    }

    const handleDetails=(e)=>{
        setEndDay(e.target.value)
    }

    const showStats=()=>{

        const role = localStorage.getItem('auth_role')

        if(role == "System Administrator" || role == "CEO"  )
        {
            return(
                <div className='row'>
    
                    <div className="col-12 col-sm-6 col-md-4">
    
                    <div className="info-box" style={{backgroundColor:'#01153e',color:'white'}}>
                    <div className="info-box-content">
                    <span className="info-box-text"><span className='fa fa-chart-line' ></span> Total Due  <span className='float-right'> <small>{stats.all}</small> </span> </span>
                    <span className="info-box-number">
                    <small>KES {stats.all_amount}</small>
                    </span>
                    </div>
                    </div>
    
                    </div>
    
                    <div className="col-12 col-sm-6 col-md-4">
    
                    <div className="info-box" style={{backgroundColor:'#01153e',color:'white'}}>
                    <div className="info-box-content">
                    <span className="info-box-text"><span className='fa fa-chart-line' ></span> Mpesa  <span className='float-right'> <small>{stats.mpesa}</small> </span> </span>
                    <span className="info-box-number">
                    <small>KES {stats.mpesa_amount}</small>
                    </span>
                    </div>
                    </div>
    
                    </div>
    
                    <div className="col-12 col-sm-6 col-md-4">
    
                    <div className="info-box" style={{backgroundColor:'#01153e',color:'white'}}>
                    <div className="info-box-content">
                    <span className="info-box-text"><span className='fa fa-chart-line' ></span> Cheque  <span className='float-right'> <small>{stats.cheque}</small> </span> </span>
                    <span className="info-box-number">
                    <small>KES {stats.cheque_amount}</small>
                    </span>
                    </div>
                    </div>
    
                    </div>
    
                    </div> 
            )
        }

    }

    useEffect(()=>{
        
        if(effectRan.current === false)
        {
            fetchCharges()
        }

        return()=>{
        effectRan.current = true
        }

    },[])

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>...</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='row'>
                <div className='col-md-12'>
                <div className='card card-primary card-outline'>

                <div className='card-header'>
                <h5>Due List </h5>
                </div>

                <div className='card-body'>

                {showStats()}       

                <form className='form'  onSubmit={searchDue}>
                <div className='row'>

                <div className='form-group col-md-5'>
                <label>Start Date</label>
                <input type="date" className='form-control' name="start_date" value={today} min={today} disabled></input>
                </div>

                <div className='form-group col-md-5'>
                <label>End Date</label>
                <input type="date" className='form-control'  name="end_date" value={end_day} min={today} onChange={handleDetails} ></input>
                </div>

                <div className='form-group col-md-2'>
                <input type="submit" className="btn btn-primary btn-sm" value="Search due list" style={{marginTop:'33px'}} ></input>
                </div>
                </div>
                </form>

        

                <div className='row'>
                <div className='col-md-12'>

                <table className = "table" >
                <thead>
                <tr className='small-row' >
                <td>#</td>
                <td><b>Customer</b></td>
                <td><b>Loan</b></td>
                <td><b>Charge</b></td>
                <td><b>Amount</b></td>
                <td><b>Due Date</b></td>
                <td><b>Pay mode</b> </td>
                </tr>
                </thead>
                <tbody >
                {charges.map((item,index)=>{
                return(
                <tr key={index} className='small-row' >
                    <td>{index+1}</td>
                    <td>{item.customer}</td>
                    <td>{item.loan}</td>
                    <td>{item.transaction}</td>
                    <td>KES {parseInt(item.amount).toLocaleString('en-US')}</td>
                    <td>{item.due_date}</td>
                    <td>{item.pay_mode}</td>
                </tr>
                )
                })}
                </tbody>
            </table>
                    
                </div>
                </div>

                            
                </div>

                <div className='card-footer'>                                
                </div>

                </div>
                </div>
                </div>

                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default DueList;