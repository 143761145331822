const Previous = ({stage,previousPage})=>{

    if(stage > 0)
    {
        return (
            <span className="btn btn-sm btn-dark" onClick={()=>previousPage()} > <span className="fa fa-arrow-left" ></span> Previous</span>
        )
    }

}
export default Previous;