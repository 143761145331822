import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import swal from 'sweetalert';

import user_image from './../../img/user1-128x128.jpg';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

import CardHeader from '../elements/cards/CardHeader';
import CardBody from '../elements/cards/CardBody';

import NewLoanTypeModal from '../elements/modals/loans/NewLoanTypeModal';
import EditLoanTypeModal from '../elements/modals/loans/EditLoanTypeModal';

function LoanTypes()
{
    // Keep track of all departments from the database
    const [types, setTypes] = useState([]);

    // Keep track of department modal state
    const[open,setOpen] = useState(false);
    
    // Keep track of edit department modal state
    const[openEdit,setOpenEdit] = useState(false);

    // Keep track of all role fields
    const [typeDetails, setTypeDetails] = useState({
        id:'',
        type:'',
        description:'',
        error_list:[],
    });

     // Close New Role modal
     const onClose = () =>{
        setOpen(false)
        setTypeDetails({
            id:'',
            type:'',
            description:'',
            error_list:[],
        })
    }

    // Close Edit Role modal
    const onCloseEdit = () =>{
        setOpenEdit(false)
        setTypeDetails({
            id:'',
            type:'',
            description:'',
            error_list:[],
        })
    }

    // Function to edit the selected role
    const editType = (id)=>{

        // Open the edit modal
        setOpenEdit(true);

        setTypeDetails({
            id:types[id].id,
            type:types[id].loan_type,
            description:types[id].description,
            error_list:[],
        })

    }

    // Function to update role
    const updateType = (e) =>{

        e.preventDefault()

        const data = {
            id: typeDetails.id,
            type: typeDetails.type,
            description: typeDetails.description,
        }

        axios.get(`sanctum/csrf-cookie`).then(request =>{
            axios.post(`api/update_loantype`,data).then(res=>{
                if(res.data.status === 200)
                {
                    swal('Success',res.data.message,"success")
                    setTypeDetails({
                        type:'',
                        description:'',
                        error_list:[],
                    })

                    setOpenEdit(false)
                    refreshTypes()
                }
                else{

                    setTypeDetails({...typeDetails, error_list: res.data.validation_errors})

                }
            })
        });

    }

    // Fetch and set roles from database

    const refreshTypes = ()=>{

        axios.get(`sanctum/csrf-cookie`).then(request=>{

            axios.post(`api/loan_types`,).then(res=>{
                
                if(res.data.status === 200)
                {
                    setTypes(res.data.loan_types)
                }

            });
    
        });

    }

    useEffect(()=>{
        refreshTypes()
    },[]);


    // Update roles details state
    const handleType = (e)=>{
        e.persist()
        setTypeDetails({...typeDetails, [e.target.name]: e.target.value})
    }

    // Save loan type
    const saveType = (e) =>{

        e.preventDefault()

        const data = {
            type: typeDetails.type,
            description: typeDetails.description,
        }

        axios.get(`sanctum/csrf-cookie`).then(request=>{
                axios.post(`api/new_loantype`,data).then(req=>{

                if(req.data.status === 200)
                {
                   
                    setOpen(false)
                    swal('Success',req.data.message,"success")
                    setTypeDetails({
                        type:'',
                        description:'',
                        error_list:[],
                    })

                    refreshTypes()

                }else{
                    setTypeDetails({...typeDetails, error_list: req.data.validation_errors})
                }

                })
        });

    }
   
    return (
        <div className="wrapper">
        
        <EditLoanTypeModal open={openEdit} onClose={onCloseEdit} typeDetails={typeDetails} handleType={handleType} saveType={updateType} />
        <NewLoanTypeModal open={open} onClose={onClose} typeDetails={typeDetails} handleType={handleType} saveType={saveType} />
        
        <Nav/>
        <Aside/>

        <div className="content-wrapper">

        <ContentHeader title="Loan Categories" />

        <div className="content">
        <div className="container">
        <div className="row">
            
        <div className="col-lg-3"  >
        
        <div className="card card-widget widget-user-2"  >
        <CardBody/>
        </div>

        </div>

        <div className = "col-lg-9" id = "centralSettings">
        
        <div className="card card-primary card-outline">
        <div className="card-header">
        <h5 className="card-title m-0"> <span className='fa fa-circle' ></span> Loan categories <span className='right btn btn-xs btn-primary' onClick={()=>setOpen(true)} ><span className='fa fa-plus-circle' ></span> New Category</span></h5>
        </div>

        <div className="card-body">

        <table className="table" id="example1">

        <thead>
        <tr style= {{fontWeight : 'bold', fontSize: '14px'}} >
        <td>#</td>
        <td>Category</td>
        <td>Description</td>
        <td> <span className="fa fa-edit" ></span> </td>
        </tr>
        </thead>

        <tbody>

        {types.map((val,index)=>{
            return(<tr style= {{fontSize: '14px'}} key={val.id} >
                <td>{index+1}</td>
                <td>{val.loan_type}</td>
                <td>{val.description}</td>
                <td> <span className='fa fa-edit' onClick={()=>editType(index)}></span> </td>
                </tr>);
        })}
        
        </tbody>

        <tfoot>
        </tfoot>
        </table>    
                
        </div>

        <div className='card-footer' ></div>

        </div>

        </div>

        </div>
        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default LoanTypes;