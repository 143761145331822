import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

const PaydayLoans=()=>{

const [loans,setLoans] = useState([])
const effectRun = useRef(false)
const fetchLoans=()=>{

axios.post(`api/payday_loans`).then((res)=>{

if(res.data.status === 200)
{
    setLoans(res.data.loans)
}

})

}

useEffect(()=>{

if(effectRun.current === false)
{
    fetchLoans()

    return()=>{
    effectRun.current = true;
    }
}

},[])

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>Payday Loans</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='row'>
                <div className='col-md-12'>
                
                <div className='card card-primary card-outline'>
                    <div className='card-body'>
                    <table className='table'>
                    <thead>
                        <tr className='small-row'>
                            <th>#</th>
                            <th>Date</th>
                            <th>Loan</th>
                            <th>Organization</th>
                            <th>Staff</th>
                            <th>Amount</th>
                            <th>Rate</th>
                            <th>Period</th>
                            <th>Installment</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    {loans.map((item,index)=>{
                    return(
                    <tr key={index} className="small-row">
                    <td>{index+1}</td>
                    <td>{item.created_at.substr(0,10)}</td>
                    <td><Link to={`/payday_loan/${item.id}`} >{item.loan_code}</Link></td>
                    <td>{item.name}</td>
                    <td>{item.first_name} {item.other_name} {item.last_name}</td>
                    <td>KES {parseInt(item.amount_loanable).toLocaleString('en-US')}</td>
                    <td>{item.interest_rate} %</td>
                    <td>{item.period} Months</td>
                    <td>KES {parseInt(item.installment).toLocaleString('en-US')}</td>
                    <td>{item.status}</td>
                    </tr>)
                    })}
                    </tbody>
                </table>
                    </div>
                </div>

                </div>
                </div>

                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default PaydayLoans;