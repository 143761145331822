const AddItem=({open,onClose,details,handleDetails,saveItem})=>{

    if(!open) return false;
    
    return (<div className='overlay' onClick={onClose} >
    <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >
    
    <div className="card card-primary card-outline">
    <div className="card-header">
    <h6> <span className="fa fa-plus-circle"></span> Add Item</h6>
    </div>
    <div className="card-body">
    
    <form className="form" onSubmit={saveItem} >
    <div className="row">

    <div className="form-group col-md-6">
        <label>Item</label>
        <input name="item" className="form-control" value={details.item} onChange={handleDetails} />
        <span className="error_holder">{details.error_list.item}</span>
    </div>

    <div className="form-group col-md-6">
        <label>Description</label>
        <input name="description" className="form-control" value={details.description} onChange={handleDetails} />
        <span className="error_holder">{details.error_list.description}</span>
    </div>

    <div className="form-group col-md-6">
        <label>Quantity</label>
        <input name="quantity" className="form-control" value={details.quantity} onChange={handleDetails} type="number"  min="1"  />
        <span className="error_holder">{details.error_list.quantity}</span>
    </div>

    <div className="form-group col-md-6">
        <label>Cost per item</label>
        <input name="cost" className="form-control" value={details.cost} onChange={handleDetails} type="number" min="1" />
        <span className="error_holder">{details.error_list.cost}</span>
    </div>

    <div className="form-group col-md-12">
        <input type="submit" className="btn btn-primary btn-sm" value="Save Item" />
    </div>

    </div>
    </form>

    </div>

    <div className="card-footer">
    </div>

    </div>

    </div>
    </div>)

}

export default AddItem;