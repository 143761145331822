import axios from 'axios';
import swal from 'sweetalert';

const Loan_Two=({handleDocument,handleScan,loanDocsInitial,uploadDocument,loanDocuments,fetchDocuments})=>{

const deleteDocument=(id)=>{

const data = {
    document: id,
}

axios.post(`api/delete_loan_document`,data).then((res)=>{
    if(res.data.status === 200)
    {
        swal("Success",res.data.message,"success")
        fetchDocuments()
    }
})

}

return(

<div className="col-md-12" >

<div className="card card-outline card-primary" >

<div className="card-body" >

<table className="table" >
<thead>
<tr style={{fontSize:'14px'}} >
<th>#</th>
<th><span className="fa fa-file" ></span> Document</th>
<th><span className="fa fa-file-invoice" ></span> Scan</th>
<th><span className="fa fa-user" ></span> Uploaded by</th>
<th><span className="fa fa-trash" style={{color:'red'}} ></span></th>
</tr>
</thead>

<tbody>
{loanDocuments.map((document,index)=>{
return(
<tr key={index} style={{fontSize:'14px'}} >
<td>{index+1}</td>
<td><span className="fa fa-file" ></span> {document.document}</td>
<td><span className="fa fa-file-invoice" ></span> {document.scan}</td>
<td><span className="fa fa-user" ></span> {document.name}</td>
<td><span className="fa fa-trash" style={{color:'red'}} onClick={()=>deleteDocument(document.id)} ></span></td>
</tr>
)
})}
</tbody>

</table>

</div>

<div className="card-footer" ></div>

</div>

<div className="card card-outline card-primary" >

<div className="card-body" >

<div className="row" >

<div className="col-md-6 form-group" >
<label>Document Name</label>
<input type="text" className="form-control" name="document" value={loanDocsInitial.document} onChange={handleDocument}/>
<span className="error_holder"  >{loanDocsInitial.error_list.document}</span>
</div>

<div className="col-md-6 form-group" >
<label>Scan</label>
<input type="file" className="form-control" name="scan" onChange={handleScan}/>

</div>

</div>

<div className="row" >
<div className="col-md-6 form-group" >
<span className="btn btn-primary btn-xs" onClick={uploadDocument} ><span className="fa fa-upload" ></span> Upload Document</span>
</div>
</div>

</div>

</div>

</div>
)
}

export default Loan_Two;