import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import axios from 'axios';

const NewScan=({scan_flag,closeScan,person,fetchDocuments})=>{

const [document,setDocument] = useState('')
const [file,setFile] = useState(null)

const [uploadErrors,setErrors] = useState({
document:'',
file:'',
})

const handleDocument =(e)=>{
    e.persist();
    setDocument(e.target.value)
}

const handleFile =(e)=>{
    e.persist();
    setFile(e.target.files[0])
}

const shutdown=()=>{
    setDocument('')
    setFile(null)
    closeScan()
}

const uploadDocument=()=>{
var flag = true

if(document === '')
{
    flag = false
    uploadErrors.document = "This field is required."
}
else
{
    uploadErrors.document = "" 
}

if(file === null)
{
    flag = false
    uploadErrors.file = "This field is required."
}
else
{
    uploadErrors.file = ""
}

if(document !== '' && file !== null)
{
    flag = true
}

if(flag)
{
    const formData = new FormData()
    
    formData.append('person',person)
    formData.append('document',document)
    formData.append('file',file,file.name)

    axios.post(`api/upload_kyc`,formData).then((res)=>{
        if(res.data.status === 200)
        {
            closeScan()
            fetchDocuments()
            swal('Success','Upload Successful',"success")
            setDocument('')
            setFile(null)
        }
    })

}
else
{
    swal('Warning','All fields are required',"warning")
}

}

if(!scan_flag) return false;
return(
    <div className='overlay' onClick={shutdown} >
    <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

    <div className='card card-primary card-outline' >

    <div className='card-header'>
    <h4>Upload Kyc Document</h4>
    </div>

    <div className='card-body' >
    <div className='row' >
    
    <div className='col-md-6 form-group' >
    <label>Document</label>
    <input type='text' className='form-control' name='document' value ={document} onChange={handleDocument} />
    <span className="error_holder"  >{uploadErrors.document}</span>
    </div>

    <div className='col-md-6 form-group' >
    <label>Scan</label>
    <input type='file' className='form-control' name='scan' onChange={handleFile} />
    <span className="error_holder"  >{uploadErrors.file}</span>
    </div>

    <div className='col-md-12 form-group' >
    <span className='btn btn-primary btn-sm' onClick={()=>uploadDocument()}>Upload Document</span>
    </div>

    </div>
    </div>

    <div className='card-footer' ></div>

    </div>

    </div>
    </div>
)

}
export default NewScan;