import axios from 'axios';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';

const VehicleDocuments=({carFlag,closeCar,carDetails,uploadDetails,handleDocument,handleScan,uploads,setUploadDetails,fetchUploads,clearData})=>{

const deleteUpload=(id)=>{

    const data = {
    id:id,
    customer_id: carDetails.customer,
    }

    axios.post(`api/deleteUpload`,data).then((res)=>{
    
        if(res.data.status === 200)
        {
    
            swal('Success',res.data.message,"success")
            fetchUploads(carDetails.id)
        }
        else
        {
            swal('Error',"Document deletion failed","error")
        }
    
        })



}

const uploadDocument=()=>{

if(uploadDetails.scan === null)
{
    swal('Error',"A file upload is required","error")
}
else
{

    const formData = new FormData()
    formData.append('id',carDetails.id)
    formData.append('customer',carDetails.customer)
    formData.append('document',uploadDetails.document)
    formData.append('scan',uploadDetails.scan,uploadDetails.scan.name)

    axios.post(`api/uploadDocument`,formData).then((res)=>{

        if(res.data.status === 200)
        {
            swal('Success',res.data.message,"success")
            fetchUploads(carDetails.id)
            clearData()
        }
        else{
            setUploadDetails({...uploadDetails, error_list: res.data.validation_errors})
        }
    
    })

}

}

const downloadDocument=(id,name)=>{

    const data = {
        id:id,
        customer:carDetails.customer,
    }

    axios({
        url: 'api/download_vehicle',
        method: 'POST',
        data: data,
        responseType: 'blob', // important
      }).then((response) => {
        
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        
      });

}

if(!carFlag) return false;
return(
<div className='overlay' onClick={closeCar} >
<div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

<div className = "row" >

<div className='col-md-12' >

<div className="card card-outline card-primary" >

<div className="card-header">
<h5>Vehicle Details</h5>
</div>

<div className="card-body" >

<div className="row" >

<div className="col-md-4" >

<div className="card card-primary card-outline" >
<div className="card-body" >
<p>
<span className="label-holder" >Registration:</span> {carDetails.registration}
</p>

<p>
<span className="label-holder" >Make:</span> {carDetails.make}
</p>

<p>
<span className="label-holder" >Model:</span> {carDetails.model}
</p>

<p>
<span className="label-holder" >Year:</span> {carDetails.year}
</p>

<p>
<span className="label-holder" >Body:</span> {carDetails.body}
</p>

<p>
<span className="label-holder" >Color:</span> {carDetails.color}
</p>

<p>
<span className="label-holder" >Transmission:</span> {carDetails.transmission}
</p>

<p>
<span className="label-holder" >Log Book:</span> {carDetails.log_book}
</p>
</div>
</div>

</div>

<div className="col-md-8">

<div className="card card-primary card-outline" >

<div className="card-body" >
<table className="table" >
<thead>
    <tr style={{fontSize:'13px'}} >
        <th>#</th>
        <th>Document</th>
        <th>Scan</th>
        <th><span className="fa fa-download"  style={{color:'green'}} ></span></th>
        <th><span className="fa fa-trash"  style={{color:'red'}} ></span></th>
    </tr>
</thead>

<tbody>
{uploads.map((item,index)=>{
    return(
        <tr style={{fontSize:'13px'}} key={index}>
            <td>{index+1}</td>
            <td>{item.document}</td>
            <td>{item.name}</td>
            <td><span className="fa fa-download" style={{color:'green'}} onClick={()=>downloadDocument(item.id,item.name)} ></span></td>
            <td><span className="fa fa-trash" style={{color:'red'}} onClick={()=>deleteUpload(item.id)} ></span></td>
        </tr>
    )
})}
</tbody>

</table>
</div>

</div>

<div className="card card-primary card-outline" >

<div className="card-header" >
<span className="fa fa-upload"></span> Upload Document
</div>

<div className="card-body" >

<div className="row" >

<div className="col-md-6 form-group" >
<label>Document</label>
<input name="document" type ="text" className="form-control" value={uploadDetails.document} onChange={handleDocument} />
<span className="error_holder"  >{uploadDetails.error_list.document}</span>
</div>

<div className="col-md-6 form-group" >
<label>Document Scan</label>
<input name="scan" type="file" className="form-control"  onChange={handleScan} />
</div>

</div>

<div className="row" >
<div className="col-md-12 form-group" >
<span className="btn btn-primary btn-xs" onClick={uploadDocument} > <span className="fa fa-upload" ></span> Upload document</span>
</div>
</div>

</div>

</div>

</div>

</div>

</div>

<div className="card-footer" ></div>

</div>

</div>

</div>

</div>
</div>
)

}
export default VehicleDocuments;