
const NewAgentModal = ({open,closeModal,handleInput,agentsDetails,onSubmit}) =>{

    if(!open) return false;
    
    return(
    <div className='overlay' onClick={closeModal} >
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className = "card card-primary" >
        <div className="card-header" ><span className='fa fa-edit' ></span> New Agent
        <span className='closeModal btn btn-sm btn-default' onClick={()=>closeModal()} > <span className='fa fa-times-circle' ></span> Close</span>
        </div>

        <div className="card-body login-card-body">

        <form className="form" onSubmit={onSubmit} >

        <div className="row" >

        <div className='col-md-4 form-group' >
            <label>Name <span className="red" >*</span></label>
            <input type = "text" name = "name" className="form-control"  onChange={handleInput} />
            <span className="error_holder"  >{agentsDetails.error_list.name}</span>
        </div>

        <div className='col-md-4 form-group' >
            <label>Phone <span className="red" >*</span></label>
            <input type = "text" name = "phone" className="form-control" onChange={handleInput} />
            <span className="error_holder"  >{agentsDetails.error_list.phone}</span>
        </div>

        <div className='col-md-4 form-group' >
            <label>Email <span className="red" >*</span></label>
            <input type = "text" name = "email" className="form-control"  onChange={handleInput}  />
            <span className="error_holder"  >{agentsDetails.error_list.email}</span>
        </div>

        <div className='col-md-4 form-group' >
            <label>Gender <span className="red" >*</span></label>
            <select name = "gender" className="form-control" onChange={handleInput} >
                <option value="na" >Select gender</option>
                <option>Male</option>
                <option>Female</option>
            </select>
            <span className="error_holder"  >{agentsDetails.error_list.gender}</span>
        </div>

        <div className='col-md-4 form-group' >
            <label>Location <span className="red" >*</span></label>
            <input type = "text" name = "location" className="form-control" onChange={handleInput}  />
            <span className="error_holder"  >{agentsDetails.error_list.location}</span>
        </div>

        </div>

        <div className="row" >

        <div className='col-md-4 form-group' >
            <input type="submit" className="btn btn-primary btn-sm" value = "Save Agent" />
        </div>

        </div>

        </form>

        </div>

        <div className='card-footer' style={{color:'red'}}>
        <i>* fields are mandatory </i>
        </div>

        </div>
   

        </div>
    </div>
    );
}

export default NewAgentModal;