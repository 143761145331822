import {Outlet, Navigate } from 'react-router-dom';

function AdminRoutes(){

    const allowed = ['CEO','System Administrator']

    return (allowed.includes(localStorage.getItem('auth_role')) ? <Outlet /> : <Navigate to ="/unauthorized" />)

}

export default AdminRoutes;