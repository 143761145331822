import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import swal from 'sweetalert';

import user_image from './../../img/user1-128x128.jpg';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

import CardHeader from '../elements/cards/CardHeader';
import CardBody from '../elements/cards/CardBody';

import NewRoleModal from '../elements/modals/roles/NewRoleModal';
import EditRoleModal from '../elements/modals/roles/EditRoleModal';

function Roles()
{
    // Keep track of all departments from the database
    const [roles, setRoles] = useState([]);

    // Keep track of department modal state
    const[open,setOpen] = useState(false);
    
    // Keep track of edit department modal state
    const[openEdit,setOpenEdit] = useState(false);

    // Keep track of all role fields
    const [roleDetails, setRoleDetails] = useState({
        id:'',
        role:'',
        description:'',
        error_list:[],
    });

     // Close New Role modal
     const onClose = () =>{
        setOpen(false)
        setRoleDetails({
            id:'',
            role:'',
            description:'',
        })
    }

    // Close Edit Role modal
    const onCloseEdit = () =>{
        setOpenEdit(false)
        setRoleDetails({
            id:'',
            role:'',
            description:'',
        })
    }

    // Function to edit the selected role
    const editRole = (id)=>{

        // Open the edit modal
        setOpenEdit(true);

        setRoleDetails({
            id:roles[id].id,
            role:roles[id].role,
            description:roles[id].description,
            error_list:[],
        })

    }

    // Function to update role
    const updateRole = (e) =>{

        e.preventDefault()

        const data = {
            id:roleDetails.id,
            role: roleDetails.role,
            description: roleDetails.description,
        }

        axios.get(`sanctum/csrf-cookie`).then(request =>{
            axios.post(`api/update_role`,data).then(res=>{
                if(res.data.status === 200)
                {
                    swal('Success',res.data.message,"success")
                    setRoleDetails({
                        role:'',
                        description:'',
                        error_list:[],
                    })

                    setOpenEdit(false)
                    refreshRoles()
                }
                else{

                    setRoleDetails({...roleDetails, error_list: res.data.validation_errors})

                }
            })
        });

    }

    // Fetch and set roles from database

    const refreshRoles = ()=>{

        axios.get(`sanctum/csrf-cookie`).then(request=>{

            axios.post(`api/roles`,).then(res=>{
                
                if(res.data.status === 200)
                {
                    setRoles(res.data.roles)
                }

            });
    
        });

    }

    useEffect(()=>{
        refreshRoles()
    },[]);


    // Update roles details state
    const handleRole = (e)=>{
        setRoleDetails({...roleDetails, [e.target.name]: e.target.value})
    }

    // Save department
    const saveRole = (e) =>{

        e.preventDefault()

        const data = {
            role:roleDetails.role,
            description:roleDetails.description,
        }

        axios.get(`sanctum/csrf-cookie`).then(request=>{
                axios.post(`api/new_role`,data).then(req=>{

                if(req.data.status === 200)
                {
                   
                    setOpen(false)
                    swal('Success',req.data.message,"success")
                    setRoleDetails({
                        role:'',
                        description:'',
                        error_list:[],
                    })
                    refreshRoles()

                }else{
                    setRoleDetails({...roleDetails, error_list: req.data.validation_errors})
                }

                })
        });

    }
   
    return (
        <div className="wrapper">
        
        <EditRoleModal open={openEdit} onClose={onCloseEdit} roleDetails={roleDetails} handleRole={handleRole} saveRole={updateRole} />
        <NewRoleModal open={open} onClose={onClose} roleDetails={roleDetails} handleRole={handleRole} saveRole={saveRole} />
        
        <Nav/>
        <Aside/>

        <div className="content-wrapper">

        <ContentHeader title="System Roles" />

        <div className="content">
        <div className="container">
        <div className="row">
            
        <div className="col-lg-3"  >
        
        <div className="card card-widget widget-user-2"  >
        <CardBody/>
        </div>

        </div>

        <div className = "col-lg-9" id = "centralSettings">
        
        <div className="card card-primary card-outline">
        <div className="card-header">
        <h5 className="card-title m-0"> <span className='fa fa-user-shield' ></span> Roles <span className='right btn btn-xs btn-primary' onClick={()=>setOpen(true)} ><span className='fa fa-plus-circle' ></span> New Role</span></h5>
        </div>

        <div className="card-body">

        <table className="table" id="example1">

        <thead>
        <tr style= {{fontWeight : 'bold', fontSize: '14px'}} >
        <td>#</td>
        <td>Role</td>
        <td>Descriptions</td>
        <td> <span className="fa fa-edit" ></span> </td>
        <td> <span className="fa fa-eye" ></span> More</td>
        </tr>
        </thead>

        <tbody>

        {roles.map((val,index)=>{
            return(<tr style= {{fontSize: '14px'}} key={val.id} >
                <td>{index+1}</td>
                <td>{val.role}</td>
                <td>{val.description}</td>
                <td> <span className='fa fa-edit' onClick={()=>editRole(index)}></span> </td>
                <td> <Link to={`/role/${val.id}`}> <span className="fa fa-eye" ></span> More </Link> </td>
                </tr>);
        })}

        </tbody>

        <tfoot>
        </tfoot>

        </table>    
                
        </div>

        <div className='card-footer' ></div>

        </div>

        </div>

        </div>
        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default Roles;