const ContactHeader = ({stage})=>{

    if(stage === 1)
    {
        return(
            <div className="col-md-12" >

               <div className="progressive-container" >
                <span className="event_holder" style={{backgroundColor:'green'}} >1</span>
                <p style={{fontWeight:'bold'}} ><span className="fa fa-user-circle" ></span> Personal Information</p>
               </div>

               <div className="progressive-container" >
               <span className="event_holder bg-dark " >2</span>
               <p  ><span className="fa fa-user-circle" ></span> Next of Kin</p>
               </div>

               <div className="progressive-container" >
               <span className="event_holder bg-dark " >3</span>
               <p><span className="fa fa-user-circle" ></span> KYC Information</p>
               </div>

               <div className="progressive-container" >
               <span className="event_holder bg-dark " >4</span>
               <p><span className="fa fa-file-invoice" ></span> KYC Documents</p>
               </div>

               <span className="progress-line"></span>

            </div>
        )
    }

    if(stage === 2)
    {
        return(
            <div className="col-md-12" >

               <div className="progressive-container" >
                <span className="event_holder " style={{backgroundColor:'green',paddingTop:'2px'}} ><span className="fa fa-check" ></span></span>
                <p ><span className="fa fa-user-circle" ></span> Personal Information</p>
               </div>

               <div className="progressive-container" >
               <span className="event_holder" style={{backgroundColor:'green'}} >2</span>
               <p  style={{fontWeight:'bold'}} ><span className="fa fa-user-circle" ></span> Next of Kin</p>
               </div>

               <div className="progressive-container" >
               <span className="event_holder bg-dark " >3</span>
               <p><span className="fa fa-user-circle" ></span> KYC Information</p>
               </div>

               <div className="progressive-container" >
               <span className="event_holder bg-dark " >4</span>
               <p><span className="fa fa-file-invoice" ></span> KYC Documents</p>
               </div>

               <span className="progress-line"></span>

            </div>
        )
    }

    if(stage === 3)
    {
        return(
            <div className="col-md-12" >

               <div className="progressive-container" >
               <span className="event_holder " style={{backgroundColor:'green',paddingTop:'2px'}} ><span className="fa fa-check" ></span></span>
                <p><span className="fa fa-user-circle" ></span> Personal Information</p>
               </div>

               <div className="progressive-container" >
               <span className="event_holder " style={{backgroundColor:'green',paddingTop:'2px'}} ><span className="fa fa-check" ></span></span>
               <p><span className="fa fa-user-circle" ></span> Next of Kin</p>
               </div>

               <div className="progressive-container" >
               <span className="event_holder" style={{backgroundColor:'green'}} >3</span>
               <p  style={{fontWeight:'bold'}} ><span className="fa fa-user-circle" ></span> KYC Information</p>
               </div>

               <div className="progressive-container" >
               <span className="event_holder" style={{backgroundColor:'green'}} >4</span>
               <p><span className="fa fa-file-invoice" ></span> KYC Documents</p>
               </div>

               <span className="progress-line"></span>

            </div>
        )
    }

    if(stage === 4)
    {
        return(
            <div className="col-md-12" >

               <div className="progressive-container" >
               <span className="event_holder " style={{backgroundColor:'green',paddingTop:'2px'}} ><span className="fa fa-check" ></span></span>
                <p><span className="fa fa-user-circle" ></span> Personal Information</p>
               </div>

               <div className="progressive-container" >
               <span className="event_holder " style={{backgroundColor:'green',paddingTop:'2px'}} ><span className="fa fa-check" ></span></span>
               <p><span className="fa fa-user-circle" ></span> Next of Kin</p>
               </div>

               <div className="progressive-container" >
               <span className="event_holder" style={{backgroundColor:'green'}} ><span className="fa fa-check" ></span></span>
               <p ><span className="fa fa-user-circle" ></span> KYC Information</p>
               </div>

               <div className="progressive-container" >
               <span className="event_holder" style={{backgroundColor:'green'}} >4</span>
               <p style={{fontWeight:'bold'}}  ><span className="fa fa-file-invoice" ></span> KYC Documents</p>
               </div>

               <span className="progress-line"></span>

            </div>
        )
    }

}

export default ContactHeader;