import { useEffect, useState, useRef } from 'react';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import PrintInsurance from '../elements/print/PrintInsurance';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';


function ExpiredInsurance()
{
    const effectRun = useRef(false)
    const [policies,setPolicies] = useState([])
    const [open,setOpen] = useState(false)

    const onClose=()=>{
        setOpen(false)
    }

    const fetchPolicies=()=>{
    
    axios.get(`api/expired_insurance`).then((res)=>{
        if(res.data.status === 200)
        {
            setPolicies(res.data.policies)
        }
    })

    }

    const insuaranceStatus=(days)=>{
    
        if(days > 60)
        {
            return(
                <td> <span className='badge badge-dark'> {days} Days </span> </td>
            )
        }else if(days <= 60)
        {
            if(days <= 30)
            {
                return(
                    <td> <span className='badge badge-danger'> {days} Days </span> </td>
                )
            }
            else
            {
                return(
                    <td> <span className='badge badge-warning'> {days} Days </span> </td>
                )
            }
        }

    }

    // Use effect to initialize critical data
    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchPolicies()
    }

    return()=>{
    effectRun.current = true
    }

    },[])

    return (

        <div className="wrapper">
        <PrintInsurance open={open} onClose={onClose} statement={policies} insuaranceStatus={insuaranceStatus} ></PrintInsurance>
        <Nav/>
        <Aside/>

        <div className="content-wrapper">        

        <div className="content">
        <div className="container">

        <div className="row" style={{marginTop:'20px'}} >
        <div className = "col-lg-12" >

        <div className="card card-primary card-outline">
        <div className="card-header">
        <h6 className="card-title">Expired Insurance Policies </h6>
        </div>
        <div className="card-body">

        <table className="table" >

        <thead>
            <tr style= {{fontWeight : 'bold', fontSize: '14px'}} >
            <th>#</th>
            <th>Customer</th>
            <th>Vehicle</th>
            <th>Insurance Company</th>
            <th>Collateral</th>
            <th>Premium</th>
            <th>Start Date</th>
            <th>Expiry Date</th>
            <th>Expired for</th>
            </tr>
        </thead>

        <tbody>
        {policies.map((item,index)=>{
        return(
        <tr className='small-row' key={index} >
        <td>{index+1}</td>
        <td>{item.first_name} {item.other_name} {item.last_name}</td>
        <td>{item.make} {item.registration}</td>
        <td>{item.organization}</td>
        <td>KES {parseInt(item.collateral).toLocaleString('en-US')}</td>
        <td>KES {parseInt(item.premium).toLocaleString('en-US')}</td>
        <td>{item.start_date}</td>
        <td>{item.expiry_date}</td>
        {insuaranceStatus(item.expiring_after)}
        </tr>
        )
        })}
        </tbody>

        </table>    
                
        </div>

        <div className='card-footer' >
        
        </div>

        </div>

        </div>
        </div>

        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default ExpiredInsurance;