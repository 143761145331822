import { useEffect, useState } from 'react';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

import MoneyDecoration from '../elements/decorations/MoneyDecoration';
import DateDecoration from '../elements/decorations/DateDecoration';

import CalculatorModal from '../elements/modals/sandbox/CalculatorModal';
import swal from 'sweetalert';

function Calculator()
{
    const [open,setOpen] = useState(false)
    const [schedule,setSchedule] = useState([])

    const [info,setInfo] = useState({
        loan_amount: 0,
        interest_rate:0,
        repayment_period: 0,
        loan_type:'flat',
        error_list: [],
    });

    const [figures,setFigures] = useState({
    installment_amount:0,
    principal:0,
    interest_amount:0,
    processing_fee:0,
    risk_fund:0,
    rtgs_fee:0,
    tracking_fee:0,
    service_fee:0,
    total_repayable:0,
    monthly_principal:0,
    monthly_interest:0,
    })

    

    const handleChange=(e)=>{
    e.persist();
    setInfo({...info, [e.target.name]: e.target.value})
    }

    const simulateLoan=(e)=>{
    e.preventDefault()
    
    const data = {
        loan_amount: info.loan_amount,
        interest_rate: info.interest_rate,
        repayment_period: info.repayment_period,
        loan_type: info.loan_type,
    }

    axios.post('api/calculator',data).then((res)=>{
        if(res.data.status === 200)
        {
            setFigures(res.data.figures)
            setSchedule(res.data.schedule)
            setOpen(false)
            swal("Success","Calculator updated successfully","success")
        }
        else
        {
            setInfo({...info, error_list: res.data.validation_errors})
        }
    })

    }

    const closeModal=()=>{
    setOpen(false)
    }

    
    useEffect(()=>{
        
    },[])

    return (

        <div className="wrapper">

        <CalculatorModal open={open} closeModal={closeModal} simulateLoan={simulateLoan} handleChange={handleChange} info={info} />
        
        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        <ContentHeader title="Calculator" />

        <div className="content">
        <div className="container">

        <div className="row">
        <div className = "col-lg-12" >

            <div className='row'>

            <div className='col-md-12' >

            <div className='card card-outline card-dark' >

            <div className='card-header'>
            <span className='fa fa-info-circle' ></span> Loan Details
            <span className='btn btn-xs btn-dark' style={{float:'right',margin:'1px'}} onClick={()=>setOpen(true)} > <span className='fa fa-chart-line' ></span> Loan Details</span>
            </div>

            <div className='card-body' >
            <div className='row'>

                <div className='col-md-6'>
                <table className='table' >
                <thead>
                    
                </thead>
                <tbody>

                <tr style={{fontSize:'13px',backgroundColor:'rgba(0,0,0,0.8)',color:'white'}} >
                <td>1</td>
                <td> <span className="fa fa-money-bill" ></span> Loan Amount</td>
                <td>KES {parseInt(info.loan_amount).toLocaleString('en-US')}</td>
                </tr>

                <tr style={{fontSize:'13px',fontWeight:'bold'}} >
                <td>2</td>
                <td> Loan Type</td>
                <td>{info.loan_type}</td>
                </tr>
                
                <tr style={{fontSize:'13px'}} >
                <td>3</td>
                <td>Interest Rate</td>
                <td> {info.interest_rate} %</td>
                </tr>

                <tr style={{fontSize:'13px'}} >
                <td>4</td>
                <td>Loan Duration</td>
                <td> {info.repayment_period} months</td>
                </tr>

                <tr style={{fontSize:'13px',backgroundColor:'rgba(0,0,0,0.9)',color:'white'}} >
                <td>5</td>
                <td>Processing Fee</td>
                <td>KES {parseInt(figures.processing_fee).toLocaleString('en-US')}</td>
                </tr>

                </tbody>
                </table>
                </div>

                <div className='col-md-6'>
                <table className='table' >
                <thead>       
                </thead>
                <tbody>                   

                <tr style={{fontSize:'13px',backgroundColor:'rgba(0,0,0,0.9)',color:'white'}} >
                <td>6</td>
                <td>Risk Fund</td>
                <td>KES {parseInt(figures.risk_fund).toLocaleString('en-US')}</td>
                </tr>

                <tr style={{fontSize:'13px'}} >
                <td>7</td>
                <td>RTGS Fee</td>
                <td>KES {parseInt(figures.rtgs_fee).toLocaleString('en-US')}</td>
                </tr>

                <tr style={{fontSize:'13px'}} >
                <td>8</td>
                <td>Principal Amount</td>
                <td>KES {parseInt(figures.principal).toLocaleString('en-US')}</td>
                </tr>

                <tr style={{fontSize:'13px'}} >
                <td>9</td>
                <td>Monthly Installment</td>
                <td>KES {parseInt(figures.installment_amount).toLocaleString('en-US')}</td>
                </tr>

                <tr style={{fontSize:'13px',backgroundColor:'rgba(0,0,0,0.9)',color:'white'}} >
                <td>...</td>
                <td>...</td>
                <td>...</td>
                </tr>

                </tbody>
            </table>
            </div>
            </div>
            </div>

            <div className='card-footer' ></div>
            </div>    

            </div>

           
            </div>

            <div className='row' >
        
            <div className='col-md-12' >

            <div className='card card-outline card-primary' >
            <div className='card-header' >
            <span className='fa fa-calendar' ></span> Amortization Schedule
            
           
            </div>

            <div className='card-body' >
            
            <table className='table' >
            <thead>
            <tr  >
            <td>Period</td>
            <td>Opening Bal</td>
            <td>Tracking Fee</td>
            <td>Service Charge</td>
            <td>Principal Repaid</td>
            <td>Interest</td>
            <td>Monthly Payment</td>
            <td>Closing Bal</td>
            </tr>
            </thead>
            <tbody>
            {schedule.map((item,index)=>{
                return(<tr key={index}>
                    <td>{index+1}</td>
                    <td>KES {parseInt(item.opening_bal).toLocaleString('en-US')}</td>
                    <td>KES {parseInt(item.tracking_fee).toLocaleString('en-US')}</td>
                    <td>KES {parseInt(item.service_charge).toLocaleString('en-US')}</td>
                    <td>KES {parseInt(item.principle_repaid).toLocaleString('en-US')}</td>
                    <td>KES {parseInt(item.interest).toLocaleString('en-US')}</td>
                    <td>KES {parseInt(item.monthly_payment).toLocaleString('en-US')}</td>
                    <td>KES {parseInt(item.closing_bal).toLocaleString('en-US')}</td>
                </tr>)
            })}
            </tbody>
            </table>

            </div>
            </div>

            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default Calculator;