function Sprints({period,handleSprint}){

const quarter = ['Q1','Q2','Q3','Q4']
const month = [1,2,3,4,5,6,7,8,9,10,11,12]

if(period === "weekly")
{
    const week = []
    for(var i=1; i<54; i++)
    {
        week.push(i)
    }

    return(<span className="col-md-3 form-group" >
        <label>Parameter</label>
        <select className="form-control" name="sprint" onChange={(e)=>handleSprint(e)} >
        
        {week.map((w,index)=>{
        return (<option key={index} >{w}</option>)
        })}

        </select>
        </span>)

    
}

if(period === "quarterly")
{
    return(<span className="col-md-3 form-group" >
    <label>Parameter</label>
    <select className="form-control" name="sprint" onChange={(e)=>handleSprint(e)} >
    
    {quarter.map((w,index)=>{
    return (<option key={index} value={index+1} >{w}</option>)
    })}

    </select>
    </span>)
}

if(period === "monthly")
{
    return(<span className="col-md-3 form-group" >
    <label>Parameter</label>
    <select className="form-control" name="sprint" onChange={(e)=>handleSprint(e)} >
    
    {month.map((w,index)=>{
    return (<option key={index} >{w}</option>)
    })}

    </select>
    </span>)
}

}

export default Sprints;