import { Link } from "react-router-dom";

const HrMenu=()=>{
    const permissions = JSON.parse(localStorage.getItem('auth_permissions'));
    const permission = "access_hr";

    if(permissions.includes(permission))
    return(
    <li className="nav-item has-treeview">
        
        <a href="#" className="nav-link">
        <i className="nav-icon fa fa-user-shield"></i>
        <p>
        HR
        <i className="fas fa-angle-right right"></i>
        </p>
        </a>       

        <ul className="nav nav-treeview">

        <li className="nav-item">
        <Link to = '/general_perfomance' className="nav-link">
        <i className="far fa-circle nav-icon"></i>
        <p>Perfomance</p>
        </Link>
        </li>

        <li className="nav-item">
        <Link to = '/teams' className="nav-link">
        <i className="far fa-circle nav-icon"></i>
        <p>Teams</p>
        </Link>
        </li>      

        </ul>

        </li>
)

}

export default HrMenu;