function PerfomanceToggle({mode,changeMode})
{
    if(mode)
    {
        return (
            <span className='badge right ' style={{display:'inlineBlock', fontSize:'18px'}} onClick={changeMode} > <span className='fa fa-toggle-on' ></span></span>
        )
    }
    else{
        return (
            <span className='badge right ' style={{display:'inlineBlock', fontSize:'18px'}}  onClick={changeMode}  > <span className='fa fa-toggle-off' ></span></span>
        )
    }
}

export default PerfomanceToggle;