    import { useReactToPrint } from "react-to-print";
    import { useRef } from "react";

    import Table from 'react-bootstrap/Table';
    import Container from 'react-bootstrap/Container';
    import Row from 'react-bootstrap/Row';
    import Col from 'react-bootstrap/Col';
    import Stack  from 'react-bootstrap/Stack';
    import Image  from 'react-bootstrap/Image';

    import logo from '../../../llogo.jpeg'

    const  OrganizationFullStatement = ({open,onClose,statement,switchPrint,details}) =>{

    const componentRef = useRef()

    const handlePrint = useReactToPrint({
    content:()=> componentRef.current,
    documentTitle: 'Full Account Statement',
    onAfterPrint:()=>onClose()
    })

    let today = new Date().toUTCString().slice(5, 16);
    if(!open) return null;
    return(
    <div className='overlay' onClick={onClose} >
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className = "card card-primary" >
        <div className="card-header" >
        <span className='btn btn-dark btn-sm' style={{margin:'2px'}} onClick={()=>switchPrint()} ><span className='fa fa-file-invoice'></span> Full Statement</span>
        <span className='btn btn-default btn-sm'  style={{margin:'2px'}} onClick={()=>switchPrint()} ><span className='fa fa-file-invoice'></span> Brief Statement</span>
        <span className='closeModal btn btn-sm btn-default' onClick={handlePrint} style={{margin:'2px'}} > <span className='fa fa-print' ></span> Print Statement</span>
        </div>
            
        <div className="card-body">

        <div ref={componentRef} statement={statement} style={{width: '100%'}} >
                
            <Container>
            <Row className="mt-5" style={{borderBottom:'1px solid black'}}>
            <Col>
            <div>
            <Image src={logo} style={{width:'150px'}} ></Image>
            </div>

            <div className="mt-3" >
            <h6><b>Full Account Statement</b></h6>
            </div>

            </Col>

            <Col style={{textAlign:'right'}} >
            <Stack gap={3}>
            <div>Northwave Credit Ltd</div>
            <div>P.O BOX 2760 - 00100</div>
            <div>Nairobi, Kenya</div>
            <div>Tel: 0703999944</div>
            <div>Email: info@northwavecredit.com</div>
            <div>Website: www.northwavecredit.com</div>
            </Stack>
            </Col>
            </Row> 

            <Row className="mt-4" >

            <Col sm={8}> 
            <div className="mb-2" ><b>Client Information</b></div>
            <Stack gap={3}>
            <div><span className="info-holder-two" > Organization:</span> {details.name}</div>
            <div><span className="info-holder-two" > Phone:</span> {details.phone} </div>
            <div><span className="info-holder-two" > Email:</span> {details.email} </div>
            <div><span className="info-holder-two" > Postal Address:</span> {details.postal_address} </div>
            <div><span className="info-holder-two" > Website:</span> {details.website} </div>
            </Stack>
            </Col>

            <Col sm={4}> 
            <div className="mb-2" ><b>Account Summary</b></div>
            <Stack gap={3}>
            <div ><span className="info-holder-two" >Account Credit :</span> KES {parseInt(details.credit).toLocaleString('en-US')} </div>
            <div ><span className="info-holder-two" >Account Balance :</span> KES - {parseInt(details.balance).toLocaleString('en-US')} </div>
            </Stack>
            </Col>

            </Row>       

            <Row className="mt-3" >
           
            <Col>
            <div>
            <small>Auto generated on :  {today}  </small>     
            </div>
            <Table striped bordered hover className="mt-3" >
            <thead>
            <tr>
            <th>#</th>
            <th>Date</th>
            <th>Item</th>
            <th>Debit</th>
            <th>Credit</th>
            <th>Loan Balance</th>
            </tr>
            </thead>
            <tbody>
            {statement.map((item,index)=>{
            
            if(item.type == "debit")
            {
            return(
            <tr key={index}>
            <td>{index+1}</td>
            <td>{item.date.substr(0,10)}</td>
            <td>{item.description}</td>
            <td>KES {parseInt(item.amount).toLocaleString('en-US')}</td>
            <td> -- </td>
            <td>KES {parseInt(item.account_balance).toLocaleString('en-US')}</td>
            </tr>
            )
            }
            else
            {

            return(
            <tr  key={index}>
            <td>{index+1}</td>
            <td>{item.date.substr(0,10)}</td>
            <td>{item.description}</td>
            <td> -- </td>
            <td>KES {parseInt(item.amount).toLocaleString('en-US')}</td>
            <td>KES {parseInt(item.account_balance).toLocaleString('en-US')}</td>
            </tr>
            )
        }

       })}
        </tbody>
        </Table>

        </Col>
        </Row>

        <Row>
                <Col>
                <div>
                
                <p><b>Payments to be remitted to:</b></p>
                    
                <Stack gap={2}>
                <div><span className="info-holder-two">Bank:</span> Prime Bank</div>
                <div><span className="info-holder-two">Branch:</span> Riverside Drive Branch</div>
                <div><span className="info-holder-two">Account Name:</span> Northwave Credit Limited</div>
                <div><span className="info-holder-two">Account Number:</span> 3000166349</div>
                </Stack>
                </div>
                </Col>

                
            </Row>

            <Row>
            <Col>
            <div className="mt-3">
            <p>Kindly present a copy of the statement with payment at our offices.</p>
            <b>NB:</b>
            <div>The statement Balance is as at the date and time of issue. Any fees or charges accrued thereafter will be applicable.</div>
            <div>In the Event where the client wants a loan buy-off to another financer a buy -off fee of 30% will be charged on the outstanding loan balance.</div>
            </div>
            </Col>
            </Row>

        </Container>
       

        </div>

        </div>

        <div className='card-footer' >
        

        </div>

        </div>
   

        </div>
    </div>
    );
}

export default OrganizationFullStatement;