function ProfileHolder({ProfileData,Kin,Kyc,Scans,tab,id,setContact,profile,kin_info,setKinFlag,fetchKin,kyc,initializeKyc,updateKyc,setNewKycFlag,fetchKyc,setScanFlag,documents,fetchDocuments,setNewKin}){

    if(tab === "profile")
    {
        return(<ProfileData setContact={setContact} profile={profile} />)
    }

    if(tab === "kin")
    {
        return(<Kin kin_info={kin_info} setKinFlag={setKinFlag} fetchKin={fetchKin} setNewKin={setNewKin} profile={profile} />)
    }

    if(tab === "kyc")
    {
        return(<Kyc kyc={kyc} initializeKyc={initializeKyc} updateKyc={updateKyc} setNewKycFlag={setNewKycFlag} fetchKyc={fetchKyc}  profile={profile} />)
    }

    if(tab === "scans")
    {
        return(<Scans id ={id} setScanFlag={setScanFlag} documents={documents} fetchDocuments={fetchDocuments}  profile={profile} />)
    }

}

export default ProfileHolder;