import { useEffect, useState } from 'react';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

import EditLeadModal from '../elements/modals/leads/EditLeadModal';
import NewLeadModal from '../elements/modals/leads/NewLeadModal';
import CommentsModal from '../elements/modals/leads/CommentsModal';

import StatusDecoration from '../elements/decorations/StatusDecoration';
import MoneyDecoration from '../elements/decorations/MoneyDecoration';
import DateDecoration from '../elements/decorations/DateDecoration';


function AllLoans()
{
    const[currentPage,setCurrentPage] = useState(1);
    const[pageLimit,setPageLimit] = useState(2);

    const[upperLimit,setUpper] = useState(pageLimit*currentPage);
    const[lowerLimit,setLower] = useState(upperLimit-pageLimit+1);

    const nextPage=(id)=>{
    setCurrentPage(id)
    setUpper(pageLimit*id)
    setLower((pageLimit*id)-pageLimit+1) 
    }

    // State to keep track of leads fetched
    const [leads,setLeads] = useState([]);
    const [results,setResults] = useState([]);

    // State to keep track of comment

    const [comments, setComments] = useState([])
    const [commentsModal, setCommentsModal] = useState(false)

    const [commentDetails, setCommentDetails] = useState({
        person:'',
        lead:'',
        comment:'',
        error_list: [],
    })

    // New lead modal state manager
    const [openModal,setOpenModal] = useState(false);
    const [editModal,setEditModal] = useState(false);

    // State to store loan types fro drop downs
    const [loanTypes,setLoanTypes] = useState([]);

    // Leads details
    const [leadDetails, setLeadDetails] = useState({
        id:'',
        name: '',
        phone: '',
        email: '',
        gender: '',
        dob: '',
        loan_type: '',
        req_amount: '',
        collateral: '',
        source: '',
        lead_type: '',
        valuation: '',
        disbursed: '',
        officer:'',
        error_list: [],
    })

    // Use effect to initialize critical data
    useEffect(()=>{
        
        fetchLoanTypes()
        refreshLeads()

    },[])

    // Fetch loan types
    const fetchLoanTypes=()=>{
        axios.post(`api/loan_types`,).then(res=>{

            if(res.data.status === 200)
            {
                setLoanTypes(res.data.loan_types)
            }

        });
    }

    // Handle input
    const handleInput=(e)=>{

        e.persist();
        setLeadDetails({...leadDetails, [e.target.name]: e.target.value})

    }

    // On close
    const onClose =()=>{
    
    setOpenModal(false)
    setEditModal(false)

    setLeadDetails({
        id:'',
        name: '',
        phone: '',
        email: '',
        gender: '',
        dob: '',
        loan_type: '',
        req_amount: '',
        collateral: '',
        source: '',
        lead_type: '',
        valuation: '',
        disbursed: '',
        officer: '',
        error_list: [],
    })
        
    }

    // Save lead
    const saveLead=(e)=>{

        e.preventDefault()

        const data = {
            staff:localStorage.getItem('auth_id'),
            name:leadDetails.name,
            phone:leadDetails.phone,
            email:leadDetails.email,
            gender:leadDetails.gender,
            dob:leadDetails.dob,
            loan_type:leadDetails.loan_type,
            req_amount:leadDetails.req_amount,
            collateral:leadDetails.collateral,
            source:leadDetails.source,
            lead_type:leadDetails.lead_type,
        }

        axios.get(`sanctum/csrf-cookie`).then(response=>{

            axios.post(`api/new_lead`,data).then(res=>{
                if(res.data.status === 200)
                {
                    swal('Success',res.data.message,"success")
                    setOpenModal(false)
                    setLeadDetails({
                        id:'',
        name: '',
        phone: '',
        email: '',
        gender: '',
        dob: '',
        loan_type: '',
        req_amount: '',
        collateral: '',
        source: '',
        lead_type: '',
        valuation: '',
        disbursed: '',
        officer:'',
        error_list: [],
                    })

                    refreshLeads()
                }
                else{

                    setLeadDetails({...leadDetails, error_list: res.data.validation_errors})

                }
            });

        })


    }

    // refresh leads
    const refreshLeads=()=>{

        const data = {
            id: localStorage.getItem('auth_id'),
        }
        
        axios.post(`api/all_loans`,data).then(res=>{
            
            if(res.data.status === 200)
            {
                setLeads(res.data.leads)
                setResults(res.data.leads)
            }

        });

    }

    // Function to edit lead
    const editLead=(index)=>{

        setEditModal(true)

        setLeadDetails({
            id: results[index].id,
            name: results[index].prospect,
            phone: results[index].phone,
            email: results[index].email,
            gender: results[index].gender,
            dob: results[index].dob,
            loan_type: results[index].loan_code,
            req_amount: results[index].req_amount,
            collateral: results[index].collateral,
            source: results[index].source,
            lead_type: results[index].prospect_type,
            valuation: results[index].valuation_amount,
            disbursed: results[index].disbursed_amount,
            officer: results[index].officer,
            error_list: [],
        })

    }

    // Function to update data
    const updateLead=(e)=>{

        e.preventDefault()

        const data = {

            id: leadDetails.id,
            name: leadDetails.name,
            phone: leadDetails.phone,
            email: leadDetails.email,
            gender: leadDetails.gender,
            dob: leadDetails.dob,
            loan_type: leadDetails.loan_type,
            req_amount: leadDetails.req_amount,
            collateral: leadDetails.collateral,
            source: leadDetails.source,
            lead_type: leadDetails.lead_type,
            valuation: leadDetails.valuation,
            disbursed: leadDetails.disbursed,
            officer: leadDetails.officer,
        }

        axios.post(`api/update_lead`,data).then(res=>{

            if(res.data.status === 200)
            {
                swal('Success',res.data.message,"success")
                refreshLeads()
                onClose()
            }
            else{
                setLeadDetails({...leadDetails, error_list: res.data.validation_errors})
            }

        })

    }


    // Open the comments modal
    const openComments=(index)=>{

        setCommentsModal(true)

        setCommentDetails({
            person:  localStorage.getItem('auth_id'),
            lead: results[index].id,
            comment: '',
            error_list: [],
        });

        fetchComments(results[index].id)

    }

    const closeComment=()=>{

        setCommentsModal(false)

        setCommentDetails({
            person: '',
            lead: '',
            comment: '',
            error_list: [],
        });

    }

    //Save comment 
    const saveComment=(e)=>{

        e.preventDefault()

        const data = {
            person: commentDetails.person,
            lead: commentDetails.lead,
            comment: commentDetails.comment,
        }

        axios.post(`api/new_comment`,data).then(res =>{
        
            if(res.data.status === 200)
            {
                closeComment()
                swal('Success',res.data.message,"success")
            }
            else
            {
                setCommentDetails({...commentDetails, error_list: res.data.validation_errors})
            }

        });

    }

     // Handle input
     const handleComment=(e)=>{

        e.persist();
        setCommentDetails({...commentDetails, [e.target.name]: e.target.value})

    }

    // Fetch comments
    const fetchComments=(lead)=>{

        const data = {
            lead: lead,
        }

        axios.post(`api/comments`,data).then(res=>{

            if(res.data.status === 200)
            {
                setComments(res.data.comments)
            }

        })

    }

    const searchResults = (items,parameter)=>{

        const holder = []

        items.map((item,index)=>{

            if(item.prospect.includes(parameter) || item.phone.includes(parameter)  )
            {
                holder.push(items[index])
            }

        })

        setResults(holder)

    }

    return (

        <div className="wrapper">
        
        <CommentsModal open={commentsModal} onClose={closeComment} handleComment={handleComment} commentDetails={commentDetails} comments={comments} saveComment={saveComment} DateDecoration={DateDecoration} />
       
        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        <ContentHeader title="Disbursed loans" />

        <div className="content">
        <div className="container">

        <div className="row">
        <div className = "col-lg-12" >

        <div className="card card-primary card-outline">
        <div className="card-header">
        <h5 className="card-title">Disbursed loans </h5>
        </div>
        <div className="card-body">

        <div className='row' >
        <div className='col-md-12' style={{paddingLeft:'7px', marginBottom:'15px'}}>

        <span className='btn btn-default btn-xs' style={{margin:'2px', float:'right'}}  > <input type="text"  placeholder="Search..."  onChange={(e)=>searchResults(leads,e.target.value)} /> </span> 
    
        </div>
        </div>

        <table className="table" id="example1">

        <thead>
        <tr style= {{fontWeight : 'bold', fontSize: '14px'}} >
        <td>#</td>
        <td>Date</td>
        <td>Lead Owner</td>
        <td>Prospect</td>
        <td>Type</td>
        <td>Collateral</td>
        <td>Amt Requested</td>
        <td>Valuation</td>
        <td>Amt Disbursed</td>
        <td> <span className='fa fa-comment-alt' ></span> </td>
        </tr>
        </thead>

        <tbody>
        {results.map((val,index)=>{
            return(
                <tr style={{fontSize:'14px'}} key={index} >
                <td>{index+1}</td>
                <td>{DateDecoration(val.created_at)}</td>
                <td >{val.name}  </td>
                <td title={val.phone} >{StatusDecoration(val.prospect_type)} {val.prospect}</td>
                <td>{val.loan_type}</td>
                <td>{val.collateral}</td>
                <td>{MoneyDecoration(val.req_amount)}</td>
                <td>{MoneyDecoration(val.valuation_amount)}</td>
                <td>{MoneyDecoration(val.disbursed_amount)}</td>
                <td> <span className='fa fa-comment-alt' onClick={()=>openComments(index)} ></span> </td>
            </tr>
            )
        })}
        </tbody>

        </table>    
                
        </div>

        <div className='card-footer' ></div>

        </div>

        </div>
        </div>

        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default AllLoans;