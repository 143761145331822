import Cards from "../../cards/Cards";

const LoanDetails=({loanEditFlag,closeLoanEdit,loanDetails})=>{

if(!loanEditFlag) return false;
return (

<div className='overlay' onClick={closeLoanEdit} >
<div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

<div className="card card-primary card-default">

<div className="card-header">
<span className="fa fa-info-circle" ></span> Loan Details
</div>

<div className="card-body">

<div className="row" >
<Cards title='Installments' data = '10' size = 'col-md-4' icon='fa fa-users' stat='100%' />
<Cards title='On time payments' data = '10' size = 'col-md-4' icon='fa fa-users' stat='100%' />
<Cards title='Late Payments' data = '10' size = 'col-md-4' icon='fa fa-users' stat='100%' />
<Cards title='Penalties' data = '10' size = 'col-md-4' icon='fa fa-users' stat='100%' />
<Cards title='Approval Status' data = '10' size = 'col-md-4' icon='fa fa-users' stat='100%' />
<Cards title='Loan Status' data = '10' size = 'col-md-4' icon='fa fa-users' stat='100%' />
</div>

<div className="row" >

<div className="col-md-5" >

<div className="card card-dark card-outline">
<div className="card-header">
<span className="fa fa-info-circle"></span> Loan Summary
</div>

<div className="card-body">

<table className="table" >
    <thead>
        <tr className="small-row">
            <th>#</th>
            <th>Item</th>
            <th>Amount</th>
        </tr>
    </thead>

    <tbody>
        <tr className="small-row row_black">
        <td>1</td>
        <td><span className="fa fa-money-bill" ></span> Amount Lonable</td>
        <td>KES {parseInt(loanDetails.amount_loanable).toLocaleString('en-US')}</td>
        </tr>

        <tr className="small-row">
        <td>2</td>
        <td>Interest Rate</td>
        <td>{loanDetails.interest_rate} %</td>
        </tr>

        <tr className="small-row">
        <td>3</td>
        <td>Loan Duration</td>
        <td>{loanDetails.period} Months</td>
        </tr>

        <tr className="small-row">
        <td>4</td>
        <td>Processing Fee</td>
        <td>KES {loanDetails.processing_fee.toLocaleString('en-US')}</td>
        </tr>

        <tr className="small-row">
        <td>5</td>
        <td>Risk Fund</td>
        <td>KES {loanDetails.risk_fund.toLocaleString('en-US')}</td>
        </tr>

        <tr className="small-row">
        <td>6</td>
        <td>RTGS Fee</td>
        <td>KES {loanDetails.rtgs_fee.toLocaleString('en-US')}</td>
        </tr>

        <tr className="small-row row_black">
        <td>7</td>
        <td><span className="fa fa-coins" ></span> Principle Amount</td>
        <td>KES {parseInt(loanDetails.principal).toLocaleString('en-US')}</td>
        </tr>

        <tr className="small-row row_black">
        <td>8</td>
        <td><span className="fa fa-chart-line" ></span> Total Interest</td>
        <td>KES {parseInt(loanDetails.interest_amount).toLocaleString('en-US')}</td>
        </tr>

        <tr className="small-row">
        <td>9</td>
        <td>Total Service Charge</td>
        <td>KES {parseInt(loanDetails.service_fee).toLocaleString('en-US')}</td>
        </tr>

        <tr className="small-row">
        <td>10</td>
        <td>Total Tracking Fee</td>
        <td>KES {parseInt(loanDetails.tracking_fee).toLocaleString('en-US')}</td>
        </tr>

        <tr className="small-row row_black">
        <td>11</td>
        <td><span className="fa fa-money-bill" ></span> Total Loan Amount</td>
        <td>KES {parseInt(loanDetails.total_repayable).toLocaleString('en-US')}</td>
        </tr>

    </tbody>

</table>

</div>

<div className="card-footer"></div>

</div>

</div>

<div className="col-md-7" >

<div className="card card-primary card-outline" >

<div className="card-header">
<span className="fa fa-clipboard"></span> Loan Notes
</div>

<div  className="card-body">

<div className="row" >

<div className="col-md-6">

<p className="small-font">
<span className="label-holder" >Date Applied:</span>{loanDetails.date_applied.substr(0,10)}
</p>

<p className="small-font">
<span className="label-holder" >Date Disbursed:</span>
</p>

<p className="small-font">
<span className="label-holder" >Due Date:</span>
</p>

 <p className="small-font" >
<span className="label-holder" >Loan Product:</span>{loanDetails.loan_product}
</p>

</div>

<div className="col-md-6">

<p className="small-font" >
<span className="label-holder" >Loan Type:</span>{loanDetails.loan_type}
</p>

<p className="small-font" >
<span className="label-holder" >Monthly Interest:</span>
</p>

</div>

</div>


</div>

<div  className="card-footer"></div>
</div>

<div className="card card-primary card-outline">

<div className="card-header">
<span className="fa fa-info-circle"></span> Loan Statement

<span className="btn btn-dark btn-xs" style={{float:'right'}} ><span className="fa fa-print" ></span> Print statement</span>

</div>

<div className="card-body">

<table class="table" >
<thead>
<tr className="small-row" >
<th>#</th>
<th>Date</th>
<th>Type</th>
<th>Amount</th>
<th>Status</th>
</tr>
</thead>
</table>

</div>

<div className="card-footer"></div>
</div>
</div>

</div>
</div>

<div className="card-footer">

</div>

</div>

</div>
</div>

)

}

export default LoanDetails;