import { Modal } from "react-bootstrap";

const OrganizationContacts=({contacts,initializeContact,setCreateContact, deleteContact,links,organizationStaff})=>{

    return(
        <div className="card card-primary card-outline">

        <div className="card-header">
        <span className="fa fa-user-shield"></span> Staff
        <span className="btn btn-primary btn-sm" style={{float:'right'}} onClick={()=>setCreateContact(true)}><span className='fa fa-user-shield'></span> Create staff</span>
        </div>

        <div className="card-body">
        <table className="table">
        <thead>
        <tr className="small-row" >
        <th>#</th>
        <th>Name</th>
        <th>Phone</th>
        <th>Email</th>
        <th>Role</th>
        <th>Status</th>
        <th><span className="fa fa-edit" style={{color:'green'}} ></span> </th>
        </tr>
        </thead>

        <tbody>
        {contacts.map((item,index)=>{
        return(
            <tr key={index} className="small-row" >
            <td>{index+1}</td>
            <td>{item.first_name} {item.other_name} {item.last_name}</td>
            <td>{item.phone}</td>
            <td>{item.email}</td>
            <td>{item.role}</td>
            <td>{item.status}</td>
            <td><span className="fa fa-edit" style={{color:'green'}} onClick={()=>initializeContact(contacts[index])} ></span> </td>          
            </tr>
        )
        })}
        </tbody>

        </table>

        </div>

        <div className="card-footer">
        <ul className="pagination pagination-sm m-0 float-left">
                        {links.map((item,index)=>{
                            if(item.url !== null)
                            {
                                if(index !== (links.length-1))
                                {
                                    if(item.active)
                                    {
                                    return(<li className="page-item active" key={index}  onClick={()=>organizationStaff(item.label)} ><a className="page-link">{index}</a></li>)
                                    }
                                    else
                                    {
                                    return(<li className="page-item" key={index}  onClick={()=>organizationStaff(item.label)}  ><a className="page-link">{index}</a></li>)
                                    }
                                }
                            }
                        })}
                        </ul>   
        </div>

        </div>
    )

}
export default OrganizationContacts;