const PaydayApprove=({action,takeAction})=>{

   if(action.status === "waiting")
   {
    return(
		<div className="card card-danger ">
		<div className="card-header">
		<h6><span className="fa fa-bell"></span> Action required</h6>
		</div>
		<div className="card-body">
		<span className="btn btn-success btn-xs" style={{margin:'2px'}} onClick={()=>takeAction('approved')} ><span className="fa fa-check-circle"></span> Approve</span>
		<span className="btn btn-dark btn-xs" style={{margin:'2px'}}  onClick={()=>takeAction('defered')}  ><span className="fa fa-minus-circle"></span> Defer</span>
		<span className="btn btn-danger btn-xs" style={{margin:'2px'}}  onClick={()=>takeAction('rejected')}  ><span className="fa fa-times-circle"></span> Reject</span>
		</div>
		</div>
		)
   }
   else{
    return (<></>)
   }

}
export default PaydayApprove;