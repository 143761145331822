import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

function InArrears(){

    const effectRan = useRef(false)


    useEffect(()=>{
        
        if(effectRan.current === false)
        {
           
        }

        return()=>{
        effectRan.current = true
        }

    },[])

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>InArrears</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default InArrears;