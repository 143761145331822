import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import Cards from '../elements/cards/Cards';

function PendingLoans(){

    const [pending,setPednding] = useState([])
    const [stats,setStats] = useState({})
    const effectRun = useRef(false)

    // Fetch pending loans 
    const fetchPending=()=>{
    
    axios.post(`api/pending_loans`).then((res)=>{

        if(res.data.status === 200)
        {
            setPednding(res.data.pending)
        }
    })

    }

    // Fetch pending loans stats
    const fetchStats=()=>{

        axios.post(`api/pending_stats`).then((res)=>{
        
        if(res.data.status === 200)
        {
            setStats(res.data.stats)
        }

        })

    }

    useEffect(()=>{
    

    if(effectRun.current === false)
    {
        fetchPending()
        fetchStats()

        return()=>{
        effectRun.current = true
        }
    }

    },[])

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5> <span className='fas fa-layer-group'></span> Pending Approval</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='row'>
                <Cards title='R.O' data = {stats.ro} size = 'col-md-3' icon='fas fa-layer-group' stat={stats.ro_percentage} />
                <Cards title='HCS' data = {stats.hcs} size = 'col-md-3' icon='fas fa-layer-group' stat={stats.hcs_percentage} />
                <Cards title='Finance' data = {stats.finance} size = 'col-md-3' icon='fas fa-layer-group' stat={stats.finance_percentage} />
                <Cards title='C.E.O' data = {stats.ceo} size = 'col-md-3' icon='fas fa-layer-group' stat={stats.ceo_percentage} />
                </div>

                <div className='row'>
                <div className='col-md-12'>

            <div className='card card-primary card-outline'>
            <div className='card-body'>

            <table className='table'>
            <thead>
            <tr>
            <th>#</th>
            <th>Loan #</th>
            <th>Client</th>
            <th>Loan Type</th>
            <th>Amount </th>
            <th>Period</th>
            <th>Interest</th>
            <th>Stage</th>
            </tr>
            </thead>

            <tbody>
            {pending.map((loan,index)=>{
            return(
                <tr key={index}>
                <td>{index+1}</td>
                <td><Link to={`/loan/${loan.id}`}><span style={{color:'blue'}} >{loan.loan_code}</span></Link></td>
                <td>{loan.first_name} {loan.last_name}</td>
                <td>{loan.type}</td>
                <td>KES {parseInt(loan.amount_loanable).toLocaleString('en-US')}</td>
                <td>{loan.period} Months</td>
                <td>{loan.interest_rate} %</td>
                <td>{loan.approval}</td>
                
                </tr>
            )
            })}
            </tbody>

            </table>

            </div>
            </div>

                </div>
                </div>

                </div>
                </div>

                </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default PendingLoans;