
const NewStaffModal = ({open,onClose,staffDetails,handleInput,registerStaff,roles,departments}) =>{
    if(!open) return null;
    return(
    <div className='overlay' onClick={onClose}>
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className = "card card-primary" >
        <div className="card-header" ><span className='fa fa-user-circle' ></span> New Staff
        <span className='closeModal btn btn-sm btn-default' onClick={onClose}> <span className='fa fa-times-circle' ></span> Close</span>
        </div>
            
        <div className="card-body login-card-body">

        <form onSubmit={registerStaff}>

        <div className='row' >

        <div className = 'col-md-3' >
        <div className="form-group">
        <label>Name <span className='red' >*</span></label>
        <input type="text" className="form-control" name = "name" value={staffDetails.name} onChange={handleInput} />
        <span className="error_holder"  >{staffDetails.error_list.name}</span>
        </div>
        </div>

        <div className = 'col-md-3' >
        <div className="form-group">
        <label>Phone  <span className='red' >*</span></label>
        <input type="text" className="form-control" name = "phone" value={staffDetails.phone} onChange={handleInput} />
        <span className="error_holder" >{staffDetails.error_list.phone}</span>
        </div>
        </div>

        <div className = 'col-md-3' >
        <div className="form-group">
        <label>Email  <span className='red' >*</span></label>
        <input type="email" className="form-control" name = "email"  value={staffDetails.email}  onChange={handleInput} />
        <span className="error_holder" >{staffDetails.error_list.email}</span>
        </div>
        </div>

        <div className='col-md-3'>
        <div className="form-group">
        <label>Gender  <span className='red' >*</span></label>
        <select className="form-control"  name = "gender"   onChange={handleInput}  value={staffDetails.gender} >
        <option>Select Gender</option>
        <option>Male</option>
        <option>Female</option>
        </select>
        <span className="error_holder" >{staffDetails.error_list.gender}</span>
        </div>
        </div>

        <div className='col-md-3'>
        <div className="form-group">
        <label>Department <span className='red' >*</span></label>
        <select className="form-control"  name = "department" onChange={handleInput}  value={staffDetails.department} >
        <option>Select Department</option>
        {departments.map((val,index)=>{
            return(<option value={val.dept_code} key={val.dept_code}>{val.department}</option>);
        })}
        </select>
        <span className="error_holder" >{staffDetails.error_list.department}</span>
        </div>
        </div>

        <div className='col-md-3'>
        <div className="form-group">
        <label>Role <span className='red' >*</span></label>
        <select className="form-control"  name = "role" onChange={handleInput}  value={staffDetails.role}  >
        <option>Select Role</option>
        {roles.map((val,index)=>{
            return(<option value={val.role_code} key={val.role_code}>{val.role}</option>);
        })}
        </select>
        <span className="error_holder" >{staffDetails.error_list.role}</span>
        </div>
        </div>

        <div className="col-md-3">
        <label>Track Perfomance</label>
        <select className="form-control" name ="perfomance" onChange={handleInput}  value={staffDetails.perfomance} >
        <option value=''>Select</option>
        <option value='yes' >yes</option>
        <option value='no' >no</option>
        </select>
        <span className="error_holder">{staffDetails.error_list.perfomance}</span>
        </div>

        <div className="col-md-3">
        <label>Link to payroll</label>
        <select className="form-control" name="payroll" onChange={handleInput}  value={staffDetails.payroll}>
        <option value=''>Select</option>
        <option>yes</option>
        <option>no</option>
        </select>
        <span className="error_holder">{staffDetails.error_list.payroll}</span>
        </div>

        <div className="col-md-3">
        <label>Status</label>
        <select className="form-control" name="status" onChange={handleInput}  value={staffDetails.status}>
        <option value=''>Select</option>
        <option value='active'>active</option>
        <option value='suspended'>suspended</option>
        </select>
        <span className="error_holder">{staffDetails.error_list.status}</span>
        </div>

        </div>

       <div className="row" style={{marginTop:'10px'}}>
       <div className='col-md-12' >
            <div className='form-group' >
                <input type = 'submit' value = 'Save Staff' className='btn btn-primary btn-sm' />
            </div>
        </div>
       </div>

        </form>

        </div>

        <div className='card-footer' style={{color:'red'}}>
        <i>* fields are mandatory </i>
        </div>

        </div>
   

        </div>
    </div>
    );
}

export default NewStaffModal;