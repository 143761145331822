import { useEffect, useState } from 'react';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

import Sprints from '../elements/Sprints';
import Cards from '../elements/cards/Cards';
import MoneyDecoration from '../elements/decorations/MoneyDecoration';

function Report()
{
    
    const [parameter,setParameter] = useState('weekly')

    const [valuations,setValuations] = useState([])
    const [loans,setLoans] = useState([])
    const [agents,setAgents] = useState([])

    const [totalValuations,setTotalValuations] = useState(0)
    const [totalLoans,setTotalLoans] = useState(0)
    const [totalAgents,setTotalAgents] = useState(0)

    const changeParameter = (e)=>{
        setParameter(e.target.value)
        setFilters({...filters, period: e.target.value})
    }

    const [filters, setFilters] = useState({
        entity: 'individual',
        period: parameter,
        sprint: 1,
        year:2022
    })

    const handleSprint=(e)=>{
        setFilters({...filters,[e.target.name]:e.target.value})
    }

    const updateResults=()=>{
       
        const data = {
        entity: filters.entity,
        period: filters.period,
        sprint: filters.sprint,
        year: filters.year
        }

        axios.post(`api/report`,data).then((res)=>{
        if(res.data.status === 200)
        {
            setLoans(res.data.loans)
            setValuations(res.data.valuations)
            setAgents(res.data.agents)

            setTotalValuations(res.data.total_valuations)
            setTotalLoans(res.data.total_loans)
            setTotalAgents(res.data.total_agents)
        }
        })

    }

    return (

        <div className="wrapper">
    
        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        <ContentHeader title="Ranking" />

        <div className="content">
        <div className="container">

        <div className='row' >
        <div className="col-md-12">
        <div className='card card-outline card-primary' >
        <div className='card-body' >

        <div className='row' >

        <div className='col-md-3 form-group' >
        <label>Entity</label>
        <select className='form-control' name = "entity" onChange={(e)=>handleSprint(e)} >
        <option value='individual' >Individuals</option>
        <option value='department' >Departments</option>
        </select>
        </div>
        
        <div className='col-md-3 form-group' >
        <label>Period</label>
        <select className='form-control' onChange={(e)=>changeParameter(e)}>
        <option value='weekly' >Weekly</option>
        <option value='monthly' >Monthly</option>
        <option value='quarterly'>Quarterly</option>
        </select>
        </div>

       
        <Sprints period={parameter} handleSprint={handleSprint}/>
    
        <div className='col-md-3 form-group' >
        <label>Year</label>
        <select className='form-control' name ="year" value={filters.year} onChange={(e)=>handleSprint(e)} >
        <option>2022</option>
        <option>2023</option>
        </select>
        </div>

        </div>

        <div className='row' >
            <div className='col-md-12' >
                <span className='btn btn-primary btn-sm' onClick={()=>updateResults()}> Filter Report</span>
            </div>
        </div>

        </div>
        </div>
        </div>
        </div>

        <div className="row">
        <div className = "col-lg-12" >

        <div className="card card-primary card-outline">

        <div className="card-body">

        <div className='row' >
        <Cards title='Valuations (KES)' data = {MoneyDecoration(totalValuations)} size = 'col-md-4' icon='fa fa-balance-scale' />
        <Cards title='Loans Disbursed (KES)' data = {MoneyDecoration(totalLoans)} size = 'col-md-4' icon='fa fa-money-bill' />
        <Cards title='Sales Agents' data = {totalAgents} size = 'col-md-4' icon='fa fa-users' />        
        </div>

        <div className='row' >

        <div className='col-md-4' >
        <div className='card card-primary card-outline' >

        <div className='card-header' >
        <span className='fa fa-chart-line' ></span> Ranking as per valuations
        </div>
      
        <div className='card-body' >
        <table className='table' >
        <thead>
        <tr>
        <th>#</th>
        <th> {filters.entity} </th>
        <th>amount</th>
        </tr>
        </thead>
        <tbody>
        {valuations.map((val,index)=>{
        return(<tr key={index} style={{fontSize:'14px'}} >
        <td>{index+1}</td>
        <td>{val[0]}</td>
        <td>{MoneyDecoration(val[1])}</td>
        </tr>)
        })}
        </tbody>
        </table>
        </div>

        </div>
        </div>

        <div className='col-md-4' >
        <div className='card card-primary card-outline' >

        <div className='card-header' >
        <span className='fa fa-chart-line' ></span> Ranking as per loans disbursed
        </div>

        <div className='card-body' >
        <table className='table' >
        <thead>
        <tr>
        <th>#</th>
        <th>{filters.entity}</th>
        <th>amount</th>
        </tr>
        </thead>
        <tbody>
        {loans.map((val,index)=>{
        return(<tr key={index} style={{fontSize:'14px'}} >
        <td>{index+1}</td>
        <td>{val[0]}</td>
        <td>{MoneyDecoration(val[1])}</td>
        </tr>)
        })}
        </tbody>
        </table>
        </div>

        </div>
        </div>

        <div className='col-md-4' >
        <div className='card card-primary card-outline' >

        <div className='card-header' >
        <span className='fa fa-chart-line' ></span> Ranking as per sales agents
        </div>

        <div className='card-body' >
        <table className='table' >
        <thead>
        <tr>
        <th>#</th>
        <th>{filters.entity}</th>
        <th>No</th>
        </tr>
        </thead>
        <tbody>
        {agents.map((val,index)=>{
        return(<tr key={index} style={{fontSize:'14px'}} >
        <td>{index+1}</td>
        <td>{val[0]}</td>
        <td>{val[1]}</td>
        </tr>)
        })}
        </tbody>
        </table>
        </div>

        </div>
        </div>
        </div>

        </div>

        <div className='card-footer' >

        </div>

        </div>

        </div>
        </div>

        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default Report;