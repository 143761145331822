import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import Modal from 'react-bootstrap/Modal';

const EditVehicle=({editVehicleFlag,closeEditVehicle,handleVehicle,vehicleDetails,updateVehicle})=>{

useEffect(()=>{

},[])

if(!editVehicleFlag) return false;

return(

<Modal
show={editVehicleFlag}
onHide={closeEditVehicle}
backdrop="static"
keyboard={false}
size="lg"
>
<Modal.Header closeButton>
<Modal.Title>Edit Vehicle</Modal.Title>
</Modal.Header>
<Modal.Body>

<form className='form' onSubmit={updateVehicle} >
<div className='row' >

<div className='col-md-3 form-group' >
<label>Registration # <span className='red' >*</span></label>
<input type="text" className="form-control" name="registration" value={vehicleDetails.registration} onChange={handleVehicle} />
<span className="error_holder"  >{vehicleDetails.error_list.registration}</span>
</div>

<div className='col-md-3 form-group' >
<label>Make <span className='red' >*</span></label>
<input type="text" className="form-control" name="make"  value={vehicleDetails.make} onChange={handleVehicle} />
<span className="error_holder"  >{vehicleDetails.error_list.make}</span>
</div>

<div className='col-md-3 form-group' >
<label>Model <span className='red' >*</span></label>
<input type="text" className="form-control" name="model"  value={vehicleDetails.model} onChange={handleVehicle} />
<span className="error_holder"  >{vehicleDetails.error_list.model}</span>
</div>

<div className='col-md-3 form-group' >
<label>Year <span className='red' >*</span></label>
<input type="number" className="form-control" name="year"  value={vehicleDetails.year} onChange={handleVehicle} />
<span className="error_holder"  >{vehicleDetails.error_list.year}</span>
</div>

<div className='col-md-3 form-group' >
<label>Body</label>
<select className="form-control" name="body"  value={vehicleDetails.body} onChange={handleVehicle} >
    <option value='' >Select Body</option>
    <option value='Sedan' >Sedan</option>
    <option value='S.U.V' >S.U.V</option>
    <option value='Wagon' >Wagon</option>
    <option value='Pickup' >Pickup</option>
    <option value='Van' >Van</option>
    <option value='Hatch Back' >Hatch Back</option>
    <option value='Sport Coupe' >Sport Coupe</option>
</select>
<span className="error_holder"  >{vehicleDetails.error_list.body}</span>
</div>

<div className='col-md-3 form-group' >
<label>Color</label>
<input type="text" className="form-control" name="color"  value={vehicleDetails.color} onChange={handleVehicle} />
<span className="error_holder"  >{vehicleDetails.error_list.color}</span>
</div>

<div className='col-md-3 form-group' >
<label>Transmission <span className='red' >*</span></label>
<select className="form-control" name="transmission"  value={vehicleDetails.transmission} onChange={handleVehicle}>
    <option value='' >Select Transmission</option>
    <option value='automatic' >Automatic</option>
    <option value='manual' >Manual</option>
    <option value='hybrid' >Hybrid</option>
</select>
<span className="error_holder"  >{vehicleDetails.error_list.transmission}</span>
</div>

<div className='col-md-3 form-group' >
<label>Log Book</label>
<input type="text" className="form-control" name="log_book"  value={vehicleDetails.log_book} onChange={handleVehicle} />
<span className="error_holder"  >{vehicleDetails.error_list.log_book}</span>
</div>

<div className='col-md-3 form-group' >
<label>Engine #</label>
<input type="text" className="form-control" name="engine_no"  value={vehicleDetails.engine_no} onChange={handleVehicle} />
<span className="error_holder"  >{vehicleDetails.error_list.engine_no}</span>
</div>

<div className='col-md-3 form-group' >
<label>Chasis #</label>
<input type="text" className="form-control" name="chasis_no"  value={vehicleDetails.chasis_no} onChange={handleVehicle} />
<span className="error_holder"  >{vehicleDetails.error_list.chasis_no}</span>
</div>

<div className='col-md-12 form-group' >
<input type = "submit" value = "Update Vehicle" className="btn btn-sm btn-primary" />
</div>

</div>
</form>

</Modal.Body>
</Modal>

)

}
export default EditVehicle;