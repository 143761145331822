import { useEffect, useState } from 'react';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

import CommentsModal from '../elements/modals/leads/CommentsModal';

import StatusDecoration from '../elements/decorations/StatusDecoration';
import MoneyDecoration from '../elements/decorations/MoneyDecoration';
import DateDecoration from '../elements/decorations/DateDecoration';

import Pagination from '../elements/Pagination';
import MainTable from '../elements/MainTable ';


function AllLeads()
{

    const[currentPage,setCurrentPage] = useState(1);
    const[pageLimit,setPageLimit] = useState(10);

    const[upperLimit,setUpper] = useState(pageLimit*currentPage);
    const[lowerLimit,setLower] = useState(upperLimit-pageLimit+1);

    const nextPage=(id)=>{
    setCurrentPage(id)
    setUpper(pageLimit*id)
    setLower((pageLimit*id)-pageLimit+1) 
    }

    // State to keep track of leads fetched
    const [leads,setLeads] = useState([]);
    const [results,setResults] = useState([]);

    // State to keep track of comment

    const [comments, setComments] = useState([])
    const [commentsModal, setCommentsModal] = useState(false)

    const [commentDetails, setCommentDetails] = useState({
        person:'',
        lead:'',
        comment:'',
        error_list: [],
    })

    // New lead modal state manager
    const [openModal,setOpenModal] = useState(false);
    const [editModal,setEditModal] = useState(false);

    // State to store loan types fro drop downs
    const [loanTypes,setLoanTypes] = useState([]);

    // Leads details
    const [leadDetails, setLeadDetails] = useState({
        id:'',
        name: '',
        phone: '',
        email: '',
        gender: '',
        dob: '',
        loan_type: '',
        req_amount: '',
        collateral: '',
        source: '',
        lead_type: '',
        valuation: '',
        disbursed: '',
        officer:'',
        error_list: [],
    })

    // Use effect to initialize critical data
    useEffect(()=>{
        
        fetchLoanTypes()
        refreshLeads()

    },[])

    // Fetch loan types
    const fetchLoanTypes=()=>{
        axios.post(`api/loan_types`,).then(res=>{

            if(res.data.status === 200)
            {
                setLoanTypes(res.data.loan_types)
            }

        });
    }

    // refresh leads
    const refreshLeads=()=>{

        const data = {
            id: localStorage.getItem('auth_id'),
        }
        
        axios.post(`api/all_leads`,data).then(res=>{
            
            if(res.data.status === 200)
            {
                setLeads(res.data.leads)
                setResults(res.data.leads)
            }

        });

    }


    // Open the comments modal
    const openComments=(index)=>{

        setCommentsModal(true)

        setCommentDetails({
            person:  localStorage.getItem('auth_id'),
            lead: results[index].id,
            comment: '',
            error_list: [],
        });

        fetchComments(results[index].id)

    }

    const closeComment=()=>{

        setCommentsModal(false)

        setCommentDetails({
            person: '',
            lead: '',
            comment: '',
            error_list: [],
        });

    }

    //Save comment 
    const saveComment=(e)=>{

        e.preventDefault()

        const data = {
            person: commentDetails.person,
            lead: commentDetails.lead,
            comment: commentDetails.comment,
        }

        axios.post(`api/new_comment`,data).then(res =>{
        
            if(res.data.status === 200)
            {
                closeComment()
                swal('Success',res.data.message,"success")
            }
            else
            {
                setCommentDetails({...commentDetails, error_list: res.data.validation_errors})
            }

        });

    }

     // Handle input
     const handleComment=(e)=>{

        e.persist();
        setCommentDetails({...commentDetails, [e.target.name]: e.target.value})

    }

    // Fetch comments
    const fetchComments=(lead)=>{

        const data = {
            lead: lead,
        }

        axios.post(`api/comments`,data).then(res=>{

            if(res.data.status === 200)
            {
                setComments(res.data.comments)
            }

        })

    }

    const searchResults = (items,parameter)=>{

        const holder = []

        items.map((item,index)=>{

            if(item.prospect.includes(parameter) || item.phone.includes(parameter)  )
            {
                holder.push(items[index])
            }

        })

        setResults(holder)

    }

    return (

        <div className="wrapper">
        
        <CommentsModal open={commentsModal} onClose={closeComment} handleComment={handleComment} commentDetails={commentDetails} comments={comments} saveComment={saveComment} DateDecoration={DateDecoration} />
       
        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        <ContentHeader title="Leads Board" />

        <div className="content">
        <div className="container">

        <div className="row">
        <div className = "col-lg-12" >

        <div className="card card-primary card-outline">
        <div className="card-header">
        <h5 className="card-title">Leads particulars </h5>
        </div>

        <div className="card-body">

        <div className='row' >
        <div className='col-md-12' style={{paddingLeft:'7px', marginBottom:'15px'}}>

        <span className='btn btn-default btn-xs' style={{margin:'2px', float:'right'}}  > <input type="text"  placeholder="Search..."  onChange={(e)=>searchResults(leads,e.target.value)} /> </span> 
    
        </div>
        </div>

        <MainTable leads={results} lowerLimit={lowerLimit} upperLimit={upperLimit}  StatusDecoration={StatusDecoration} MoneyDecoration={MoneyDecoration} openComments={openComments} />

        </div>

        <div className='card-footer' >
        <Pagination currentPage={currentPage} setCurrentPage={nextPage} pageLimit={pageLimit} totalItems={results.length} />
        </div>

        </div>

        </div>
        </div>

        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default AllLeads;