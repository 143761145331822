import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Nav from '../nav/Nav';
import swal from 'sweetalert';
import Aside from '../nav/Aside';
import Footer from '../nav/Footer';
import ControlSidebar from '../nav/ControlSidebar';

import OrganizationHolder from './organizations/OrganizationHolder';
import EditOrganization from '../elements/modals/payday/Organization/EditOrganization';
import EditDesignated from '../elements/modals/payday/Organization/EditDesignated';
import CreateContact from '../elements/modals/payday/Organization/CreateContact';
import NewDocument from '../elements/modals/payday/Organization/NewDocument';
import NewPayment from '../elements/modals/payday/Payments/NewPayment';

import OrganizationFullStatement from '../elements/print/OrganizationFullStatement';
import OrganizationBriefStatement from '../elements/print/OrganizationBriefStatement';

const Organization=()=>{

    const parameters = useParams()
    const organization = parameters.id
    const effectRun = useRef(false)

    const [tab,setTab] = useState('summary')
    const [editOrg,setEditOrg] = useState(false)
    const [editContact,setEditContact] = useState(false)
    const [createContact,setCreateContact] = useState(false)
    const [createDocument,setCreateDocument] = useState(false)
    const [links,setLinks] = useState([])
    const [label,setLabel] = useState(1)

    const [payFlag,setPayFlag] = useState(false)

    const [open,setOpen] = useState(false)
    const [brief,setBrief] = useState(false)

    const [details,setDetails] = useState({})
    const [manager,setManager] = useState({})
    const [contacts,setContacts] = useState([])
    const [documents,setDocuments] = useState([])
    const [statement,setStatement] = useState([])
    const [invoices,setInvoices] = useState([])
    const [loans,setLoans] = useState([])
    const [roles,setRoles] = useState([])

    const [users,setUsers] = useState({})

    const [scan,setScan] = useState(null)

    const [accSummary,setAccSumary] = useState({
        total_invoiced:0,
        total_charges:0,
        total_paid:0
    })

    const [document,setDocument] = useState({
    entry_by: localStorage.getItem('auth_id'),
    organization:organization,
    id:'na',
    name:'',
    error_list: []
    })

    const [contact,setContact] = useState({
        id:'na',
        organization:organization,
        first_name:'',
        last_name:'',
        other_name:'',
        phone:'',
        email:'',
        id_number:'',
        payroll:'',
        employment_date:'',
        employment_type:'',
        contract_expiry:'',
        status:'approved',
        gross_income:0,
        net_income:0,
        total_deduction:0,
        role:'',
        role_id:'',
        error_list: [],
    })

    const [payment,setPayment] = useState({
    organization: organization,
    date:'',
    amount:1,
    mode:'',
    reference:'',
    error_list: [],
    })

    const recordPayment=()=>{
    
    const data = {
    organization: payment.organization,
    date: payment.date,
    amount: payment.amount,
    mode: payment.mode,
    reference: payment.reference,
    }

    axios.post('api/record_payday_payment',data).then((res)=>{
        if(res.data.status === 200)
        {
            swal("Success",res.data.message,"swal")

            setPayment({
            organization: organization,
            date:'',
            amount:0,
            mode:'',
            reference:'',
            error_list: [],
            })

            closePayFlag()

        }
        else
        {
            setPayment({...payment, error_list: res.data.validation_errors})
        }
    })

    }

    const handlePayment=(e)=>{
        setPayment({...payment, [e.target.name]: e.target.value })
    }

    const fetchRoles=()=>{
        axios.post(`api/roles`).then((res)=>{
            if(res.data.status === 200)
            {
                setRoles(res.data.roles)
            }
        })
    }

    const fetchDetails=()=>{
    
    const data = {
    organization:organization
    }

    axios.post(`api/organization_summary`,data).then((res)=>{

    if(res.data.status === 200)
    {
        setDetails(res.data.details)
        setManager(res.data.manager)
        setContacts(res.data.contacts.data)
        setLinks(res.data.contacts.links)
        setDocuments(res.data.documents)

        const fetchedDetails = res.data.details

    setEditDetails({
    id: fetchedDetails.id,
    organization: fetchedDetails.name,
    phone: fetchedDetails.phone,
    email: fetchedDetails.email,
    website: fetchedDetails.website,
    postal_address: fetchedDetails.postal_address,
    city: fetchedDetails.city,
    account_manager: fetchedDetails.manager,
    status: fetchedDetails.status,
    error_list:[]
    })

    }

    })

    }

    const [editDetails,setEditDetails] = useState({
    id:'',
    organization:'',
    phone:'',
    email:'',
    website:'',
    postal_address:'',
    city:'',
    account_manager:'',
    status:'',
    error_list: [],
    })

    const closeEditOrg=()=>{
    setEditOrg(false)
    }

    const closeEditContact=()=>{
    setEditContact(false)
    }

    const closeCreateContact=()=>{
    setCreateContact(false)
    }

    const closeCreateDocument=()=>{
    setCreateDocument(false)
    }

    // Fetch Users
    const fetchUsers=()=>{

    axios.post(`api/users`).then((res)=>{
    if(res.data.status)
    {
    setUsers(res.data.users)
    }
    })
    
    }

    // Update Organization
    const updateOrganization=(e)=>{
    e.preventDefault()
    const data = {
    id: editDetails.id,
    organization: editDetails.organization,
    phone: editDetails.phone,
    email: editDetails.email,
    website: editDetails.website,
    postal_address: editDetails.postal_address,
    city: editDetails.city,
    account_manager: editDetails.account_manager,
    status: editDetails.status,
    }

    axios.post(`api/update_organization`,data).then((res)=>{
        if(res.data.status === 200)
        {
            closeEditOrg()
            fetchDetails()
            swal("Success",res.data.message,'success')
        }
        else
        {
            setEditDetails({...editDetails, error_list: res.data.validation_errors})
        }
    })

    }

    const clearContact=()=>{
        setContact({ id:'',
        organization: '',
        first_name: '',
        last_name: '',
        other_name: '',
        phone: '',
        email: '',
        role: '',
        role_id:'',
        error_list: [],})
    }

    // Create new contact
    const saveContact=(e)=>{
    e.preventDefault()

    const data = {
        id:'na',
        organization:organization,
        first_name: contact.first_name,
        last_name: contact.last_name,
        other_name: contact.other_name,
        phone: contact.phone,
        email: contact.email,
        id_number: contact.id_number,
        payroll: contact.payroll,
        employment_date: contact.employment_date,
        employment_type: contact.employment_type,
        contract_expiry: contact.contract_expiry,
        status: contact.status,
        gross_income: contact.gross_income,
        net_income: contact.net_income,
        total_deductions: contact.total_deduction,
        role: contact.role,
    }

    axios.post(`api/create_staff`,data).then((res)=>{
        if(res.data.status === 200)
        {
            clearContact()
            closeCreateContact()
            fetchDetails()
            swal("Success",res.data.message,"success")
        }
        else
        {
            setContact({...contact, error_list: res.data.validation_errors})
        }
    })

    }

    // Update contact
    const updateContact=(e)=>{
    e.preventDefault()

    const data = {
        id: contact.id,
        organization: contact.organization,
        first_name: contact.first_name,
        last_name: contact.last_name,
        other_name: contact.other_name,
        phone: contact.phone,
        email: contact.email,
        designation: contact.role_id,
    }

    axios.post(`api/update_contact`,data).then((res)=>{
        if(res.data.status === 200)
        {
            fetchDetails()
            setEditContact(false)
            contact.error_list = []
            swal('Success',res.data.message,'success')
        }
        else
        {
            setContact({...contact, error_list:res.data.validation_errors})
        }
    })

    }

    // Upload document
    const uploadDocument=(e)=>{
    e.preventDefault()

        if(scan === null)
        {
            swal("Warning",'A file upload is required','warning')
        }
        else
        {
            const formData = new FormData()

    formData.append('entry_by',document.entry_by)
    formData.append('organization',document.organization)
    formData.append('name',document.name)
    formData.append('scan',scan,scan.name)

    axios.post(`api/upload_orgDocs`,formData).then((res)=>{
        if(res.data.status === 200)
        {   
            closeCreateDocument()
            fetchDetails()
            swal("Success",res.data.message,"success")
        }
        else
        {
            setDocument({...document, error_list: res.data.validation_errors})
        }
        })
        }


        }

    const fetchStatement=()=>{
    
    const data = {
        organization:organization,
    }

    axios.post(`api/payday_statement`,data).then((res)=>{

        if(res.data.status === 200)
        {
            setStatement(res.data.statement)
        }

    })

    }

    const fetchInvoices=()=>{
    
    axios.get(`api/organization_invoices/${organization}`).then((res)=>{
        if(res.data.status === 200)
        {
            setInvoices(res.data.invoices)
            setAccSumary(res.data.account_summary)
        }
    })

    }

    const fetchLoans=()=>{

        const data = {
        organization:organization
        }

        axios.post(`api/fetch_organization_loans`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
            setLoans(res.data.loans)
        }

        })

    }

    const organizationStaff=(label)=>{
    
    var url = "api/organization_staff/"+organization+"/"+"?page="+label
    axios.get(url).then((res)=>{
    
    if(res.data.status == 200)
    {
        setContacts(res.data.contacts.data)
        setLinks(res.data.contacts.links)
    }

    })

    }

    const initializeContact=(person)=>{

    contact.id = person.id
    contact.organization = person.organization
    contact.first_name = person.first_name
    contact.last_name = person.last_name
    contact.other_name = person.other_name
    contact.phone = person.phone
    contact.email = person.email
    contact.role = person.role
    contact.role_id = person.role_id
    setEditContact(true)
    }

    const deleteContact=(id,organization)=>{
    
    const data = {
    id:id,
    organization: organization
    }

    axios.post(`api/delete_designated`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        fetchDetails()
        swal("Success",res.data.message,'success')
    }
    else if(res.data.status === 402)
    {
        swal("Warning",res.data.message,'warning')
    }

    })

    }

    const closePayFlag=()=>{
    setPayFlag(false)
    }

    

    const handleDetails=(e)=>{
    setEditDetails({...editDetails, [e.target.name]: e.target.value})
    }

    const handleContact=(e)=>{
    setContact({...contact, [e.target.name]: e.target.value})
    }

    const handleDocument=(e)=>{
        setDocument({...document, [e.target.name]: e.target.value})
    }

    const handleScan=(e)=>{
        setScan(e.target.files[0])
    }

    const onClose=()=>{
    setOpen(false)
    }

    const closeBrief=()=>{
    setBrief(false)
    }

    const switchPrint=()=>{
    
    if(open)
    {
        setBrief(true)
        setOpen(false)
    }
    else
    {
        setBrief(false)
        setOpen(true)
    }

    }

    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchRoles()
        fetchDetails()
        fetchUsers()
        fetchStatement()
        fetchInvoices()
        fetchLoans()
    }

    return()=>{
        effectRun.current = true
    }

    },[])

    return (
        <div className="wrapper">
        <OrganizationFullStatement open={open} onClose={onClose} switchPrint={switchPrint} statement={statement} details={details} ></OrganizationFullStatement>
        <OrganizationBriefStatement open={brief} onClose={closeBrief} switchPrint={switchPrint} details={details} accSummary={accSummary}></OrganizationBriefStatement>
        <NewPayment payFlag={payFlag} closePayFlag={closePayFlag} payment={payment} handlePayment={handlePayment} recordPayment={recordPayment} />
        <EditOrganization editOrg={editOrg} closeEditOrg={closeEditOrg} details={editDetails} handleDetails={handleDetails} users={users} updateOrganization={updateOrganization} />
        <EditDesignated editContact={editContact} closeEditContact={closeEditContact} contact={contact} handleContact={handleContact} updateContact={updateContact} roles={roles}  />
        <CreateContact createContact={createContact} closeCreateContact={closeCreateContact} contact={contact} handleContact={handleContact} saveContact={saveContact} roles={roles} />
        <NewDocument createDocument={createDocument} closeCreateDocument={closeCreateDocument} document={document} handleScan={handleScan} handleDocument={handleDocument} uploadDocument={uploadDocument} />

        <Nav/>
        <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5><span className='fa fa-landmark'></span> {details.name}</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='row'>
                
                <div className='col-md-3'>

                <div className='card card-primary card-outline'>
                <div className='card-body box-profile'>
                
                <ul className="list-group list-group-unbordered mb-3">

                <li className="list-group-item" onClick={()=>setTab('summary')}>
                <b><span className='fa fa-clipboard'></span> Summary</b>
                </li>

                <li className="list-group-item" onClick={()=>setTab('contacts')} >
                <b><span className="fa fa-users"></span> Staff</b>
                </li>

                <li className="list-group-item" onClick={()=>setTab('loans')} >
                <b><span className="fa fa-file-invoice"></span> Loans</b>
                </li>

                <li className="list-group-item" onClick={()=>setTab('statement')} >
                <b><span className="fa fa-file-invoice"></span> Statement</b>
                </li>

                <li className="list-group-item" onClick={()=>setTab('invoices')}  >
                <b><span className="fa fa-file-invoice"></span> Invoices</b>
                </li>

                <li className="list-group-item" onClick={()=>setTab('documents')} >
                <b><span className='fa fa-folder'></span> Documents</b>
                </li>

                </ul>

                </div>
                </div>

                <div className='card card-primary card-outline'>
                <div className="card-header">
                <b>Action Pane </b>
                </div>
                <div className='card-body box-profile'>
                
                <ul className="list-group list-group-unbordered mb-3">

                <li className="list-group-item">
                <span className='btn btn-success btn-sm' onClick={()=>setPayFlag(true)} style={{width:'100%'}} ><i className="nav-icon fas fa-money-bill" ></i> Record payment</span>
                </li>

                </ul>

                </div>
                </div>
                
                </div>

                <div className='col-md-9'>
                <OrganizationHolder tab={tab} details={details} contacts={contacts} documents={documents} manager={manager} setEditOrg={setEditOrg} initializeContact={initializeContact} setCreateContact={setCreateContact} setCreateDocument={setCreateDocument} deleteContact={deleteContact}  statement={statement} invoices={invoices} loans={loans} setOpen={setOpen} fetchDetails={fetchDetails} links={links} organizationStaff={organizationStaff} />
                </div>

                </div>

                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default Organization;