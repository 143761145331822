import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import swal from 'sweetalert';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import Footer from '../nav/Footer';
import ContentHeader from '../nav/ContentHeader';
import CardBody from '../elements/cards/CardBody';
import ControlSidebar from '../nav/ControlSidebar';

import AddKpi from '../elements/modals/kpis/AddKpi';
import EditTarget from '../elements/modals/kpis/EditTarget';
import AddPermission from '../elements/modals/permissions/AddPermission';

function Role()
{
    const role = useParams().id
    const effectRun = useRef(false)
    const [open,setOpen] = useState(false)
    const [permissions,setPermissions] = useState([])
    const [openTarget,setOpenTarget] = useState(false)
    const [openPermission,setOpenPermission] = useState(false)
    const [categories,setCategories] = useState([])
    const [missing,setMissing] = useState([])
    const [assignedPermissions,setAssignedPermissions] = useState([])

    const [target,setTarget] = useState(2)
    const [kpi_id,setKpi] = useState('')

    const [missingKpis,setMissingKpis] = useState([])
    const [assigned,setAssigned] = useState([])

    const fetchMissing=()=>{
    
    const data = {
        role:role,
    }

    axios.post(`api/missing_kpis`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        setMissingKpis(res.data.kpis)
    }

    })

    }

    const fetchAssigned=()=>{

    const data = {
        role:role
    }

    axios.post(`api/fetch_assigned`,data).then((res)=>{
        if(res.data.status === 200)
        {
            setAssigned(res.data.kpis)
        }
    })

    }

    const fetchPermissions=()=>{

        const data = {
            role:role
        }

        axios.post(`api/assigned_permissions`,data).then((res)=>{
            if(res.data.status === 200)
            {
                setPermissions(res.data.permissions)
            }
        })
    }

    const fetchCategories=()=>{
    
        axios.post(`api/permissions_categories`).then((res)=>{
            if(res.data.status === 200)
            {
                setCategories(res.data.categories)
            }
        })

    }

    const fetchMissingPermissions=()=>{

    const data = {
    role:role
    }

    axios.post(`api/missing_permissions`,data).then((res)=>{

        if(res.data.status === 200)
        {
            setMissing(res.data.permissions)
        }

    })

    }

    const removePermission=(id)=>{

        const data = {
            id:id
        }

        axios.post(`api/remove_permission`,data).then((res)=>{

            if(res.data.status === 200)
            {
                fetchPermissions()
                fetchMissingPermissions()
            }

        })

    }

    const deleteKpi=(id)=>{
    
    }

    const onClose =()=>{
    setOpen(false)
    }

    const closeTarget=()=>{
        setTarget(2)
        setKpi('')
        setOpenTarget(false)
    }

    const editTarget=(id,target)=>{
    setTarget(target)
    setKpi(id)
    setOpenTarget(true)
    }

    const handleTarget=(e)=>{
    setTarget(e.target.value)
    }

    const AssignKpi=(id)=>{

        const data = {
            role:role,
            kpi:id,
        }

        axios.post(`api/save_kpi`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
            swal("Success",res.data.message,'success')
            fetchMissing()
            fetchAssigned()
        }

        })

    }

    const updateKpi=(e)=>{
    e.preventDefault()
    const data = {
        id:kpi_id,
        target: target
    }

    axios.post(`api/update_target`,data).then((res)=>{
    
    if(res.data.status == 200)
    {
        fetchAssigned()
        closeTarget()
        swal("Success",res.data.message,'success')
    }

    })

    }

    const closePermission=()=>{
    setOpenPermission(false)
    }

    const assignPermission=(permission)=>{

        const data = {
            role:role,
            permission: permission
        }

        axios.post(`api/assign_permission`,data).then((res)=>{

        if(res.data.status === 200)
        {
            fetchMissingPermissions()
            fetchPermissions()
        }

        })

    }

    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchMissing()
        fetchAssigned()
        fetchCategories()
        fetchPermissions()
        fetchMissingPermissions()
    }

    return()=>{
    effectRun.current = true
    }

    })

    return (
        <div className="wrapper">
        
        <AddPermission openPermission={openPermission} closePermission={closePermission} permissions={missing} categories={categories} assignPermission={assignPermission} />
        <AddKpi open={open} onClose={onClose} missingKpis={missingKpis} AssignKpi={AssignKpi}  />
        <EditTarget openTarget={openTarget} closeTarget={closeTarget} target={target} handleTarget={handleTarget} updateKpi={updateKpi} />

        <Nav/>
        <Aside/>

        <div className="content-wrapper">

        <ContentHeader title="System Settings" />

        <div className="content">
        <div className="container">
        <div className="row">
            
        <div className="col-lg-3"  >
        
        <div className="card card-widget widget-user-2"  >
        <CardBody/>
        </div>

        </div>

        <div className = "col-lg-9" id = "centralSettings">
        
        <div className="card card-primary card-outline">
        <div className="card-header">
        <h5 className="card-title m-0">Role 
        <span className='btn btn-xs btn-primary float-right' style={{margin:'2px'}} onClick={()=>setOpen(true)}><span className='fa fa-plus-circle'></span> New Kpi</span> 
        <span className='btn btn-xs btn-primary float-right'  style={{margin:'2px'}} onClick={()=>setOpenPermission(true)} ><span className='fa fa-plus-circle'></span> New Permission</span> </h5>
        </div>

        <div className="card-body">

        <div className='row'>
            <div className='col-md-12'>

                <div className='card card-primary card-outline'>
                <div className='card-header'>
                <h6>Assigned Kpi's</h6>
                </div>
                <div className='card-body'>
                <table className='table'>
                <thead>
                <tr className='small-row' >
                <td>#</td>
                <td>Kpi</td>
                <td>Target</td>
                <td> <span className='fa fa-edit'  style={{color:'green'}}  ></span> </td>
                <td><span className='fa fa-trash red'></span>  </td>
                </tr>
                </thead>

                <tbody>
                    {assigned.map((item,index)=>{
                    return(
                        <tr className='small-row' key={index}>
                            <td>{index+1}</td>
                            <td>{item.kpi}</td>
                            <td>{item.target}</td>
                            <td> <span className='fa fa-edit' style={{color:'green'}} onClick={()=>editTarget(item.id,item.target)} ></span> </td>
                            <td><span className='fa fa-trash red' onClick={()=>deleteKpi(item.id)} ></span></td>
                        </tr>
                    )
                    })}
                </tbody>

                </table>
                </div>
                </div>

                <div className='card card-primary card-outline'>
                <div className='card-header'>
                <h6>Assigned Permissions</h6>
                </div>
                <div className='card-body'>
                <table className="table">
            <thead>
                <tr className="small-row">
                    <th>#</th>
                    <th>Category</th>
                    <th>Permissions</th>
                </tr>
            </thead>
            <tbody>
                {categories.map((item,index)=>{
                return(
                    <tr key={index} className="small-row">
                        <td>{index+1}</td>
                        <td>{item.category}</td>
                        <td>
                            {permissions.map((perm,element)=>{
                                {if(perm.category == item.category){
                                    return(<span className="btn btn-dark btn-xs" key={element} style={{width:'100px',margin:'2px',fontSize:'9px'}}><input type="checkbox" style={{display:'inline'}} checked onClick={()=>removePermission(perm.id)}></input> <span style={{display:'inline'}}>{perm.permission} </span></span>)
                                }}
                            })}
                        </td>
                    </tr>
                )
                })}
            </tbody>
        </table>
                </div>
            </div>

            </div>
        </div>
           
        </div>

        <div className='card-footer' ></div>

        </div>

        </div>

        </div>
        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default Role;