import { Link } from "react-router-dom";
const RoleNav=({page,link,icon,roles})=>{

return(roles.includes(localStorage.getItem('auth_role'))? 
<li className="nav-item">
<Link to={link} className="nav-link">
<i className={icon} ></i>
<p>{page}</p>
</Link>
</li> :'')
    
}
export default RoleNav;