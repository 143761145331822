import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

import PaydayNext from './PaydayNext';
import PaydayPrevious from './PaydayPrevious';
import PayHeader from './PayHeader';
import OrganizationHolder from './OrganizationHolder';
import { Modal } from 'react-bootstrap';

const NewOrganization=({open,onClose,users,fetchOrganizations,roles})=>{

const [documents,setDocuments] = useState([])

const [stage,setStage] = useState(1);

const [details,setDetails] = useState({
    id:'na',
    organization:'',
    phone:'',
    email:'',
    website:'',
    postal_address:'',
    city:'',
    account_manager:'',
    max_duration:6,
    interest_rate:1,
    error_list: [],
    })

    const handleDetails=(e)=>{
    setDetails({...details, [e.target.name]: e.target.value})
    }

    const [contact,setContact] = useState({
    id:'na',
    organization:'',
    first_name:'',
    last_name:'',
    other_name:'',
    phone:'',
    email:'',
    id_number:'',
    payroll:'',
    employment_date:'',
    employment_type:'',
    contract_expiry:'',
    status:'approved',
    gross_income:0,
    net_income:0,
    total_deduction:0,
    role:'',
    error_list: [],
    })

    const handleContact=(e)=>{
    setContact({...contact, [e.target.name]: e.target.value})
    }

    const [scan,setScan] = useState(null)

    const [document,setDocument] = useState({
    entry_by: localStorage.getItem('auth_id'),
    organization:'',
    id:'na',
    name:'',
    error_list: []
    })

    const handleDocument=(e)=>{
        setDocument({...document, [e.target.name]: e.target.value})
    }

    const handleScan=(e)=>{
        setScan(e.target.files[0])
    }

    const uploadDocument=()=>{
    
    if(scan === null)
    {
        swal('Warning','A file upload is required.','warning')
    }
    else
    {
        const formData = new FormData()
        formData.append('name',document.name)
        formData.append('scan',scan,scan.name)
        formData.append('entry_by',document.entry_by)
        formData.append('organization',document.organization)

        axios.post(`api/upload_orgDocs`,formData).then((res)=>{
            
        if(res.data.status === 200)
        {
            swal('Success',res.data.message,'success')
            setDocuments(res.data.documents)
        }
        else
        {
            setDocument({...document, error_list: res.data.validation_errors})
        }

        })

    }

    }

    const clearData=()=>{
    
    setDetails({
        id:'na',
        organization:'',
        phone:'',
        email:'',
        website:'',
        postal_address:'',
        city:'',
        account_manager:'',
        max_duration:6,
        interest_rate:1,
        error_list:[],
    })

    setContact({
        id:'na',
        organization:'',
        first_name:'',
        last_name:'',
        other_name:'',
        phone:'',
        email:'',
        id_number:'',
        payroll:'',
        employment_type:'',
        contract_expiry:'',
        status:'',
        gross_income:0,
        net_income:0,
        total_deduction:0,
        role:'',
        error_list: [],
    })

    setDocument({
        entry_by: localStorage.getItem('auth_id'),
        organization:'',
        id:'na',
        name:'',
        error_list: []
    })

    setDocuments({})

    }

    const processStage=()=>{

    if(stage === 1)
    {
    const data = {
        id:details.id,
        organization: details.organization,
        phone: details.phone,
        email: details.email,
        website: details.website,
        postal_address: details.postal_address,
        city: details.city,
        max_duration:details.max_duration,
        interest_rate:details.interest_rate,
        account_manager: details.account_manager,  
    }

    axios.post(`api/create_organization`,data).then((res)=>{

        if(res.data.status === 200)
        {
            swal('Success',res.data.message,'success')
            fetchOrganizations()
            onClose()
                        
        }
        else
        {
            setDetails({...details, error_list: res.data.validation_errors})
        }

    })
    }
    
   
    }

    const processPrevious=()=>{

        if(stage > 1)
        {
        setStage(stage-1)
        }

    }

if(!open) return false;
return(
<Modal
            show={open}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title>New Organization</Modal.Title>
            </Modal.Header>
            <Modal.Body>                

            <div className='card card-primary' >

            <div className='card-body'>
            <OrganizationHolder open={open} stage={stage} setStage={setStage} users={users} details={details} handleDetails={handleDetails} contact={contact} handleContact={handleContact} document={document} handleScan={handleScan} handleDocument={handleDocument} uploadDocument={uploadDocument} documents={documents} roles={roles} />
            </div>

            </div>
            </Modal.Body>

            <Modal.Footer>
            <PaydayNext processStage={processStage} stage={stage} />
            <PaydayPrevious processPrevious={processPrevious}  stage={stage} />
            </Modal.Footer>

            </Modal>
)

}
export default NewOrganization;