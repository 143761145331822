import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

function Log(){

    const effectRan = useRef(false)
    const [logs,setLogs] = useState([])
    const [links,setLinks] = useState([])

    const fetchLogs=()=>{
        axios.get(`api/logs`).then((res)=>{
            if(res.data.status === 200)
            {
                setLogs(res.data.logs.data)
                setLinks(res.data.logs.links)
            }
        }).catch(error=>{
            if(error.message == "Request failed with status code 403")
                {
                    swal("Warning","You are not authorized to perfom this action","warning")
                }
                else
                {
                    swal("Warning",error.message,"warning")
                }
        })
    }

    
    const fecthPage=(label)=>{
        var url = "api/logs?page="+label
        axios.get(url).then((res)=>{
            if(res.data.status === 200)
            {
                setLogs(res.data.logs.data)
                setLinks(res.data.logs.links)
            }
            }).catch(error=>{
                if(error == "AxiosError: Request failed with status code 401")
                {
                    swal("Warning",error.message,"warning")
                }
            })
    }

    useEffect(()=>{
        
        if(effectRan.current === false)
        {
           fetchLogs()
        }

        return()=>{
        effectRan.current = true
        }

    },[])

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">

                <div className="col-sm-6">
                <h5>Activity log</h5>
                </div>
     
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card card-primary card-outline'>
                            <div className='card-header'>
                                <span className='fa fa-users' ></span> Users activity logs
                            </div>
                            <div className='card-body'>
                                <table className='table'>
                                <thead>
                                    <tr className='small-row' >
                                        <td>#</td>
                                        <td>Date</td>
                                        <td>Action</td>
                                        <td>Author</td>
                                        <td>IP Address</td>
                                        <td>Category</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {logs.map((log,index)=>{
                                        return(
                                        <tr className='small-row' key={index}>
                                        <td>{index+1}</td>
                                        <td>{log.created_at.substr(0,10)} {log.created_at.substr(11,8)}</td>
                                        <td>{log.log}</td>
                                        <td>{log.name}</td>
                                        <td>{log.ip_address}</td>
                                        <td>{log.category}</td>
                                        </tr>
                                        )
                                    })}
                                </tbody>
                                </table>
                            </div>

                            <div className='card-footer'>
                            <ul className="pagination pagination-sm m-0 float-left">
                        {links.map((item,index)=>{
                            if(item.url !== null)
                            {
                                if(index !== (links.length-1))
                                {
                                    if(item.active)
                                    {
                                    return(<li className="page-item active" key={index} onClick={()=>fecthPage(item.label)}  ><a className="page-link">{index}</a></li>)
                                    }
                                    else
                                    {
                                    return(<li className="page-item" key={index} onClick={()=>fecthPage(item.label)}  ><a className="page-link">{index}</a></li>)
                                    }
                                }
                            }
                        })}
                        </ul>
                            </div>

                        </div>
                    </div>
                </div>
                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default Log;