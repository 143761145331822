const LoanHeader=({stage})=>{

    if(stage === 0)
    {
        return(
            <div className="col-md-12" >
    
            <div className="loan-container" >
            <span className="event_holder" style={{backgroundColor:'green'}} >1</span>
            <p style={{fontWeight:'bold'}} ><span className="fa fa-clipboard" ></span> Smart Check</p>
            </div>
    
            <div className="loan-container" >
            <span className="event_holder bg-dark " >2</span>
            <p><span className="fa fa-file-invoice" ></span> Loan Information</p>
            </div>

            <div className="loan-container" >
            <span className="event_holder bg-dark " >3</span>
            <p><span className="fa fa-file-invoice" ></span> Loan Documents</p>
            </div>
    
            <span className="progress-line"></span>
    
            </div>
        )
    }else if(stage === 1)
    {
        return(
            <div className="col-md-12" >
            
            <div className="loan-container" >
            <span className="event_holder" style={{backgroundColor:'green',paddingTop:'2px'}} ><span className="fa fa-check" ></span></span>
            <p style={{fontWeight:'bold'}} ><span className="fa fa-clipboard" ></span> Smart Check</p>
            </div>

            <div className="loan-container" >
            <span className="event_holder bg-dark" style={{backgroundColor:'green'}} >2</span>
            <p style={{fontWeight:'bold'}} ><span className="fa fa-user-circle" ></span> Loan Information</p>
            </div>
    
            <div className="loan-container" >
            <span className="event_holder bg-dark" >3</span>
            <p><span className="fa fa-file-invoice" ></span> Loan Documents</p>
            </div>
    
            <span className="progress-line"></span>
    
            </div>
        )
    }else if(stage === 2)
    {
        return(
            <div className="col-md-12" >
            
            <div className="loan-container" >
            <span className="event_holder" style={{backgroundColor:'green',paddingTop:'2px'}} ><span className="fa fa-check" ></span></span>
            <p style={{fontWeight:'bold'}} ><span className="fa fa-clipboard" ></span> Smart Check</p>
            </div>

            <div className="loan-container" >
            <span className="event_holder" style={{backgroundColor:'green',paddingTop:'2px'}} ><span className="fa fa-check" ></span></span>
            <p style={{fontWeight:'bold'}} ><span className="fa fa-clipboard" ></span> Loan Information</p>
            </div>
    
            <div className="loan-container" >
            <span className="event_holder bg-dark" >3</span>
            <p><span className="fa fa-file-invoice" ></span> Loan Documents</p>
            </div>
    
            <span className="progress-line"></span>
    
            </div>
        )
    }

   
}
export default LoanHeader;