

const NewLeadModal = ({open,onClose,loanTypes,handleInput,leadDetails,saveLead}) =>{
    if(!open) return null;
    return(
    <div className='overlay' onClick={onClose}>
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className = "card card-primary" >
        <div className="card-header" ><span className='fa fa-user-circle' ></span> New Lead
        <span className='closeModal btn btn-sm btn-default' onClick={onClose}> <span className='fa fa-times-circle' ></span> Close</span>
        </div>
            
        <div className="card-body login-card-body">

        <form onSubmit={saveLead}>

        <div className='row' >

        <div className = 'col-md-3' >
        <div className="form-group">
        <label>Name <span className='red' >*</span></label>
        <input type="text" className="form-control" name = "name" onChange={handleInput} />
        <span className="error_holder" >{leadDetails.error_list.name}</span>
        </div>
        </div>

        <div className = 'col-md-3' >
        <div className="form-group">
        <label>Phone  <span className='red' >*</span></label>
        <input type="text" className="form-control" name = "phone" onChange={handleInput} />
        <span className="error_holder" >{leadDetails.error_list.phone}</span>
        </div>
        </div>

        <div className = 'col-md-3' >
        <div className="form-group">
        <label>Email</label>
        <input type="email" className="form-control" name = "email" onChange={handleInput} />
        <span className="error_holder" >{leadDetails.error_list.email}</span>
        </div>
        </div>

        <div className='col-md-3'>
        <div className="form-group">
        <label>Gender  <span className='red' >*</span></label>
        <select className="form-control"  name = "gender" onChange={handleInput} >
        <option>Select gender</option>
        <option>Male</option>
        <option>Female</option>
        </select>
        <span className="error_holder" >{leadDetails.error_list.gender}</span>
        </div>
        </div>

        <div className = 'col-md-3' >
        <div className="form-group">
        <label>Date of birth</label>
        <input type="date" className="form-control" name = "dob" onChange={handleInput} />
        <span className="error_holder" >{leadDetails.error_list.dob}</span>
        </div>
        </div>

        <div className='col-md-3'>
        <div className="form-group">
        <label>Loan Type <span className='red' >*</span></label>
        <select className="form-control"  name = "loan_type" onChange={handleInput} >
        <option>Select loan type</option>
        {loanTypes.map((val,index)=>{
            return(<option value={val.loan_code} key={val.loan_code}>{val.loan_type}</option>)
        })}
        </select>
        <span className="error_holder" >{leadDetails.error_list.loan_type}</span>
        </div>
        </div>

        <div className = 'col-md-3' >
        <div className="form-group">
        <label>Requested Amount <span className='red' >*</span></label>
        <input className="form-control"  name = "req_amount" type = "number" onChange={handleInput} />
        <span className="error_holder" >{leadDetails.error_list.req_amount}</span>
        </div>
        </div>

        <div className = 'col-md-3' >
        <div className="form-group">
        <label>Collateral <span className='red' >*</span></label>
        <input type="text" className="form-control" name = "collateral" onChange={handleInput}  />
        <span className="error_holder" >{leadDetails.error_list.collateral}</span>
        </div>
        </div>

        <div className = 'col-md-6' >
        <div className="form-group">
        <label>Source <span className='red' >*</span></label>
        <input type="text" className="form-control" name = "source" onChange={handleInput}  />
        <span className="error_holder" >{leadDetails.error_list.source}</span>
        </div>
        </div>

        <div className='col-md-6'>
        <div className="form-group">
        <label>Lead type <span className='red' >*</span></label>
        <select className="form-control"  name = "lead_type" onChange={handleInput} >
        <option>Select lead type</option>
        <option>Hot</option>
        <option>Warm</option>
        <option>Cold</option>
        </select>
        <span className="error_holder" >{leadDetails.error_list.lead_type}</span>
        </div>
        </div>

        </div>

        <div className='row' >
            <div className='form-group' >
                <input type = 'submit' value = 'Save Lead' className='btn btn-primary btn-sm' />
            </div>
        </div>

        </form>

        </div>

        <div className='card-footer' style={{color:'red'}}>
        <i>* fields are required </i>
        </div>

        </div>
   

        </div>
    </div>
    );
}

export default NewLeadModal;