const NewKpi=({open,onClose,details,handleKpi,saveKpi})=>{
    if(!open) return null;

    return(
        <div className='overlay' onClick={onClose}>
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

            <div className="card card-primary card">
                <div className="card-header">
                <h4>New Kpi</h4>
                </div>

                <div className="card-body">

                <form onSubmit={(e)=>saveKpi(e)} >

                    <div className='row' >

                    <div className = 'col-md-12' >
                    <div className="form-group">
                    <label>Name <span className='red' >*</span></label>
                    <input type="text" className="form-control" name = "kpi"  value={details.kpi} onChange={(e)=>handleKpi(e)} />
                    <span className="red">{details.error_list.kpi}</span>
                    </div>
                    </div>

                    <div className = 'col-md-12' >
                    <div className="form-group">
                    <label>Description <span className='red' >*</span></label>
                    <input type="text" className="form-control" name = "description" value={details.description}  onChange={(e)=>handleKpi(e)} />
                
                    </div>
                    </div>

                    </div>

                    <div className='row' >
                    <div className='form-group' >
                    <input type="submit" className="btn btn-primary btn-sm" value="Save KPI" />    
                    </div>
                    </div>

                </form>

                </div>

                <div className="card-footer"></div>
            </div>

        </div>
        </div>
    )

}
export default NewKpi;