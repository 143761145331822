import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

import CeoManual from '../elements/modals/payments/CeoManual';
import FinanceManual from '../elements/modals/payments/FinanceManual';
import PrintPayments from '../elements/print/PrintPayments';
import Confirm from '../elements/modals/utility/Confirm';

import Modal from 'react-bootstrap/Modal';

function Payments()
{
    const effectRun = useRef(false)
    const [payments,setPayments] = useState([])
    const [links,setLinks] = useState([])
    const [flag,setFlag] = useState(false)
    const [financeFlag,setFinanceFlag] = useState(false)
    const [page,setPage] = useState(1)
    const [paymentId,setPaymentId] = useState(null)
    const [open,setOpen] = useState(false)
    const [id,setId] = useState(null)

    const [confirm,setConfirm] = useState(false)

    const closeConfirm=()=>{
        setConfirm(false)
        setId(false)
    }

    const initializeCancel=(id)=>{
        setId(id)
        setConfirm(true)
    }

    const navigate = useNavigate()

    const onClose=()=>{
        setFlag(false)
        setPaymentId(null)
    }

    const closePayment=()=>{
        setOpen(false)
    }

    const financeClose=()=>{
        setFinanceFlag(false)
        setPaymentId(null)
    }

    const [searchDetails,setSearchDetails] = useState({
        start_date:'2023-01-01',
        end_date:'',
        error_list:[]
    })

    const handleDetails=(e)=>{
        setSearchDetails({...searchDetails, [e.target.name]: e.target.value})
    }

    const searchPayments=(e)=>{

        e.preventDefault()
        const data = {
            start_date: searchDetails.start_date,
            end_date: searchDetails.end_date
        }

        axios.post(`api/search_payments`,data).then((res)=>{
            if(res.data.status == 200)
            {
                setPayments(res.data.payments.data)
                setLinks(res.data.payments.links)
                setSearchDetails({...searchDetails, error_list: []})
            }
            else
            {
                setSearchDetails({...searchDetails, error_list:res.data.validation_errors})
            }
        })

    }

    // Create function to initialize approval

    const initializeFinanceApproval=(id)=>{
        setFinanceFlag(true)
        setPaymentId(id)
    }

    const initializeCeoApproval=(id)=>{
        setFlag(true)
        setPaymentId(id)
    }

    const fetchPayments=()=>{
    
    axios.get(`api/payments`).then((res)=>{
    
        if(res.data.status === 200)
        {
            setPayments(res.data.payments.data)
            setLinks(res.data.payments.links)
        }

    })

    }

    const chargeCheque=(id)=>{
        
    
        axios.get(`api/charge_cheque/`+id).then((res)=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success")
                closeConfirm()
                fetchPayments()            
            }
            else if(res.data.status === 403)
            {
                swal("Warning",res.data.message,"warning")
            }

        }).catch(error=>{
            if(error.message == "Request failed with status code 403")
                {
                    swal("Warning","You are not authorized to perfom this action","warning")
                }
                else
                {
                    swal("Warning",error.message,"warning")
                }
        })
    
        }

        const fecthPage=(label)=>{
        var url = "api/payments?page="+label
        axios.get(url).then((res)=>{
            if(res.data.status === 200)
            {
                setPayments(res.data.payments.data)
                setLinks(res.data.payments.links)
                setPage(label)
            }
            }).catch(error=>{
                if(error == "AxiosError: Request failed with status code 401")
                {
                  localStorage.clear()
                  navigate('/login');
                }
            })
        }

        // Ability for CEO to confirm manual payment
        const ceo_confirm=(id)=>{
        
            const data = {
                id:id
            }

            axios.post(`api/ceo_confirm_manual`,data).then((res)=>{
                if(res.data.status == 200)
                {
                    swal("Success",res.data.message,'sucess')
                    onClose()
                    fecthPage(page)
                }
            }).catch(error=>{

                if(error.message == "Request failed with status code 403")
                {
                    swal("Warning","You are not authorized to perfom this action","warning")
                }
                else
                {
                    swal("Warning",error.message,"warning")
                }
                
            })

        }

        // Ability for CEO to reject
        const ceo_reject=(id)=>{
        
            const data = {
                id:id
            }

            axios.post(`api/ceo_reject_manual`,data).then((res)=>{
                if(res.data.status == 200)
                {
                    swal("Success",res.data.message,'sucess')
                    onClose()
                    fecthPage(page)
                }
            }).catch(error=>{

                if(error.message == "Request failed with status code 403")
                {
                    swal("Warning","You are not authorized to perfom this action","warning")
                }
                else
                {
                    swal("Warning",error.message,"warning")
                }
                
            })

        }

        const confirmManual=(id)=>{
        const data = {
            id: id
        }

        axios.post(`api/confirm_manual`,data).then((res)=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success")
                fetchPayments()
                financeClose()
            }
        }).catch(error=>{

            if(error.message == "Request failed with status code 403")
            {
                swal("Warning","You are not authorized to perfom this action","warning")
            }
            else
            {
                swal("Warning",error.message,"warning")
            }
            
        })

        }

        const rejectManual=(id)=>{
            const data = {
                id: id
            }
    
            axios.post(`api/reject_manual`,data).then((res)=>{
                if(res.data.status === 200)
                {
                    swal("Success",res.data.message,"success")
                    fetchPayments()
                    financeClose()
                }
            }).catch(error=>{
    
                if(error.message == "Request failed with status code 403")
                {
                    swal("Warning","You are not authorized to perfom this action","warning")
                }
                else
                {
                    swal("Warning",error.message,"warning")
                }
                
            })
    
        }

        // Show Finance status on the payment
        const paymentStatus=(status,id)=>{

        if(status == "live")
        {
            return(
                <td style={{color:'green'}} > <span className='fa fa-check-circle'></span> cleared</td>
            )
        }else if(status == "rejected")
        {
            return(
                <td style={{color:'orange'}} > <span className='fa fa-times-circle'></span> {status}</td>
            )
        }
        else if(status == "pending")
        {
            return(
                <td style={{color:'red'}} > <span onClick={()=>initializeFinanceApproval(id)} > <span className='fa fa-user-shield'></span> Action </span> </td>
            )
        }else{

            return(
                <td style={{color:'black'}} >na</td>
            )
        }

        }
        
        // Show C.E.O  status on the payment
        const ceoStatus=(status,id)=>{

        if(status == "cleared")
        {
            return(
                <td style={{color:'green'}} > <span className='fa fa-check-circle'></span> cleared</td>
            )
        }else if(status == "rejected")
        {
            return(
                <td style={{color:'orange'}} > <span className='fa fa-times-circle'></span> {status}</td>
            )
        }
        else if(status == "pending")
        {
            return(
                <td style={{color:'red'}} > <span onClick={()=>initializeCeoApproval(id)} ><span className='fa fa-user-shield'></span> Action</span> </td>
            )
        }else{
            return(
                <td style={{color:'black'}} >na</td>
            )
        }

        }

        const cancel_status=(status,id)=>{
        
        if(status == "no")
        {
            return(
                <td> <span title='Cancel for bounced payments' onClick={()=>initializeCancel(id)} > Cancel</span></td>
            )
        }
        else
        {
            return(
                <td> <span style = {{color:'red'}} > Bounced</span></td>
            )
        }

        }

    // Use effect to initialize critical data
    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchPayments()
    }

    return()=>{
    effectRun.current = true
    }

    },[])

    return (

        <div className="wrapper">
        
        <Modal
        show={financeFlag}
        onHide={financeClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        >
        <Modal.Header closeButton>
        <Modal.Title>Action on Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <span className='btn btn-success btn-sm m-1' onClick={()=>confirmManual(paymentId)} > <span className='fa fa-check-circle'></span> Confirm Payment</span>
        <span className='btn btn-danger btn-sm m-1' onClick={()=>rejectManual(paymentId)}> <span className='fa fa-times-circle'></span> Reject Payment</span>
        </Modal.Body>
        </Modal>

        <Modal
        show={flag}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        >
        <Modal.Header closeButton>
        <Modal.Title>C.E.O action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <span className='btn btn-success btn-sm m-1' onClick={()=>ceo_confirm(paymentId)} > <span className='fa fa-check-circle'></span> Confirm Payment</span>
        <span className='btn btn-danger btn-sm m-1' onClick={()=>ceo_reject(paymentId)}> <span className='fa fa-times-circle'></span> Reject Payment</span>
        </Modal.Body>
        </Modal>

        <Modal
        show={confirm}
        onHide={closeConfirm}
        backdrop="static"
        keyboard={false}
        size="lg"
        >
        <Modal.Header closeButton>
        <Modal.Title>Are you sure ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <span className='btn btn-success btn-sm m-1' onClick={()=>chargeCheque(id)} > <span className='fa fa-check-circle'></span> Yes</span>
        <span className='btn btn-danger btn-sm m-1' onClick={()=>closeConfirm()}> <span className='fa fa-times-circle'></span> No</span>
        </Modal.Body>
        </Modal>

        <PrintPayments open={open} onClose={closePayment} statement={payments}></PrintPayments>
        
        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        

        <div className="content">
        <div className="container">

        <div className="row" style={{marginTop:'20px'}} >
        <div className = "col-lg-12" >

        <div className="card card-primary card-outline">
        <div className="card-header">
        <h6 className="card-title"> <span className='fa fa-coins'></span> Payments</h6>
        </div>
        <div className="card-body">

        <form className='form' method="post" onSubmit={searchPayments} >

        <div className='row' style={{marginBottom:'20px'}} >

            <div className='col-md-4'>
                <div className='form-group'>
                    <label><span className='fa fa-calendar-alt'></span> Start Date</label>
                    <input type = 'date' className='form-control' name= 'start_date' value={searchDetails.start_date} onChange={handleDetails}  min='2023-01-01' ></input>
                    <span className='error_holder'>{searchDetails.error_list.start_date}</span>
                </div>
            </div>

            <div className='col-md-4'>
                <div className='form-group'>
                    <label><span className='fa fa-calendar-alt'></span> End Date</label>
                    <input type = 'date' className='form-control' name= 'end_date' value={searchDetails.end_date} onChange={handleDetails} min={searchDetails.start_date} ></input>
                    <span className='error_holder'>{searchDetails.error_list.end_date}</span>
                </div>
            </div> 

            <div className='col-md-4'>
                <div className='form-group' style={{marginTop:'35px'}} >
                    <input type = 'submit' className='btn btn-sm btn-success m-1' value='Search Payments' ></input>
                    <span className='btn btn-dark btn-sm m-1' onClick={()=>setOpen(true)} > <span className='fa fa-print'></span> Print Payment</span>
                </div>
            </div>            

            </div>

        </form>

        <table className="table" >

        <thead>
        <tr style={{fontSize:'12px'}} >
        <th>#</th>
        <th>Entry date</th>
        <th>Payment date</th>
        <th>Contact</th>
        <th>Amount</th>
        <th>Mode</th>
        <th>Reference</th>
        <th>Finance</th>
        <th>C.E.O</th>
        <th>Cancel</th>
        </tr>
        </thead>

        <tbody>
        {payments.map((item,index)=>{
        

        return(
            <tr style={{fontSize:'11px'}} key = {index}>
                <td>{index+1}</td>
                <td>{item.created_at.substr(0,10)}</td>
                <td>{item.entry_date.substr(0,10)}</td>
                <td>{item.first_name} {item.other_name} {item.last_name}</td>
                <td>KES {parseInt(item.amount).toLocaleString('en-US')}</td>
                <td>{item.payment_mode}</td>
                <td>{item.reference}</td>
                {paymentStatus(item.status,item.id)}
                {ceoStatus(item.ceo,item.id)}
                {cancel_status(item.canceled, item.id)}
            </tr>
            )

        })}
        </tbody>

        </table>    
                
        </div>

        <div className='card-footer' >
        <ul className="pagination pagination-sm m-0 float-left">
        {links.map((item,index)=>{
        if(item.url !== null)
        {
            if(index !== (links.length-1))
            {
                if(item.active)
                {
                return(<li className="page-item active" key={index} onClick={()=>fecthPage(item.label)}  ><a className="page-link">{index}</a></li>)
                }
                else
                {
                return(<li className="page-item" key={index} onClick={()=>fecthPage(item.label)}  ><a className="page-link">{index}</a></li>)
                }
            }
        }
        })}
        </ul>
        </div>

        </div>

        </div>
        </div>

        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default Payments;