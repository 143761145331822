const AddMember=({open,onClose,members,users,addPerson})=>{

    if(!open) return null;
    return(
    
    <div className='overlay' onClick={onClose}>
    <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >
    
    <div className="card card-primary card-outline">
    <div className="card-header">
    <h5><span className="fa fa-users"></span> Add Members</h5>
    </div>
    <div className="card-body">

        <div className="row" style={{maxHeight:'300px', overflow:'scroll'}}> 

        {users.map((items,index)=>{
            return(<div className="col-md-4">

            <div className="card card-widget widget-user-2">
            <div className="widget-user-header bg-warning">
            <div className="widget-user-image">
            <img className="img-circle elevation-2" src="../dist/img/avatar5.png" alt="User Avatar" />
            </div>
                    
            <h5 className="widget-user-desc">{items.name}</h5>
            <h6 className="widget-user-desc">{items.role}</h6>
            <div>
            <span className="btn btn-dark btn-xs" style={{marginLeft:'7px'}} onClick={()=>addPerson(items.id)} ><span className="fa fa-plus"></span> Add to team</span>
            </div>
            </div>
            </div>
    
            </div> )
        })}
               
        </div>

    </div>

    <div className="card-footer"></div>
    </div>

    </div>
    </div>

    )

}

export default AddMember;