import DateDecoration from "../../decorations/DateDecoration";


function Invoice({open,closeModal,onInvoice,makeInvoice,invoiceDetails})
{

    if(!open) return false;

    return(
        <div className='overlay' onClick={closeModal} >
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className="card card-primary card-outline" >
        <div className="card-header" >
        Invoices
        <span className='closeModal btn btn-xs btn-danger' onClick={()=>closeModal()} > <span className='fa fa-times-circle' ></span> Close modal</span>
        </div>

        <div className='card-body' >

        <div className="row" >
        <div className="col-md-12" >

        <div className="card card-success card-outline" >
                    <div className="card-header" >
                        <h6>Make invoice</h6>
                    </div>

                    <div className="card-body" >
                    <form className="form" onSubmit={(e)=>makeInvoice(e)}>

                    <div className="row" >

                    <div className="form-group col-md-4" >
                    <label>Invoice Date</label>
                    <input type = "date" className="form-control" name = "date" onChange={onInvoice} />
                    <span className="error_holder"  >{invoiceDetails.error_list.date}</span>
                    </div>

                    <div className="form-group col-md-4" >
                    <label>Item</label>
                    <input type = "text" className="form-control" name = "item" onChange={onInvoice} />
                    <span className="error_holder"  >{invoiceDetails.error_list.date}</span>
                    </div>

                    <div className="form-group col-md-4" >
                    <label>Amount</label>
                    <input type = "number" className="form-control" name = "amount" onChange={onInvoice} />
                    <span className="error_holder"  >{invoiceDetails.error_list.amount}</span>
                    </div>

                    </div>

                    <div className="row" >
                        <div className="col-md-12" >
                            <input type = "submit" className="btn btn-success btn-sm" value="Save Invoice" />
                        </div>
                    </div>

                    </form>
                    </div>

                    </div>

            
                    </div>
                    </div>

        </div>




<div className="card-footer">
</div>

</div>
</div>
</div>
    )

}

export default Invoice;