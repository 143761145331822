const OrganizationInvoices=({invoices})=>{

return (
<div className="card card-primary card-outline">

<div className="card-header">
<h6>
<span className="fa fa-file-invoice"></span> Invoices
</h6>
</div>

<div className="card-body">
<table className="table">
    <thead>
        <tr className='small-row' >
            <th>#</th>
            <th>Date</th>
            <th>Description</th>
            <th>Amount</th>
            <th>Paid</th>
            <th>Balance</th>
            <th>More</th>
        </tr>
    </thead>
    <tbody>
        {invoices.map((items,index)=>{
            return(
            <tr className='small-row' >
                <td>{index+1}</td>
                <td>{items.date}</td>
                <td>{items.description}</td>
                <td>KES {parseInt(items.amount).toLocaleString('en-US')}</td>
                <td>KES {parseInt(items.paid).toLocaleString('en-US')}</td>
                <td>KES {parseInt(items.balance).toLocaleString('en-US')}</td>
                <td>More</td>
            </tr>
            )
        })}
    </tbody>
</table>
</div>

<div className="card-footer">
</div>

</div>
)

}
export default OrganizationInvoices;