function StatusDecoration(status){

    if(status === 'Hot')
    {
        return (<span className='fa fa-fire' style = {{color:'red'}} ></span>);
    }

    if(status === 'Warm')
    {
        return (<span className='fa fa-circle' style = {{color:'gray'}} ></span>);
    }

    if(status === 'Cold')
    {
        return (<span className='fa fa-snowflake' style = {{color:'blue'}} ></span>);
    }

}

export default StatusDecoration;