import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

import Table from 'react-bootstrap/Table';
import ValuationsReport from '../elements/print/ValuationsPrint';

function ValuationReport(){

    const [valuations,setValuations] = useState([])
    const [valuers,setValuers] = useState([])
    const [open,setOpen] = useState(false)
    const navigate = useNavigate()

    const onClose=()=>{
    setOpen(false)
    }

    const effectRun = useRef(false)

    const [details,setDetails] = useState({
        start_date:'2023-01-01',
        end_date:'',
        valuer:'All',
        status:'All'
    })

    const handleChange=(e)=>{
    setDetails({...details, [e.target.name]: e.target.value})
    }

    const fetchValuers=()=>{
        
        const data = {
            category:'car valuer'
        }

        axios.post(`api/query_provider`,data).then((res)=>{
            
            if(res.data.status === 200)
            {
                setValuers(res.data.providers)
            }

        }).catch(error=>{
            if(error == "AxiosError: Request failed with status code 403")
            {
              navigate('/unauthorized');
            }
        })

    }

    const fetchValuations=()=>{
    
    axios.post(`api/valuations_report`).then((res)=>{
        if(res.data.status === 200)
        {
            setValuations(res.data.valuations)
            setDetails({...details, end_date:res.data.current_date})
        }
    }).catch(error=>{
        if(error == "AxiosError: Request failed with status code 403")
        {
          navigate('/unauthorized');
        }
    })

    }

    const queryValuations=()=>{

        const data = {
            start_date: details.start_date,
            end_date: details.end_date,
            valuer: details.valuer,
            status: details.status
        }

        axios.post(`api/query_valuations`,data).then((res)=>{
            if(res.data.status === 200)
            {
                setValuations(res.data.valuations)
            }
        })

    }

    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchValuations()
        fetchValuers()
    }

    return()=>{
    effectRun.current = true
    }

    })

    return (
        <div className="wrapper">
            <ValuationsReport open={open} onClose={onClose} details={details} entries={valuations} ></ValuationsReport>
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>Valuations Report</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >
                <div className='row'>
                <div className='col-md-12'>

                <div className='card card-primary card-outline'>
                <div className='card-header'>
                <h5>Particulars
                <span className='btn btn-dark btn-sm float-right m-1' onClick={()=>setOpen(true)} ><span className='fa fa-print'></span> Print Report </span>
                <span className='btn btn-dark btn-sm float-right m-1' onClick={()=>fetchValuations()}><span className='fa fa-print'></span> Clear </span>
                <span className='btn btn-dark btn-sm float-right m-1' onClick={()=>queryValuations()} ><span className='fa fa-search'></span> Search </span>
                </h5>
                </div>

                <div className='card-body'>
                
                <div className='row'>

                <div className='form-group col-md-3'>
                <label>Start Date</label>
                <input type="date" className='form-control' name="start_date" value={details.start_date} onChange={handleChange} ></input>
                </div>

                <div className='form-group col-md-3'>
                <label>End Date</label>
                <input type="date" className='form-control'  name="end_date" value={details.end_date} onChange={handleChange}  ></input>
                </div>

                <div className='form-group col-md-3'>
                <label>Valuer</label>
                <select className='form-control'  name="valuer" value={details.valuer} onChange={handleChange}  >
                    <option>All</option>
                    {valuers.map((item,index)=>{
                    return(<option key={index} value={item.id} >{item.name} ({item.organization}) </option>)
                    })}
                </select>
                </div>

                <div className='form-group col-md-3'>
                <label>Status</label>
                <select className='form-control'  name="status" value={details.status} onChange={handleChange}  >
                <option>All</option>
                <option>approved</option>
                <option>rejected</option>
                <option>pending</option>
                </select>
                </div>

                </div>               

                <Table hover striped>
                    <thead>
                        <tr className='small-row'>
                            <th>#</th>
                            <th>Date</th>
                            <th>Contact</th>
                            <th>Valuer</th>
                            <th>Vehicle</th>
                            <th>Forced Value</th>
                            <th>Market Value</th>
                            <th>Payment</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    {valuations.map((item,index)=>{
                    return(
                    <tr className='small-row' key={index}>
                        <td>{index+1}</td>
                        <td>{item.created_at.substr(0,10)}</td>
                        <td>{item.first_name} {item.last_name}</td>
                        <td>{item.name} <br></br><small>({item.organization})</small> </td>
                        <td>{item.make} <br></br> <small>{item.registration}</small> </td>
                        <td>KES {parseInt(item.forced_value).toLocaleString('en-US')}</td>
                        <td>KES {parseInt(item.market_value).toLocaleString('en-US')}</td>
                        <td>{item.payment} <br></br> <small>KES {parseInt(item.cost).toLocaleString('en-US')}</small> </td>
                        <td>{item.status}</td>
                    </tr>
                    )
                    })}
                    </tbody>
                </Table>
                </div>
                <div className='card-footer'></div>
                </div>

                </div>
                </div>
                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default ValuationReport;