import { useEffect, useState } from 'react';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import swal from 'sweetalert';

import NewAgentModal from '../elements/modals/agents/NewAgentModal';
import EditAgentModal from '../elements/modals/agents/EditAgentModal';
import Pagination from '../elements/Pagination';

function AgentsView()
{
    /************************* Configure Pages and Pagination*************************/

    const[currentPage,setCurrentPage] = useState(1);
    const[pageLimit,setPageLimit] = useState(10);

    const[upperLimit,setUpper] = useState(pageLimit*currentPage);
    const[lowerLimit,setLower] = useState(upperLimit-pageLimit+1);

    const nextPage=(id)=>{
    setCurrentPage(id)
    setUpper(pageLimit*id)
    setLower((pageLimit*id)-pageLimit+1) 
    }

    // Manage Agents List
    const [agents,setAgents] = useState([])
    const [results,setResults] = useState([])

    useEffect(()=>{
        refreshAgents()
    },[])

    // Refresh agents lists
    const refreshAgents=()=>{

    const data = {
        id: localStorage.getItem('auth_id')
    }

    axios.post(`api/my_agents`,data).then(res=>{

    if(res.data.status === 200)
    {
        setAgents(res.data.agents)
        setResults(res.data.agents)
    }

    })

    }

    // Manage new agent modal
    const [modal,setModal] = useState(false);

    // Manage edit modal
    const [editmodal,setEditModal] = useState(false);

    const editAgent=(index)=>{

    setEditModal(true)

    setAgentsDetails({
        id: results[index].id,
        name: results[index].name,
        phone: results[index].phone,
        email: results[index].email,
        gender: results[index].gender,
        location: results[index].location,
        error_list:[],
    })

    }

    // Manage agent details
    const [agentsDetails,setAgentsDetails] = useState({
        id:'',
        name:'',
        phone: '',
        email: '',
        gender: 'na',
        location: 'na',
        error_list:[],
    })

    // Handle closure event
    const handleInput=(e)=>{
        setAgentsDetails({...agentsDetails, [e.target.name]: e.target.value})
    }

    // Handle closure event
    const closeModal = ()=>{
        setModal(false)
        setEditModal(false)

        setAgentsDetails({
            id:'',
            name:'',
            phone: '',
            email: '',
            gender: 'na',
            location: 'na',
            error_list:[],
        })

    }

    // Handle submit event on agents modal

    const onSubmit = (e)=>{

        e.preventDefault()

        const data = {
            'staff': localStorage.getItem('auth_id'),
            'name': agentsDetails.name,
            'phone': agentsDetails.phone,
            'email': agentsDetails.email,
            'location': agentsDetails.location,
            'gender': agentsDetails.gender,
        }

        axios.post(`api/add_agent`,data).then(res=>{

            if(res.data.status === 200)
            {
                swal('Success',res.data.message,"success")
                closeModal(false)
                refreshAgents()
            }
            else
            {
                setAgentsDetails({...agentsDetails, error_list: res.data.validation_errors})
            }

        })

    }

    // Handle updates
    const updateAgent=(e)=>{

    e.preventDefault()

    const data = {
        'id': agentsDetails.id,
        'staff': localStorage.getItem('auth_id'),
        'name': agentsDetails.name,
        'phone': agentsDetails.phone,
        'email': agentsDetails.email,
        'location': agentsDetails.location,
        'gender': agentsDetails.gender,
    }

    axios.post(`api/update_agent`,data).then((res)=>{

            if(res.data.status === 200)
            {
                swal('Success',res.data.message,"success")
                closeModal(false)
                refreshAgents()
            }
            else
            {
                setAgentsDetails({...agentsDetails, error_list: res.data.validation_errors})
            }

    })

    }

    const searchResults = (items,parameter)=>{

        const holder = []

        items.map((item,index)=>{

            if(item.name.includes(parameter) || item.phone.includes(parameter) || item.email.includes(parameter)  )
            {
                holder.push(items[index])
            }

        })

        setResults(holder)

    }

    return (

        <div className="wrapper">
        
        <EditAgentModal open={editmodal} closeModal={closeModal} handleInput={handleInput} agentsDetails={agentsDetails} onSubmit={updateAgent} />
        <NewAgentModal open={modal} closeModal={closeModal} handleInput={handleInput} agentsDetails={agentsDetails} onSubmit={onSubmit} />

        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        <ContentHeader title="Sales Agents" />

        <div className="content">
        <div className="container">

        <div className="row">
        <div className = "col-lg-12" >

        <div className="card card-primary card-outline">
        <div className="card-header">
        <h5 className="card-title"> <span className='fa fa-users' ></span> Sales Agents <span className='right btn btn-xs btn-primary' onClick={()=>setModal(true)}><span className='fa fa-plus-circle' ></span> New Agent</span></h5>
        </div>

        <div className="card-body">

        <div className='row' >
        <div className='col-md-12' style={{paddingLeft:'7px', marginBottom:'15px'}}>
        <span className='btn btn-default btn-xs' style={{margin:'2px', float:'right'}}  > <input type="text"  placeholder="Search..." onChange={(e)=>searchResults(agents,e.target.value)} /> </span> 
        </div>
        </div>

        <div className='row' >
            <div className='col-md-12' >
                <table className='table table-stripped' >
                    <thead>
                        <tr style={{fontWeight:'bold'}} >
                            <td>#</td>
                            <td>Agent</td>
                            <td>Phone</td>
                            <td>Email</td>
                            <td>Location</td>
                            <td>Gender</td>
                            <td> <span className='fa fa-edit' ></span> </td>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map((agent,index)=>{

                        let marker = index+1
        
                        if(marker >= lowerLimit && marker <= upperLimit)
                        {
                            return (<tr style={{fontSize:'14px'}} key={index}>
                            <td>{index+1}</td>
                            <td>{agent.name}</td>
                            <td>{agent.phone}</td>
                            <td>{agent.email}</td>
                            <td>{agent.location}</td>
                            <td>{agent.gender}</td>
                            <td> <span className='fa fa-edit' onClick={()=>editAgent(index)}></span> </td>
                            </tr>)
                        }

                        })}
                    </tbody>
                </table>
            </div>
        </div>

        </div>

        <div className='card-footer' >
        <Pagination currentPage={currentPage} setCurrentPage={nextPage} pageLimit={pageLimit} totalItems={results.length} />
        </div>

        </div>

        </div>
        </div>

        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default AgentsView;