import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";

const OrganizationHolder=({open,stage,users,details,handleDetails,contact,handleContact,document,handleScan,handleDocument,uploadDocument,documents,roles})=>{

if(stage === 1)
{
    return(<StepOne users={users} details={details} handleDetails={handleDetails} />)
}

}

export default OrganizationHolder;