const EditSupplier=({openEdit,onClose,details,handleDetails,updateProvider})=>{

    if(!openEdit) return null;
    return(
    
    <div className='overlay' onClick={onClose}>
    <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >
    
    <div className="card card-primary card-outline">
    <div className="card-header">
    <h5> <span className="fa fa-edit"></span> Edit Supplier</h5>
    </div>
    <div className="card-body">
    
    <form className="form" onSubmit={updateProvider}>
        <div className="row">

        <div className="form-group col-md-4">
            <label>Name <span className="red">*</span></label>
            <input type="text" className="form-control" name="name" value={details.name} onChange={handleDetails} />
            <span className="red">{details.error_list.name}</span>
        </div>

        <div className="form-group col-md-4">
            <label>Organization</label>
            <input type="text" className="form-control" name="organization" value={details.organization} onChange={handleDetails}/>
        </div>

        <div className="form-group col-md-4">
            <label>Phone <span className="red">*</span></label>
            <input type="text" className="form-control" name="phone" value={details.phone} onChange={handleDetails} />
            <span className="red">{details.error_list.phone}</span>
        </div>

        <div className="form-group col-md-4">
            <label>Email <span className="red">*</span></label>
            <input type="text" className="form-control" name="email" value={details.email} onChange={handleDetails} />
            <span className="red">{details.error_list.email}</span>
        </div>

        <div className="form-group col-md-4">
            <label>Category <span className="red">*</span></label>
            <select className="form-control" name="category" value={details.category} onChange={handleDetails} >
            <option value=''>Select category</option>
                <option>insurance</option>
                <option>car valuer</option>
                <option>lawyer</option>
                <option>auctioneer</option>
                <option>consultant</option>
                <option>others</option>
            </select>
            <span className="red">{details.error_list.category}</span>
        </div>

        <div className="form-group col-md-4">
            <label>Status <span className="red">*</span></label>
            <select className="form-control" name="status" value={details.status} onChange={handleDetails} >
            <option>active</option>
            <option>suspended</option>
            </select>
            <span className="red">{details.error_list.status}</span>
        </div>

        </div>

        <div className="row">
        <div className="form-group col-md-12">
        <input type="submit" className="btn btn-primary btn-sm" value="Save supplier"/>
        </div>
        </div>

    </form>

    </div>

    <div className="card-footer"></div>
    </div>

    </div>
    </div>

    )

}

export default EditSupplier;