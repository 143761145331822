import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import axios from 'axios';

import Log from './components/main/Log';
import Login from './components/authentication/Login';
import Reset from './components/authentication/Reset';
import Recovery from './components/authentication/Recovery';
import SalesDashboard from './components/users_components/SalesDashboard';
import NotFound from './components/main/NotFound';
import NotAuthorized from './components/main/NotAuthorized';

import Main from './components/main/Main';
import Perfomance from './components/main/Perfomance';
import Index from './components/main/Index';
import Contacts from './components/main/Contacts';

import LeadsView from './components/users_components/LeadsView';
import ValuationsView from './components/users_components/ValuationsView';
import LoansView from './components/users_components/LoansView';
import Mpesa from './components/users_components/Mpesa';
import Payments from './components/users_components/Payments';
import Developers from './components/main/Developers';
import ServiceProviders from './components/users_components/ServiceProviders';

import AllLeads from './components/main/AllLeads';
import AllValuations from './components/main/AllValuations';
import AllLoans from './components/main/AllLoans';
import AllAgents from './components/main/AllAgents';

import Report from './components/main/Report';

import PrivateRoutes from './components/authentication/PrivateRoutes';
import AdminRoutes from './components/authentication/AdminRoutes';
import DynamicRoutes from './components/authentication/DynamicRoutes';

import Permissions from './components/dashboard/Permissions';
import Settings from './components/dashboard/Settings';
import Staff from './components/dashboard/Staff';
import User from './components/dashboard/User';
import Departments from './components/dashboard/Departments';
import Roles from './components/dashboard/Roles';
import Role from './components/dashboard/Role';
import LoanTypes from './components/dashboard/LoanTypes';
import AgentsView  from './components/users_components/AgentsView';
import LoanSimulator from  './components/simulator/LoanSimulator';
import Profile from './components/main/Profile';

import Loans from './components/main/Loans';
import LoanDetails from './components/main/LoanDetails';
import PendingLoans from './components/main/PendingLoans';
import ApprovedLoans from './components/main/ApprovedLoans';
import DisbursedLoans from './components/main/DisbursedLoans';
import RejectedLoans from './components/main/RejectedLoans';
import InArrears from './components/main/InArrears';
import Organizations from './components/main/Organizations';
import Finance from './components/main/Finance';
import Invoices from './components/main/Invoices';
import Requisitions from './components/users_components/Requisitions';
import NewRequisition from './components/users_components/NewRequisition';
import ViewRequisition from './components/users_components/ViewRequisition';

import Payday from './components/main/Payday';
import PaydayLoans from './components/main/PaydayLoans';
import PaydayLoan from './components/main/PaydayLoan';
import PaydayDisbursal from './components/main/PaydayDisbursal';
import PendingDisbursal from './components/main/PendingDisbursal';
import Kpis from './components/dashboard/Kpis';

import Organization from './components/main/Organization';
import PaydayStaff from './components/main/PaydayStaff';
import InsuranceWatch from './components/users_components/InsuranceWatch';
import Accounts from './components/users_components/Accounts';

import Teams from './components/main/Teams';
import TeamInfo from './components/main/TeamInfo';
import Aging from './components/users_components/Aging';
import PaydayIncome from './components/main/PaydayIncome';
import LoanIncome from './components/main/LoanIncome';
import DisbursmentReport from './components/main/DisbursmentReport';
import PaydayDisbursementReport from './components/main/PaydayDisbursementReport';
import RequisitionReport from './components/main/RequisitionReport';
import ValuationReport from './components/main/ValuationReport';
import CollateralList from './components/users_components/CollateralList';
import GeneralPerfomance from './components/main/GeneralPerfomance';
import ChequeRegister from './components/main/ChequeRegister';
import Repocessions from './components/main/Repocessions';

import WatchList from './components/main/WatchList';
import DueList from './components/main/DueList';
import ChangePassword from './components/main/ChangePassword';
import ExpiredInsurance from './components/users_components/ExpiredInsurance';

import Calculator from './components/simulator/Calculator';
//axios.defaults.baseURL = "http://127.0.0.1:8000";
axios.defaults.baseURL = "https://laravel.northwaveapp.co.ke/";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function(config){
const token = localStorage.getItem('auth_token');
config.headers.Authorization = token ? `Bearer ${token}` : '';
return config;
});

function App() {
  return (
  
  <Router>
  
  <Routes>

        <Route path = "/*" element={<NotFound/>} />
        <Route path = "/" element={<Login/>} />
        <Route path = "/login" element={<Login/>} />
        <Route path = "/reset" element={<Reset/>} />
        <Route path = "/recovery" element={<Recovery/>} />
        <Route path = "/unauthorized" element={<NotAuthorized/>} />
            
        <Route element={<PrivateRoutes/>} >

        /*********************** This is an administrative Area *********************/
        <Route element={<AdminRoutes/>} >
        <Route path = "/settings" element={<Settings/>} />
        <Route path = "/staff" element={<Staff/>} />
        <Route path = "/user/:id" element={<User/>} />
        <Route path = "/departments" element={<Departments/>} />
        <Route path = "/permissions" element={<Permissions/>} />
        <Route path = "/role/:id" element={<Role/>} />
        <Route path = "/roles" element={<Roles/>} />
        <Route path = "/loan_types" element={<LoanTypes/>} />
        <Route path = "/kpis" element={<Kpis/>} />
        <Route path = "/developers" element={<Developers/>} />
        </Route>
        /*********************** End of administrative section **********************/

        <Route element={<DynamicRoutes></DynamicRoutes>} >
        <Route path = "/index" element={<Index/>} />
        </Route>

        
        <Route path = "/logs" element={<Log/>} />
        <Route path = "/repocessions" element={<Repocessions/>} />
        <Route path = "/cheque_register" element={<ChequeRegister/>} />
        <Route path = "/change_password" element={<ChangePassword/>} />
        <Route path="/teams" element={<Teams></Teams>} ></Route>
        <Route path="/aging" element={<Aging></Aging>} ></Route>
        <Route path = "/team_info/:id" element={<TeamInfo/>} />
        <Route path="/valuations_report" element={<ValuationReport/>} />
        <Route path="/expenditure_report" element={<RequisitionReport/>} />
        <Route path = "/disbursment_report" element={<DisbursmentReport />} />
        <Route path="/payday_disbursment" element={<PaydayDisbursementReport/>} />
        <Route path = "/payday_income" element={<PaydayIncome/>} />
        <Route path = "/loan_income" element={<LoanIncome/>} />
        <Route path = "/finance" element={<Finance/>} />
        <Route path = "/perfomance" element={<SalesDashboard/>} />
        <Route path = "/leads" element={<LeadsView/>} />
        <Route path = "/valuations" element={<ValuationsView/>} />
        <Route path = "/agents" element={<AgentsView/>} />
        <Route path = "/payments" element={<Payments/>} />
        <Route path = "/mpesa" element={<Mpesa/>} />
        <Route path = "/insurance" element={<InsuranceWatch/>} />
        <Route path = "/expired_insurance" element={<ExpiredInsurance/>} />
        <Route path = "/invoices" element={<Invoices/>} />
        <Route path = "/accounts" element={<Accounts/>} />
        <Route path = "/requisitions" element={<Requisitions/>} />
        <Route path = "/new_requisition" element={<NewRequisition/>} />
        <Route path = "/view_requisition/:id" element={<ViewRequisition/>} />
        <Route path = "/collaterals" element={<CollateralList></CollateralList>} ></Route>
        <Route path="/general_perfomance" element={<GeneralPerfomance></GeneralPerfomance>}></Route>

        <Route path = "/loans" element={<Loans/>} />
        <Route path = "/watchlist" element={<WatchList/>} />
        <Route path = "/pending" element={<PendingLoans />} />
        <Route path = "/approved" element={<ApprovedLoans />} />
        <Route path = "/disbursed" element={<DisbursedLoans />} />
        <Route path = "/rejected" element={<RejectedLoans />} />
        <Route path = "/arrears" element={<InArrears />} />
        <Route path = "/suppliers" element={<ServiceProviders />} />

        <Route path = "/profile/:id" element={<Profile/>} />
        <Route path = "/loan/:id" element={<LoanDetails/>} />
        <Route path = "/organization/:id" element={<Organization/>} />

        <Route path = "/all_leads" element={<AllLeads/>} />
        <Route path = "/all_valuations" element={<AllValuations/>} />
        <Route path = "/all_loans" element={<AllLoans/>} />
        <Route path = "/all_agents" element={<AllAgents/>} />
        <Route path = "/ranking" element={<Report/>} />
        <Route path = "/due_list" element={<DueList/>} />

        <Route path = "/main" element={<Main/>} />
        
        <Route path = "/contacts" element={<Contacts/>} />
        <Route path = "/perfomance_analysis" element={<Perfomance/>} />
        <Route path = "/payday" element={<Payday/>} />
        <Route path = "/payday_loans" element={<PaydayLoans/>} />
        <Route path = "/payday_loan/:id" element={<PaydayLoan/>} />
        <Route path = "/payday_staff" element={<PaydayStaff/>} />
        <Route path = "/pending_disbursal" element={<PendingDisbursal/>} />
        <Route path = "/organizations" element={<Organizations/>} />

        <Route path = "/sandbox" element={<LoanSimulator/>} />
        <Route path = "/calculator" element={<Calculator/>} />
        <Route path = "/profile" element={<Profile/>} />

        </Route>

  </Routes>

  </Router>
    
  );
}

export default App;
