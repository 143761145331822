const Loan_One=({loanProducts,loanTypes,vehicles,loanInitials,handleLoanInitials,users,profile})=>{
return(
<div className="row" >

<div className="col-md-3 form-group" >
<label>Loan product</label>
<select className="form-control" name="loan_product" value={loanInitials.loan_product} onChange={handleLoanInitials} >
<option value="" >Select Loan Product</option>
{loanProducts.map((product,index)=>{

return(
<option key={index} value={product.id}>{product.product}</option>
)

})}
</select>
<span className="error_holder"  >{loanInitials.error_list.loan_product}</span>
</div>

<div className="col-md-3 form-group" name="loan_type" >
<label>Loan type</label>
<select className="form-control" name="loan_type" value={loanInitials.loan_type} onChange={handleLoanInitials} >
<option value="" >Select Loan Type</option>
{loanTypes.map((item,index)=>{
return(
<option key={index} value={item.id} >{item.loan_type}</option>
)
})}
</select>
<span className="error_holder"  >{loanInitials.error_list.loan_type}</span>
</div>

<div className="col-md-3 form-group" >
<label>Amount Loanable</label>
<input className="form-control" type="number" name="amount_loanable" value={loanInitials.amount_loanable} onChange={handleLoanInitials} />
<span className="error_holder"  >{loanInitials.error_list.amount_loanable}</span>
</div>

<div className="col-md-3 form-group" >
<label>Interest rate (%)</label>
<input className="form-control" type="text" name="interest_rate" value={loanInitials.interest_rate} onChange={handleLoanInitials} />
<span className="error_holder"  >{loanInitials.error_list.interest_rate}</span>
</div>

<div className="col-md-3 form-group" >
<label>Period (<i>Months</i>) </label>
<input className="form-control" type="number" name = "period" value={loanInitials.period} onChange={handleLoanInitials} />
<span className="error_holder"  >{loanInitials.error_list.period}</span>
</div>

<div className="col-md-3 form-group" >
<label>Collateral</label>
<select className="form-control" name = "collateral" value={loanInitials.collateral} onChange={handleLoanInitials} >
<option value = '' >Select collateral</option>
{vehicles.map((item,index)=>{

return(
<option key={index} value={item.id} >{item.registration}</option>
)

})}
</select>
<span className="error_holder"  >{loanInitials.error_list.collateral}</span>
</div>

<div className="col-md-3 form-group" >
<label>Loan Officer</label>
<select className="form-control" name = "loan_officer"  value={loanInitials.loan_officer} onChange={handleLoanInitials}  >
<option value = '' >Select officer</option>
{users.map((item,index)=>{
    return(<option key={index} value={item.id}>{item.name}</option>)
})}
</select>
<span className="error_holder"  >{loanInitials.error_list.loan_officer}</span>
</div>

</div>
)
}

export default Loan_One;