const Pagination = ({currentPage,setCurrentPage,pageLimit,totalItems}) =>{
    let pages = Math.ceil(totalItems/pageLimit)
    let page_array =[]

    for(let i=0; i<pages; i++){
        page_array.push(i+1)
    }

    return (<ul className="pagination pagination-sm m-0 float-left">
    <li className="page-item"><a className="page-link" href="#">&laquo;</a></li>
    {page_array.map(index => 
        
        {if(index === currentPage)
        {
            return <li className="page-item active" key={index} onClick={()=>setCurrentPage(index)} ><a className="page-link">{index}</a></li>
        }
        else{
            return <li className="page-item" key={index} onClick={()=>setCurrentPage(index)} ><a className="page-link">{index}</a></li>
        }}

    )}
    <li className="page-item"  ><a className="page-link" href="#">&raquo;</a></li>
  </ul> )

}

export default Pagination;

