import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

const DisplayValuation=({valuationDisplayFlag,closeDisplay,valuationDetails,valuationDocuments,fetchValuationDocuments})=>{

const [details,setDetails] = useState({
    document:'',
    scan:null,
    error_list: [],
})

const handleDetails=(e)=>{
e.persist()
setDetails({...details, [e.target.name]: e.target.value})
}

const handleScan=(e)=>{
e.persist()
setDetails({...details, [e.target.name]: e.target.files[0]})
}

const uploadScan=()=>{

if(details.scan === null)
{
    swal('Error',"A file upload is required.","error")
}
else
{

    const formData = new FormData()

    formData.append('valuation',valuationDetails.valuation_id)
    formData.append('customer',valuationDetails.customer_id)
    formData.append('vehicle',valuationDetails.vehicle_id)
    formData.append('document',details.document)
    formData.append('scan',details.scan,details.scan.name)
    formData.append('uploaded_by',localStorage.getItem('auth_id'))

    axios.post(`api/upload_valuation`,formData).then((res)=>{
    
    if(res.data.status === 200)
    {
        swal('Success',res.data.message,"success")
        fetchValuationDocuments(valuationDetails.valuation_id)
    }else if(res.data.status === 403){
        swal('Error',res.data.message,"error")
    }
    else{
        setDetails({...details, error_list: res.data.validation_errors})
    }


    })

}

}

const deleteDocument=(id)=>{

const data = {
    id:id,
}

axios.post(`api/delete_valuationDoc`,data).then((res)=>{

    if(res.data.status === 200)
    {
        swal('Success',res.data.message,"success")
        fetchValuationDocuments(valuationDetails.valuation_id)
    }else if(res.data.status === 403){
        swal('Error',res.data.message,"error")
    }
    else{
        setDetails({...details, error_list: res.data.validation_errors})
    }
})

}
const downloadDocument=(id,name)=>{

    const data = {
        id:id,
    }

    axios({
        url: 'api/download_valuationDoc',
        method: 'POST',
        data: data,
        responseType: 'blob', // important
      }).then((response) => {
        
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        
      });

}

if(!valuationDisplayFlag) return false;

return(
    <div className='overlay' onClick={closeDisplay} >
    <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

    <div className="card card-primary card-outline" >
    <div className="card-header" ><h5>Valuation Details </h5></div>
    

    <div className="card-body" >
    <div className="row">
    
    <div className="col-md-5" >
    
    <div className="card card-primary card-outline" >
    <div className="card-body">
    
    <p>
    <span className="label-holder" >Date:</span> {valuationDetails.date}
    </p>

    <p>
    <span className="label-holder" >Valuer:</span> {valuationDetails.valuer}
    </p>

    <p>
    <span className="label-holder" >Vehicle:</span> {valuationDetails.vehicle}
    </p>

    <p>
    <span className="label-holder" >Forced Value:</span>KES {valuationDetails.forced_value}
    </p>

    <p>
    <span className="label-holder" >Market Value:</span>KES {valuationDetails.market_value}
    </p>

    <p>
    <span className="label-holder" >Contact:</span> {valuationDetails.contact}
    </p>

    <p>
    <span className="label-holder" >Phone:</span> {valuationDetails.phone}
    </p>

    <p>
    <span className="label-holder" >Cost:</span>KES {valuationDetails.cost}
    </p>

    <p>
    <span className="label-holder" >Payment:</span>{valuationDetails.payment}
    </p>

    <p>
    <span className="label-holder" >Status:</span>{valuationDetails.status}
    </p>

    <p>
    <span className="label-holder" >Notes:</span>{valuationDetails.notes}
    </p>


    </div>
    </div>

    </div>

    <div className="col-md-7" >
    
    <div className="card card-primary card-outline">

    <div className="card-body">

    <table className="table">
    <thead>
    <tr style={{fontSize:'14px'}} >
    <th>#</th>
    <th>Document</th>
    <th>Scan</th>
    <th><span className="fa fa-download" style={{color:'green'}} ></span></th>
    <th><span className="fa fa-trash" style={{color:'red'}}></span></th>
    </tr>
    </thead>

    <tbody>
    {valuationDocuments.map((doc,index)=>{
        return(
        <tr style={{fontSize:'13px'}} key={index} >
        <td>{index+1}</td>
        <td>{doc.document}</td>
        <td>{doc.name}</td>
        <td><span className="fa fa-download" style={{color:'green'}} onClick={()=>downloadDocument(doc.id,doc.name)} ></span></td>
        <td><span className="fa fa-trash" style={{color:'red'}} onClick={()=>deleteDocument(doc.id)} ></span></td>
        </tr>
        )
    }
    )}
    </tbody>

    </table>

    </div>
    <div className="card-footer" >
    
    </div>
    </div>

    <div className="card card-primary card-outline">

    <div className="card-header">
    <span className="fa fa-upload" ></span> Upload Document
    </div>

    <div className="card-body">

    <div className="row">

    <div className="col-md-6 form-group">
    <label>Document</label>
    <input type = "text" name = "document" className="form-control" value={details.document} onChange={handleDetails} />
    <span className="error_holder"  >{details.error_list.document}</span>
    </div>

    <div className="col-md-6 form-group">
    <label>Scan</label>
    <input type = "file" name = "scan" className="form-control" onChange={handleScan} />
    </div>

    </div> 
    
    <div className="row">
    <div className="col-md-12 form-group">

    <span className="btn btn-primary btn-xs" onClick={uploadScan}>
    <span className="fa fa-upload"></span> Upload Document
    </span>

    </div>
    </div>

    </div>

    <div className="card-footer" ></div>

    </div>

    </div>

    </div>
    </div>

    <div className="card-footer" >
    
    </div>
    </div>

    </div>
    </div>
)

}
export default DisplayValuation;