import { Modal } from "react-bootstrap";
const EditOrganization=({editOrg,closeEditOrg,details,handleDetails,users,updateOrganization})=>{
if(!editOrg) return false;
return(
<Modal
show={editOrg}
onHide={closeEditOrg}
backdrop="static"
keyboard={false}
size="lg"
>
<Modal.Header closeButton>
<Modal.Title>Edit Organization</Modal.Title>
</Modal.Header>

<Modal.Body>
<form className="form" onSubmit={updateOrganization} >
<div className="row">

<div className="form-group col-md-3">
<label>Organization <span className='red'>*</span></label>
<input type="text" className="form-control" name="organization" value = {details.organization} onChange={handleDetails} />
<span className="error_holder"  >{details.error_list.organization}</span>
</div>

<div className="form-group col-md-3">
<label>Phone <span className='red'>*</span></label>
<input type="text" className="form-control" name="phone" value = {details.phone} onChange={handleDetails} />
<span className="error_holder"  >{details.error_list.phone}</span>
</div>

<div className="form-group col-md-3">
<label>Email <span className='red'>*</span></label>
<input type="text" className="form-control" name="email"  value = {details.email} onChange={handleDetails} />
<span className="error_holder"  >{details.error_list.email}</span>
</div>

<div className="form-group col-md-3">
<label>Website</label>
<input type="text" className="form-control" name="website"  value = {details.website} onChange={handleDetails} />
<span className="error_holder"  >{details.error_list.website}</span>
</div>

<div className="form-group col-md-3">
<label>Postal Address <span className='red'>*</span></label>
<input type="text" className="form-control" name="postal_address"  value = {details.postal_address} onChange={handleDetails} />
<span className="error_holder"  >{details.error_list.postal_address}</span>
</div>

<div className="form-group col-md-3">
<label>City / Town <span className='red'>*</span></label>
<input type="text" className="form-control" name="city"  value = {details.city} onChange={handleDetails}/>
<span className="error_holder"  >{details.error_list.city}</span>
</div>

<div className="form-group col-md-3">
<label>Account manager <span className='red'>*</span></label>
<select name="account_manager" className="form-control"  value = {details.account_manager} onChange={handleDetails} >
<option value='' >Select Manager</option>
{users.map((user,index)=>{
return(<option value={user.id} key={index} >{user.name}</option>)
})}
</select>
<span className="error_holder"  >{details.error_list.account_manager}</span>
</div>

<div className="form-group col-md-3">
<label>Status <span className='red'>*</span></label>
<select name="status" className="form-control"  value = {details.status} onChange={handleDetails} >
<option value="pending" >pending</option>
<option value = "active" >active</option>
<option value = "suspended" >suspended</option>
</select>
<span className="error_holder"  >{details.error_list.status}</span>
</div>

<div className="form-group col-md-12">
<input type = "submit" className="btn btn-success btn-sm" value="Update Organization" ></input>
</div>

</div>
</form>      
</Modal.Body>

<Modal.Footer>
</Modal.Footer>

</Modal>
)

}
export default EditOrganization;

