    import { useReactToPrint } from "react-to-print";
    import { useRef } from "react";

    import Table from 'react-bootstrap/Table';
    import Container from 'react-bootstrap/Container';
    import Row from 'react-bootstrap/Row';
    import Col from 'react-bootstrap/Col';
    import Stack  from 'react-bootstrap/Stack';
    import Image  from 'react-bootstrap/Image';

    import logo from '../../../llogo.jpeg'

    const  BriefLoanStatement = ({open,onClose,statement,client,details,switchPrint}) =>{

    const componentRef = useRef()

    const handlePrint = useReactToPrint({
    content:()=> componentRef.current,
    documentTitle: 'Loan Statement',
    onAfterPrint:()=>onClose()
    })

    let today = new Date().toUTCString().slice(5, 16);
    if(!open) return null;
    return(
    <div className='overlay' onClick={onClose} >
        <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

        <div className = "card card-primary" >
        <div className="card-header" >
        <span className='btn btn-default btn-sm' style={{margin:'2px'}} onClick={()=>switchPrint()} ><span className='fa fa-file-invoice'></span> Full Statement</span>
        <span className='btn btn-dark btn-sm'  style={{margin:'2px'}} onClick={()=>switchPrint()} ><span className='fa fa-file-invoice'></span> Brief Statement</span>
        <span className='closeModal btn btn-sm btn-default' onClick={handlePrint} style={{margin:'2px'}} > <span className='fa fa-print' ></span> Print Statement </span>
        </div>
            
        <div className="card-body">

        <div ref={componentRef} statement={statement} style={{width: '100%'}} >
                
            <Container>
            <Row className="mt-5" style={{borderBottom:'1px solid black'}}>
            <Col>
            <div>
            <Image src={logo} style={{width:'150px'}} ></Image>
            </div>

            <div className="mt-3" >
            <h6><b>Brief Loan Statement</b></h6>
            </div>

            </Col>

            <Col style={{textAlign:'right'}} >
            <Stack gap={1}>
            <div>Northwave Credit Ltd</div>
            <div>P.O BOX 2760 - 00100</div>
            <div>Nairobi, Kenya</div>
            <div>Tel: 0703999944</div>
            <div>Email: info@northwavecredit.com</div>
            <div>Website: www.northwavecredit.com</div>
            </Stack>
            </Col>
            </Row> 

            <Row className="mt-4" >

            <Col sm={8}> 
            <div className="mb-2" ><b>Client Information</b></div>
            <Stack gap={1}>
            <div><span className="info-holder" >Name:</span> {client.first_name} {client.other_name} {client.last_name}</div>
            <div><span className="info-holder">Phone:</span> {client.phone}</div>
            <div><span className="info-holder">Email:</span> {client.email}</div>
            <div><span className="info-holder">ID No</span> {client.id_no}</div>
            <div><span className="info-holder">Address:</span> {client.city}, {client.country}</div>
            </Stack>
            </Col>

            <Col sm={4}> 
            <div className="mb-2" ><b>Loan Details</b></div>
            <Stack gap={1}>
            <div ><span className="info-holder-two" >Loan Type:</span>{details.loan_type} </div>
            <div ><span className="info-holder-two" >Loan No:</span>{details.loan_code}</div>
            <div ><span className="info-holder-two" >Loan Amount:</span>KES {parseInt(details.amount_loanable).toLocaleString('en-US')} </div>
            <div ><span className="info-holder-two" >Arrears:</span><span style={{color:'red'}}>KES {parseInt(details.amount_due).toLocaleString('en-US')}</span> </div>
            <div ><span className="info-holder-two" >Over Payment:</span>KES {parseInt(client.credit).toLocaleString('en-US')} </div>
            <div ><span className="info-holder-two" >Disbursed on:</span> {(details.date_disbursed).substr(0,10)} </div>
            </Stack>
            </Col>

            </Row>       

            <Row className="mt-3" >
           
            <Col>
            <div>
            Auto generated on :  {today}       
            </div>
            <Table striped bordered hover className="mt-3" >
            <thead>
            <tr>
            <th>#</th>
            <th>Item</th>
            <th>Amount</th>
            </tr>
            </thead>
            <tbody>

            <tr className="small-row" >
                <td>1</td>
                <td>Principal</td>
                <td>KES {parseInt(details.principal).toLocaleString('en-US')}</td>
            </tr>

            <tr className="small-row" >
                <td>2</td>
                <td>Interest</td>
                <td>KES {parseInt(details.interest).toLocaleString('en-US')}</td>
            </tr>

            <tr className="small-row" >
                <td>3</td>
                <td>Maintenace Fee</td>
                <td>KES {parseInt(details.tracking_fee).toLocaleString('en-US')}</td>
            </tr>
            <tr className="small-row" >
                <td>4</td>
                <td>Service Charge</td>
                <td>KES {parseInt(details.service_charge).toLocaleString('en-US')}</td>
            </tr>            
           
            <tr className="small-row" >
                <td>5</td>
                <td>Loan Balance</td>
                <td>KES {parseInt(details.balance).toLocaleString('en-US')}</td>
            </tr>

            </tbody>
            </Table>

            </Col>
            </Row>

            <Row>
                <Col>
                <div>
                
                <p><b>Payments to be remitted to:</b></p>
                    
                <Stack gap={1}>
                <div><span className="info-holder-two">Bank:</span> Prime Bank</div>
                <div><span className="info-holder-two">Branch:</span> Riverside Drive Branch</div>
                <div><span className="info-holder-two">Account Name:</span> Northwave Credit Limited</div>
                <div><span className="info-holder-two">Account Number:</span> 3000166349</div>
                </Stack>
                </div>
                </Col>

                <Col>
                <Stack gap={1} className="mt-3">
                <div><b>Mpesa Payments</b></div>
                <div><span className="info-holder-two">Paybill #:</span> 734127</div>
                <div><span className="info-holder-two">Acc #:</span> {client.id_no}</div>
                </Stack>
                </Col>
            </Row>

            <Row>
                <Col>
                <div className="mt-3">
                <p>Kindly present a copy of the statement with payment at our offices.</p>
                <b>NB:</b>
                <div>The statement Balance is as at the date and time of issue. Any fees or charges accrued thereafter will be applicable.</div>
                <div>In the Event where the client wants a loan buy-off to another financer a buy -off fee of 30% will be charged on the outstanding loan balance.</div>
                </div>
                </Col>
            </Row>

            </Container>
       

        </div>

        </div>

        <div className='card-footer' >
        

        </div>

        </div>
   

        </div>
    </div>
    );
}

export default BriefLoanStatement;