const Confirm=({openConfirm,closeConfirm,action,id})=>{
if(!openConfirm) return null;
return (
    <div className='overlay' onClick={closeConfirm}>
    <div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

    <div className="card card-dark">
    <div className="card-header">
        <h6>Are you sure ?</h6>
    </div>
    <div className="card-body">
        <div className="row">
            <div className="col-md-12">
                <span className="btn btn-success" style={{width:'100px', margin:'3px' }} onClick={()=>action(id)} >Yes</span>
                <span className="btn btn-danger" style={{width:'100px' ,margin:'3px' }}  onClick={()=>closeConfirm()} >No</span>
            </div>
        </div>
    </div>
    </div>

    </div>
    </div>
)   

}
export default Confirm;