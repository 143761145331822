const Statement =({statement})=>{

return(
    <div className='card card-primary card-outline'>

    <div className='card-header'>
    <h5> <span className="fa fa-file-invoice" ></span> Account Statement
    </h5>
    </div>

    <div className='card-body'>

    <table className='table'>
        <thead>
            <tr className="small-row" >
                <th>#</th>
                <th>Date</th>
                <th>Description</th>
                <th>Debit</th>
                <th>Credit</th>
                <th>Balance</th>
            </tr>
        </thead>
        <tbody>
        {statement.map((item,index)=>{
           if(item.type == "debit")
           {
                return(
                <tr className="small-row" key={index} >
                    <td>{index+1}</td>
                    <td>{item.date.substr(0,10)}</td>
                    <td>{item.reference}</td>
                    <td>{parseInt(item.amount).toLocaleString('en-US')}</td>
                    <td>-</td>
                    <td>{parseInt(item.balance).toLocaleString('en-US')}</td>
                </tr>
                )
           }
           else
           {
                return(
                <tr className="small-row"  key={index}  >
                    <td>{index+1}</td>
                    <td>{item.date.substr(0,10)}</td>
                    <td>{item.mode} {item.reference} </td>
                    <td>-</td>
                    <td>{parseInt(item.amount).toLocaleString('en-US')}</td>
                    <td>{parseInt(item.balance).toLocaleString('en-US')}</td>
                </tr>
            )
           }
        })}
        </tbody>
    </table>

    </div>

    <div className='card-footer'>
        
    </div>

    </div>
)

}
export default Statement;