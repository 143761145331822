import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

import NewSupplier from '../elements/modals/suppliers/NewSupplier';
import EditSupplier from '../elements/modals/suppliers/EditSupplier';

function ServiceProviders()
{
    const effectRun = useRef(false)
    const [suppliers,setSuppliers] = useState([])
    const [open,setOpen] = useState(false)
    const [openEdit,setOpenEdit] = useState(false)

    const [details,setDetails] = useState({
    id:'',
    name:'',
    organization:'',
    phone:'',
    email:'',
    category:'',
    error_list: []
    })

    const handleDetails=(e)=>{
        setDetails({...details, [e.target.name]: e.target.value})
    }

    const onClose=()=>{

    setDetails({
        id:'',
        name:'',
        organization:'',
        phone:'',
        email:'',
        category:'',
        error_list: []
    })

    setOpen(false)
    setOpenEdit(false)

    }

    const fetchSuppliers =()=>{
    
    axios.post(`api/service_providers`).then((res)=>{
    
    if(res.data.status === 200)
    {
        setSuppliers(res.data.providers)
    }

    })

    }

    const editProvider=(index)=>{
        
        
        setDetails({
            id: suppliers[index].id,
            name: suppliers[index].name,
            organization: suppliers[index].organization,
            phone: suppliers[index].phone,
            email: suppliers[index].email,
            category: suppliers[index].category,
            status: suppliers[index].status,
            error_list:[]
        })
        
        setOpenEdit(true)

    }

    const updateProvider=(e)=>{

        e.preventDefault()

        const data = {
            id: details.id,
            name: details.name,
            organization: details.organization,
            category: details.category,
            phone: details.phone,
            email: details.email,
            status: details.status
        }

        axios.post(`api/update_provider`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
            onClose()
            fetchSuppliers()
            swal("Success",res.data.message,"success")
        }
        else
        {
            setDetails({...details, error_list:res.data.validation_errors})
        }

        })

    }

    const saveProvider=(e)=>{

        e.preventDefault()

        const data = {
            name: details.name,
            organization: details.organization,
            category: details.category,
            phone: details.phone,
            email: details.email
        }

        axios.post(`api/create_provider`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
            onClose()
            fetchSuppliers()
            swal("Success",res.data.message,"success")
        }
        else
        {
            setDetails({...details, error_list:res.data.validation_errors})
        }

        })

    }

    // Use effect to initialize critical data
    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchSuppliers()
    }

    return()=>{
    effectRun.current = true
    }

    },[])

    return (

        <div className="wrapper">
        
        <NewSupplier open={open} onClose={onClose} details={details} handleDetails={handleDetails} saveProvider={saveProvider} />
        <EditSupplier openEdit={openEdit} onClose={onClose} details={details} handleDetails={handleDetails} updateProvider={updateProvider} />

        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        

        <div className="content">
        <div className="container">

        <div className="row" style={{marginTop:'20px'}} >
        <div className = "col-lg-12" >

        <div className="card card-primary card-outline">
        <div className="card-header">
        <h6 className="card-title">
        Service Providers
        <span className='btn btn-primary btn-sm float-right' onClick={()=>setOpen(true)} > <span className='fa fa-plus'></span> Add Supplier</span>
        </h6>
        </div>
        <div className="card-body">

        <table className="table" >

        <thead>
        <tr className='small-row' >
        <th>#</th>
        <th>Name</th>
        <th>Organization</th>
        <th>Phone</th>
        <th>Email</th>
        <th>Category</th>
        <th>Status</th>
        </tr>
        </thead>

        <tbody>
        {suppliers.map((item,index)=>{
        return(
        <tr className='small-row' key={index}>
            <td>{index+1}</td>
            <td>{item.name}</td>
            <td>{item.organization}</td>
            <td>{item.phone}</td>
            <td>{item.email}</td>
            <td>{item.category}</td>
            <td>{item.status}</td>
        </tr>
        )
        })}
        </tbody>

        </table>    
                
        </div>

        <div className='card-footer' >
        
        </div>

        </div>

        </div>
        </div>

        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default ServiceProviders;