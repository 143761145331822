import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';


function ProfileData({setContact,profile}){

const editButton=()=>{
    var role = localStorage.getItem('auth_role')
    
    if(role == "System Administrator" || role == "CEO")
    {
        return(
            <span className='btn btn-xs btn-default' style={{float:'right'}} onClick={()=>setContact(true)}> <span className='fa fa-edit' ></span> Edit Profile</span>
        )
    }
    else
    {
        if(profile.edit_flag == 0)
        {
            return(
                <span className='btn btn-xs btn-default' style={{float:'right'}} onClick={()=>setContact(true)}> <span className='fa fa-edit' ></span> Edit Profile</span>
            )
        }
    }

}

return (
        <div >
        
        <div class="card card-primary card-outline">
            <div class="card-header">
            <h5><span className='fa fa-file-invoice'></span> Profile 
            {editButton()}
            </h5>
            </div>
            <div class="card-body box-profile contact-box">

            <div className='row' >
            
            <div className='col-md-6' >

            <p className='label-block'>
            <span className='label-holder'> Name:</span> {profile.salutation} {profile.first_name} {profile.last_name}
            </p>

            <p className='label-block'>
            <span className='label-holder'> ID / Passport #:</span> {profile.id_no}
            </p>

            <p className='label-block'>
            <span className='label-holder'> Phone:</span> {profile.phone}
            </p>

            <p className='label-block'>
            <span className='label-holder'> Email:</span> {profile.email}
            </p>

            

            <p className='label-block'>
            <span className='label-holder'> Annual Turnover:</span>KES {parseInt(profile.annual_turnover).toLocaleString('en-US')}
            </p>

            <p className='label-block'>
            <span className='label-holder' > Industry:</span> {profile.industry}
            </p>

            <p className='label-block'>
            <span className='label-holder' > Settlement mode:</span> {profile.settlement_mode}
            </p>

            </div>

            <div className='col-md-4' >

            <p className='label-block'>
            <span className='label-holder'> Postal Address:</span> {profile.postal_address}
            </p>

            <p className='label-block'>
            <span className='label-holder'> City:</span> {profile.city}
            </p>

            <p className='label-block'>
            <span className='label-holder'> Country:</span> {profile.country}
            </p>
            
            <p className='label-block'>
            <span className='label-holder'> Branch:</span> {profile.branch}
            </p>

            <p className='label-block'>
            <span className='label-holder'> Status:</span> {profile.status}
            </p>

            <p className='label-block'>
            <span className='label-holder'> Source:</span> {profile.source}
            </p>

            </div>

            </div>

            </div>

            <div className='card-footer'></div>

            </div>
        </div>
)

}

export default ProfileData;