import kra from './../../../../kra.jpg';
import shield from './../../../../shield.jpg';

const Stage_Three = ({stageThree,handleStageThree}) =>{

    return (
        <div className="row" >

        <div className="col-md-12" >
        
        <div className="form-group">
        <label><img src={shield} className="shield" /> ID NO / Passport No <span className='red' >*</span></label>
        <input type = "text" className="form-control" name = "id" value={stageThree.id}  onChange={handleStageThree}  />
        <span className="error_holder"  >{stageThree.error_list.id}</span>
        </div>

        <div className="form-group">
        <label> <img src={kra} className="kra_image" /> KRA Pin <span className='red' >*</span></label>
        <input type = "text" className="form-control"  name = "kra" value={stageThree.kra} onChange={handleStageThree} />
        <span className="error_holder"  >{stageThree.error_list.kra}</span>
        </div>
        
        </div>

        </div>
    )

}

export default Stage_Three;