import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

import NewAccount from '../elements/modals/accounts/NewAccount';
import EditAccount from '../elements/modals/accounts/EditAccount';

function Accounts()
{
    const [open,setOpen] = useState(false)
    const [openEdit,setOpenEdit] = useState(false)

    const [accounts,setAccounts] = useState([])
    const effectRun = useRef(false)

    const [details,setDetails] = useState({
    id:'',
    account:'',
    description:'',
    type:'',
    error_list: []
    })

    const handleDetails=(e)=>{
    setDetails({...details, [e.target.name]: e.target.value})
    }

    const onClose=()=>{

    setDetails({
        id:'',
        account:'',
        description:'',
        type:'',
        error_list: []
    })

    setOpen(false)
    setOpenEdit(false)

    }

    const saveAccount=(e)=>{
    e.preventDefault()
    
    const data = {
        account: details.account,
        description: details.description,
        type: details.type
    }

    axios.post(`api/create_account`,data).then((res)=>{
        if(res.data.status === 200)
        {
            onClose()
            fetchAccounts()
            swal("Success",res.data.message,"success")
        }
        else
        {
            setDetails({...details, error_list: res.data.validation_errors})
        }
    })

    }

    const updateAccount=(e)=>{
    
    e.preventDefault()

    const data = {
        id: details.id,
        account: details.account,
        description: details.description,
        type: details.type
    }

    axios.post(`api/update_account`,data).then((res)=>{
        if(res.data.status === 200)
        {
            onClose()
            fetchAccounts()
            swal("Success",res.data.message,"success")
        }
        else
        {
            setDetails({...details, error_list: res.data.validation_errors})
        }
    })

    }

    const editAccount=(index)=>{

        setDetails({
        id: accounts[index].id,
        account: accounts[index].account_name,
        description: accounts[index].description,
        type: accounts[index].type,
        error_list: []
        })

        setOpenEdit(true)
    }

    const fetchAccounts=()=>{
    
    axios.post(`api/accounts`).then((res)=>{
        if(res.data.status === 200)
        {
            setAccounts(res.data.accounts)
        }
    })

    }

    // Use effect to initialize critical data
    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchAccounts()
    }

    return()=>{
    effectRun.current = true
    }

    },[])

    return (

        <div className="wrapper">
        <EditAccount openEdit={openEdit} onClose={onClose} details={details} handleDetails={handleDetails} updateAccount={updateAccount} />
        <NewAccount open={open} onClose={onClose} details={details} handleDetails={handleDetails} saveAccount={saveAccount} />
        <Nav/>
        <Aside/>

        <div className="content-wrapper">
        

        <div className="content">
        <div className="container">

        <div className="row" style={{marginTop:'20px'}} >
        <div className = "col-lg-12" >

        <div className="card card-primary card-outline">
        <div className="card-header">
        <h6 className="card-title">Chart of Accounts <span className='btn btn-primary btn-xs float-right' onClick={()=>setOpen(true)} ><span className='fa fa-plus-circle'></span> New Account</span> </h6>
        </div>
        <div className="card-body">

        <table className="table" >

        <thead>
        <tr className='small-row' >
        <th>#</th>
        <th>Name</th>
        <th>Description</th>
        <th>Type</th>
        <th><span className='fa fa-edit' style={{color:'green'}}></span>  </th>
        <th><span className='fa fa-trash red'></span> </th>
        </tr>
        </thead>

        <tbody>
        {accounts.map((item,index)=>{
        return(
        <tr className='small-row' key={index}>
        <td>{index+1}</td>
        <td>{item.account_name}</td>
        <td>{item.description}</td>
        <td>{item.type}</td>
        <td> <span className='fa fa-edit' style={{color:'green'}} onClick={()=>editAccount(index)}></span> </td>
        <td> <span className='fa fa-trash red'></span> </td>
        </tr>
        )
        })}
        </tbody>

        </table>    
                
        </div>

        <div className='card-footer' >
        
        </div>

        </div>

        </div>
        </div>

        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default Accounts;