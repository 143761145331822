import {Link, NavLink, useNavigate} from 'react-router-dom';

function Cards({title,data,size,icon,stat,page}){

return (<div className={size} >

<div className="info-box">
<span className="info-box-icon bg-primary elevation-1"><i className={icon}></i></span>

<div className="info-box-content">
<span className="info-box-text"> <Link to={page} >{title} <span className="badge badge-success right" >{stat}</span></Link> </span>
<span className="info-box-number">{data}
</span>
</div>
                 
</div>

</div> )

}

export default Cards;