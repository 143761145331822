import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';

import Table from 'react-bootstrap/Table';
import ExpenditureGraph from '../elements/charts/ExpenditureGraph';
import ExpenditureChart from '../elements/charts/ExpenditureChart';
import { useNavigate } from 'react-router-dom';

function RequisitionReport(){

    const effectRun = useRef(false)
    const [requisitions,setRequisitions] = useState([])
    const [year,setYear] = useState(new Date().getFullYear())
    const [months,setMonths] = useState([0,0,0,0,0,0,0,0,0,0,0,0])
    const [segments,setSegments] = useState([])
    const navigate = useNavigate()

    const fetchRequisitions=()=>{
        axios.post(`api/expenditure_report`).then((res)=>{
            if(res.data.status === 200)
            {
                setRequisitions(res.data.requisitions)
            }
        }).catch(error=>{
            if(error == "AxiosError: Request failed with status code 403")
            {
              navigate('/unauthorized');
            }
        })
    }

    const fetchGraph=(year)=>{

        axios.get(`api/annual_expenditure/${year}`).then((res)=>{
            if(res.data.status === 200)
            {
                setMonths(res.data.months)
                setSegments(res.data.segments)
            }
        }).catch(error=>{
            if(error == "AxiosError: Request failed with status code 403")
            {
              navigate('/unauthorized');
            }
        })

    }

    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchRequisitions()
        fetchGraph(year)
    }

    return()=>{
    effectRun.current = true
    }

    })

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>Expenditure Reports</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >
                <div className='row'>
                    <div className='col-md-12'>

                        <div className='card card-primary card-outline'>
                        <div className='card-header'>
                        <h6>Annual Expenditure Overview</h6>
                        </div>
                        <div className='card-body'>
                        <div className='row'>
                       
                        <div className='col-md-8'>
                        <div className='card card-default card-outline'>
                       
                        <div className='card-body'>
                        <div className='form-group'>
                        <select className='form-control'>
                        <option>2023</option>
                        <option>2024</option>
                        </select>
                        </div>
                        <ExpenditureGraph monthlyDisbursal={months}></ExpenditureGraph>
                        </div>
                        </div>
                        </div>

                        <div className='col-md-4'>
                        <div className='card card-default card-outline'>
                        <div className='card-header'>
                        <h6>Expenditure segmentation</h6>
                        </div>
                        <div className='card-body'>
                        <ExpenditureChart segments={segments}></ExpenditureChart>
                        </div>
                        </div>
                        </div>

                        </div>
                        </div>
                        <div className='card-footer'>
                        </div>
                        </div>

                        <div className='card card-primary card-outline'>
                        <div className='card-header'>
                        <h6>
                        All Expenditures
                        <span className='btn btn-dark btn-sm float-right'><span className='fa fa-print'></span> Print Report</span>
                        </h6>
                        </div>

                        <div className='card-body'>
                        <Table striped hover bordered>
                        
                        <thead>
                        <tr className='small-row'>
                        <th>#</th>
                        <th>Date</th>
                        <th>Req #</th>
                        <th>Author</th>
                        <th>Account</th>
                        <th>Description</th>
                        <th>Amount</th>
                        </tr>
                        </thead>

                        <tbody>
                        {requisitions.map((item,index)=>{
                        return(
                        <tr key={index} className='small-row'>
                        <td>{index+1}</td>
                        <td>{item.created_at.substr(0,10)}</td>
                        <td>{item.code}</td>
                        <td>{item.name}</td>
                        <td>{item.account_name}</td>
                        <td>{item.title}</td>
                        <td>KES {parseInt(item.totals).toLocaleString('en-US')}</td>
                        </tr>
                        )
                        })}
                        </tbody>
                            
                        </Table>
                        </div>
                        <div className='card-footer'></div>
                        </div>
                    </div>
                </div>
                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default RequisitionReport;