import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

import Nav from '../nav/Nav';
import Aside from '../nav/Aside';
import ContentHeader from '../nav/ContentHeader';
import ControlSidebar from '../nav/ControlSidebar';
import Footer from '../nav/Footer';
import CardBody from '../elements/cards/CardBody';

import EditTarget from '../elements/modals/kpis/EditTarget';

function User()
{
    const user = useParams().id
    const effectRun = useRef(false)
    const [targets,setTargets] = useState([])
    const [openTarget,setOpenTarget] = useState(false)

    const [details,setDetails] = useState({
        id:'',
        target:'',
        error_list: []
    })

    const updateKpi=(e)=>{

    e.preventDefault()

    const data = {
    id: details.id,
    target: details.target
    }

    axios.post(`api/update_kpi`,data).then((res)=>{

    if(res.data.status === 200)
    {
        closeTarget()
        fetchTargets()
        swal("Success",res.data.message,"success")
    }
    else
    {
        setDetails({...details, error_list:res.data.validation_errors})
    }

    })

    }

    const handleTarget=(e)=>{
    setDetails({...details, [e.target.name]: e.target.value})
    }

    const closeTarget=()=>{
    setOpenTarget(false)
    }

    const initializeTarget=(target_id,target)=>{
    setDetails({...details, id:target_id, target:target})
    setOpenTarget(true)
    }

    const fetchTargets=()=>{
    
    const data = {
        id:user
    }

    axios.post(`api/user_kpis`,data).then((res)=>{
        if(res.data.status === 200)
        {
            setTargets(res.data.targets)
        }
    })

    }

    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchTargets()
    }

    return()=>{
    effectRun.current = true
    }

    })

    return (
        <div className="wrapper">
        
        <EditTarget openTarget={openTarget} closeTarget={closeTarget} target={details.target} handleTarget={handleTarget} updateKpi={updateKpi} />
        <Nav/>
        <Aside/>

        <div className="content-wrapper">

        <ContentHeader title="System Settings" />

        <div className="content">
        <div className="container">
        <div className="row">
            
        <div className="col-lg-3"  >
        
        <div className="card card-widget widget-user-2"  >
        <CardBody/>
        </div>

        </div>

        <div className = "col-lg-9" id = "centralSettings">
        
        <div className="card card-primary card-outline">
        <div className="card-header">
        <span className='float-right' >
        <Link to='/staff'> <span className='btn btn-dark btn-xs'><span className='fa fa-arrow-left'></span> Back</span> </Link>
        </span> <span className='fa fa-user-shield'></span> User Settings
        </div>

        <div className="card-body">
        <div className='row'>

        <div className='col-md-5'>

        <div className='card card-primary card-outline'>
        <div className='card-header'>
        <h6> <span className='fa fa-chart-bar'></span> Kpi Targets</h6>
        </div>
        <div className='card-body'>

        <table className='table'>
            <thead>
                <tr className='small-row' >
                    <th>#</th>
                    <th>Kpi</th>
                    <th>Target</th>
                    <th> <span className='fa fa-edit'></span> </th>
                </tr>
            </thead>
            <tbody>
                {targets.map((item,index)=>{
                return(
                    <tr  className='small-row' key={index} >
                        <td>{index+1}</td>
                        <td>{item.kpi}</td>
                        <td>{item.target}</td>
                        <td><span className='fa fa-edit' onClick={()=>initializeTarget(item.id,item.target)} ></span> </td>
                    </tr>
                )
                })}
            </tbody>
        </table>

        </div>
        <div className='card-footer'>
        </div>
        </div>

        </div>

        </div>
        </div>

        <div className='card-footer' >
        </div>

        </div>

        </div>

        </div>
        </div>
        </div>

        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    );
}

export default User;