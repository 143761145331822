import React, {useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import Modal  from 'react-bootstrap/Modal';

const NewValuation=({newValuationFlag,closeValuation,person,fetchValuations,vehicles,valuers})=>{

const [details,setDetails] = useState({
    created_by: localStorage.getItem('auth_id'),
    customer:person,
    vehicle:'',
    valuer: '',
    cost:'',
    contact:'',
    phone:'',
    notes:'',
    error_list: [],
    })


const handleDetails=(e)=>{
    e.persist()
    setDetails({...details, [e.target.name]: e.target.value})
}



const clearData=()=>{

setDetails({
    created_by: localStorage.getItem('auth_id'),
    customer:person,
    vehicle:'',
    valuer: '',
    cost:'',
    contact:'',
    phone:'',
    notes:'',
    error_list: [],
    })

}

const createValuation=(e)=>{
    e.preventDefault()
    const data = {
    customer:person,
    vehicle: details.vehicle,
    valuer: details.valuer,
    cost: details.cost,
    contact: details.contact,
    phone: details.phone,
    notes: details.notes,
    created_by: details.created_by,
    }

axios.post(`api/create_valuation`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        swal('Success',res.data.message,"success")
        closeValuation()
        clearData()
        fetchValuations()
        
    }else if(res.data.status === 403){
        swal('Error',res.data.message,"error")
    }
    else{
        setDetails({...details, error_list: res.data.validation_errors})
    }

})

}


if(!newValuationFlag) return false;

return(

    <Modal
    show={newValuationFlag}
    onHide={closeValuation}
    backdrop="static"
    keyboard={false}
    size="lg"
    >
    <Modal.Header closeButton>
    <Modal.Title>New Valuation</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    
    <form className='form' onSubmit={createValuation} >
    <div className="row" >

    <div className="form-group col-md-4" >
    <label>Vehicle <span className='red' >*</span></label>
    <select className="form-control" name = "vehicle" value={details.vehicle}  onChange={handleDetails} >
    <option value='' >Select vehicle</option>
    {vehicles.map((car,index)=>{
        return(
        <option value={car.id} key={index} >{car.registration}</option>
        )
    })}
    </select>
    <span className="error_holder"  >{details.error_list.vehicle}</span>
    </div>

    <div className="form-group col-md-4" >
    <label>Valuer <span className='red' >*</span></label>
    <select className="form-control" name = "valuer" value={details.valuer}   onChange={handleDetails} >
    <option value='' >Select valuer</option>
    {valuers.map((valuer,index)=>{
    return(
    <option value={valuer.id} key={index}>{valuer.name}</option>
    )
    })}
    </select>
    <span className="error_holder"  >{details.error_list.valuer}</span>
    </div>

    <div className="form-group col-md-4" >
    <label>Cost <span className='red' >*</span></label>
    <input type="number" name="cost" className="form-control" value={details.cost}   onChange={handleDetails} />
    <span className="error_holder"  >{details.error_list.cost}</span>
    </div>

    <div className="form-group col-md-4" >
    <label>Contact Person <span className='red' >*</span></label>
    <input type="text" name="contact" className="form-control" value={details.contact}   onChange={handleDetails} />
    <span className="error_holder"  >{details.error_list.contact}</span>
    </div>

    <div className="form-group col-md-4" >
    <label>Contact's Phone <span className='red' >*</span></label>
    <input type="text" name="phone" className="form-control" value={details.phone}   onChange={handleDetails} />
    <span className="error_holder"  >{details.error_list.phone}</span>
    </div>

    <div className="form-group col-md-4" >
    <label>Note to Valuer <span className='red' >*</span></label>
    <input type="text" name="notes" className="form-control" value={details.notes}   onChange={handleDetails} />
    <span className="error_holder"  >{details.error_list.notes}</span>
    </div>

    <div className="form-group col-md-4" >
    <input type="submit" className='btn btn-sm btn-primary' value="initiate valuation"></input>
    </div>
        
    </div>
    </form>

    </Modal.Body>
    </Modal>



)

}
export default NewValuation;