function PerfomanceButton({current,label,onClick}){

    if(current === label)
    {
        return ( <span className='btn btn-xs btn-primary' style={{margin:'2px'}} onClick={()=>onClick(label)} ><span className='fa fa-chart-bar' ></span> {label}</span> )
    }
    else
    {
       return ( <span className='btn btn-xs btn-default' style={{margin:'2px'}} onClick={()=>onClick(label)} ><span className='fa fa-chart-bar' ></span> {label}</span> )
    }

}

export default PerfomanceButton;