function PerfomanceCard({mode,items,fetchSummary,fetchDeptSummary})
{
    if(mode)
    {
        return(
            <div className='card-body' >
            <ul className="nav flex-column">
            {items.map((dep,index)=>{
            return (
            <li className="nav-item" key={index} style={{marginBottom:'15px'}} >
            <span style = {{display:'block'}} key={index} onClick={()=>fetchDeptSummary(dep.dept_code,dep.department)} > <span className="fa fa-university" ></span> {dep.department} </span>
            </li>
            )
            })}
            </ul>
            </div>
        )
    }
    else{
        return(
            <div className='card-body' >
             <ul className="nav flex-column">
             {items.map((person,index)=>{
            return (
            <li className="nav-item" key={index} style={{marginBottom:'15px'}} >
            <span style = {{display:'block'}} onClick={()=>fetchSummary(person.id,person.name)} > <span className="fa fa-user-shield" ></span> {person.name} </span>
            </li>
            )
            })}
             </ul>
            
            </div>
        )
    }
}

export default PerfomanceCard;